import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function InfoCardsUsers({ metaData, theme }) {
  const [wallet, setWallet] = useState([]);
  const [totalSubscriptionAmount, setTotalSubscriptionAmount] = useState(0);
  const [totalRechargeAmount, setTotalRechargeAmount] = useState(0); // For recharges
  const userinfo = useSelector((state) => state?.userDetails?.user);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/walletHistory/getByUserId?userId=${userinfo.id}`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        const response = await fetch(url, options);
        const { data } = await response.json();

        // Sort the data by `paymentDate` from latest to earliest
        const walletHistoryData = data.walletHistoryData.sort((a, b) => {
          const dateA = moment(a.paymentDate);
          const dateB = moment(b.paymentDate);
          return dateB - dateA; // Sort descending (latest first)
        });

        setWallet({
          ...data,
          walletHistoryData: walletHistoryData,
        });
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [userinfo]);

  // Calculate total spent on subscriptions
  useEffect(() => {
    if (Array.isArray(wallet.walletHistoryData)) {
      const subscriptionTotal = wallet.walletHistoryData
        .filter((entry) => entry.description === "subscription")
        .reduce((acc, entry) => acc + Number(entry.amount), 0);
      setTotalSubscriptionAmount(subscriptionTotal);

      // Calculate total recharged into wallet
      const rechargeTotal = wallet.walletHistoryData
        .filter((entry) => entry.description === "Recharging wallet")
        .reduce((acc, entry) => acc + Number(entry.amount), 0);
      setTotalRechargeAmount(rechargeTotal);
    } else {
      setTotalSubscriptionAmount(0);
      setTotalRechargeAmount(0);
    }
  }, [wallet]);

  return (
    <div className="mx-auto">
      <div className="row">
        <div className="col-md-6">
          <div className="block block-rounded">
            <div className="block-content block-content-full bg-gd-emerald">
              <div className="fs-lg fw-bold text-white mb-2">Wallet</div>
              <p className="fs-lg fw-bold text-white mt-5 mb-0 d-flex justify-content-between">
                <h3>{metaData?.wallet},00 €</h3>
                {theme?.settingsGeneral?.electronicPayment ? (
                  <button className="btn btn-emerald mb-0">Recharge</button>
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
        </div>

        {userinfo?.role && userinfo?.role === "5" ? (
          <div className="col-md-6">
            <div className="block block-rounded">
              <div className="block-content block-content-full bg-gd-cherry">
                <p className="fs-lg fw-bold text-white mb-0 d-flex justify-content-between">
                  <span>Total Money Spent:</span>
                  <h3>{totalRechargeAmount - metaData?.wallet},00 €</h3>
                </p>
                <div className="fs-lg fw-bold text-white mt-4 mb-0 d-flex justify-content-between">
                  <span>Total Wallet Recharge:</span>
                  <h3>{totalRechargeAmount},00 €</h3>{" "}
                  {/* Display recharge total */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-6">
            <div className="block block-rounded">
              <div className="block-content block-content-full bg-gd-cherry">
                <div className="fs-lg fw-bold text-white mb-2">Balance</div>
                <p className="fs-lg fw-bold text-white mb-0 d-flex justify-content-between">
                  <span>Total Money Spent:</span>
                  <h3>{totalSubscriptionAmount},00 €</h3>
                </p>
                <div className="fs-lg fw-bold text-white mt-4 mb-0 d-flex justify-content-between">
                  <span>Total Wallet Recharge:</span>
                  <h3>{totalRechargeAmount},00 €</h3>{" "}
                  {/* Display recharge total */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default InfoCardsUsers;
