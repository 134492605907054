import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DetailModel from "./DetailModel";

function RightsideTable({ data }) {
  const [selectedId, setSelectedId] = useState(null);

  const handleDelete = async (id) => {
    const userConfirmed = window.confirm("Are you sure you want to delete?");
    if (!userConfirmed) {
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/admin/moneyMovement/deleteById`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ recordId: id }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Money Movement deleted successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(`Failed to delete Money Movement: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error deleting Money Movement: ${error.message}`);
    }
  };

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  function cloneClick(id) {
    const url = `${process.env.REACT_APP_API_URL}/admin/moneyMovement/cloneRecord`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    };
  
    try {
      fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            toast.success("Money Movement cloned successfully");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            toast.error(`Failed to clone Money Movement: ${data.message}`);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Failed to clone Money Movement");
        });
    } catch (error) {
      console.error(error);
      toast.error("Failed to clone Money Movement");
    }
  }
  

  return (
    <div className="content ps-md-0">
      <div className="block block-rounded block-fx-shadow">
        <div className="block block-rounded overflow-hidden">
          <div className="block-content tab-content">
            <div
              className="tab-pane active"
              id="btabs-static-home"
              role="tabpanel"
              aria-labelledby="btabs-static-home-tab"
            >
              <div className="row ">
                <div className="col-lg-12">
                  <h1 className="block-title fs-4 fw-bolder">
                    Money Movements
                  </h1>
                </div>
              </div>

              <div style={{ overflowX: "auto" }}>
                <table
                  id="balanceDetailsDataTable"
                  className="js-table-checkable table table-hover table-vcenter table-responsive"
                >
                  <thead>
                    <tr>
                      <th>DATE OF MOVEMENT</th>
                      <th>MONEY SOURCE</th>
                      <th>MONEY DESTINATION</th>
                      <th>AMOUNT</th>
                      <th>REASON FOR MOVEMENT</th>
                      <th>BALANCE OPERATOR</th>
                      <th>BALANCE REFERENCE</th>
                      <th>PAYMENT VERIFIED</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.moneyMovements?.map((move, index) => (
                      <tr key={index}>
                        <td>{formatDate(move.dateOfMovement)}</td>
                        <td>{move.sourceType}</td>
                        <td>{move.destinationType}</td>
                        <td>{move.amount},00 €</td>
                        <td>{move.reasonForMovement}</td>
                        <td>
                          {move?.balanceOperator?.firstName +
                            " " +
                            move.balanceOperator?.lastName}
                        </td>
                        <td>{move?.balanceReference?.balanceName}</td>
                        <td>
                          <span
                            className={`badge ${
                              move.paymentVerified ? "bg-success" : "bg-danger"
                            } rounded`}
                          >
                            {move.paymentVerified
                              ? "Verified"
                              : "Waiting verification"}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex">
                            <Link
                              to={`/money-movements/${move.id}`}
                              className="btn btn-alt-primary btn-sm rounded-0 mx-1"
                            >
                              Details
                            </Link>
                            <button
                              className="btn btn-alt-primary btn-sm rounded-0 mx-1"
                              onClick={() => handleDelete(move.id)}
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                            <button
                              className="btn btn-alt-primary btn-sm rounded-0 mx-1"
                              onClick={() => cloneClick(move.id)}
                            >
                              <i className="fa fa-clone"></i>
                            </button>
                            <button
                              className="btn btn-alt-primary btn-sm rounded-0 mx-1"
                              data-bs-toggle="modal"
                              data-bs-target="#modal-largeDetail"
                              onClick={() => setSelectedId(move.id)}
                            >
                              Move
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DetailModel id={selectedId} />
    </div>
  );
}

export default RightsideTable;
