import React, { useState } from "react";
import { useSelector } from "react-redux";
function PersonalInfo({ formData, onChange }) {
  const tshirt = useSelector((state) => state.settings.settings);
  const [inputType, setInputType] = useState("text");
  const tShirtSizes = tshirt?.settingsGeneral?.tShirt
    .split(";")
    .map((size) => size.trim())
    .filter((size) => size !== "");

  const handleFocus = () => {
    setInputType("date");
  };

  const handleBlur = (event) => {
    if (!event.target.value) {
      setInputType("text");
    }
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-4 search ">
            <div className="bg-dark">
              <i className="far fa-user pe-2 icon text-center"></i>
            </div>
            <input
              type="text"
              className="form-control form-control-alt rounded"
              name="firstName"
              required
              placeholder="Nome di battesimo*"
              style={{ paddingLeft: "35px" }}
              value={formData.firstName}
              onChange={onChange}
              data-step="1"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="search mb-4">
            <i className="far fa-user  pe-2 icon text-center"></i>
            <input
              type="text"
              className="form-control form-control-alt rounded"
              name="lastName"
              required
              placeholder="Cognome*"
              style={{ paddingLeft: "35px" }}
              value={formData.lastName}
              onChange={onChange}
              data-step="1"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="search mb-4">
            <i
              className="far fa-envelope icon
              pe-2 text-center"
            ></i>
            <input
              type="text"
              className="form-control form-control-alt rounded"
              name="email"
              required
              placeholder="Email*"
              style={{ paddingLeft: "35px" }}
              value={formData.email}
              onChange={onChange}
              data-step="1"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="search mb-4">
            <i className="fa fa-calendar-day pe-2 icon text-center"></i>
            <input
              type={inputType}
              className="form-control form-control-alt rounded  "
              name="birthday"
              required
              placeholder="Data di nascita*"
              style={{
                paddingLeft: inputType === "date" ? "12px" : "35px",
              }}
              value={formData.birthday}
              onChange={onChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              data-step="1"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="search mb-4">
            <i className="fa  fa-location-dot pe-2 icon text-center"></i>
            <input
              type="text"
              className="form-control form-control-alt rounded"
              name="birthPlace"
              required
              placeholder="Luogo di nascita*"
              style={{ paddingLeft: "35px" }}
              value={formData.birthPlace}
              onChange={onChange}
              data-step="1"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="search mb-4">
            <i className="si si-globe pe-2 icon text-center"></i>
            <input
              type="text"
              className="form-control form-control-alt rounded"
              name="nationality"
              required
              placeholder="Nazionalità*"
              style={{ paddingLeft: "35px" }}
              value={formData.nationality}
              onChange={onChange}
              data-step="1"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="search mb-4">
            <i className="si si-symbol-male pe-2 icon text-center"></i>
            <select
              className=" form-control form-control-alt rounded form-select form-select-lg mb-4 py-3  "
              aria-label=".form-select-lg example"
              style={{ paddingLeft: "60px" }}
              value={formData.sex}
              onChange={onChange}
              name="sex"
              required
              data-step="1"
            >
              <option disabled value="" selected>
                Scegli Sesso*
              </option>
              <option value="male"> MASCHIO</option>
              <option value="female">FEMMINA</option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="search mb-4">
            <i
              style={{ padding: "7px" }}
              className="fa  fa-shirt  icon text-center"
            ></i>
            <select
              className=" form-control form-control-alt rounded form-select form-select-lg mb-4 py-3  "
              aria-label=".form-select-lg example"
              style={{ paddingLeft: "60px" }}
              name="shirtSize"
              required
              value={formData.shirtSize}
              onChange={onChange}
              data-step="1"
            >
              <option disabled value="" selected>
                Taglia della maglietta*
              </option>
              {tShirtSizes?.map((size, index) => (
                <option key={index} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-12">
          <div className="search mb-4">
            <i className="fa fa-envelope-open-text pe-2 icon text-center"></i>
            <input
              type="text"
              className="form-control form-control-alt rounded"
              name="fiscalCode"
              placeholder="Codice fiscale"
              style={{ paddingLeft: "35px" }}
              value={formData.fiscalCode}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
