import React, { useEffect, useState } from "react";
import TableGroup from "./TableGroup";
import TableUser from "./TableUser";
import Loader from "../Common/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Main() {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [userFilters, setUserFilters] = useState({
    age: "",
    eventName: "",
    groupName: "",
    name: "",
  });
  const [groupFilters, setGroupFilters] = useState({
    groupName: "",
    eventName: "",
    tag: "",
  });
  const [userTableKey, setUserTableKey] = useState(0);
  const [groupTableKey, setGroupTableKey] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [userFilterType, setUserFilterType] = useState("");
  const [groupFilterType, setGroupFilterType] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/user/user/getAllWithEventAndGroup`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.status === "success") {
          setUsers(result.data.users);
          setFilteredUsers(result.data.users);
        } else {
          console.error("Error:", result.message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching users:", error);
      }
    };

    if (step === 1) fetchUsers();
  }, [step]);

  useEffect(() => {
    const fetchGroups = async () => {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/admin/group/getAll`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.status === "success") {
          setGroups(result.data);
          setFilteredGroups(result.data);
        } else {
          console.error("Error:", result.message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching groups:", error);
      }
    };

    fetchGroups();
  }, [step]);

  const handleUserSelection = (userId) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(userId)
        ? prevSelectedUsers.filter((id) => id !== userId)
        : [...prevSelectedUsers, userId]
    );
  };

  const handleGroupSelection = (groupId) => {
    setSelectedGroups((prevSelectedGroups) =>
      prevSelectedGroups.includes(groupId)
        ? prevSelectedGroups.filter((id) => id !== groupId)
        : [...prevSelectedGroups, groupId]
    );
  };

  const handleNextStep = () => {
    if (step === 1 && selectedUsers.length > 0) {
      setStep(2);
    }
  };

  const handlePreviousStep = () => {
    if (step === 2) {
      setStep(1);
    }
  };

  const clearUserFilters = () => {
    setUserFilters({
      age: "",
      eventName: "",
      groupName: "",
      name: "",
    });
    setUserTableKey((prevKey) => prevKey + 1);
    setFilteredUsers(users);
  };

  const clearGroupFilters = () => {
    setGroupFilters({
      groupName: "",
      eventName: "",
      tag: "",
    });
    setGroupTableKey((prevKey) => prevKey + 1);
    setFilteredGroups(groups);
  };

  const applyUserFilters = () => {
    let data = users;

    if (userFilters.age) {
      const ages = userFilters.age
        .split(",")
        .map((age) => parseInt(age.trim()));
      data = data.filter((user) => ages.includes(calculateAge(user.birthday)));
    }

    if (userFilters.eventName) {
      const eventNames = userFilters.eventName
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      data = data.filter((user) =>
        user.subscribedEvents.some(
          (event) =>
            event &&
            eventNames.some((name) =>
              event.eventName.toLowerCase().includes(name)
            )
        )
      );
    }

    if (userFilters.groupName) {
      const groupNames = userFilters.groupName
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      data = data.filter((user) =>
        user.groupsJoined.some(
          (group) =>
            group &&
            groupNames.some((name) =>
              group.groupName.toLowerCase().includes(name)
            )
        )
      );
    }

    if (userFilters.name) {
      const names = userFilters.name
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      data = data.filter(
        (user) =>
          names.some((name) => user.firstName.toLowerCase().includes(name)) ||
          names.some((name) => user.lastName.toLowerCase().includes(name))
      );
    }

    setFilteredUsers(data);
    setUserTableKey((prevKey) => prevKey + 1);
    setUserFilterType("filter");
  };

  const applyUserReverseFilters = () => {
    let data = users;

    if (userFilters.age) {
      const ages = userFilters.age
        .split(",")
        .map((age) => parseInt(age.trim()));
      data = data.filter((user) => !ages.includes(calculateAge(user.birthday)));
    }

    if (userFilters.eventName) {
      const eventNames = userFilters.eventName
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      data = data.filter(
        (user) =>
          !user.subscribedEvents.some(
            (event) =>
              event &&
              eventNames.some((name) =>
                event.eventName.toLowerCase().includes(name)
              )
          )
      );
    }

    if (userFilters.groupName) {
      const groupNames = userFilters.groupName
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      data = data.filter(
        (user) =>
          !user.groupsJoined.some(
            (group) =>
              group &&
              groupNames.some((name) =>
                group.groupName.toLowerCase().includes(name)
              )
          )
      );
    }

    if (userFilters.name) {
      const names = userFilters.name
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      data = data.filter(
        (user) =>
          !names.some((name) => user.firstName.toLowerCase().includes(name)) &&
          !names.some((name) => user.lastName.toLowerCase().includes(name))
      );
    }

    setFilteredUsers(data);
    setUserTableKey((prevKey) => prevKey + 1);
    setUserFilterType("reverseFilter");
  };

  const applyGroupFilters = () => {
    let filteredData = groups;

    if (groupFilters.groupName) {
      const groupNames = groupFilters.groupName
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      filteredData = filteredData.filter((item) =>
        groupNames.some((name) => item.groupName.toLowerCase().includes(name))
      );
    }

    if (groupFilters.eventName) {
      const eventNames = groupFilters.eventName
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      filteredData = filteredData.filter((item) =>
        eventNames.some((name) =>
          item.linkedEvent.eventName.toLowerCase().includes(name)
        )
      );
    }

    if (groupFilters.tag) {
      const tags = groupFilters.tag
        .toLowerCase()
        .split(",")
        .map((tag) => tag.trim());
      filteredData = filteredData.filter((item) =>
        tags.some((tag) => item.labelName.toLowerCase().includes(tag))
      );
    }

    setFilteredGroups(filteredData);
    setGroupTableKey((prevKey) => prevKey + 1);
    setGroupFilterType("filter");
  };

  const applyGroupReverseFilters = () => {
    let filteredData = groups;

    if (groupFilters.groupName) {
      const groupNames = groupFilters.groupName
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      filteredData = filteredData.filter(
        (item) =>
          !groupNames.some((name) =>
            item.groupName.toLowerCase().includes(name)
          )
      );
    }

    if (groupFilters.eventName) {
      const eventNames = groupFilters.eventName
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      filteredData = filteredData.filter(
        (item) =>
          !eventNames.some((name) =>
            item.linkedEvent.eventName.toLowerCase().includes(name)
          )
      );
    }

    if (groupFilters.tag) {
      const tags = groupFilters.tag
        .toLowerCase()
        .split(",")
        .map((tag) => tag.trim());
      filteredData = filteredData.filter(
        (item) =>
          !tags.some((tag) => item.labelName.toLowerCase().includes(tag))
      );
    }

    setFilteredGroups(filteredData);
    setGroupTableKey((prevKey) => prevKey + 1);
    setGroupFilterType("reverseFilter");
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const ageDiff = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const distributeUsers = async (distributionType) => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/admin/group/distributeUsersToGroups`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userIds: selectedUsers,
        groupIds: selectedGroups,
        distributionType,
      }),
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      if (result.status === "success") {
        toast.success("Users distributed successfully");
        navigate("/group-coaches");
      } else {
        toast.error("Something went wrong.Please try again");
        console.error("Error:", result.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong.Please try again");
      console.error("Error distributing users:", error);
    }
  };

  if (loading || users.length === 0 || groups.length === 0) return <Loader />;

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className="fa fa-users me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">
                Groups/Distribution
                {userFilterType && (
                  <span
                    className={`${
                      userFilterType === "filter"
                        ? "bg-primary"
                        : "bg-secondary"
                    } badge ms-2`}
                    style={{ fontSize: "10px" }}
                  >
                    {userFilterType === "filter"
                      ? "Filter Applied"
                      : "Reverse Filter Applied"}
                  </span>
                )}
                {groupFilterType && (
                  <span
                    className={`${
                      groupFilterType === "filter"
                        ? "bg-primary"
                        : "bg-secondary"
                    } badge ms-2`}
                    style={{ fontSize: "10px" }}
                  >
                    {groupFilterType === "filter"
                      ? "Filter Applied"
                      : "Reverse Filter Applied"}
                  </span>
                )}
              </h1>
            </div>
            {step === 1 && (
              <div>
                <TableUser
                  key={userTableKey}
                  users={filteredUsers}
                  selectedUsers={selectedUsers}
                  handleUserSelection={handleUserSelection}
                  filters={userFilters}
                  setFilters={setUserFilters}
                  clearFilters={clearUserFilters}
                  applyFilters={applyUserFilters}
                  applyReverseFilters={applyUserReverseFilters}
                />
                <div className="row py-4 px-5">
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        handleNextStep();
                        clearUserFilters();
                        clearGroupFilters();
                      }}
                      disabled={selectedUsers.length === 0}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}
            {step === 2 && (
              <div>
                <TableGroup
                  key={groupTableKey}
                  group={filteredGroups}
                  selectedGroups={selectedGroups}
                  handleGroupSelection={handleGroupSelection}
                  filters={groupFilters}
                  setFilters={setGroupFilters}
                  clearFilters={clearGroupFilters}
                  applyFilters={applyGroupFilters}
                  applyReverseFilters={applyGroupReverseFilters}
                />
                <div className="row py-4 px-5">
                  <div className="col-12 d-flex justify-content-end gap-2">
                    <button
                      className="btn btn-secondary mt-3 me-2"
                      onClick={() => {
                        handlePreviousStep();
                        clearUserFilters();
                        clearGroupFilters();
                      }}
                    >
                      Previous
                    </button>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => distributeUsers("random")}
                      disabled={selectedGroups.length === 0}
                    >
                      Distribute Randomly
                    </button>
                    <button
                      className="btn btn-success mt-3"
                      onClick={() => distributeUsers("sequence")}
                      disabled={selectedGroups.length === 0}
                    >
                      Distribute By Age Sequence
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Main;
