import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateSuppliers() {
  const { id } = useParams();
  const [suppliers, setSuppliers] = useState({});

  useEffect(() => {
    async function fetchData() {
      const url = `${process.env.REACT_APP_API_URL}/admin/supplier/getById/${id}`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.status === "success") {
          setSuppliers(data.data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
    // eslint-disable-next-line
  }, [id]);

  const onChange = (e) => {
    setSuppliers({ ...suppliers, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}/admin/supplier/updateRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(suppliers),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Supplier Updated successfully!");
      } else {
        toast.error("Unable to update Supplier");
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className=" fa fa-calendar me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">Update Supplier</h1>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="block block-rounded">
                  <div className="container">
                    <div className="block-content ">
                      <form>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4 mt-3">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Type Of Business
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="typeOfBusiness"
                                onChange={onChange}
                                value={suppliers.typeOfBusiness}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4 mt-md-3">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Name Of Business
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="businessName"
                                onChange={onChange}
                                value={suppliers.businessName}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-4 mt-md-3">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Address
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="address"
                                onChange={onChange}
                                value={suppliers.address}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-tel-input"
                              >
                                Phone Number 1
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-lg"
                                id="example-tel-input"
                                name="phone1"
                                onChange={onChange}
                                value={suppliers.phone1}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-tel-input"
                              >
                                Phone Number 2
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-lg"
                                id="example-tel-input"
                                name="phone2"
                                onChange={onChange}
                                value={suppliers.phone2}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-email-input"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                className="form-control form-control-lg"
                                id="example-email-input"
                                name="email"
                                onChange={onChange}
                                value={suppliers.email}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-email-input"
                              >
                                Certified Email
                              </label>
                              <input
                                type="email"
                                className="form-control form-control-lg"
                                id="example-email-input"
                                name="emailCertified"
                                onChange={onChange}
                                value={suppliers.emailCertified}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-tel-input"
                              >
                                VAT Number
                              </label>
                              <input
                                type="tel"
                                className="form-control form-control-lg"
                                id="example-tel-input"
                                name="vatNumber"
                                onChange={onChange}
                                value={suppliers.vatNumber}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Fiscal Code
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="fiscalCode"
                                onChange={onChange}
                                value={suppliers.fiscalCode}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                SWIFT/BIC Code
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="bicCode"
                                onChange={onChange}
                                value={suppliers.bicCode}
                              />
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                IBAN
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="iban"
                                onChange={onChange}
                                value={suppliers.iban}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Bank Name
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="bankName"
                                onChange={onChange}
                                value={suppliers.bankName}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Country of the Bank
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="bankCountry"
                                onChange={onChange}
                                value={suppliers.bankCountry}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-md-4 ">
                              <label
                                className="form-label"
                                for="example-textarea-input"
                              >
                                Account Number
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="accountNumber"
                                onChange={onChange}
                                value={suppliers.accountNumber}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-md-4 ">
                              <label
                                className="form-label"
                                for="example-textarea-input"
                              >
                                Description / Notes
                              </label>
                              <textarea
                                className="form-control form-control-lg"
                                id="example-textarea-input"
                                name="description"
                                value={suppliers.description}
                                onChange={onChange}
                                rows="4"
                              >
                                {suppliers.description}
                              </textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="block-content block-content-full block-content-sm d-flex justify-content-between">
                            <Link
                              to={"/suppliers"}
                              className=" btn btn-lg btn-secondary py-2 px-4"
                            >
                              Back
                            </Link>
                            <button
                              type="submit"
                              className=" btn btn-lg btn-success py-2 px-4"
                              onClick={handleSubmit}
                            >
                              Update Supplier
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default UpdateSuppliers;
