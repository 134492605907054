import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Forget() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const url = `${process.env.REACT_APP_API_URL}/user/user/forgotPassword`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ email }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        localStorage.setItem("otp", data.data.otp);
        localStorage.setItem("email", email);
        navigate("/verify-otp");
      } else {
        toast.error("Failed to send OTP, please try again!");
      }
    } catch (error) {
      console.error("Failed to send email:", error);
      alert("Error sending email. Please check your connection and try again.");
    }
  };

  return (
    <div id="page-container" className="main-content-boxed">
      <main id="main-container">
        <div className="bg-body-dark">
          <div className="row mx-0 justify-content-center">
            <div className="hero-static col-lg-8 col-xl-5 d-flex justify-content-center align-items-center">
              <div className="content content-full overflow-hidden">
                <form className="js-validation-signin" onSubmit={handleSubmit}>
                  <div className="block block-themed block-rounded block-fx-shadow">
                    <div className="text-center py-4">
                      <img src="./assets/media/logo.png" alt="" width={"30%"} />
                    </div>
                    <div className="block-content">
                      <h3 className="text-black">Hai dimenticato la password?</h3>
                      <div className="search mb-4">
                        <i className="far fa-envelope icon pe-2"></i>
                        <input
                          type="email"
                          className="form-control form-control-alt rounded"
                          name="email"
                          placeholder="Inserisci la tua email"
                          style={{ paddingLeft: "35px" }}
                          value={email}
                          onChange={handleEmailChange}
                        />
                      </div>

                      <div className="row">
                        <div className="col-sm-12 text-sm-end push">
                          <button
                            type="submit"
                            className="btn rounded btn-lg btn-alt-primary fw-bold fs-5 w-100"
                          >
                            Resetta la password
                          </button>
                        </div>
                      </div>
                      <div className="text-center mb-4 fw-bold">
                        <Link to={"/"}>
                          Ritorno a <span className="text-primary">Login</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Forget;
