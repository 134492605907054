import React, { useEffect, useState } from "react";
import Navbar from "../components/Common/Navbar";
import Header from "../components/Common/Header";
import EventtableForm from "../components/events/EventtableForm";
import $ from "jquery";
import "datatables.net-bs5";
import { useSelector } from "react-redux";

function EventTable() {
  const [events, setEvents] = useState([]);
  const userinfo = useSelector((state) => state?.userDetails?.user);

  const [ fetching, setFetching ] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const fields = [
        "eventName",
        "startDate",
        "endDate",
        "eventImages",
        "subscriptionState",
        "tag",
        "subscriptionStartDate",
        "personalizedRoles",
        "subscriptions",
        "maxSubscribers",
        "authorId",
      ];
      setFetching(true)
      const url = `${
        process.env.REACT_APP_API_URL
      }/user/event/getAllEvents?userId=${userinfo?.id}&fields=${fields.join(
        ","
      )}`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.status === "success") {
          setEvents(result.data);
        } else {
          console.error("Error:", result.message);
        }
        setFetching(false);
      } catch (error) {
        setFetching(false)
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [userinfo]);

  useEffect(() => {
    if (events.length > 0) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const table = $("#eventDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
        pageLength: 4,
        lengthMenu: [4, 10, 20, 50],
        sort: false,
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        table.destroy();
      };
    }
  }, [events]);

  return (
    <div
      id="page-container"
      className="sidebar-o enable-page-overlay side-scroll page-header-modern main-content-boxed"
    >
      <Navbar />
      <Header />
      <EventtableForm events={events} fetching={fetching} />
    </div>
  );
}

export default EventTable;
