import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader";
import EventShowCard from "./EventShowCard";
import Pagination from "../Common/Pagination";
import { useSelector } from "react-redux";

function Event5() {
  const [events, setEvents] = useState([]);
  const userinfo = useSelector((state) => state?.userDetails?.user);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 6;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const fields = [
          "eventName",
          "startDate",
          "endDate",
          "eventImages",
          "subscriptionState",
          "tag",
        ];
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/event/getAll?userId=${
            userinfo?.id
          }&fields=${fields.join(",")}`
        );
        const result = await response.json();
        if (result.status === "success" && Array.isArray(result.data)) {
          setEvents(result.data);
        } else {
          console.error("Error:", result.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchEvents();
  }, [userinfo]);

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);
  const totalPages = Math.ceil(events.length / eventsPerPage);

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className="fa fa-calendar me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">Events</h1>
              {userinfo.role !== "5" && (
                <Link to="/event/view">
                  <i className="fa fa-1x fa-eye"></i>
                </Link>
              )}
            </div>
            <div className="row">
              {Array.isArray(currentEvents) && currentEvents.length > 0 ? (
                currentEvents.map((event) => (
                  <EventShowCard key={event.id} event={event} />
                ))
              ) : (
                <div className="col-md-12">
                  <Loader />
                </div>
              )}
            </div>
            <div className="text-center">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Event5;
