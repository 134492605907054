import React from "react";
function Groups({ userData }) {
  return (
    <div className="block block-rounded">
      <div className="col-lg-12">
        <h1 className="block-title fs-4 fw-bolder mb-3">Groups</h1>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table className="js-table-checkable table table-hover table-vcenter">
          <thead>
            <tr>
              <th>Name</th>
              <th>EVENT Linked</th>
              <th>Tag</th>
            </tr>
          </thead>
          <tbody>
          {userData?.data?.groupsJoined?.map((group, index) => (
            <tr>
              <td>{group?.groupName}</td>
              <td>{group?.linkedEvent?.eventName}</td>
              <td>
                <span
                  style={{
                    backgroundColor: group?.labelColor,
                  }}
                  className="badge rounded"
                >
                  {group?.labelName}
                </span>
              </td>
            </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Groups;
