import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function TermsModel() {
  const navigate = useNavigate();
  const terms = useSelector((state) => state.settings.settings);
  const logoUrl = terms.settingsTheme?.logo;
  const [checkboxes, setCheckboxes] = useState({
    joinService: false,
    agreeRegulation: false,
    consentProcessing: false,
  });
  const handleClick = () => {
    navigate("/signup");
  };

  const handleCheckboxChange = (checkboxName) => {
    setCheckboxes({
      ...checkboxes,
      [checkboxName]: !checkboxes[checkboxName],
    });
  };

  const allCheckboxesChecked = () => {
    return Object.values(checkboxes).every((value) => value);
  };
  return (
    <div
      className="modal"
      id="modal-large"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title">Termini e Condizioni</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="my-3">
              <div className="container">
                <div className="text-center">
                  <img width={150} src={logoUrl} alt="logo" />
                  <h2 className="mt-5 fw-bold text-black">
                  {terms?.settingsGeneral?.institutionName}
                  </h2>
                </div>
                <div className="fw-semibold mt-5 text-black">
                  <h5>{terms?.settingsGeneral?.address}</h5>
                  <h5>Telefono: {terms?.settingsGeneral?.telephone}</h5>
                  <h5>Mobile: {terms?.settingsGeneral?.mobile}</h5>
                  <h5>{terms?.settingsGeneral?.email}</h5>
                </div>
                <h6 className="fw-semibold mt-5 text-black">
                {terms?.settingsGeneral?.message}
                </h6>
                <div className="mt-5 d-flex flex-column">
                  <div>
                    <input
                      className="me-3 form-check-input"
                      style={{ width: "15px", height: "15px" }}
                      type="checkbox"
                      name="joinService"
                      id="joinService"
                      checked={checkboxes.joinService}
                      onChange={() => handleCheckboxChange("joinService")}
                    />
                    <label htmlFor="joinService">
                      <u>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={terms?.settingsRegistration?.joiningService}
                        >
                          Adesione al servizio
                        </a>
                      </u>
                    </label>
                  </div>
                  <div>
                    <input
                      className="me-3 form-check-input"
                      style={{ width: "15px", height: "15px" }}
                      type="checkbox"
                      name="agreeRegulation"
                      id="agreeRegulation"
                      checked={checkboxes.agreeRegulation}
                      onChange={() => handleCheckboxChange("agreeRegulation")}
                    />
                    <label htmlFor="agreeRegulation">
                      <u>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={terms?.settingsRegistration?.regulation}
                        >
                          Accetto il regolamento
                        </a>
                      </u>
                    </label>
                  </div>
                  <div>
                    <input
                      className="me-3 form-check-input"
                      style={{ width: "15px", height: "15px" }}
                      type="checkbox"
                      name="consentProcessing"
                      id="consentProcessing"
                      checked={checkboxes.consentProcessing}
                      onChange={() => handleCheckboxChange("consentProcessing")}
                    />
                    <label htmlFor="consentProcessing">
                      <u>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            terms?.settingsRegistration?.personalDataProcessing
                          }
                        >
                          Consenso al trattamento dei dati personali
                        </a>
                      </u>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="block-content block-content-full block-content-sm text-end">
              <button
                type="button"
                className="btn btn-alt-primary px-4"
                data-bs-dismiss="modal"
                onClick={handleClick}
                disabled={!allCheckboxesChecked()}
              >
                Continua
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsModel;
