import React, { useEffect, useState } from "react";
import InfoCards from "./InfoCards";
import UserDetails from "./UserDetails";
import { useSelector } from "react-redux";
import DetailsUsers from "./DetailsUsers";
import InfoCardsUsers from "./InfoCardsUsers";

function CardMain() {
  const userinfo = useSelector((state) => state?.userDetails?.user);
  const theme = useSelector((state) => state.settings.settings);

  const [metaData, setMetaData] = useState({});

  useEffect(() => {
    const fetchAdminData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/admin/metaInfo`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.status === "success") {
          setMetaData(data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const fetchUserData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/user/metaInfo?userId=${userinfo?.id}`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.status === "success") {
          setMetaData(data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (
      userinfo?.role === "3" ||
      userinfo?.role === "4" ||
      userinfo?.role === "5"
    ) {
      fetchUserData();
    } else {
      fetchAdminData();
    }
  }, [userinfo]);

  return (
    <div id="page-container" className="container">
      <main id="main-container" className="my-5 mx-3">
        <div className=" row block block-themed block-rounded">
          <div className="text-black mt-5 ms-3 mb-3">
            <h3>Hi, {`${userinfo?.firstName}\u00A0${userinfo?.lastName}`}</h3>
            <h6>Welcome to {theme?.settingsGeneral?.institutionName}</h6>
          </div>
          {userinfo?.role === "0" ? <InfoCards metaData={metaData} /> : ""}
          {userinfo?.role === "0" ? <UserDetails metaData={metaData} /> : ""}
          {userinfo?.role === "3" ||
          userinfo?.role === "4" ||
          userinfo?.role === "5" ? (
            <InfoCardsUsers metaData={metaData} theme={theme} />
          ) : (
            ""
          )}
          {userinfo?.role === "3" ||
          userinfo?.role === "4" ||
          userinfo?.role === "5" ? (
            <DetailsUsers metaData={metaData} theme={theme} />
          ) : (
            ""
          )}
        </div>
      </main>
    </div>
  );
}

export default CardMain;
