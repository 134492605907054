import React from "react";
import { FlexiPasswordChecklist } from "react-flexi-password-checklist";

function ChoosePassword({ formData, onChange }) {
  return (
    <div className="vh-50">
      <div className="row">
        <div className="col-md-6">
          <div className="search mb-4">
            <i className="fa fa-lock pe-2 icon text-center"></i>
            <input
              type="password"
              className="form-control form-control-alt rounded"
              id="createpassword"
              name="password"
              placeholder="Crea password*"
              style={{ paddingLeft: "35px" }}
              value={formData.password}
              onChange={onChange}
              required
              data-step="4"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="search mb-4">
            <i className="fa fa-lock pe-2 icon text-center"></i>
            <input
              type="password"
              className="form-control form-control-alt rounded"
              id="confirmpassword"
              name="confirmPassword"
              placeholder="Conferma password*"
              style={{ paddingLeft: "35px" }}
              value={formData.confirmPassword}
              onChange={onChange}
              required
              data-step="4"
            />
          </div>
        </div>
      </div>
      <FlexiPasswordChecklist
        password={formData.password || ""}
        confirmPassword={formData.confirmPassword || ""}
        config={{
          minLength: 8,
          conditionTexts: {
            lengthMatchText: "La lunghezza minima dovrebbe essere 8.",
            matchPasswordsText: "Entrambe le password devono corrispondere.",
            specialCharText : "Utilizza almeno uno dei seguenti caratteri speciali: ! @ # * _ | . ?",
            includesUsernameText:
              "Evita di utilizzare il nome utente nella password.",
            upperCaseAndNumericText: {
              upperCaseText:
                "Si prega di utilizzare almeno 2 caratteri maiuscoli.",
              numericText: "Si prega di includere almeno 2 numeri.",
            },
            symbolsText: "Si prega di includere almeno 1 simbolo.",
            lowerCaseText:
              "Si prega di utilizzare almeno 2 caratteri minuscoli.",
          },
          matchPasswords: true,
          upperCaseMinCount: 2,
          numericMinCount: 2,
          symbolMinCount: 1,
          lowerCaseMinCount: 2,
        }}
      />
    </div>
  );
}

export default ChoosePassword;
