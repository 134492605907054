import moment from "moment";
import React, { useState } from "react";
import WalletModel from "./WalletModel";
import ViewModel from "../wallet/ViewModel";
import ReceiptModel from "../wallet/ReceiptModel";

function Wallet({ userData }) {
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [receiptData, setReceiptData] = useState([]);
  const [checkedRows, setCheckedRows] = useState(new Set());

  const handleViewClick = (wal, modalId) => {
    setSelectedWallet(wal);
    setTimeout(() => {
      const modal = new window.bootstrap.Modal(
        document.getElementById(modalId)
      );
      modal.show();
    }, 0);
  };

  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      const allItems = userData?.data?.walletHistory?.map((wal) => ({
        paymentType: wal?.paymentType,
        description: wal?.description,
        eventName: wal?.eventId?.eventName,
        amount: wal.amount,
      }));
      setReceiptData(allItems);
      const allCheckedRows = new Set(
        userData?.data?.walletHistory?.map((_, index) => index)
      );
      setCheckedRows(allCheckedRows);
    } else {
      setReceiptData([]);
      setCheckedRows(new Set());
    }
  };

  const handleRowCheckboxChange = (event, wal, index) => {
    if (event.target.checked) {
      setReceiptData((prevItems) => [
        ...prevItems,
        {
          paymentType: wal?.paymentType,
          description: wal?.description,
          eventName: wal?.eventId?.eventName,
          amount: wal.amount,
        },
      ]);
      setCheckedRows((prev) => new Set(prev).add(index));
    } else {
      setReceiptData((prevItems) =>
        prevItems.filter(
          (item) =>
            item.paymentType !== wal.paymentType || item.amount !== wal.amount
        )
      );
      setCheckedRows((prev) => {
        const newSet = new Set(prev);
        newSet.delete(index);
        return newSet;
      });
    }
  };

  const handleClearAllChecks = () => {
    setReceiptData([]);
    setCheckedRows(new Set());
  };

  // Sort wallet history by paymentDate in descending order (latest first)
  const sortedWalletHistory = userData?.data?.walletHistory?.sort((a, b) =>
    moment(b.paymentDate).diff(moment(a.paymentDate))
  );

  return (
    <div className="block block-rounded">
      <div className="col-lg-12">
        <h1 className="block-title fs-4 fw-bolder mb-3">Wallet</h1>
      </div>
      <div className="col-12 d-flex justify-content-end">
        <button
          className="btn btn-primary me-2"
          data-bs-toggle="modal"
          data-bs-target="#modal-large10"
          disabled={receiptData.length === 0}
        >
          Generate Receipt
        </button>
        {checkedRows.size > 0 && (
          <button
            className="btn btn-secondary me-2"
            onClick={handleClearAllChecks}
          >
            Clear Checked Items
          </button>
        )}
        <button
          data-bs-toggle="modal"
          data-bs-target="#modal-wallet"
          className="btn btn-primary"
        >
          Recharge wallet
        </button>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table
          id="userWalletHistoryDataTable"
          className="js-table-checkable table table-hover table-vcenter table-responsive"
        >
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleHeaderCheckboxChange}
                  checked={
                    checkedRows.size === sortedWalletHistory?.length
                  }
                />
              </th>
              <th>EVENT NAME</th>
              <th>AMOUNT OF MONEY</th>
              <th>DATE OF PAYMENT</th>
              <th>REASON OF PAYMENT</th>
              <th>STATE</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {sortedWalletHistory?.map((wallet, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    onChange={(event) =>
                      handleRowCheckboxChange(event, wallet, index)
                    }
                    checked={checkedRows.has(index)}
                  />
                </td>
                <td>{wallet?.eventId?.eventName}</td>
                <td>{wallet?.amount},00 €</td>
                <td>{moment(wallet?.paymentDate).format("DD/MM/YYYY")}</td>
                <td>{wallet?.description}</td>
                <td>
                  <span
                    className={`badge ${
                      wallet?.state === "paid" ? "bg-success" : "bg-danger"
                    }`}
                  >
                    {wallet?.state}
                  </span>
                </td>
                <td>
                  <button
                    className="badge p-2 me-2 my-1"
                    onClick={() => handleViewClick(wallet, "modal-largeR")}
                    style={{
                      backgroundColor: " #FBC054",
                      border: "2px solid #F28C28",
                    }}
                  >
                    View
                  </button>
                  {wallet?.state === "paid" ? (
                    ""
                  ) : (
                    <button
                      className="badge p-2"
                      onClick={() => handleViewClick(wallet, "modal-largeR")}
                      style={{
                        backgroundColor: " #FBC054",
                        border: "2px solid #F28C28",
                      }}
                    >
                      Pay
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <WalletModel userData={userData} />
      {selectedWallet && <ViewModel wallet={selectedWallet} />}
      {receiptData.length > 0 && <ReceiptModel receiptData={receiptData} />}
    </div>
  );
}

export default Wallet;
