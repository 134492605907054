import React, { useState, useEffect } from "react";
import ContactList from "./ContactList";
import ChatWindow from "./ChatWindow";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Loader from "../Common/Loader";
import Popup from "reactjs-popup";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Main() {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const query = useQuery();
  const active = query.get("active");
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const onClosePopup = () => {
    setOpenPopup(false);
  };

  const onCloseDeletePopup = () => {
    setOpenDeletePopup(false);
  };

  const userId = useSelector((state) => state.userDetails.user.id);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [messages, setMessages] = React.useState(null);

  useEffect(() => {
    const getData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/chat/getByUserId/${userId}`;
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      setLoading(true);
      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.status !== "success") {
          toast.error("Something went wrong");
        } else {
          console.log(data)
          const filteredData = data.data.map((chat) => {
            if (chat.chatType === "user") {

              let u = chat.receiverId === userId ? chat.sender : chat.receiver;
              return {
                id: chat._id,
                senderId: chat.senderId,
                receiverIds: [u.id],
                type: "user",
                name: u.firstName,
                avatar:
                  u.profilePic === "" || u.profilePic === "null"
                    ? "/assets/media/avatars/avatar0.jpg"
                    : u.profilePic,
                messages: chat.messages.map((m) => {
                  let time = moment(m.createdAt);
                  return {
                    userId: m.senderId,
                    user: m.name,
                    text: m.message,
                    timestamp: time.fromNow(),
                    createdAt: m.createdAt,
                  };
                }),
                members: [chat.sender.firstName, chat.receiver.firstName],
              };
            } else if (chat.chatType === "group") {
              return {
                id: chat._id,
                senderId: chat.senderId,
                type: "group",
                tag: {
                  key: chat.group.labelName,
                  value: chat.group.labelColor,
                },
                name: chat.group.groupName,
                avatar:
                  chat.group.groupImage || "/assets/media/avatars/avatar0.jpg",
                messages: chat.messages.map((m) => {
                  let time = moment(m.createdAt);
                  return {
                    userId: m.senderId,
                    user: m.name,
                    text: m.message,
                    timestamp: time.fromNow(),
                    createdAt: m.createdAt,
                  };
                }),
                members: [
                  chat.sender.firstName + "  - Creator",
                  ...chat.members.map(
                    (m) =>
                      m?.userId?.firstName +
                      " " +
                      m?.userId?.lastName +
                      " -- " +
                      moment(m?.userId?.birthday).format("DD/MM/YYYY")
                  ),
                ],
                receiverIds: [
                  ...chat.members
                    .map((m) => m?.userId?.id)
                    .filter((id) => id !== null && id !== undefined),
                ],
              };
            } else if (chat.chatType === "event") {
              return {
                id: chat._id,
                senderId: chat.senderId,
                type: "event",
                name: chat.event.eventName,
                tag: chat.event.tag,
                avatar: chat.event.eventImages[0],
                messages: chat.messages.map((m) => {
                  let time = moment(m.createdAt);
                  return {
                    userId: m.senderId,
                    user: m.name,
                    text: m.message,
                    timestamp: time.fromNow(),
                    createdAt: m.createdAt,
                  };
                }),
                members: [
                  chat.sender.firstName +
                    " ( " +
                    chat.sender.email +
                    " ) - Creator",
                  ...chat.members.map(
                    (m) =>
                      m?.userId?.firstName + " ( " + m?.userId?.email + " )"
                  ),
                ],
                receiverIds: [
                  ...chat.members
                    .map((m) => m?.userId?.id)
                    .filter((id) => id !== null && id !== undefined),
                ],
              };
            } else {
              return null;
            }
          });

          if (filteredData.length !== 0) {
            const sortedUsers = filteredData.sort((a, b) => {
              const getLastMessageTimestamp = (chat) => {
                if (chat.messages.length === 0) return moment(0); // No messages, use epoch time
                const lastMessage = chat.messages[chat.messages.length - 1];
                return moment(lastMessage.createdAt);
              };

              const aLatest = getLastMessageTimestamp(a);
              const bLatest = getLastMessageTimestamp(b);

              return bLatest - aLatest; // Sort descending by timestamp
            });

            setUsers(sortedUsers);
            let activeUser = active
              ? sortedUsers.filter((d) => d.id === active)[0]
              : sortedUsers[0];

            if (!activeUser) {
              activeUser = sortedUsers[0];
            }

            if (!activeUser) {
              setSelectedUser(null);
              setMessages(null);
            } else {
              setSelectedUser(activeUser);
              setMessages(activeUser.messages);
            }
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
        toast.error("An error occurred: " + error.message);
      }
    };

    if (refresh) getData();
    setRefresh(false);
  }, [userId, active, refresh]);

  const addMessage = (msg) => {
    const newMessages = [
      ...messages,
      { ...msg, timestamp: moment().fromNow() },
    ];
    setMessages(newMessages);
    // Update user's message in users array (optional, if you plan to manage a more dynamic, persisting state)
    const updatedUsers = users.map((user) =>
      user.id === selectedUser.id ? { ...user, messages: newMessages } : user
    );
    setUsers(updatedUsers);
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setMessages(user.messages);
  };

  const refreshChat = () => {
    setRefresh(true);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = users?.filter((user) =>
    user?.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (users) {
      const sortedUsers = users.sort((a, b) => {
        const getLastMessageTimestamp = (chat) => {
          if (chat.messages.length === 0) return moment(0); // No messages, use epoch time
          const lastMessage = chat.messages[chat.messages.length - 1];
          return moment(lastMessage.createdAt);
        };

        const aLatest = getLastMessageTimestamp(a);
        const bLatest = getLastMessageTimestamp(b);

        return bLatest - aLatest; // Sort descending by timestamp
      });

      setUsers(sortedUsers);
      let activeUser = active
        ? sortedUsers.filter((d) => d.id === active)[0]
        : sortedUsers[0];

      if (!activeUser) {
        activeUser = sortedUsers[0];
      }

      if (!activeUser) {
        setSelectedUser(null);
        setMessages(null);
      } else {
        setSelectedUser(activeUser);
        setMessages(activeUser.messages);
      }
      setLoading(false);
    }
  }, [users, active]);

  const deleteChat = async () => {
    const url = `${process.env.REACT_APP_API_URL}/admin/chat/deleteById`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        recordId: selectedUser.id,
      }),
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (data.status === "success") {
        const newUsers = users.filter((u) => u.id !== selectedUser.id);
        setUsers(newUsers);
        toast.success("Chat deleted successfully");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error);
    }
  };

  const confirmDeleteChat = () => {
    setOpenDeletePopup(true);
  };


  if (loading) return <Loader />;

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className="fa fa-calendar me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">Chat</h1>
              <div className="d-flex gap-4 align-items-center">
                {selectedUser && selectedUser.type !== "user" ? (
                  <button
                    className="btn btn-secondary"
                    onClick={() => setOpenPopup(true)}
                  >
                    View Members
                  </button>
                ) : (
                  ""
                )}
                <div onClick={refreshChat} style={{ cursor: "pointer" }}>
                  Refresh
                  <i className="fa-solid fa-arrows-rotate ms-2"></i>
                </div>
                {selectedUser &&
                selectedUser.type !== "user" &&
                selectedUser.senderId !== userId ? (
                  ""
                ) : (
                  <button
                    className="btn btn-danger"
                    onClick={confirmDeleteChat}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row clearfix">
                  <div className="col-lg-12">
                    {users && selectedUser ? (
                      <div className="card chat-app">
                        <div id="plist" className="people-list">
                          <div className="input-group mb-3 px-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search by name..."
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                            <button className="input-group-text">
                              <i className="fa-solid fa-magnifying-glass"></i>
                            </button>
                          </div>
                          <ContactList
                            contacts={filteredUsers}
                            onSelectUser={handleSelectUser}
                            selectedUser={selectedUser}
                          />
                        </div>
                        <ChatWindow
                          selectedUser={selectedUser}
                          messages={messages}
                          addMessage={addMessage}
                        />
                      </div>
                    ) : (
                      <div className="card chat-app text-center py-4">
                        No Chat Record
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Popup
        open={openPopup}
        onClose={onClosePopup}
        closeOnDocumentClick
        contentStyle={{
          width: "max-content",
          maxHeight: "400px",
          overflowY: "auto",
        }}
      >
        <div className="d-flex gap-2 p-4 flex-column">
          <h3 className="mb-0 text-center">Chat Members</h3>
          <hr />
          {selectedUser &&
            selectedUser.members.map((member, index) => (
              <React.Fragment key={index}>
                <div>{member}</div>
                <hr />
              </React.Fragment>
            ))}
        </div>
      </Popup>

      <Popup
        open={openDeletePopup}
        onClose={onCloseDeletePopup}
        closeOnDocumentClick
        contentStyle={{
          width: "max-content",
        }}
      >
        <div className="d-flex gap-2 p-4 flex-column text-center">
          <h3 className="mb-0">Confirm Delete</h3>
          <p>Are you sure you want to delete this chat?</p>
          <div className="d-flex justify-content-center gap-2">
            <button className="btn btn-secondary" onClick={onCloseDeletePopup}>
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                deleteChat();
                onCloseDeletePopup();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default Main;
