import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Pagination from "../Common/Pagination";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2";
import { useSelector } from "react-redux";

function UserTable({ allUsers, setChangeStatus, filters, handleFilterChange }) {
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const userinfo = useSelector((state) => state.userDetails.user);
  const currentUserId = userinfo.id;
  const [hiddenColumns, setHiddenColumns] = useState([
    "email",
    "phonePersonal",
    "residentialAddress",
    "shirtSize",
    "houseNumber",
    "city",
    "province",
    "schoolName",
    "schoolBlonging",
    "role",
  ]);
  const usersPerPage = 10;
  const selectRef = useRef();

  useEffect(() => {
    $(selectRef.current)
      .select2({
        width: "100%",
        placeholder: "Select columns to hide",
      })
      .val(hiddenColumns)
      .trigger("change");

    $(selectRef.current).on("change", (e) => {
      setHiddenColumns($(e.target).val());
    });
    // eslint-disable-next-line
  }, []);

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      allUsers.map((user) => ({
        "First Name": user.firstName,
        "Last Name": user.lastName,
        "Date of Birth": formatDate(user.birthday),
        Age: user.age,
        Email: user.email,
        "Shirt Size": user.shirtSize,
        "House Number": user.houseNumber,
        "Residential Address": user.residentialAddress,
        City: user.city,
        Province: user.province,
        "School Name": user.schoolName,
        "Parish of Belonging": user.schoolBlonging,
        "Wallet Content": user.walletBalance,
        "User Policy": user.role,
        Telephone: user.phonePersonal,
        Rfid: user.rfid,
        State: user.accountActive ? "Active" : "Inactive",
        "Group Ownership": "Group Name",
        "Created At": user.createdAt,
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, "UserData.xlsx");
  };

  const handleExportExcelPDF = () => {
    const doc = new jsPDF("landscape", "mm", "a4");
    const pageWidth = doc.internal.pageSize.getWidth();

    autoTable(doc, {
      head: [
        [
          "First Name",
          "Last Name",
          "DOB",
          "Age",
          "Email",
          "Shirt Size",
          "House Number",
          "Residential Address",
          "City",
          "Province",
          "School Name",
          "Parish of Belonging",
          "Wallet",
          "Role",
          "Phone",
          "State",
          "RFID",
          "Group",
          "CreatedAt",
        ],
      ],
      body: allUsers.map((user) => [
        user.firstName,
        user.lastName,
        formatDate(user.birthday),
        user.age,
        user.email,
        user.shirtSize,
        user.houseNumber,
        user.residentialAddress,
        user.city,
        user.province,
        user.schoolName,
        user.schoolBlonging,
        user.walletBalance,
        user.role,
        user.phonePersonal,
        user.accountActive ? "Active" : "Inactive",
        user.rfid,
        "Group Name",
        formatDate(user.createdAt),
      ]),
      styles: { fontSize: 8 },
      theme: "grid",
      columnStyles: {
        0: { cellWidth: pageWidth / 20 }, // First Name
        1: { cellWidth: pageWidth / 20 }, // Last Name
        2: { cellWidth: pageWidth / 20 }, // DOB
        3: { cellWidth: pageWidth / 30 }, // Age
        4: { cellWidth: pageWidth / 20 }, // Email
        5: { cellWidth: pageWidth / 20 }, // Shirt Size
        6: { cellWidth: pageWidth / 25 }, // House Number
        7: { cellWidth: pageWidth / 15 }, // Residential Address
        8: { cellWidth: pageWidth / 20 }, // City
        9: { cellWidth: pageWidth / 30 }, // Province
        10: { cellWidth: pageWidth / 15 }, // School Name
        11: { cellWidth: pageWidth / 15 }, // Parish of Belonging
        12: { cellWidth: pageWidth / 20 }, // Wallet
        13: { cellWidth: pageWidth / 30 }, // Role
        14: { cellWidth: pageWidth / 20 }, // Phone
        15: { cellWidth: pageWidth / 20 }, // State
        16: { cellWidth: pageWidth / 20 }, // RFID
        17: { cellWidth: pageWidth / 20 }, // Group
        18: { cellWidth: pageWidth / 25 }, // CreatedAt
      },
      margin: { top: 10, bottom: 10, left: 10, right: 10 },
      didDrawPage: function (data) {
        doc.setFontSize(12);
        doc.text("User Data", pageWidth / 2, 10, { align: "center" });
      },
    });

    doc.save("UserData.pdf");
  };

  const handleExport = (event) => {
    const exportType = event.target.value;
    switch (exportType) {
      case "excel":
        handleExportExcel();
        break;
      case "pdf":
        handleExportExcelPDF();
        break;
      default:
        console.error("No export type selected");
    }
  };

  const handleStatus = async (userId) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/user/toggleActiveVerified`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ userId }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("User status updated successfully:");
        setChangeStatus((prev) => !prev);
      } else {
        toast.error("Failed to update user status:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error:", error);
    }
  };

  const activateChat = async (receiverId) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/chat/addRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        senderId: currentUserId,
        receiverId,
        chatType: "user",
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status !== "success") {
        toast.success("Something went wrong");
      } else {
        navigate(`/chat?active=${data.data._id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred: " + error.message);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = allUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(allUsers.length / usersPerPage);
  return (
    <div className="container">
      <div className="block-content">
        <form className="mb-3">
          <div className="d-flex justify-content-between">
            <div>
              Showing Result of
              <strong> {allUsers.length} </strong> Users
            </div>
            <div className="w-25">
              <select multiple ref={selectRef} className="form-control">
                <option value="firstName">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="birthday">Date of Birth</option>
                <option value="age">Age</option>
                <option value="email">Email</option>
                <option value="shirtSize">Shirt Size</option>
                <option value="houseNumber">House No</option>
                <option value="residentialAddress">Residential Address</option>
                <option value="city">City</option>
                <option value="province">Province</option>
                <option value="schoolName">School Name</option>
                <option value="schoolBlonging">Parish of Belonging</option>
                <option value="walletContent">Wallet Content</option>
                <option value="role">User Policy</option>
                <option value="phonePersonal">Telephone</option>
                <option value="rfid">RFID</option>
                <option value="state">State</option>
                <option value="groupOwnership">Group Ownership</option>
                <option value="action">Action</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="mt-4">
                <select
                  className="form-control"
                  name="role"
                  value={filters.role}
                  onChange={handleFilterChange}
                  aria-label="Filter by Role"
                >
                  <option value="">Role</option>
                  {[...Array(6).keys()].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="mt-4">
                <select
                  className="form-control"
                  name="state"
                  value={filters.state}
                  onChange={handleFilterChange}
                  aria-label="Filter by State"
                >
                  <option value="">State</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="mt-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name"
                  value={filters.search}
                  onChange={handleFilterChange}
                  name="search"
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="mt-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="RFID"
                  value={filters.rfid}
                  onChange={handleFilterChange}
                  name="rfid"
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="mt-4">
                <input
                  type="text"
                  className="form-control"
                  onChange={handleFilterChange}
                  value={filters.age}
                  placeholder="Age"
                  name="age"
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="mt-4">
                <select
                  className="btn btn-lg btn-alt-primary form-select form-select-lg w-100"
                  onChange={handleExport}
                >
                  <option value="" selected disabled>
                    Export As
                  </option>
                  <option value="excel">Excel File</option>
                  <option value="pdf">PDF File</option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <div style={{ overflowX: "auto" }}>
          <table className="table table-bordered table-striped table-vcenter table-responsive">
            <thead>
              <tr>
                <th>
                  <input type="checkbox" />
                </th>
                {!hiddenColumns.includes("firstName") && <th>FIRST NAME</th>}
                {!hiddenColumns.includes("lastName") && <th>LAST NAME</th>}
                {!hiddenColumns.includes("birthday") && <th>DATE OF BIRTH</th>}
                {!hiddenColumns.includes("age") && <th>AGE</th>}
                {!hiddenColumns.includes("email") && <th>EMAIL</th>}
                {!hiddenColumns.includes("shirtSize") && <th>SHIRT SIZE</th>}
                {!hiddenColumns.includes("houseNumber") && (
                  <th>HOUSE NUMBER</th>
                )}
                {!hiddenColumns.includes("residentialAddress") && (
                  <th>RESIDENTIAL ADDRESS</th>
                )}
                {!hiddenColumns.includes("city") && <th>CITY</th>}
                {!hiddenColumns.includes("province") && <th>PROVINCE</th>}
                {!hiddenColumns.includes("schoolName") && <th>SCHOOL NAME</th>}
                {!hiddenColumns.includes("schoolBlonging") && (
                  <th>PARISH OF BELONGING</th>
                )}
                {!hiddenColumns.includes("walletContent") && (
                  <th>WALLET CONTENT</th>
                )}
                {!hiddenColumns.includes("role") && <th>USER POLICY</th>}
                {!hiddenColumns.includes("phonePersonal") && <th>TELEPHONE</th>}
                {!hiddenColumns.includes("rfid") && <th>RFID</th>}
                {!hiddenColumns.includes("state") &&
                  !(userinfo?.role === "4" || userinfo?.role === "5") && (
                    <th>STATE</th>
                  )}

                {!hiddenColumns.includes("groupOwnership") && (
                  <th>GROUP OWNERSHIP</th>
                )}
                {!hiddenColumns.includes("action") && <th>ACTION</th>}
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, index) => (
                <tr key={index}>
                  <td>
                    <input type="checkbox" value={user.id} />
                  </td>
                  {!hiddenColumns.includes("firstName") && (
                    <td>{user.firstName}</td>
                  )}
                  {!hiddenColumns.includes("lastName") && (
                    <td>{user.lastName}</td>
                  )}
                  {!hiddenColumns.includes("birthday") && (
                    <td>{formatDate(user.birthday)}</td>
                  )}
                  {!hiddenColumns.includes("age") && <td>{user.age}</td>}
                  {!hiddenColumns.includes("email") && <td>{user.email}</td>}
                  {!hiddenColumns.includes("shirtSize") && (
                    <td>{user.shirtSize}</td>
                  )}
                  {!hiddenColumns.includes("houseNumber") && (
                    <td>{user.houseNumber}</td>
                  )}
                  {!hiddenColumns.includes("residentialAddress") && (
                    <td>{user.residentialAddress}</td>
                  )}
                  {!hiddenColumns.includes("city") && <td>{user.city}</td>}
                  {!hiddenColumns.includes("province") && (
                    <td>{user.province}</td>
                  )}
                  {!hiddenColumns.includes("schoolName") && (
                    <td>{user.schoolName}</td>
                  )}
                  {!hiddenColumns.includes("schoolBlonging") && (
                    <td>{user.schoolBlonging}</td>
                  )}
                  {!hiddenColumns.includes("walletContent") && (
                    <td> {user.walletBalance},00€</td>
                  )}
                  {!hiddenColumns.includes("role") && <td>{user.role}</td>}
                  {!hiddenColumns.includes("phonePersonal") && (
                    <td>{user.phonePersonal}</td>
                  )}
                  {!hiddenColumns.includes("rfid") && <td>{user.rfid}</td>}
                  {!hiddenColumns.includes("state") &&
                    (userinfo?.role === "4" || userinfo?.role === "5" ? null : (
                      <td>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleStatus(user.id)}
                          className={`badge ${
                            user.accountActive ? "bg-success" : "bg-danger"
                          }`}
                        >
                          {user.accountActive ? "Active" : "Inactive"}
                        </span>
                      </td>
                    ))}
                  {!hiddenColumns.includes("groupOwnership") && (
                    <td>
                      <div className="d-flex flex-column gap-2">
                        {user.groupsJoined.map((group) => {
                          if (!group) return "";
                          return (
                            <div className="fs-xs d-flex justify-content-between">
                           
                                {group?.groupName}
                                <div>
                                <span
                                  className="badge ms-2"
                                  style={{
                                    background: group.labelColor,
                                    color:
                                      group.labelColor === "yellow"
                                        ? "black"
                                        : "white",
                                  }}
                                >
                                  {group.labelName}
                                </span>
                                </div>
                         
                            </div>
                          );
                        })}
                      </div>
                    </td>
                  )}
                  {!hiddenColumns.includes("action") && (
                    <td >
                      <div className="d-flex align-item-center justify-content-around">
                      {userinfo?.role === "4" || userinfo?.role === "5" ? (
                        ""
                      ) : (
                        <Link
                          to={`/edituser/${user.id}`}
                          className="btn btn-primary btn-sm rounded-0 me-1"
                        >
                          <i className="fa fa-pen"></i>
                        </Link>
                      )}
                      {userinfo?.role === "0" || userinfo?.role === "1" ? (
                        <button
                          className="btn btn-primary btn-sm rounded-0 "
                          onClick={() => activateChat(user.id)}
                        >
                          <i className="fab fa-rocketchat"></i>
                        </button>
                      ) : (
                        ""
                      )}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
}

export default UserTable;
