import React from "react";
import SuppliersModal from "./SuppliersModal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function SuppliersTable({ suppliers }) {
  const handleDelete = async (id) => {
    const userConfirmed = window.confirm("Are you sure you want to delete?");
    if (!userConfirmed) {
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/admin/supplier/deleteById`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ recordId: id }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Supplier deleted successfully");
        window.location.reload();
      } else {
        toast.error(`Failed to delete supplier: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error deleting supplier: ${error.message}`);
    }
  };
  const userinfo = useSelector((state) => state.userDetails.user);

  return (
    <div className="block-content">
      {userinfo?.role === "3" ||
      userinfo?.role === "4" ||
      userinfo?.role === "5" ? (
        ""
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3 d-flex justify-content-end">
              <button
                className="btn btn-lg btn-alt-primary px-4"
                data-bs-toggle="modal"
                data-bs-target="#modal-large38"
              >
                Add Supplier
              </button>
            </div>
          </div>
        </div>
      )}
      <div style={{ overflowX: "auto" }}>
        <table
          id="suppliersDataTable"
          className="js-table-checkable table table-hover table-vcenter table-responsive"
        >
          <thead>
            <tr>
              <th>TYPE</th>
              <th>NAME</th>
              <th>ADDRESS</th>
              <th>TELEPHONE</th>
              <th>EMAIL</th>
              <th>VAT NUMBER</th>
              <th>FISCAL CODE</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {suppliers?.map((supplier, index) => (
              <tr key={index}>
                <td>
                  <p>{supplier.typeOfBusiness}</p>
                </td>
                <td>
                  <p>{supplier.businessName}</p>
                </td>
                <td>
                  <p>{supplier.address}</p>
                </td>
                <td>
                  <p>
                    Phone 1: {supplier.phone1} <br /> Phone 2: {supplier.phone2}
                  </p>
                </td>
                <td>
                  <p>{supplier.email}</p>
                </td>
                <td>
                  <p>{supplier.vatNumber}</p>
                </td>
                <td>
                  <p>{supplier.fiscalCode}</p>
                </td>
                <td>
                  <div className="d-flex justify-content-center mb-1">
                    <Link
                      to={`/supplier/${supplier.id}`}
                      className="btn btn-primary btn-sm rounded-0"
                    >
                      <i className="fa fa-pen"></i>
                    </Link>
                    {userinfo?.role === "0" ? (
                      <button
                      className="btn btn-primary btn-sm rounded-0 mx-1"
                      onClick={() => handleDelete(supplier.id)}
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                    ) : (
                      ""
                    )}
                  </div>
                  {userinfo?.role === "0" ? (
                  <Link
                    to={`/supplier/monenymoment/${supplier.id}`}
                    className="btn btn-primary btn-sm rounded-0 mx-1 text-nowrap"
                  >
                    Get Movements
                  </Link>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <SuppliersModal />
    </div>
  );
}

export default SuppliersTable;
