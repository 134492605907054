import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const userinfo = useSelector((state) => state.userDetails.user);
  const Theme = useSelector((state) => state.settings.settings);
  const logoUrl = Theme.settingsTheme?.logo;
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleNav = (event) => {
      event.preventDefault();
      const element = event.currentTarget;
      const navMain = element.closest(".nav-main");
      if (
        !(
          (window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth) > 991 &&
          navMain.classList.contains("nav-main-horizontal") &&
          navMain.classList.contains("nav-main-hover")
        )
      ) {
        const listItem = element.closest("li");
        if (listItem.classList.contains("open")) {
          listItem.classList.remove("open");
          element.setAttribute("aria-expanded", "false");
        } else {
          Array.from(element.closest("ul").children).forEach((child) => {
            child.classList.remove("open");
          });
          listItem.classList.add("open");
          element.setAttribute("aria-expanded", "true");
        }
      }
      return false;
    };

    const elements = document.querySelectorAll('[data-bs-toggle="submenu"]');
    elements.forEach((element) => {
      element.addEventListener("click", handleNav);
    });

    return () => {
      elements.forEach((element) => {
        element.removeEventListener("click", handleNav);
      });
    };
  }, []);

  const isMainLinkActive = (path) => {
    if (Array.isArray(path)) {
      return path.some((p) => activeLink.includes(p));
    }
    return activeLink.includes(path);
  };
  const logout = () => {
    localStorage.removeItem("userData");
    window.location.reload("/");
  };

  const HandleNavbarClose = () => {
    const page = document.getElementById("page-container");
    window.innerWidth > 991
      ? page.classList.remove("sidebar-o")
      : page.classList.remove("sidebar-o-xs");
  };

  return (
    <nav id="sidebar">
      <div className="sidebar-content">
        <div className="content-header justify-content-lg-center">
          <div>
            <Link className="mx-auto" to="/dashboard">
              <div className="text-center py-4">
                <img src={logoUrl} alt="logo" width={"60"} />
              </div>
            </Link>
          </div>

          <div>
            <button
              type="button"
              className="btn btn-sm btn-alt-danger d-lg-none"
              data-toggle="layout"
              onClick={HandleNavbarClose}
            >
              <i className="fa fa-fw fa-times"></i>
            </button>
          </div>
        </div>
        <div className="js-sidebar-scroll">
          <div className="content-side content-side-user px-0 py-0">
            <div className="smini-visible-block animated fadeIn px-3">
              <img
                className="img-avatar img-avatar32"
                src={
                  userinfo?.profilePic === null ||
                  userinfo?.profilePic === undefined ||
                  userinfo?.profilePic === "" ||
                  userinfo?.profilePic === "null"
                    ? "/assets/media/avatars/avatar15.jpg"
                    : userinfo.profilePic
                }
                alt="profile"
              />
            </div>

            <div className="smini-hidden text-center mx-auto">
              <Link className="img-link" to="/dashboard">
                <img
                  className="img-avatar"
                  src={
                    userinfo?.profilePic || "/assets/media/avatars/avatar15.jpg"
                  }
                  alt=""
                />
              </Link>
              <ul className="list-inline mt-3 mb-0">
                <li className="list-inline-item">
                  <Link
                    className="link-fx text-dual fs-sm fw-semibold text-uppercase"
                    to="/personal-info"
                  >
                    {`${userinfo?.firstName}\u00A0${userinfo?.lastName}`}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <button className=" btn link-fx text-dual" onClick={logout}>
                    <i className="fa fa-sign-out-alt"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="content-side content-side-full">
            <ul className="nav-main">
              <li className="nav-main-item">
                <Link
                  className={`nav-main-link ${
                    isMainLinkActive("/dashboard") ? "active triangle" : ""
                  }`}
                  to="/dashboard"
                >
                  <i className="nav-main-link-icon fa fa-house-user"></i>
                  <span className="nav-main-link-name">Dashboard</span>
                </Link>
              </li>
              <li
                className={`nav-main-item ${
                  isMainLinkActive(["/wallet", "/userinfo"]) ? "open" : ""
                }`}
              >
                <a
                  href="##"
                  className={`nav-main-link nav-main-link-submenu ${
                    isMainLinkActive(["/wallet", "/userinfo"]) ? "active" : ""
                  }`}
                  data-bs-toggle="submenu"
                  aria-haspopup="true"
                >
                  <i className="nav-main-link-icon fa fa-user"></i>
                  <span className="nav-main-link-name">User Information</span>
                </a>
                <ul className="nav-main-submenu">
                  <li className="nav-main-item">
                    <Link
                      to="/wallet"
                      className={`nav-main-link ${
                        isMainLinkActive("/wallet") ? "active" : ""
                      }`}
                    >
                      <span className="nav-main-link-name ms-4">
                        User Wallets
                      </span>
                    </Link>
                  </li>
                  <li className="nav-main-item">
                    {userinfo?.role === "4" || userinfo?.role === "5" ? (
                      ""
                    ) : (
                      <Link
                        to="/userinfo"
                        className={`nav-main-link ${
                          isMainLinkActive("/userinfo") ? "active" : ""
                        }`}
                      >
                        <span className="nav-main-link-name ms-4">Users</span>
                      </Link>
                    )}
                  </li>
                </ul>
              </li>
              {userinfo?.role === "5" ? (
                ""
              ) : (
                <li className="nav-main-item">
                  <Link
                    className={`nav-main-link ${
                      isMainLinkActive("/entries-exits")
                        ? "active triangle"
                        : ""
                    }`}
                    to="/entries-exits"
                  >
                    <i className="nav-main-link-icon fa fa-door-open"></i>
                    <span className="nav-main-link-name">Entries/Exits</span>
                  </Link>
                </li>
              )}
              {userinfo?.role === "5" ? (
                ""
              ) : (
                <li className="nav-main-item">
                  <Link
                    className={`nav-main-link ${
                      isMainLinkActive("/group-coaches")
                        ? "active triangle"
                        : ""
                    }`}
                    to="/group-coaches"
                  >
                    <i className="nav-main-link-icon fa fa-users"></i>
                    <span className="nav-main-link-name">Groups/Coaches</span>
                  </Link>
                </li>
              )}
              <li className="nav-main-item">
                <Link
                  className={`nav-main-link ${
                    isMainLinkActive("/events") ? "active triangle" : ""
                  }`}
                  to="/events"
                >
                  <i className="nav-main-link-icon  far  fa-calendar"></i>
                  <span className="nav-main-link-name">Events</span>
                </Link>
              </li>
              {userinfo?.role === "3" ||
              userinfo?.role === "4" ||
              userinfo?.role === "5" ? (
                ""
              ) : (
                <li
                  className={`nav-main-item ${
                    isMainLinkActive([
                      "/balance-sheet",
                      "/money-movement",
                      "/suppliers",
                    ])
                      ? "open"
                      : ""
                  }`}
                >
                  <a
                    className={`nav-main-link nav-main-link-submenu ${
                      isMainLinkActive([
                        "/balance-sheet",
                        "/money-movement",
                        "/suppliers",
                      ])
                        ? "active"
                        : ""
                    }`}
                    data-bs-toggle="submenu"
                    aria-haspopup="true"
                    href="##"
                  >
                    <i className="nav-main-link-icon fa fa-money-check"></i>
                    <span className="nav-main-link-name">Balance Overview</span>
                  </a>
                  <ul className="nav-main-submenu">
                    <li className="nav-main-item">
                      <Link
                        to="/balance-sheet"
                        className={`nav-main-link ${
                          isMainLinkActive("/balance-sheet") ? "active" : ""
                        }`}
                      >
                        <span className="nav-main-link-name ms-4">
                          Balance Sheet
                        </span>
                      </Link>
                    </li>
                    <li className="nav-main-item">
                      <Link
                        to="/money-movements"
                        className={`nav-main-link ${
                          isMainLinkActive("/money-movement") ? "active" : ""
                        }`}
                      >
                        <span className="nav-main-link-name ms-4">
                          Money Movements
                        </span>
                      </Link>
                    </li>
                    <li className="nav-main-item">
                      <Link
                        to="/suppliers"
                        className={`nav-main-link ${
                          isMainLinkActive("/suppliers") ? "active" : ""
                        }`}
                      >
                        <span className="nav-main-link-name ms-4">
                          Suppliers
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
              <li className="nav-main-item">
                <Link
                  className={`nav-main-link ${
                    isMainLinkActive("/chat") ? "active triangle" : ""
                  }`}
                  to="/chat"
                >
                  <i className="fab fa-rocketchat nav-main-link-icon"></i>
                  <span className="nav-main-link-name">Chat</span>
                </Link>
              </li>
              {userinfo?.role === "4" || userinfo?.role === "5" ? (
                ""
              ) : (
                <li className="nav-main-item">
                  <Link
                    className={`nav-main-link ${
                      isMainLinkActive("/email") ? "active triangle" : ""
                    }`}
                    to="/email"
                  >
                    <i className="fa fa-envelope nav-main-link-icon"></i>
                    <span className="nav-main-link-name">Email User</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
