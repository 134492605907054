import React, { useEffect, useState } from "react";
import Table from "./Table";
import $ from "jquery";
import moment from "moment";
import Card from "./Card";
import { useSelector } from "react-redux";

function Main() {
  const [movements, setMovements] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [balance, setBalance] = useState([]);
  const [filteredMovements, setFilteredMovements] = useState([]);

  useEffect(() => {
    const fetchMovementData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/moneyMovement/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
      
        // Sort the data by `dateOfMovement` from latest to earliest
        const sortedData = data.sort((a, b) => {
          const dateA = moment(a.dateOfMovement);
          const dateB = moment(b.dateOfMovement);
          return dateB - dateA; // Sort descending (latest first)
        });
      
        setMovements(sortedData);
        setFilteredMovements(sortedData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    const fetchUsersData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/user/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
        setAllUsers(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    const fetchSuppliersData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/supplier/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
        setAllSuppliers(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    const fetchBalanceSheetData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/balanceSheet/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
        setBalance(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchMovementData();
    fetchUsersData();
    fetchSuppliersData();
    fetchBalanceSheetData();
  }, []);

  useEffect(() => {
    if (movements.length > 0) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const table = $("#movementsDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
        order: false
      });

      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        const min = $("#minDate").val();
        const max = $("#maxDate").val();
        const date = moment(data[0], "DD/MM/YYYY");

        if (
          (!min && !max) ||
          (!min && date.isSameOrBefore(moment(max))) ||
          (moment(min).isSameOrBefore(date) && !max) ||
          (moment(min).isSameOrBefore(date) && date.isSameOrBefore(moment(max)))
        ) {
          return true;
        }
        return false;
      });

      // Event listener for date inputs to redraw on change
      $("#minDate, #maxDate").on("change", function () {
        table.draw();
        updateFilteredMovements();
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        table.destroy();
      };
    }
    //eslint-disable-next-line
  }, [movements]);

  const updateFilteredMovements = () => {
    const minDate = $("#minDate").val();
    const maxDate = $("#maxDate").val();

    const filtered = movements.filter((movement) => {
      const date = moment(
        moment(movement.dateOfMovement).format("DD/MM/YYYY"),
        "DD/MM/YYYY"
      );
      const min = minDate;
      const max = maxDate;

      if (
        (!min && !max) ||
        (!min && date.isSameOrBefore(moment(max))) ||
        (moment(min).isSameOrBefore(date) && !max) ||
        (moment(min).isSameOrBefore(date) && date.isSameOrBefore(moment(max)))
      ) {
        return true;
      }
      return false;
    });

    setFilteredMovements(filtered);
  };

  const resetDateFilters = () => {
    $("#minDate").val("");
    $("#maxDate").val("");
    $("#movementsDataTable").DataTable().draw();
    setFilteredMovements(movements);
  };
  const userinfo = useSelector((state) => state.userDetails.user);

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-content">
              <Card data={filteredMovements} />
              <div className="row">
                <div className="col-lg-12">
                  <h1 className="block-title fs-4 mb-4 fw-bolder">
                    Money Movements
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 order-1 order-md-0">
                  <div className="d-flex gap-3 mb-3 align-items-center">
                    <input
                      type="date"
                      id="minDate"
                      className="form-control"
                      name="minDate"
                    />
                    <span className="fw-bolder">-</span>
                    <input
                      type="date"
                      className="form-control"
                      id="maxDate"
                      name="maxDate"
                    />
                    <button
                      onClick={resetDateFilters}
                      className="btn btn-alt-primary text-nowrap"
                    >
                      Reset Filter
                    </button>
                  </div>
                </div>
                {userinfo?.role === "3" ||
                userinfo?.role === "4" ||
                userinfo?.role === "5" ? (
                  ""
                ) : (
                  <div className="col-md-6 order-0 order-md-1 d-flex justify-content-end">
                    <div className="mb-md-5 mb-3">
                      <button
                        className="btn btn-lg btn-alt-primary px-4"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-large48"
                      >
                        Add Movement
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <Table
                allUsers={allUsers}
                allSuppliers={allSuppliers}
                movements={movements}
                balance={balance}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Main;
