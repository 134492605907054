import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2";

function Events2({ formData, onChange }) {
  const [optionsData, setOptionsData] = useState([]);
  const selectRef = useRef();
  const fatherEventSelectRef = useRef();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/event/getAll`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        setOptionsData(data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    $(selectRef.current).select2({
      placeholder: "Select Event",
      allowClear: true,
    });

    $(selectRef.current).on("change", (e) => {
      const selectedOptions = $(e.target).val();
      onChange({
        target: {
          name: "incompatibleEvents",
          value: selectedOptions,
        },
      });
    });

    return () => {
      // eslint-disable-next-line
      $(selectRef.current).select2("destroy");
    };
    // eslint-disable-next-line
  }, [optionsData]);

  useEffect(() => {
    if (isChecked) {
      $(fatherEventSelectRef.current).select2({
        placeholder: "Select Father Event",
        allowClear: true,
      });

      $(fatherEventSelectRef.current).on("change", (e) => {
        const selectedOptions = $(e.target).val();
        onChange({
          target: {
            name: "fatherEvent",
            value: selectedOptions === null ? "" : selectedOptions,
          },
        });
      });
    } else {
      if ($(fatherEventSelectRef.current).data("select2")) {
        $(fatherEventSelectRef.current).select2("destroy");
      }
    }

    return () => {
      if ($(fatherEventSelectRef.current).data("select2")) {
        // eslint-disable-next-line
        $(fatherEventSelectRef.current).select2("destroy");
      }
    };
    // eslint-disable-next-line
  }, [isChecked, optionsData]);

  useEffect(() => {
    if (formData?.fatherEvent) {
      setIsChecked(true);
    }
  }, [formData?.fatherEvent]);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const today = new Date();
  const minAgeDate = new Date(today.setFullYear(today.getFullYear() - 4))
    .toISOString()
    .split("T")[0];
  const maxAgeDate = new Date(today.setFullYear(today.getFullYear() - 80))
    .toISOString()
    .split("T")[0];

  return (
    <div className="text-start my-md-5 my-3">
      <div className="row">
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label" htmlFor="subscriberMinAge">
              Age Of Subscribers (Start Date)
            </label>
            <input
              type="date"
              className="form-control form-control-lg"
              onChange={onChange}
              value={formData?.subscriberMinAge}
              name="subscriberMinAge"
              id="subscriberMinAge"
              min={maxAgeDate}
              max={minAgeDate}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label" htmlFor="subscriberMaxAge">
              Age Of Subscribers (End Date)
            </label>
            <input
              type="date"
              className="form-control form-control-lg"
              onChange={onChange}
              value={formData?.subscriberMaxAge}
              name="subscriberMaxAge"
              id="subscriberMaxAge"
              min={maxAgeDate}
              max={minAgeDate}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label" htmlFor="eventSelect">
              Select Incompatible Event
            </label>
            <select
              ref={selectRef}
              className="form-control"
              name="eventSelect"
              id="eventSelect"
              multiple
              value={formData?.incompatibleEvents}
            >
              {optionsData?.map((option, index) => (
                <option key={index} value={option.id}>
                  {option?.eventName} ({formatDate(option?.startDate)}) (
                  {option?.tag?.key})
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="mb-4">
            <label className="form-label">Father Event</label>
            <div className="space-y-2 mt-3">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
        </div>

        {isChecked && (
          <div className="col-md-4">
            <div className="mb-4">
              <label className="form-label" htmlFor="fatherEventSelect">
                Select Father Event
              </label>
              <select
                ref={fatherEventSelectRef}
                className="form-control"
                name="fatherEvent"
                id="fatherEvent"
                value={formData?.fatherEvent || ""}
              >
                <option value="" disabled>
                  Select Father Event
                </option>
                {optionsData?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option?.eventName} ({formatDate(option?.startDate)}) (
                    {option?.tag?.key})
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Events2;
