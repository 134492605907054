import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function SubscribeFromEventTitle({ eventData, userData }) {
  const [messages, setMessages] = useState([]);
  const [disableSubscription, setDisableSubscription] = useState(false);
  const { id } = useParams();

  const userinfo = useSelector((state) => state.userDetails.user);

  const [credentials, setCredentials] = useState({
    userId: userData?.id,
    eventId: id,
    amount: "",
    multipleSubscription: "",
    subscriptionParam1: "",
    subscriptionParam2: "",
    personalizedRole: {},
    note: "",
    balanceOperator: userinfo?.id
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const onPersonalizedRoleChange = (e) => {
    const selectedRole = eventData?.personalizedRoles?.find(
      (role) => role.name === e.target.value
    );
    setCredentials({
      ...credentials,
      personalizedRole: selectedRole,
      amount: selectedRole?.price || "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(credentials)

    const url = `${process.env.REACT_APP_API_URL}/user/eventSubscription/addRecord`;
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(credentials),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Event Subscribed successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        setCredentials({
          userId: "",
          amount: "",
          multipleSubscription: "",
          subscriptionParam1: "",
          subscriptionParam2: "",
          personalizedRole: {},
          note: "",
          balanceOperator: userinfo?.id
        });
      } else {
        toast.error(data?.message ? data?.message : "Something Went Wrong!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let disable = false;
    let messageArray = [];

    setCredentials({ ...credentials, eventId: eventData?.id });
    setCredentials({ ...credentials, userId: userData?.id });
    // Check Age Range
    if (
      userData?.age &&
      (userData?.age < calculateAge(eventData?.subscriberMaxAge) ||
        userData?.age > calculateAge(eventData?.subscriberMinAge))
    ) {
      disable = true;
      messageArray.push("Age is out of Range");
    }

    // Check Father Event
    if (
      eventData?.fatherEvent &&
      userData?.subscribedEventIds &&
      !checkFatherEvent(
        eventData?.fatherEvent?.id,
        userData?.subscribedEventIds
      )
    ) {
      disable = true;
      messageArray.push("Father Event not Subscribed");
    }

    // Incompatible Events Validation
    if (
      eventData?.incompatibleEvents.length > 0 &&
      userData?.subscribedEventIds &&
      !checkIncompatibilityOfEvents(
        eventData?.incompatibleEvents,
        userData?.subscribedEventIds
      )
    ) {
      disable = true;
      messageArray.push("This is not Compatible with the current one");
    }

    setDisableSubscription(disable);
    setMessages(messageArray);
    //eslint-disable-next-line
  }, [eventData, userData]);

  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    const dayDiff = today.getDate() - birth.getDate();
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    return age;
  };

  const checkFatherEvent = (fatherId, objectsArray) => {
    for (let obj of objectsArray) {
      if (fatherId === obj) {
        return true;
      }
    }
    return false;
  };

  const checkIncompatibilityOfEvents = (idsArray, objectsArray) => {
    for (let id of idsArray) {
      for (let obj of objectsArray) {
        if (id === obj) {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <div
      className="modal"
      id="modal-subscribe-event"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-lggg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Applied Event</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row mx-2 mt-2">
                <div className="col-6">
                  <div className="mb-4">
                    <label className="form-label">User</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      disabled
                      value={userData?.firstName + " " + userData?.lastName}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-4">
                    <label className="form-label">Personalized Role</label>
                    <select
                      className="form-select"
                      name="personalizedRole"
                      onChange={onPersonalizedRoleChange}
                      required
                    >
                      <option disabled selected value="">
                        Select Personalized Role
                      </option>
                      {eventData?.personalizedRoles?.map((role, index) => (
                        <option key={index} value={role.name}>
                          {role.name + " (" + role.description + ")"}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-4">
                    <label className="form-label">Amount</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      disabled
                      name="amount"
                      value={`${credentials.amount},00 €`}
                    />
                  </div>
                </div>
                {eventData?.subscriptionParameter1 && (
                  <div className="col-6">
                    <div className="mb-4">
                      <label className="form-label">
                        {eventData?.subscriptionParameter1}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="subscriptionParam1"
                        onChange={onChange}
                        value={credentials.subscriptionParam1}
                      />
                    </div>
                  </div>
                )}
                {eventData?.subscriptionParameter2 && (
                  <div className="col-6">
                    <div className="mb-4">
                      <label className="form-label">
                        {eventData?.subscriptionParameter2}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="subscriptionParam2"
                        onChange={onChange}
                        value={credentials.subscriptionParam2}
                      />
                    </div>
                  </div>
                )}
                {eventData?.maxSubscription && (
                  <div className="col-12">
                    <div className="mb-4">
                      <label className="form-label">
                        Multiple Subscription
                      </label>
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        name="multipleSubscription"
                        onChange={onChange}
                        value={credentials.multipleSubscription}
                      />
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <div className="mt-3 form-label">Note</div>
                  <textarea
                    className="form-control"
                    name="note"
                    onChange={onChange}
                    value={credentials.note}
                    cols="17"
                    rows="3"
                  ></textarea>
                </div>
              </div>
              <div className="text-center my-3">
                <button
                  disabled={disableSubscription}
                  type="submit"
                  className="btn btn-alt-primary"
                >
                  Subscribe to Event
                </button>
              </div>
            </form>
            <div className="row">
              <div className="col-md-6 offset-md-3">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className="alert alert-danger text-center my-3"
                  >
                    {message}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeFromEventTitle;
