import React from "react";
import Navbar from "../components/Common/Navbar";
import Header from "../components/Common/Header";
import UpdateSuppliers from "../components/balance-sheet/suppliers/UpdateSuppliers";

function Suppliers() {
  return (
    <div
      id="page-container"
      className="sidebar-o enable-page-overlay side-scroll page-header-modern main-content-boxed"
    >
      <Navbar />
      <Header />
      <UpdateSuppliers />
    </div>
  );
}

export default Suppliers;
