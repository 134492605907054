import React from "react";

function NoteModel({ note, subscriptionParameter1, subscriptionParameter2, maxSubscription, selectedEvent }) {
  return (
    <div
      className="modal"
      id="modal-note"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Note</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="block block-rounded">
              <div className="container">
                <div className="block-content">
                  <div className="mb-3">
                    <strong>Description : </strong>
                    {note}
                  </div>
                  <div className="mb-3">
                    <strong>{selectedEvent?.subscriptionParameter1} : </strong>
                    {subscriptionParameter1}
                  </div>
                  <div className="mb-3">
                    <strong>{selectedEvent?.subscriptionParameter2} : </strong>
                    {subscriptionParameter2}
                  </div>
                  <div className="mb-3">
                    <strong>Number of Reserved Seats : </strong>
                    {maxSubscription ? maxSubscription : '1'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoteModel;
