import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import Loader from "../Common/Loader";

function PublicSummery() {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/event/getById/${id}`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.status === "success") {
          setFormData(result.data);
        } else {
          console.error("Error fetching data:", result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchEvents = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/event/getAll`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        const eventsArray = result.data || [];
        if (!Array.isArray(eventsArray)) {
          throw new Error("Events data is not an array");
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    if (formData) {
      fetchEvents();
    }
  }, [formData]);

  const convertDateFormat = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // const calculateAge = (birthDate) => {
  //   const birth = new Date(birthDate);
  //   const today = new Date();
  //   let age = today.getFullYear() - birth.getFullYear();
  //   const monthDiff = today.getMonth() - birth.getMonth();
  //   const dayDiff = today.getDate() - birth.getDate();
  //   if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
  //     age--;
  //   }
  //   return age;
  // };

  if (!formData) {
    return <Loader />;
  }

  function getBadgeClass(state) {
    switch (state) {
      case "Waiting Subscription":
        return "bg-warning";
      case "Subscription Opened":
        return "bg-success";
      case "Event Started":
        return "bg-primary";
      case "Event Ended":
        return "bg-danger";
      case "Subscription Closed":
        return "bg-danger";
      default:
        return "bg-danger";
    }
  }

function checkAge(date) {
  if (!date) return null;
  const birthDate = new Date(date);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

function AgeRange() {
  const minAge = checkAge(formData?.subscriberMinAge);
  const maxAge = checkAge(formData?.subscriberMaxAge);

  const [lowerAge, higherAge] =
    minAge && maxAge
      ? [Math.min(minAge, maxAge), Math.max(minAge, maxAge)]
      : [minAge, maxAge];

  return (
    <h5 className="fw-medium">
      {lowerAge && higherAge
        ? `From ${lowerAge} years to ${higherAge} years old`
        : lowerAge
        ? `Minimum age: ${lowerAge} years`
        : higherAge
        ? `Maximum age: ${higherAge} years`
        : "For All ages"}
    </h5>
  );
}

  return (
    <main id="main-container" className="event-summary-page py-5 px-4">
      <div className="container">
        <div className="header-section text-center mb-5 bg-light rounded shadow-sm">
          <h1 className="event-title">
            {formData?.eventName || "No data available"}
          </h1>
          <p className="event-date">
            {formData?.startDate &&
            formData?.endDate &&
            formData.startDate === formData.endDate
              ? convertDateFormat(formData.startDate)
              : `${
                  convertDateFormat(formData.startDate) || "No data available"
                } - ${
                  convertDateFormat(formData.endDate) || "No data available"
                }`}
          </p>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="event-details mb-4">
              <h2 className="section-title">Event Details</h2>
              <div className="event-images row">
                {formData?.eventImages?.length > 0 ? (
                  formData?.eventImages.map((imageSrc, index) => (
                    <div className="col-md-6 col-12">
                      <img
                        key={index}
                        className="event-image img-fluid rounded shadow-sm mb-3 p-3"
                        src={imageSrc}
                        alt={`Event ${index}`}
                        height={"350px"}
                        style={{ width: "100%", height: "350px" }}
                      />
                    </div>
                  ))
                ) : (
                  <div>No images available</div>
                )}
              </div>
              <h2 className="section-title mt-2">Description</h2>
              <div className="event-description bg-light p-4 rounded shadow-sm mb-4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: formData?.description
                      ? DOMPurify?.sanitize(formData?.description)
                      : "No description available",
                  }}
                />
              </div>
              <div className="event-info bg-light p-4 rounded shadow-sm">
                <h5 className="info-title">
                  Event Location:{" "}
                  <span className="fw-normal">
                    {formData?.eventLocation || "No data available"}
                  </span>
                </h5>
                <h5 className="info-title">
                  Event Time:{" "}
                  <span className="fw-normal">
                    {formData?.startHour && formData?.endHour
                      ? `${formData?.startHour} to ${formData?.endHour}`
                      : "No data available"}
                  </span>
                </h5>

                <h5 className="info-title">
                  Max Subscribers:{" "}
                  <span className="fw-normal">{formData?.maxSubscribers}</span>
                </h5>

                <h5 className="info-title">
                  Recurring Event:{" "}
                  <span className="fw-normal">
                    {formData?.recurringEvent ? "Yes" : "No"}
                  </span>
                </h5>

                {formData?.recurringEvent &&
                  formData?.recurringDays?.length > 0 && (
                    <ul className="recurring-days">
                      {formData?.recurringDays.map((day, index) => (
                        <li key={index}>{day}</li>
                      ))}
                    </ul>
                  )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="subscription-details mb-4">
              <h2 className="section-title">Subscription Details</h2>
              <div className="subscription-info bg-light p-4 rounded shadow-sm">
                <h5 className="info-title">Subscription State:</h5>
                <p
                  className={`badge ${getBadgeClass(
                    formData?.subscriptionState
                  )}`}
                >
                  {formData?.subscriptionState || "No data available"}
                </p>
                {formData?.subscriptionStartDate &&
                  formData?.subscriptionEndDate && (
                    <>
                      <h5 className="info-title">Subscription Duration:</h5>

                      <p>
                        {convertDateFormat(formData.subscriptionStartDate)} -{" "}
                        {convertDateFormat(formData.subscriptionEndDate)}
                      </p>
                    </>
                  )}
                <h5 className="info-title">Age Of Subscriber:</h5>
                <p>{AgeRange()}</p>
              </div>
            </div>
            <div className="documents-section mb-4">
              <h2 className="section-title">Documents</h2>
              <div className="documents-list d-flex justify-content-center bg-light p-4 rounded shadow-sm">
                {formData?.eventFiles && formData?.eventFiles.length > 0 ? (
                  formData?.eventFiles.map((imageSrc, index) => (
                    <a
                      key={index}
                      target="_blank"
                      rel="noreferrer"
                      href={imageSrc}
                      download={`optional-value-${index}`}
                      className="document-link mx-2"
                    >
                      <img
                        className="document-image img-fluid rounded shadow-sm"
                        src="/assets/media/pdf.png"
                        width="80"
                        height="auto"
                        alt={`Event ${index}`}
                      />
                    </a>
                  ))
                ) : (
                  <p>No documents available</p>
                )}
              </div>
            </div>
            <div className="documents-section mb-4">
              <div className="documents-list bg-light p-4 rounded shadow-sm">
                <h5>Prices</h5>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>PRICE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.personalizedRoles &&
                    formData?.personalizedRoles.length > 0 ? (
                      formData?.personalizedRoles.map((role, index) => (
                        <tr key={index}>
                          <td>{role.name}</td>
                          <td>{role.price},00 €</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No data entered
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="actions-section mb-4 d-flex justify-content-center">
          {!(
            (formData.subscriptionEndDate &&
              new Date(formData.subscriptionEndDate) < new Date()) ||
            (!formData.subscriptionEndDate &&
              formData.endDate &&
              new Date(formData.endDate) < new Date())
          ) && (
            <Link to="/" className="btn btn-primary btn-lg px-5">
              Subscribe Now
            </Link>
          )}
        </div>
      </div>
      <style jsx>{`
        .event-summary-page {
          background-color: #f5f5f5;
        }
        .header-section {
          padding: 1rem;
          border-radius: 5px;
        }
        .event-title {
          font-size: 2.5rem;
          margin-bottom: 0.5rem;
        }
        .event-date {
          font-size: 1.2rem;
        }
        .section-title {
          font-size: 1.8rem;
          margin-bottom: 1rem;
          color: #333;
        }
        .event-details,
        .subscription-details,
        .personalized-roles,
        .documents-section,
        .actions-section {
          margin-bottom: 2rem;
        }
        .info-title {
          font-size: 1.1rem;
          font-weight: bold;
        }
        .badge-container {
          display: flex;
          align-items: center;
        }
        .badge {
          font-size: 1rem;
          padding: 0.5rem 1rem;
        }
        .recurring-days {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        .recurring-days li {
          margin-bottom: 0.5rem;
        }
        .document-link {
          display: inline-block;
        }
      `}</style>
    </main>
  );
}

export default PublicSummery;
