import React, { useState } from "react";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import Events2 from "./Events2";
import Events1 from "./Events1";
import Events3 from "./Events3";
import Events4 from "./Events4";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function EventsCard() {
  const Theme = useSelector((state) => state.settings.settings);
  const userinfo = useSelector((state) => state.userDetails.user);
  const navigate = useNavigate();

  const location = useLocation();
  const { newFormData } = location?.state || {};

  const [formData, setFormData] = useState({
    authorId: userinfo?.id,
    eventName: newFormData?.eventName || "",
    tag: {
      key: newFormData?.tag?.key || "",
      value: newFormData?.tag?.value || "",
    },
    subscriptionState: newFormData?.subscriptionState || "Waiting Subscription",
    maxSubscription: newFormData?.maxSubscription || "",
    eventFiles: newFormData?.eventFiles || [],
    eventImages: newFormData?.eventImages || [],
    description: newFormData?.description || "",
    subscriberMinAge: newFormData?.subscriberMinAge || "",
    subscriberMaxAge: newFormData?.subscriberMaxAge || "",
    incompatibleEvents: newFormData?.incompatibleEvents || [],
    fatherEvent: newFormData?.fatherEvent || "",
    startDate: newFormData?.startDate || "",
    endDate: newFormData?.endDate || "",
    eventLocation: newFormData?.eventLocation || "",
    startHour: newFormData?.startHour || "00:00",
    endHour: newFormData?.endHour || "23:59",
    subscriptionStartDate: newFormData?.subscriptionStartDate || "",
    subscriptionEndDate: newFormData?.subscriptionEndDate || "",
    subscriptionParameter1: newFormData?.subscriptionParameter1 || "",
    subscriptionParameter2: newFormData?.subscriptionParameter2 || "",
    allowUnsubscription: newFormData?.allowUnsubscription || false,
    recurringEvent: newFormData?.recurringEvent || false,
    recurringDays: newFormData?.recurringDays || [],
    mutipleSubscription: newFormData?.mutipleSubscription || false,
    // balanceSelection: newFormData?.balanceSelection || "",
    applyPersonalDiscount: newFormData?.applyPersonalDiscount || false,
    maxSubscribers: newFormData?.maxSubscribers || "10000",
    personalizedRoles: newFormData?.personalizedRoles || [],
  });
  const handleComplete = () => {
    if (
      formData.personalizedRoles.length === 0
      // formData.balanceSelection === ""
    ) {
      toast.error("Please fill in the required fields");
    } else {
      navigate("/event/summary", { state: { formData } });
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "eventImages" || name === "eventFiles") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: [...prevState[name], value],
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const checkValidateTabOne = () => {
    if (
      formData.eventName === "" ||
      formData.eventImages.length === 0 ||
      formData.subscriptionState === ""
    ) {
      return false;
    }
    return true;
  };

  const checkValidateTabTwo = () => {
    if (
      formData.startDate === "" ||
      formData.endDate === "" ||
      formData.eventLocation === ""
    ) {
      return false;
    }
    return true;
  };

  const errorMessages = () => {
    toast.error("Please fill in the required fields");
  };


  return (
    <>
      <div className="block block-rounded">
        <div className="container">
          <FormWizard
            onComplete={handleComplete}
            color={Theme.settingsTheme.colorSelection}
          >
            <FormWizard.TabContent title="Event Details" icon="far fa-calendar">
              <Events1 formData={formData} onChange={onChange} />
            </FormWizard.TabContent>
            <FormWizard.TabContent
              isValid={checkValidateTabOne()}
              validationError={errorMessages}
              title="Subscription Details"
              icon="fa fa-circle-dollar-to-slot"
            >
              <Events3 formData={formData} onChange={onChange} />
            </FormWizard.TabContent>
            <FormWizard.TabContent
              isValid={checkValidateTabTwo()}
              validationError={errorMessages}
              title="Participant Details"
              icon="fa fa-users"
            >
              <Events2 formData={formData} onChange={onChange} />
            </FormWizard.TabContent>
            <FormWizard.TabContent
              title="Balance Details"
              icon="fa fa-scale-balanced"
            >
              <Events4 formData={formData} onChange={onChange} />
            </FormWizard.TabContent>
          </FormWizard>
        </div>
      </div>
    </>
  );
}

export default EventsCard;
