import React, { useState, useRef, useEffect } from "react";

function ExitModel({ allUsers, setChangeStatus }) {
  const [rfid, setRfid] = useState("");
  const [scannedUsers, setScannedUsers] = useState([]);
  const [message, setMessage] = useState("");
  const inputReff = useRef(null);

  useEffect(() => {
    const modal = document.getElementById("modal-large40");
    const handleModalOpen = () => {
      if (inputReff.current) {
        inputReff.current.focus();
      }
    };

    modal.addEventListener("shown.bs.modal", handleModalOpen);
    return () => {
      modal.removeEventListener("shown.bs.modal", handleModalOpen);
    };
  }, []);

  function getCurrentTimestampInItaly() {
    const now = new Date();
    const options = {
      timeZone: "Europe/Rome",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const formatter = new Intl.DateTimeFormat("en-GB", options);
    const formattedDate = formatter.format(now);
    return formattedDate;
  }

  const handleChange = async (e) => {
    const newRfid = e.target.value;
    setRfid(newRfid);
    setMessage("");

    if (newRfid.length === 10) {
      const user = allUsers.find((user) => user.rfid === newRfid);
      if (user) {
        const isAlreadyScanned = scannedUsers.some(
          (scannedUser) => scannedUser.rfid === newRfid
        );

        if (isAlreadyScanned) {
          setMessage("User already added.");
        } else {
          setScannedUsers((prevScannedUsers) => [
            ...prevScannedUsers,
            {
              firstName: user.firstName,
              lastName: user.lastName,
              dateOfBirth: user.birthday,
              rfid: newRfid,
            },
          ]);

          const url =
          `${process.env.REACT_APP_API_URL}/admin/entriesExit/addRecord`;
          const options = {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({
              timestamp: getCurrentTimestampInItaly(),
              firstName: user.firstName,
              lastName: user.lastName,
              state: "OUT",
              rfid: newRfid,
            }),
          };

          try {
            const response = await fetch(url, options);
            const data = await response.json();
            if (data.status === "success") {
              setRfid("");
              if (inputReff.current) {
                inputReff.current.focus();
              }
              setChangeStatus((prev) => !prev);
            }
          } catch (error) {
            console.error(error);
          }
        }
      } else {
        setMessage("User not found.");
        setRfid("")
      }
    }
  };

  return (
    <div
      className="modal"
      id="modal-large40"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lggg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Entries Scanning</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => window.location.reload()}
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="my-3">
              <div className="container">
                <div className="block-content">
                  <div className="row">
                    <div className="d-flex justify-content-center">
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label className="form-label" htmlFor="rfid-input">
                            RFID Code
                          </label>
                          <input
                            type="number"
                            className="form-control form-control-lg"
                            name="rfid"
                            value={rfid}
                            onChange={handleChange}
                            placeholder="012345678"
                            autoFocus
                            ref={inputReff}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {message && (
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  )}
                  <div>
                    <table className="js-table-checkable table table-hover table-vcenter table-responsive">
                      <thead>
                        <tr>
                          <th
                            className="text-center"
                            style={{ width: "40px" }}
                          ></th>
                          <th>FIRST NAME</th>
                          <th>LAST NAME</th>
                          <th className="d-sm-table-cell">DATE OF BIRTH</th>
                        </tr>
                      </thead>
                      <tbody>
                        {scannedUsers.map((user, index) => (
                          <tr key={index}>
                            <td className="text-center"></td>
                            <td>
                              <p className="fs-sm mb-0">{user.firstName}</p>
                            </td>
                            <td>
                              <p className="fs-sm mb-0">{user.lastName}</p>
                            </td>
                            <td className="d-sm-table-cell">
                              {user.dateOfBirth}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExitModel;
