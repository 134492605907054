import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

function WalletModel({ userData }) {
  const userinfo = useSelector((state) => state.userDetails.user);
  const today = new Date().toISOString().split("T")[0];
  const [formData, setFormData] = useState({
    userId: "",
    amount: "",
    paymentType: "",
    paymentDate: today,
    moneyOperator: userinfo?.id,
    reasonOfPayment: "Recharging wallet",
  });

  useEffect(() => {
    if (userData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: userData.id,
      }));
    }
  }, [userData]);

  async function handleSubmit() {
    const url = `${process.env.REACT_APP_API_URL}/user/user/rechargeWallet`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(formData),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Wallet Recharged Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(data.message || "Failed to Recharge");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <div
      className="modal"
      id="modal-wallet2"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lggg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Recharge Wallet</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>

            <div className="block block-rounded">
              <div className="container">
                <div className="block-content">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4 mt-md-3">
                          <label className="form-label" htmlFor="paymentDate">
                            Date
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-lg"
                            name="paymentDate"
                            onChange={onChange}
                            value={formData.paymentDate}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="mb-4 mt-md-3">
                          <label className="form-label" htmlFor="amount">
                            Amount (€)
                          </label>
                          <input
                            type="number"
                            className="form-control form-control-lg"
                            name="amount"
                            onChange={onChange}
                            value={formData.amount}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4 mt-md-3">
                          <label className="form-label" htmlFor="paymentType">
                            Payment Type
                          </label>
                          <select
                            className="form-control form-control-lg"
                            name="paymentType"
                            onChange={onChange}
                            value={formData.paymentType}
                          >
                            <option disabled selected value="">
                              Select Payment Type
                            </option>
                            <option value="Cash">Cash</option>
                            <option value="Bank transfer">Bank transfer</option>
                            <option value="Inside organization">
                              Inside organization
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4 mt-md-3">
                          <label className="form-label" htmlFor="moneyOperator">
                            Money Operator
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            value={`${userinfo.firstName} ${userinfo.lastName}`}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4 mt-md-3">
                          <label className="form-label" htmlFor="reasonOfPayment">
                            Reason of Payment
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="reasonOfPayment"
                            value={formData.reasonOfPayment}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4 mt-md-3">
                          <label className="form-label" htmlFor="userId">
                            User
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="userId"
                            value={
                              userData?.firstName + " " + userData?.lastName
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-end">
                      <button
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-lg btn-alt-primary px-4"
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalletModel;
