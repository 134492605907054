import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSettings } from "./redux/actions/settings";
import Error from "./Pages/Error";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Forget from "./Pages/Forget";
import UpdatePassword from "./Pages/UpdatePassword";
import Otp from "./Pages/Otp";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import Test from "./Pages/Test";
import Dashboard from "./Pages/Dashboard";
import Settings from "./Pages/Settings";
import PersonalSetting from "./Pages/PersonalSetting";
import User from "./Pages/User";
import Wallet from "./Pages/Wallet";
import UserInfo from "./Pages/UserInfo";
import EditUser from "./Pages/EditUser";
import Entries from "./Pages/Entries";
import GroupCoaches from "./Pages/GroupCoaches";
import GroupList from "./Pages/GroupList";
import GrouplistLevel from "./Pages/GrouplistLevel";
import Suppliers from "./Pages/Suppliers";
import Events from "./Pages/Events";
import EventsSummary from "./Pages/EventsSummary";
import EventcardImage from "./Pages/EventcardImage";
import EventTable from "./Pages/EventTable";
import TitleeventMember from "./Pages/TitleeventMember";
import { ColorProvider } from "./context/ColorContext";
import BalancesheetDetails from "./Pages/BalancesheetDetails";
import BalanceSheet from "./Pages/BalanceSheet";
import MoneyMovements from "./Pages/MoneyMovements";
import EventDetails from "./Pages/EventDetails";
import Token from "./Pages/Token";
import Chat from "./Pages/Chat";
import Favicon from "./components/Common/Favicon";
import Title from "./components/Common/Title";
import EventUpdate from "./Pages/EventUpdate";
import SupplierUpdate from "./Pages/SupplierUpdate";
import GroupDistribution from "./Pages/GroupDistribution";
import "datatables.net-bs5";
import MoneyMomentDetail from "./Pages/MoneyMomentDetail";
import Email from "./Pages/Email";
import SupplierDetail from "./Pages/SupplierDetail";
import { ToastContainer } from "react-toastify";
import 'reactjs-popup/dist/index.css';
import PublicSummery from "./components/events/PublicSummery";
import ScrollToTop from "./components/Common/ScrollToTop";
import AddMember from "./Pages/AddMember";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/admin/getAllSettings`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === "success") {
          dispatch(setSettings(data.data));
        } else {
          console.error("Failed to load settings:", data.message);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);
  return (
    <ColorProvider>
      <Favicon />
      <Title />
      <Router>
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/add-member" element={<AddMember />} />
            <Route exact path="/forget-password" element={<Forget />} />
            <Route exact path="/update-password" element={<UpdatePassword />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/verify-otp" element={<Otp />} />
            <Route exact path="/test" element={<Test />} />
            <Route exact path="/personal-info" element={<PersonalSetting />} />
            <Route exact path="/money-movements" element={<MoneyMovements />} />
            <Route
              exact
              path="/money-movements/:id"
              element={<MoneyMomentDetail />}
            />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="/wallet" element={<Wallet />} />
            <Route exact path="/user" element={<User />} />
            <Route exact path="/userinfo" element={<UserInfo />} />
            <Route exact path="/event/summary" element={<EventsSummary />} />
            <Route exact path="/events" element={<EventcardImage />} />
            <Route exact path="/edituser/:id" element={<EditUser />} />
            <Route exact path="/suppliers" element={<Suppliers />} />
            <Route exact path="/supplier/:id" element={<SupplierUpdate />} />
            <Route
              exact
              path="/supplier/monenymoment/:id"
              element={<SupplierDetail />}
            />
            <Route
              exact
              path="/eventmembers/:id"
              element={<TitleeventMember />}
            />
            <Route
              exact
              path="/eventbalance/:id"
              element={<BalancesheetDetails />}
            />
            <Route exact path="/event/:id" element={<EventDetails />} />
            <Route exact path="/public/event/:id" element={<PublicSummery />} />
            <Route exact path="/balance-sheet" element={<BalanceSheet />} />
            <Route exact path="/update-event/:id" element={<EventUpdate />} />
            <Route exact path="/event/view" element={<EventTable />} />
            <Route exact path="/group-list/:id" element={<GroupList />} />
            <Route exact path="/chat" element={<Chat />} />
            <Route
              exact
              path="/group-list-level/:id"
              element={<GrouplistLevel />}
            />
            <Route exact path="/group-coaches" element={<GroupCoaches />} />
            <Route
              exact
              path="/group-distribution"
              element={<GroupDistribution />}
            />
            <Route exact path="/entries-exits" element={<Entries />} />
            <Route exact path="/event/create" element={<Events />} />
            <Route exact path="/token/:token" element={<Token />} />
            <Route exact path="/email" element={<Email />} />
            <Route exact path="*" element={<Error />} />
          </Routes>
        </ScrollToTop>
      </Router>
      <ToastContainer />
    </ColorProvider>
  );
}
export default App;
