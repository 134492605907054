import React, { useState, useEffect } from "react";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { setSettings } from "../../redux/actions/settings";

function EmailSetting() {
  const theme = useSelector((state) => state.settings.settings);
  const dispatch = useDispatch();

  const [emailSettings, setEmailSettings] = useState({
    fromName: theme.settingsEmail?.fromName || "",
    fromEmail: theme.settingsEmail?.fromEmail || "",
    host: theme.settingsEmail?.host || "",
    port: theme.settingsEmail?.port || "",
    username: theme.settingsEmail?.username || "",
    password: theme.settingsEmail?.password || "",
  });

  // Update local state when theme changes
  useEffect(() => {
    setEmailSettings({
      fromName: theme.settingsEmail?.fromName || "",
      fromEmail: theme.settingsEmail?.fromEmail || "",
      host: theme.settingsEmail?.host || "",
      port: theme.settingsEmail?.port || "",
      username: theme.settingsEmail?.username || "",
      password: theme.settingsEmail?.password || "",
    });
        // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    setEmailSettings({ ...emailSettings, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const url = `${process.env.REACT_APP_API_URL}/admin/settingsEmail/updateRecord`;
    const formData = new URLSearchParams();
    for (const key in emailSettings) {
      formData.append(key, emailSettings[key]);
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: formData,
      });
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Email settings updated successfully!");
        dispatch(setSettings(data.data));
      } else {
        throw new Error(data.message || "Failed to update email settings");
      }
    } catch (error) {
      console.error("Error updating email settings:", error);
      toast.error(error.message || "Failed to update email settings");
    }
  };
  return (
    <div className="row push">
      <div className="col-lg-12">
        <h1 className="block-title fs-4 fw-bolder">Email Setting</h1>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-4 mt-3">
            <label className="form-label">Mail from Name </label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="fromName"
              placeholder="easyoratorio"
              onChange={onChange}
              value={emailSettings.fromName}

            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-4 mt-md-3">
            <label className="form-label">Mail From Email </label>
            <input
              type="email"
              className="form-control form-control-lg"
              name="fromEmail"
              placeholder="admin@gmail.com"
              onChange={onChange}
              value={emailSettings.fromEmail}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-4">
            <label className="form-label">Mail Host </label>
            <input
              type="email"
              className="form-control form-control-lg"
              name="host"
              placeholder="easyoratorio.it"
              onChange={onChange}
              value={emailSettings.host}

            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-4">
            <label className="form-label">Mail Port </label>
            <input
              type="number"
              className="form-control form-control-lg"
              name="port"
              placeholder="546"
              onChange={onChange}
              value={emailSettings.port}

            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-4">
            <label className="form-label">Mail Username </label>
            <input
              type="email"
              disabled
              className="form-control form-control-lg"
              name="username"
              placeholder="eathukkk"
              onChange={onChange}
              value={emailSettings.fromEmail}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-4">
            <label className="form-label">Mail Password </label>
            <input
              type="password"
              className="form-control form-control-lg"
              name="password"
              onChange={onChange}
              placeholder="**********"
              value={emailSettings.password}
            />
          </div>
        </div>
      </div>
      <div className="block-content block-content-full block-content-sm text-end">
        <button
          type="button"
          className="btn btn-alt-primary px-4"
          onClick={handleSubmit}
        >
          Update
        </button>
      </div>
    </div>
  );
}

export default EmailSetting;
