import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSettings } from "../../redux/actions/settings";

function RegistrationSetting() {
  const theme = useSelector((state) => state.settings.settings);
  const dispatch = useDispatch();

  const [joiningServiceUrl, setJoiningServiceUrl] = useState(theme?.settingsRegistration?.joiningService || '');
  const [regulationUrl, setRegulationUrl] = useState(theme?.settingsRegistration?.regulation || '');
  const [personalDataProcessingUrl, setPersonalDataProcessingUrl] = useState(theme?.settingsRegistration?.personalDataProcessing || '');

  const handleFileUpload = async (event, key) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/utils/uploadImage`, {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (response.ok && data.status === "success") {
        const url = data.data.image;
        if (key === 'joiningService') {
          setJoiningServiceUrl(url);
        } else if (key === 'regulation') {
          setRegulationUrl(url);
        } else if (key === 'personalDataProcessing') {
          setPersonalDataProcessingUrl(url);
        }
        toast.success(`${key.replace(/([A-Z])/g, ' $1').trim()} Selected!`);
      } else {
        throw new Error(data.message || 'Failed to upload image');
      }
    } catch (error) {
      console.error('Upload error for ' + key, error);
      toast.error('Error uploading ' + key.replace(/([A-Z])/g, ' $1').trim());
    }
  };

  const handleSubmit = async () => {
    const url = `${process.env.REACT_APP_API_URL}/admin/settingsRegistration/updateRecord`;
    const formData = new URLSearchParams({
      joiningService: joiningServiceUrl,
      regulation: regulationUrl,
      personalDataProcessing: personalDataProcessingUrl,
    });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: formData
      });
      const data = await response.json();
      if (data.status === "success") {
        toast.success('Registration settings updated successfully!');
        dispatch(setSettings(data.data));
      } else {
        throw new Error(data.message || 'Failed to update settings');
      }
    } catch (error) {
      console.error('Error updating settings:', error);
      toast.error('Failed to update settings');
    }
  };

  return (
    <div className="row push">
      <div className="col-lg-12">
        <h1 className="block-title fs-4 fw-bolder">Registration Settings</h1>
      </div>
      <div className="col-md-6 overflow-hidden">
        <div className="mb-4 mt-3">
          <label className="form-label">Joining Service</label>
          <input
            className="form-control form-control-lg"
            type="file"
            accept="application/pdf"
            onChange={(e) => handleFileUpload(e, 'joiningService')}
          />
          {joiningServiceUrl && (
            <a href={joiningServiceUrl} target="_blank" rel="noopener noreferrer" className="ms-2">
              <i className="fa fa-file-pdf fa-2x m-2"></i>
            </a>
          )}
        </div>
      </div>
      <div className="col-md-6 overflow-hidden">
        <div className="mb-4 mt-3">
          <label className="form-label">Regulation</label>
          <input
            className="form-control form-control-lg"
            type="file"
            accept="application/pdf"
            onChange={(e) => handleFileUpload(e, 'regulation')}
          />
          {regulationUrl && (
            <a href={regulationUrl} target="_blank" rel="noopener noreferrer" className="ms-2">
              <i className="fa fa-file-pdf fa-2x m-2"></i>
            </a>
          )}
        </div>
      </div>
      <div className="col-md-6 overflow-hidden">
        <div className="mb-4 mt-3">
          <label className="form-label">Personal Data Processing</label>
          <input
            className="form-control form-control-lg"
            type="file"
            accept="application/pdf"
            onChange={(e) => handleFileUpload(e, 'personalDataProcessing')}
          />
          {personalDataProcessingUrl && (
            <a href={personalDataProcessingUrl} target="_blank" rel="noopener noreferrer" className="ms-2">
              <i className="fa fa-file-pdf fa-2x m-2"></i>
            </a>
          )}
        </div>
      </div>
      <div className="block-content block-content-full block-content-sm text-end">
        <button
          type="button"
          className="btn btn-alt-primary px-4"
          onClick={handleSubmit}
        >
          Update
        </button>
      </div>
    </div>
  );
}

export default RegistrationSetting;
