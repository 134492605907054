import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
import { setSettings } from "../../redux/actions/settings";

function PaymentSetting() {
  const theme = useSelector((state) => state.settings.settings);
  const dispatch = useDispatch();

  const [paymentSettings, setPaymentSettings] = useState({
    paypalClientId: theme?.settingsPayment?.paypalClientId || '',
    paypalSecret: theme?.settingsPayment?.paypalSecret || ''
  });

  useEffect(() => {
    setPaymentSettings({
      paypalClientId: theme?.settingsPayment?.paypalClientId || '',
      paypalSecret: theme?.settingsPayment?.paypalSecret || ''
    });
        // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setPaymentSettings({ ...paymentSettings, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const url = `${process.env.REACT_APP_API_URL}/admin/settingsPayment/updateRecord`;
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(paymentSettings)
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success('Payment settings updated successfully!');
        dispatch(setSettings(data?.data));
      } else {
        throw new Error(data.message || 'Failed to update payment settings');
      }
    } catch (error) {
      console.error('Error updating payment settings:', error);
      toast.error(error.message || 'Failed to update payment settings');
    }
  };

  return (
    <div className="row push">
      <div className="col-lg-12">
        <h1 className="block-title fs-4 fw-bolder">Payment Setting</h1>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-4 mt-3">
            <label className="form-label" htmlFor="paypalClientId">
              Paypal Client Id
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="paypalClientId"
              name="paypalClientId"
              value={paymentSettings?.paypalClientId}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="mb-4 mt-md-3">
            <label className="form-label" htmlFor="paypalSecret">
              Paypal Secret
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="paypalSecret"
              name="paypalSecret"
              value={paymentSettings?.paypalSecret}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="block-content block-content-full block-content-sm text-end">
        <button
          type="button"
          className="btn btn-alt-primary px-4"
          onClick={handleSubmit}
        >
          Update
        </button>
      </div>
    </div>
  );
}

export default PaymentSetting;
