import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { setSettings } from "../../redux/actions/settings";

function GeneralSetting() {
  const theme = useSelector((state) => state.settings.settings);
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    institutionName: theme?.settingsGeneral?.institutionName || "",
    address: theme?.settingsGeneral?.address || "",
    email: theme?.settingsGeneral?.email || "",
    telephone: theme?.settingsGeneral?.telephone || "",
    mobile: theme?.settingsGeneral?.mobile || "",
    tShirt: theme?.settingsGeneral?.tShirt || "",
    parishesName: theme?.settingsGeneral?.parishesName || "",
    message: theme?.settingsGeneral?.message || "",
    userDebts: theme?.settingsGeneral?.userDebts || "true",
    electronicPayment: theme?.settingsGeneral?.electronicPayment || "true",
  });

  useEffect(() => {
    setFormValues({
      institutionName: theme?.settingsGeneral?.institutionName || "",
      address: theme?.settingsGeneral?.address || "",
      email: theme?.settingsGeneral?.email || "",
      telephone: theme?.settingsGeneral?.telephone || "",
      mobile: theme?.settingsGeneral?.mobile || "",
      tShirt: theme?.settingsGeneral?.tShirt || "",
      parishesName: theme?.settingsGeneral?.parishesName || "",
      message: theme?.settingsGeneral?.message || "",
      userDebts: theme?.settingsGeneral?.userDebts.toString() || "",
      electronicPayment:
        theme?.settingsGeneral?.electronicPayment.toString() || "",
    });
    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url =
      `${process.env.REACT_APP_API_URL}/admin/settingsGeneral/updateRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formValues),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Settings updated successfully!");
        dispatch(setSettings(data.data));
      } else {
        throw new Error(data.message || "An error occurred");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Failed to update settings.");
    }
  };
  return (
    <div className="row push">
      <div className="col-lg-12">
        <h1 className="block-title fs-4 fw-bolder">General Settings</h1>
      </div>
      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4 mt-3">
              <label className="form-label" htmlFor="institutionName">
                Name of Institution
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="institutionName"
                name="institutionName"
                value={formValues?.institutionName}
                onChange={onChange}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-4 mt-md-3">
              <label className="form-label" htmlFor="address">
                Address
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="address"
                name="address"
                value={formValues?.address}
                onChange={onChange}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                className="form-control form-control-lg"
                id="email"
                name="email"
                value={formValues?.email}
                onChange={onChange}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" htmlFor="telephone">
                Telephone
              </label>
              <input
                type="tel"
                className="form-control form-control-lg"
                id="telephone"
                name="telephone"
                value={formValues?.telephone}
                onChange={onChange}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" htmlFor="mobile">
                Mobile
              </label>
              <input
                type="tel"
                className="form-control form-control-lg"
                id="mobile"
                name="mobile"
                value={formValues?.mobile}
                onChange={onChange}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" htmlFor="tShirt">
                T-Shirt
              </label>
              <textarea
                className="form-control form-control-lg"
                id="tShirt"
                name="tShirt"
                rows="3"
                value={formValues?.tShirt}
                onChange={onChange}
              ></textarea>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" htmlFor="parishesName">
                Name Of Parishes
              </label>
              <textarea
                className="form-control form-control-lg"
                id="parishesName"
                name="parishesName"
                rows="4"
                value={formValues?.parishesName}
                onChange={onChange}
              ></textarea>
            </div>
          </div>



          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" htmlFor="message">
                Message
              </label>
              <textarea
                className="form-control form-control-lg"
                id="message"
                name="message"
                rows="4"
                value={formValues?.message}
                onChange={onChange}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" htmlFor="userDebts">
                Debts For User
              </label>
              <div className="space-x-2">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input me-1"
                    type="radio"
                    name="userDebts"
                    value="true"
                    checked={formValues.userDebts === "true"}
                    onChange={onChange}
                  />
                  <label className="form-check-label ms-1" htmlFor="userDebts1">
                    Enable
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input me-1"
                    type="radio"
                    name="userDebts"
                    value="false"
                    checked={formValues.userDebts === "false"}
                    onChange={onChange}
                  />
                  <label className="form-check-label ms-1" htmlFor="userDebts2">
                    Disable
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" htmlFor="electronicPayment">
                Electronic Payment
              </label>
              <div className="space-x-2">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="electronicPayment1"
                    name="electronicPayment"
                    value="true"
                    checked={formValues.electronicPayment === "true"}
                    onChange={onChange}
                  />
                  <label
                    className="form-check-label ms-1"
                    htmlFor="electronicPayment1"
                  >
                    Enable
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="electronicPayment2"
                    name="electronicPayment"
                    value="false"
                    checked={formValues.electronicPayment === "false"}
                    onChange={onChange}
                  />
                  <label
                    className="form-check-label ms-1"
                    htmlFor="electronicPayment2"
                  >
                    Disable
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block-content block-content-full block-content-sm text-end">
          <button
            type="submit"
            className="btn btn-alt-primary px-4"
            onClick={handleSubmit}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}

export default GeneralSetting;
