import React, { useState, useEffect } from "react";
import { Gallery } from "react-grid-gallery";

function ImageGallery() {
  const [images, setImages] = useState([]);
  const [displayImages, setDisplayImages] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/user/event/findRandomEventImages`;
    const options = { method: "GET" };

    const fetchData = async () => {
      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.status === "success") {
          const formattedImages = data?.data?.map((image) => ({
            src: image,
            thumbnail: image,
            width: 70,
            height: 50,
            caption: `Image ${image}`,
          }));
          setImages(formattedImages);
          setDisplayImages(getInitialRandomImages(formattedImages));
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (images.length === 0) return;

    const interval = setInterval(() => {
      changeOneImageRandomly();
    }, 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [images, displayImages]);

  const getInitialRandomImages = (imagesArray) => {
    const shuffled = imagesArray.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 9);
  };

  const changeOneImageRandomly = () => {
    if (images.length === 0 || displayImages.length === 0) return;

    const newDisplayImages = [...displayImages];
    const randomIndex = Math.floor(Math.random() * displayImages.length);
    let newRandomImage;

    do {
      newRandomImage = images[Math.floor(Math.random() * images.length)];
      // eslint-disable-next-line
    } while (displayImages.some((img) => img.src === newRandomImage.src));
    newDisplayImages[randomIndex] = newRandomImage;
    setDisplayImages(newDisplayImages);
  };

  return (
    <div className="d-none d-md-block mt-6">
      <Gallery images={displayImages} margin={4} enableImageSelection={false} />
    </div>
  );
}

export default ImageGallery;
