import React from "react";
import { useSelector } from "react-redux";

function SchoolInfo({ formData, onChange }) {
  const theme = useSelector((state) => state.settings.settings);
  const parishNames = theme?.settingsGeneral?.parishesName
    .split(";")
    .map((name) => name.trim())
    .filter((name) => name !== "");
  return (
    <div className=" vh-50 my-0">
      <div className="row">
        <div className="col-md-6">
          <div className="search mb-4">
            <i className="fa fa-building-columns text-center pe-2 icon"></i>
            <select
              className=" form-control form-control-alt rounded form-select form-select-lg mb-4 py-3"
              aria-label=".form-select-lg example"
              style={{ paddingLeft: "60px" }}
              name="schoolType"
              value={formData.schoolType}
              onChange={onChange}
            >
              <option disabled value="" selected>
                Tipo di scuola
              </option>
              <option value="SCUOLA MATERNA">SCUOLA MATERNA</option>
              <option value="SCUOLA PRIMARIA">SCUOLA PRIMARIA</option>
              <option value="SCUOLA SECONDARIA DI PRIMO GRADO">
                SCUOLA SECONDARIA DI PRIMO GRADO
              </option>
              <option value="SCUOLA SECONDARIA DI SECONDO GRADO">
                SCUOLA SECONDARIA DI SECONDO GRADO
              </option>
              <option value="UNIVERSITA">UNIVERSITA&apos;</option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="search mb-4">
            <i className="fa fa-building-columns text-center pe-2 icon"></i>
            <input
              type="text"
              className="form-control form-control-alt rounded"
              name="schoolName"
              value={formData.schoolName}
              onChange={onChange}
              placeholder="Nome della scuola"
              style={{ paddingLeft: "35px" }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="search mb-4">
            <i className="fa fa-building-columns text-center pe-2 icon"></i>
            <select
              name="schoolBlonging"
              value={formData.schoolBlonging}
              onChange={onChange}
              className=" form-control form-control-alt rounded form-select form-select-lg mb-4 py-3"
              aria-label=".form-select-lg example"
              style={{ paddingLeft: "60px" }}
              required
              data-step="3"
            >
              <option disabled value="" selected>
                Parrocchia D'Appartenenza*
              </option>
              {parishNames?.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
              <option value="Altra">Altra</option>
            </select>
          </div>
        </div>
        {formData?.schoolBlonging === "Altra" ? (
          <div className="col-md-6">
            <div className="search mb-4">
              <i className="fa fa-building-columns text-center pe-2 icon"></i>
              <input
                type="text"
                className="form-control form-control-alt rounded"
                placeholder="Altra Parrocchia di appartenenza"
                name="otherParish"
                value={formData.otherParish}
                onChange={onChange}
                style={{ paddingLeft: "35px" }}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="mb-2 mt-4">
          Nome delle persone autorizzate a prelevare il minore
        </div>
        <div className="col-md-12">
          <div className="mb-4">
            <input
              type="text"
              className="form-control form-control-alt rounded py-3"
              name="schoolAuthority"
              value={formData.schoolAuthority}
              onChange={onChange}
              placeholder="In caso l’utente sia minore <14 anni, dichiara nome e cognome dei maggiorenni autorizzati al ritiro"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolInfo;
