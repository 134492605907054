import React, { useEffect, useState } from "react";
import UserTable from "./UserTable";
import $ from "jquery";
import "datatables.net-bs5";

function Main() {
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [changeStatus, setChangeStatus] = useState(false);
  const [filters, setFilters] = useState({
    state: "",
    role: "",
    search: "",
    rfid: "",
    age: ""
  });

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const filterUsers = async () => {
      const newFilteredUsers = allUsers.filter((user) => {
        const firstName = user.firstName ? user.firstName.toLowerCase() : "";
        const lastName = user.lastName ? user.lastName.toLowerCase() : "";
        const rfid = user.rfid ? user.rfid : "";
        const age = user.age ? user.age.toString() : ""; // Assuming age is a number
  
        return (
          (filters.state === "" ||
            (filters.state === "Active" && user.accountActive) ||
            (filters.state === "Inactive" && !user.accountActive)) &&
          (filters.role === "" || user.role === filters.role) &&
          (firstName.includes(filters.search.toLowerCase()) ||
            lastName.includes(filters.search.toLowerCase())) &&
          (filters.rfid === "" || rfid.includes(filters.rfid)) &&
          (filters.age === "" || age.includes(filters.age))
        );
      });
      setFilteredUsers(newFilteredUsers);
    };
  
    filterUsers();
  }, [filters, allUsers]);
  

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/user/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
        setAllUsers(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [changeStatus]);

  useEffect(() => {
    if (filteredUsers.length > 0) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const table = $("#userDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
        sort: false,
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        table.destroy();
      };
    }
  }, [filteredUsers]);

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className="fa  fa-user me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">Users</h1>
            </div>
            <UserTable
              allUsers={filteredUsers}
              setChangeStatus={setChangeStatus}
              filters={filters}
              handleFilterChange={handleFilterChange}
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export default Main;
