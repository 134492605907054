import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setUser } from "../../redux/actions/userProfile";
import ProfilePic from "./ProfilePic";

function ProfileEdit() {
  const userinfo = useSelector((state) => state.userDetails.user);
  const theme = useSelector((state) => state.settings.settings);
  const parishNames = theme?.settingsGeneral.parishesName
    .split(";")
    .map((name) => name.trim())
    .filter((name) => name !== "");
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    recordId: userinfo?.id || "",
    firstName: userinfo.firstName,
    lastName: userinfo?.lastName || "",
    email: userinfo?.email || "",
    birthday: userinfo?.birthday || "",
    birthPlace: userinfo?.birthPlace || "",
    nationality: userinfo?.nationality || "",
    sex: userinfo?.sex || "",
    shirtSize: userinfo?.shirtSize || "",
    fiscalCode: userinfo?.fiscalCode || "",
    residentialAddress: userinfo?.residentialAddress || "",
    houseNumber: userinfo?.houseNumber || "",
    city: userinfo?.city || "",
    province: userinfo?.province || "",
    phonePersonal: userinfo?.phonePersonal || "",
    phoneFather: userinfo?.phoneFather || "",
    phoneMother: userinfo?.phoneMother || "",
    phoneCustom1: {
      key: userinfo?.phoneCustom1?.key || "Name",
      value: userinfo?.phoneCustom1?.value || "",
    },
    phoneCustom2: {
      key: userinfo?.phoneCustom2?.key || "Name",
      value: userinfo?.phoneCustom2?.value || "",
    },
    schoolType: userinfo?.schoolType || "",
    schoolName: userinfo?.schoolName || "",
    schoolBlonging: userinfo?.schoolBlonging || "",
    schoolAuthority: userinfo?.schoolAuthority || "",
    rfid: userinfo?.rfid || "",
    maxSpending: userinfo?.maxSpending || "",
    fatherName: userinfo?.fatherName || "",
    motherName: userinfo?.motherName || "",
    otherParish: userinfo?.otherParish || "",

  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleInputChange2 = (event, fieldName) => {
    const { value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [fieldName]: {
        ...prevData[fieldName],
        value: value,
      },
    }));
  };
  const handleSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/user/updateRecord`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(userData),
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Update Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(setUser(data.data));
      } else {
        throw new Error(data.message || "Failed to save!");
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to save!",
        text: error.message || "An error occurred while saving.",
      });
    }
  };

  const handleSelectChange = (event, fieldName) => {
    const { value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  const handleCustomPhoneEdit = (event, fieldName, key) => {
    const newValue = event.target.textContent.trim();
    setUserData((prevData) => ({
      ...prevData,
      [fieldName]: {
        ...prevData[fieldName],
        [key]: newValue,
      },
    }));
  };
  const options = [
    "AG",
    "AL",
    "AN",
    "AO",
    "AR",
    "AP",
    "AT",
    "AV",
    "BA",
    "BT",
    "BL",
    "BN",
    "BG",
    "BI",
    "BO",
    "BZ",
    "BS",
    "BR",
    "CA",
    "CL",
    "CB",
    "CI",
    "CE",
    "CT",
    "CZ",
    "CH",
    "CO",
    "CS",
    "CR",
    "KR",
    "CN",
    "EN",
    "FM",
    "FE",
    "FI",
    "FG",
    "FC",
    "FR",
    "GE",
    "GO",
    "GR",
    "IM",
    "IS",
    "SP",
    "AQ",
    "LT",
    "LE",
    "LC",
    "LI",
    "LO",
    "LU",
    "MC",
    "MN",
    "MS",
    "MT",
    "ME",
    "MI",
    "MO",
    "MB",
    "NA",
    "NO",
    "NU",
    "OT",
    "OR",
    "PD",
    "PA",
    "PR",
    "PV",
    "PG",
    "PU",
    "PE",
    "PC",
    "PI",
    "PT",
    "PN",
    "PZ",
    "PO",
    "RG",
    "RA",
    "RC",
    "RE",
    "RI",
    "RN",
    "RM",
    "RO",
    "SA",
    "VS",
    "SS",
    "SV",
    "SI",
    "SR",
    "SO",
    "TA",
    "TE",
    "TR",
    "TO",
    "OG",
    "TP",
    "TN",
    "TV",
    "TS",
    "UD",
    "VA",
    "VE",
    "VB",
    "VC",
    "VR",
    "VV",
    "VI",
    "VT",
  ];
  const optionSchool = [
    { value: "SCUOLA MATERNA", label: "Scuola Materna" },
    { value: "SCUOLA PRIMARIA", label: "Scuola Primaria" },
    {
      value: "SCUOLA SECONDARIA DI PRIMO GRADO",
      label: "Scuola Secondaria di Primo Grado",
    },
    {
      value: "SCUOLA SECONDARIA DI SECONDO GRADO",
      label: "Scuola Secondaria di Secondo Grado",
    },
    { value: "UNIVERSITA", label: "Università" },
  ];
  return (
    <div className="block block-rounded">
      <div className="block-header block-header-default">
        <i className="fa  fa-user me-3 text-warning"></i>
        <h1 className="block-title fs-4 fw-bolder">Personal Information</h1>
      </div>
      <ProfilePic />
      <div className="container">
        <div className="block-content ">
          <form>
            <div className="row push">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-4 mt-3">
                    <label className="form-label" for="example-text-input">
                      First Name
                    </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="firstName"
                      value={userData.firstName}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-4 mt-md-3">
                    <label className="form-label" for="example-text-input">
                      Last Name
                    </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="lastName"
                      value={userData.lastName}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4 mt-md-3">
                    <label className="form-label" for="example-text-input">
                      Email Address
                    </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      id="example-text-input"
                      name="email"
                      value={userData?.email}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-password-input">
                      Password
                    </label>
                    <input
                      type="password"
                      readOnly
                      className="form-control form-control-lg"
                      placeholder="******"
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-date-input">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      readOnly
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="birthday"
                      value={userData?.birthday}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-text-input">
                      Place of Birth{" "}
                    </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="birthPlace"
                      value={userData?.birthPlace}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-text-input">
                      Residential Address{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="residentialAddress"
                      value={userData?.residentialAddress}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-text-input">
                      House Number{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="houseNumber"
                      value={userData?.houseNumber}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-text-input">
                      City{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="city"
                      value={userData?.city}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-text-input">
                      Province{" "}
                    </label>
                    <select
                      className="form-select form-select-lg mb-4"
                      aria-label=".form-select-lg example"
                      onChange={(event) =>
                        handleSelectChange(event, "province")
                      }
                    >
                      <option selected disabled value="">
                        Province
                      </option>
                      {options.map((option, index) => (
                        <option
                          key={index}
                          value={option}
                          selected={
                            userData?.province === option ? true : false
                          }
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="mb-4">
                    <label className="form-label" for="example-text-input">
                    Fiscal Code
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="fiscalCode"
                      value={userData?.fiscalCode}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-text-input">
                      Type Of School{" "}
                    </label>
                    <select
                      onChange={(event) =>
                        handleSelectChange(event, "schoolType")
                      }
                      className="form-select form-select-lg mb-4"
                      aria-label=".form-select-lg example"
                    >
                      <option selected value="" disabled>
                        Select School
                      </option>
                      {optionSchool?.map((option, index) => (
                        <option
                          key={index}
                          value={option?.value}
                          selected={
                            userData?.schoolType === option?.value
                              ? true
                              : false
                          }
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className=" mb-4">
                    <label className="form-label" for="example-text-input">
                      School Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="schoolName"
                      value={userData?.schoolName}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className=" mb-4">
                    <label className="form-label" for="example-text-input">
                      Parish Of Belonging
                    </label>
                    <select
                      onChange={(event) =>
                        handleSelectChange(event, "schoolBlonging")
                      }
                      className="form-select form-select-lg mb-4"
                      aria-label=".form-select-lg example"
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      {parishNames?.map((name, index) => (
                        <option
                          key={index}
                          value={name}
                          selected={
                            userData?.schoolBlonging === name ? true : false
                          }
                        >
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-tel-input">
                      Other Parish
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="otherParish"
                      value={userData?.otherParish}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-tel-input">
                      Personal Phone
                    </label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="phonePersonal"
                      value={userData?.phonePersonal}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-tel-input">
                      Father’s Phone{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="phoneFather"
                      value={userData?.phoneFather}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-tel-input">
                      Mother’s Phone{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="phoneMother"
                      value={userData?.phoneMother}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div>
                    <label className="form-label">Name</label>
                    <label className="form-label ms-5">Phone Number</label>
                  </div>
                  <div className="input-group mb-4">
                    <span
                      className="input-group-text"
                      onBlur={(event) =>
                        handleCustomPhoneEdit(event, "phoneCustom1", "key")
                      }
                      contentEditable="true"
                    >
                      {userData?.phoneCustom1.key}
                    </span>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      onChange={(event) =>
                        handleInputChange2(event, "phoneCustom1")
                      }
                      name="value"
                      value={userData?.phoneCustom1.value}
                      placeholder="Phone Number "
                      style={{
                        borderTopRightRadius: "0.5rem",
                        borderBottomRightRadius: "0.5rem",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <label className="form-label">Name</label>
                    <label className="form-label ms-5">Phone Number</label>
                  </div>
                  <div className="input-group mb-4">
                    <span
                      className="input-group-text"
                      onBlur={(event) =>
                        handleCustomPhoneEdit(event, "phoneCustom2", "key")
                      }
                      contentEditable="true"
                    >
                      {userData?.phoneCustom2.key}
                    </span>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      onChange={(event) =>
                        handleInputChange2(event, "phoneCustom2")
                      }
                      name="value"
                      value={userData?.phoneCustom2.value}
                      placeholder="Phone Number "
                      style={{
                        borderTopRightRadius: "0.5rem",
                        borderBottomRightRadius: "0.5rem",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label" for="example-tel-input">
                      RFID Code{" "}
                    </label>
                    <input
                      type="number"
                      readOnly
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      name="example-tel-input"
                      value={userData?.rfid}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label" for="example-tel-input">
                      Father Name and Surname
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      value={userData?.fatherName}
                      name="fatherName"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label" for="example-tel-input">
                      Mother Name and Surname
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      value={userData?.motherName}
                      name="motherName"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="mb-4">
                    <label className="form-label" for="example-tel-input">
                      Maximum amount of daily spending at the bar (€){" "}
                    </label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                      value={userData?.maxSpending}
                      name="maxSpending"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg me-md-10 me-5"
                      style={{ width: "120px" }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-success btn-lg"
                      style={{ width: "120px" }}
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProfileEdit;
