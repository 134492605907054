import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CloningModal from "./CloningModal";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { toast } from "react-toastify";
import moment from "moment";
import { useSelector } from "react-redux";
import QrCode from "./QrCode";
import Loader from "../Common/Loader";

function EventtableForm({ events, fetching }) {
  function getBadgeClass(state) {
    switch (state) {
      case "Waiting Subscription":
        return "bg-warning";
      case "Subscription Opened":
        return "bg-success";
      case "Event Started":
        return "bg-primary";
      default:
        return "bg-danger";
    }
  }

  const navigate = useNavigate();

  const user = useSelector((state) => state.userDetails.user);

  const currentUserId = user.id;

  const handleExport = (event) => {
    const { value } = event.target;

    if (value === "excel") {
      exportToExcel();
    } else if (value === "pdf") {
      exportToPDF();
    }
  };

  const exportToExcel = () => {
    const headers = [
      [
        "NAME",
        "SUBSCRIPTION START",
        "EVENT START",
        "PRICE",
        "NO OF USER SUBSCRIBED",
        "EVENT TAG",
        "EVENT STATE",
      ],
    ];

    const data = events.map((event) => [
      event.eventName,
      new Date(event.subscriptionStartDate).toLocaleDateString(),
      new Date(event.startDate).toLocaleDateString(),
      event.personalizedRoles.map((role) => role.price).join(", "),
      "5",
      event.tag.key,
      event.subscriptionState,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([...headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Events");
    XLSX.writeFile(workbook, "events.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = [
      "NAME",
      "SUBSCRIPTION START",
      "EVENT START",
      "PRICE",
      "NO OF USER SUBSCRIBED",
      "EVENT TAG",
      "EVENT STATE",
    ];
    const tableRows = [];

    events.forEach((event) => {
      const eventData = [
        event.eventName,
        new Date(event.subscriptionStartDate).toLocaleDateString(),
        new Date(event.startDate).toLocaleDateString(),
        event.personalizedRoles.map((role) => role.price).join(", "),
        "5",
        event.tag.key,
        event.subscriptionState,
      ];
      tableRows.push(eventData);
    });

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
    });

    doc.save("events.pdf");
  };
  const [selectedEvent, setSelectedEvent] = useState({ id: null, name: "" });
  const handleDelete = async (id) => {
    const userConfirmed = window.confirm("Are you sure you want to Delete?");
    if (!userConfirmed) {
      return;
    }
    const url = `${process.env.REACT_APP_API_URL}/user/event/deleteById`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ recordId: id }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Event deleted successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error("Failed to delete Event:", data.message);
      }
    } catch (error) {
      toast.error("Error deleting Event:", error);
    }
  };

  useEffect(() => {
    if (
      events?.maxSubscribers &&
      events?.subscriptions >= events?.maxSubscribers
    ) {
      events.subscriptionState = "Subscription Closed";
    }
  }, [events]);
  const activateChat = async (senderId, receiverId) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/chat/addRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        senderId,
        receiverId,
        chatType: "event",
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status !== "success") {
        toast.success("Something went wrong");
      } else {
        navigate(`/chat?active=${data.data._id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred: " + error.message);
    }
  };

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className="fa fa-calendar me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">Events</h1>
              <Link to="/events">
                <i className="fa fa-1x fa-eye"></i>
              </Link>
            </div>
            {fetching ? (
              <Loader />
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="block block-rounded">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <div className="mt-3 d-flex justify-content-between">
                            <select
                              className="btn btn-lg btn-alt-primary"
                              onChange={handleExport}
                            >
                              <option value="" selected disabled>
                                Export As
                              </option>
                              <option value="excel">Excel File</option>
                              <option value="pdf">PDF File</option>
                            </select>
                            {user.role === "0" || user.role === "1" ? (
                              <Link
                                to="/event/create"
                                className="btn btn-lg btn-alt-primary px-4"
                              >
                                Create Event
                              </Link>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="my-md-4 my-3">
                        <div style={{ overflowX: "auto" }}>
                          <table
                            id="eventDataTable"
                            className="js-table-checkable table table-hover table-vcenter table-responsive"
                            style={{ fontSize: "14px" }}
                          >
                            <thead style={{ fontSize: "14px" }}>
                              <tr>
                                <th>SUBSCRIBED</th>
                                <th>NAME</th>
                                <th>SUBSCRIPTION START</th>
                                <th>EVENT START</th>
                                <th>PRICE & Role</th>
                                <th>NO OF USER SUBSCRIBED</th>
                                <th>EVENT TAG</th>
                                <th>EVENT STATE</th>
                                <th className="text-center">ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              {events.map((event) => {

                                console.log(event)
                                return (
                                  <tr key={event.id}>
                                    <td>
                                      {event?.subscribed ? (
                                        <span className="badge bg-danger rounded">
                                          I
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>{event.eventName}</td>
                                    <td>
                                      {event.subscriptionStartDate &&
                                        moment(
                                          event.subscriptionStartDate
                                        ).format("DD/MM/YYYY")}
                                    </td>
                                    <td>
                                      {moment(event.startDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {event.personalizedRoles.map(
                                        (role, index) => (
                                          <div
                                            className="text-nowrap"
                                            key={index}
                                          >
                                            {role.name} ( {role.price},00€)
                                            {index <
                                              event.personalizedRoles.length -
                                                1 && <hr />}
                                          </div>
                                        )
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {event?.subscribedUsersCount} /{" "}
                                      {event?.maxSubscribers}
                                    </td>
                                    <td>
                                      <span
                                        className={`badge rounded ${
                                          event?.tag?.value === "yellow"
                                            ? "text-black"
                                            : ""
                                        }`}
                                        style={{
                                          backgroundColor: event?.tag?.value,
                                        }}
                                      >
                                        {event?.tag?.key}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={`badge rounded ${getBadgeClass(
                                          event?.subscriptionState
                                        )}`}
                                      >
                                        {event?.subscriptionState}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-center">
                                        {user.role === "0" ||
                                        user.role === "1" ? (
                                          <>
                                            <Link
                                              to={`/update-event/${event.id}`}
                                              className="btn btn-alt-primary btn-sm rounded-0 m-1"
                                            >
                                              <i className="fa fa-pen"></i>
                                            </Link>
                                            <button
                                              className="btn btn-alt-primary btn-sm rounded-0 m-1"
                                              onClick={() =>
                                                setSelectedEvent({
                                                  id: event.id,
                                                  name: event.eventName,
                                                })
                                              }
                                              data-bs-toggle="modal"
                                              data-bs-target="#modal-large42"
                                            >
                                              <i className="fa fa-clone"></i>
                                            </button>

                                            <button
                                              onClick={() =>
                                                handleDelete(event.id)
                                              }
                                              className="btn btn-alt-primary btn-sm rounded-0 m-1"
                                            >
                                              <i className="fa fa-minus"></i>
                                            </button>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <button
                                          className="btn btn-alt-primary btn-sm rounded-0 m-1"
                                          onClick={() =>
                                            setSelectedEvent({
                                              id: event.id,
                                              name: event.eventName,
                                            })
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#modal-qrcode"
                                        >
                                          <i className="fa fa-qrcode"></i>
                                        </button>
                                        {(user.role && user.role === "0") ||
                                        event.authorId === currentUserId ? (
                                          <button
                                            className="btn btn-primary btn-sm rounded-0 mx-1 mt-1"
                                            onClick={() =>
                                              activateChat(
                                                event.authorId,
                                                event.id
                                              )
                                            }
                                          >
                                            <i className="fab fa-rocketchat"></i>
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <CloningModal
                                        eventId={selectedEvent.id}
                                        eventName={selectedEvent.name}
                                      />
                                      <QrCode
                                        eventId={selectedEvent.id}
                                        eventName={selectedEvent.name}
                                      />
                                      <div className="d-flex">
                                        {user?.role === "5" ? (
                                          ""
                                        ) : (
                                          <Link
                                            to={`/eventmembers/${event.id}`}
                                            className="btn btn-alt-primary btn-sm rounded-0 m-1"
                                          >
                                            Users
                                          </Link>
                                        )}
                                        <Link
                                          to={`/event/${event.id}`}
                                          type="button"
                                          className="btn btn-alt-primary btn-sm rounded-0 m-1"
                                        >
                                          View
                                        </Link>
                                        {user.role === "0" ||
                                        user.role === "1" ||
                                        user.role === "2" ? (
                                          <Link
                                            to={`/eventbalance/${event.balanceSelection.id}`}
                                            className="btn btn-alt-primary btn-sm rounded-0 m-1"
                                          >
                                            Balance
                                          </Link>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default EventtableForm;
