import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

function DetailModel({ id }) {
  const [balances, setBalances] = useState([]);
  const [selectedBalanceId, setSelectedBalanceId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/balanceSheet/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
        setBalances(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  const handleMove = async () => {
    if (!selectedBalanceId) {
      toast.error("Please select a balance sheet");
      return;
    }

    const url = "https://apis.easyoratorio.it/admin/moneyMovement/updateRecord";
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ balanceReference: selectedBalanceId, id: id }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Updated Successfully");
        window.location.reload();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Failed to update record:", error);
      toast.error("Failed to update record");
    }
  };

  return (
    <div
      className="modal"
      id="modal-largeDetail"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Move Movement</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="block block-rounded">
              <div className="container">
                <div className="block-content">
                  <select
                    name="balanceSheet"
                    id="balanceSheet"
                    className="form-select"
                    value={selectedBalanceId}
                    onChange={(e) => setSelectedBalanceId(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Balance Sheet
                    </option>
                    {balances?.map((bal, index) => (
                      <option key={index} value={bal?.id}>
                        {bal?.balanceName} ({bal?.description})
                      </option>
                    ))}
                  </select>
                  <div className="text-end my-3">
                    <button className="btn btn-primary" onClick={handleMove}>
                      Move
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailModel;
