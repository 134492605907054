import moment from "moment";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ViewNote from "./ViewNote";

function TitlememberTable2({ usersData, eventData }) {
  const [selectedUser, setSelectedUser] = useState(null);
  const { id } = useParams();
  const userinfo = useSelector((state) => state.userDetails.user);
  const handleRechargeClick = (user) => {
    setSelectedUser(user);
  };

  const handleUnsubscribeEvent = async (userId) => {
    const eventUnsubscribeConfirmed = window.confirm(
      "Are you sure you want to Unsubscribe this Event?"
    );
    if (!eventUnsubscribeConfirmed) {
      return;
    }
    const url = `${process.env.REACT_APP_API_URL}/user/eventSubscription/deleteByParams`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        userId: userId,
        eventId: id,
        moneyOperator: userinfo?.id,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Event Unsubscribed successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(data.message || "Unsubscription failed");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="block block-rounded">
      <div className="container">
        <div className="my-md-4 my-3">
          <div style={{ overflowX: "auto" }}>
            <table
              id="subscribedUsersDataTable"
              className="js-table-checkable table table-hover table-vcenter table-responsive"
            >
              <thead>
                <tr>
                  <th>FIRST NAME</th>
                  <th>LAST NAME</th>
                  <th>DATE OF BIRTH</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {usersData?.map((user, index) => (
                  <tr key={index}>
                    <td>{user?.firstName}</td>
                    <td>{user?.lastName}</td>
                    <td>{moment(user?.birthday).format("DD/MM/YYYY")}</td>
                    <td>
                      {["0", "1", "2", "3"].includes(userinfo?.role) ? (
                        <button
                          className="btn btn-danger me-3"
                          onClick={() => handleUnsubscribeEvent(user?.id)}
                        >
                          Unsubscribe
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#modal-note"
                        className="btn btn-primary"
                        onClick={() => handleRechargeClick(user)}
                      >
                        Note
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ViewNote userData={selectedUser} eventData={eventData} />
    </div>
  );
}

export default TitlememberTable2;
