import React, { useEffect } from "react";
import Main from "../components/login/Main";
import { useNavigate } from "react-router-dom";
function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Main />
    </div>
  );
}

export default Login;
