import React, { useEffect, useState } from "react";
import TitlememberTable1 from "./TitlememberTable1";
import TitlememberTable2 from "./TitlememberTable2";
import { useParams } from "react-router-dom";
import $ from "jquery";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import moment from "moment";
import Loader from "../Common/Loader";

function TitleMember() {
  const { id } = useParams();
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const fields =
        "eventName,subscriptionState,startDate,endDate,firstName,lastName,birthday";
      const url = `${process.env.REACT_APP_API_URL}/user/eventSubscription/getSubscribedAndUnsubscribedUsersByEventId?eventId=${id}&fields=${fields}`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.status === "success") {
          setEventData(result.data);
        } else {
          console.error("Error fetching data:", result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (eventData?.unSubscribedUsersData?.length > 0) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const table = $("#unsubscribedUsersDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
        sort: false,
        lengthMenu: [5, 10, 20, 50],
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        table.destroy();
      };
    }
  }, [eventData]);

  useEffect(() => {
    if (eventData?.subscribedUsersData?.length > 0) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const table = $("#subscribedUsersDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
        lengthMenu: [5, 10, 20, 50],
        sort: false,
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        table.destroy();
      };
    }
  }, [eventData]);

  const handleExport = (event) => {
    const exportType = event.target.value;
    const data = eventData.subscribedUsersData.map((user, index) => ({
      "Sr No": index + 1,
      "First Name": user.firstName,
      "Last Name": user.lastName,
      "Date of Birth": moment(user.birthday).format("DD/MM/YYYY"),
    }));

    if (exportType === "excel") {
      const worksheet = XLSX.utils.json_to_sheet([]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [[`Member of Event: ${eventData?.eventData?.eventName}`]],
        { origin: "A1" }
      );
      XLSX.utils.sheet_add_json(worksheet, data, {
        origin: "A2",
        skipHeader: false,
      });
      XLSX.utils.book_append_sheet(workbook, worksheet, "Subscribed Users");
      XLSX.writeFile(workbook, `${eventData?.eventData?.eventName}.xlsx`);
    } else if (exportType === "pdf") {
      const doc = new jsPDF();
      doc.text(`Member of Event: ${eventData?.eventData?.eventName}`, 10, 10);
      doc.autoTable({
        startY: 20,
        head: [["Sr No", "First Name", "Last Name", "Date of Birth"]],
        body: data.map((row) => [
          row["Sr No"],
          row["First Name"],
          row["Last Name"],
          row["Date of Birth"],
        ]),
      });
      doc.save(`${eventData?.eventData?.eventName}.pdf`);
    }
  };


  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <h1 className="block-title fs-4 fw-bolder">
                Members of event: {eventData?.eventData?.eventName}
              </h1>
            </div>
            {eventData ? (
              <>
                {eventData?.eventData?.subscriptionState === "Event Ended" ? (
                  <div className="row">
                    <div className="col-md-6 offset-md-3 mt-3">
                      <div className="alert alert-danger text-center">
                        Event Ended
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="mt-2 ms-5">Unsubscribed Users</h4>
                    <TitlememberTable1
                      usersData={eventData?.unSubscribedUsersData}
                      eventData={eventData?.eventData}
                    />
                  </div>
                  <div className="col-12">
                    <div className="mt-3 d-flex justify-content-end me-5">
                      <select
                        className="btn btn-lg btn-alt-primary"
                        onChange={handleExport}
                      >
                        <option value="" selected disabled>
                          Export As
                        </option>
                        <option value="excel">Excel File</option>
                        <option value="pdf">PDF File</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h4 className="mt-4 ms-5">Subscribed Users</h4>
                    <TitlememberTable2
                      usersData={eventData?.subscribedUsersData}
                      eventData={eventData?.eventData}
                    />
                  </div>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default TitleMember;
