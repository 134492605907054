import React, { useEffect } from "react";
import $ from "jquery";

function TableGroup({
  group,
  selectedGroups,
  handleGroupSelection,
  filters,
  setFilters,
  clearFilters,
  applyFilters,
  applyReverseFilters,
}) {
  useEffect(() => {
    const table = $("#groupDataTable").DataTable({
      autoWidth: false,
      responsive: true,
      destroy: true,
      sort: false,
    });

    return () => {
      table.destroy();
    };
  }, [group]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  return (
    <div className="container">
      <div className="block-content">
        <h5>Select Groups</h5>
        <div className="row mb-4">
          <div className="col-md-4">
            <input
              type="text"
              name="groupName"
              value={filters.groupName}
              onChange={handleInputChange}
              placeholder="Filter by Group Name"
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="eventName"
              value={filters.eventName}
              onChange={handleInputChange}
              placeholder="Filter by Event Name"
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="tag"
              value={filters.tag}
              onChange={handleInputChange}
              placeholder="Filter by Tag"
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 d-flex flex-column flex-md-row gap-3 justify-content-end">
            <button className="btn btn-primary" onClick={applyFilters}>
              Apply Filters
            </button>
            <button className="btn btn-secondary" onClick={applyReverseFilters}>
              Reverse Filters
            </button>
            <button className="btn btn-light" onClick={clearFilters}>
              Clear Filters
            </button>
          </div>
        </div>
        <div style={{ overflowX: "auto" }}>
          <table
            id="groupDataTable"
            className="js-table-checkable table table-hover table-vcenter table-responsive"
          >
            <thead>
              <tr>
                <th></th>
                <th>NAME</th>
                <th>DESCRIPTION</th>
                <th>USER'S CAPACITY</th>
                <th>NUMBER OF USERS</th>
                <th>EVENT</th>
                <th>TAG</th>
              </tr>
            </thead>
            <tbody>
              {group?.map((item) => (
                <tr key={item?.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedGroups.includes(item.id)}
                      onChange={() => handleGroupSelection(item.id)}
                    />
                  </td>
                  <td>{item?.groupName}</td>
                  <td>{item?.description}</td>
                  <td>{item?.maxMembers === 1 ? "∞" : item?.maxMembers}</td>
                  <td>{item?.membersCount}</td>
                  <td>{item?.linkedEvent.eventName}</td>
                  <td>
                    <span
                      className={`badge rounded ${
                        item?.labelColor === "yellow" ? "text-black" : ""
                      }`}
                      style={{ backgroundColor: item?.labelColor }}
                    >
                      {item?.labelName}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TableGroup;
