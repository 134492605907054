import React, { useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function MultiUser({ groupId, selectedUsers }) {
  const [role, setRole] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}/admin/groupMember/addRecord`;

    for (let index = 0; index < selectedUsers.length; index++) {
      const userId = selectedUsers[index];
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          userId: userId,
          groupId: groupId,
          progressiveFlag: "0",
          role: role,
        }),
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.status === "success") {
          toast.success(`Member ${index + 1} added successfully`);
        } else {
          toast.error(`Error adding member ${index + 1}: ${data.message}`);
        }
      } catch (error) {
        console.error(`Error adding member ${index + 1}:`, error);
        toast.error(`Error adding member ${index + 1}`);
      }
    }

    // Reload after all requests have completed
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  return (
    <div
      className="modal"
      id="modal-multi"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal12-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Add User</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="my-7">
              <div className="container">
                <div className="container-fluid">
                  <div className="row">
                    <div className="d-flex justify-content-center">
                      <div className="col-md-6 col-12">
                        <form onSubmit={handleSubmit}>
                          <div className="mb-4 ">
                            <label className="form-label" htmlFor="role-input">
                              Define Your Role Here:{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              id="role-input"
                              name="role"
                              required
                              value={role}
                              onChange={(e) => setRole(e.target.value)}
                            />
                          </div>
                          <div className="text-end mt-md-6 mt-5">
                            <button
                              type="submit"
                              className="btn py-2 btn-alt-primary px-4 btn-lg"
                            >
                              Add
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiUser;
