import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

function DetailsUsers({ metaData, theme }) {
  function getBadgeClass(state) {
    switch (state) {
      case "Waiting Subscription":
        return "bg-warning";
      case "Subscription Opened":
        return "bg-success";
      case "Event Started":
        return "bg-primary";
      default:
        return "bg-danger";
    }
  }

  return (
    <div className="mx-auto">
      <div className="row">
        <div className="col-md-6">
          <div className="block block-themed shadow-lg block-rounded mb-5">
            <div className="block block-rounded">
              <h4 className="pt-3 ps-3 mb-0">Unsubscribed Events</h4>
              <div style={{ overflowX: "auto" }} className="block-content">
                <table className="table table-vcenter">
                  <thead className="thead-light">
                    <tr>
                      <th>Name</th>
                      <th>Date</th>
                      <th>State</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metaData?.nonSubscribedEvents?.map((event, index) => (
                      <tr key={index}>
                        <td>{event?.eventName}</td>
                        <td>
                          {moment(event?.eventId?.startDate).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>
                          <span
                            className={`badge rounded ${getBadgeClass(
                              event?.subscriptionState
                            )}`}
                          >
                            {event?.subscriptionState}
                          </span>
                        </td>
                        <td className="text-center">
                          <Link
                            to={`/event/${event?.id}`}
                            className="btn btn-primary btn-sm"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="block block-themed shadow-lg block-rounded mb-5">
            <div className="block block-rounded">
              <h4 className="pt-3 ps-3 mb-0">Subscribed Events</h4>
              <div className="block-content">
                <table className="table table-vcenter">
                  <thead className="thead-light">
                    <tr>
                      <th>Name</th>
                      <th>Date</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metaData?.subscribedEventsData?.map((event, index) => (
                      <tr key={index}>
                        <td>{event?.eventId?.eventName}</td>
                        <td>
                          {moment(event?.eventId?.startDate).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td className="text-center">
                          <Link
                            to={`/event/${event?.eventId?.id}`}
                            className="btn btn-primary btn-sm mt-1"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsUsers;
