import React from "react";
import Navbar from "../components/Common/Navbar";
import Header from "../components/Common/Header";
import Main from "../components/balance-sheet/moneny-moments/Main";

function MoneyMovements() {
  return (
    <div
      id="page-container"
      className="sidebar-o enable-page-overlay side-scroll page-header-modern main-content-boxed"
    >
      <Navbar />
      <Header />
      <Main />
    </div>
  );
}

export default MoneyMovements;
