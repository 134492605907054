import React, { useEffect, useState, useMemo } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function CreategroupModal() {
  const [optionsData, setOptionsData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formData, setFormData] = useState({
    groupName: "",
    linkedEvent: "",
    maxMembers: "",
    description: "",
    labelName: "",
    labelColor: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/event/getAll`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        setOptionsData(data.data);
      } catch (error) {
        console.error("Error fetching event data:", error);
        toast.error("Failed to load event data");
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleEventChange = (selectedOption) => {
    setSelectedEvent(selectedOption);
    setFormData((prevFormData) => ({
      ...prevFormData,
      linkedEvent: selectedOption ? selectedOption.value : "",
    }));
  };

  const eventOptions = useMemo(() => 
    optionsData.map((event) => ({
      value: event.id,
      label: `${event.eventName} - ${event.tag.key}`,
    })),
    [optionsData]
  );

  const handleSubmit = async () => {

    const url = `${process.env.REACT_APP_API_URL}/admin/group/addRecord`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Group Created Successfully");
        setTimeout(() => {
          window.location.reload()
        }, 1500);

        setSelectedEvent(null);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div
      className="modal"
      id="modal-large31"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Create Group</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="my-5">
              <div className="container">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label className="form-label" htmlFor="group-name-input">
                          Group Name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          name="groupName"
                          value={formData.groupName}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Linked Events</label>
                      <Select
                        id="linked-events-select"
                        name="linkedEvent"
                        options={eventOptions}
                        value={selectedEvent}
                        onChange={handleEventChange}
                        placeholder="Select an event"
                        isClearable
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label className="form-label" htmlFor="max-members-input">
                          Max Number Of Members
                        </label>
                        <input
                          type="number"
                          className="form-control form-control-lg"
                          name="maxMembers"
                          value={formData.maxMembers}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label className="form-label" htmlFor="description-input">
                          Description
                        </label>
                        <textarea
                          className="form-control form-control-lg"
                          id="description-input"
                          name="description"
                          value={formData.description}
                          onChange={onChange}
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <h2 className="block-title fw-bold">Personalized Label</h2>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4 mt-4">
                        <label className="form-label" htmlFor="label-input">
                          Label
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          name="labelName"
                          onChange={onChange}
                          value={formData.labelName}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4 mt-md-4">
                        <label className="form-label" htmlFor="color-select">
                          Color
                        </label>
                        <select
                          className="form-select form-select-lg"
                          name="labelColor"
                          onChange={onChange}
                          value={formData.labelColor}
                        >
                          <option disabled value="">
                            Select Color
                          </option>
                          <option value="red">Red</option>
                          <option value="yellow">Yellow</option>
                          <option value="brown">Brown</option>
                          <option value="green">Green</option>
                          <option value="orange">Orange</option>
                          <option value="blue">Blue</option>
                          <option value="pink">Pink</option>
                          <option value="purple">Purple</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-alt-primary px-4 btn-lg"
                      onClick={handleSubmit}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreategroupModal;
