import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

function GroupModal({ selectedUserId, previousGroupId }) {
  const [allGroups, setAllGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [role, setRole] = useState("");

  useEffect(() => {
    const fetchGroupsData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/group/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
        const groupOptions = data.map((group) => ({
          value: group?.id,
          label: group?.groupName,
        }));
        setAllGroups(groupOptions);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchGroupsData();
  }, []);

  const handleSelectChange = (selectedOption) => {
    setSelectedGroup(selectedOption);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedGroup) {
      alert("Please select a group.");
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/admin/groupMember/addRecord`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        userId: selectedUserId,
        groupId: selectedGroup.value,
        progressiveFlag: "0",
        role: role,
        previousGroupId,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Member Added To Other Group");
        setSelectedGroup(null);
        setRole("");
        setTimeout(() => {
          window.location.reload();
        }, []);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Failed to submit data:", error);
    }
  };

  return (
    <div
      className="modal"
      id="modal-large34"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Moving User</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="my-7">
              <div className="container">
                <div className="container-fluid">
                  <div className="row">
                    <div className="d-flex justify-content-center">
                      <div className="col-md-6 col-12">
                        <div className="mb-5">
                          <label className="form-label" htmlFor="sourceUser">
                            All Groups:
                          </label>
                          <Select
                            id="sourceUser"
                            options={allGroups}
                            onChange={handleSelectChange}
                            value={selectedGroup}
                            isClearable
                            name="sourceUser"
                          />
                        </div>
                        <div className="mt-5">
                          <label className="form-label" htmlFor="role">
                            Add Role:
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="role"
                            value={role}
                            onChange={handleRoleChange}
                          />
                        </div>
                        <div className="text-end mt-md-6 mt-5">
                          <button
                            type="button"
                            className="btn py-2 btn-success btn-lg px-4"
                            onClick={handleSubmit}
                          >
                            CONFIRM
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupModal;
