import React, { useState } from "react";
import CreategroupModal from "./CreategroupModal";
import EditModal from "./EditModal";
import CloneModal from "./CloneModal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function TableGroup({ group }) {
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleEditClick = (item) => {
    setSelectedGroup(item);
  };
  
  const navigate = useNavigate();

  const user = useSelector((state) => state.userDetails.user);

  const handleDeleteClick = async (id) => {
    const userConfirmed = window.confirm("Are you sure you want to delete?");
    if (!userConfirmed) {
      return;
    }
    const url = `${process.env.REACT_APP_API_URL}/admin/group/deleteById`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ recordId: id }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Group deleted successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("An error occurred while deleting the group");
      console.error(error);
    }
  };

  const activateChat = async (senderId, receiverId) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/chat/addRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        senderId,
        receiverId,
        chatType: "group",
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status !== "success") {
        toast.success("Something went wrong");
      } else {
        navigate(`/chat?active=${data.data._id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred: " + error.message);
    }
  };

  return (
    <div className="container">
      <div className="block-content">
        <div className="d-flex justify-content-end">
          <Link
            to={"/group-distribution"}
            className="btn btn-alt-primary btn-lg me-3"
          >
            Multiple Distribution
          </Link>
          {["0", "1"].includes(user?.role) ? (
            <button
              className="btn btn-alt-primary btn-lg"
              data-bs-toggle="modal"
              data-bs-target="#modal-large31"
            >
              Create Group
            </button>
          ) : (
            ""
          )}
        </div>
        <div style={{ overflowX: "auto" }}>
          <table
            id="groupDataTable"
            className="js-table-checkable table table-hover table-vcenter table-responsive"
          >
            <thead>
              <tr>
                <th>NAME</th>
                <th>DESCRIPTION</th>
                <th>USER'S CAPACITY</th>
                <th>NUMBER OF USERS</th>
                <th>EVENT</th>
                <th>TAG</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {group?.map((item) => (
                <tr key={item?.id}>
                  <td>{item?.groupName}</td>
                  <td>{item?.description}</td>
                  <td>{item?.maxMembers <= 1 ? "∞" : item?.maxMembers}</td>
                  <td>{item?.membersCount}</td>
                  <td>{item?.linkedEvent.eventName}</td>
                  <td>
                    <span
                      className={`badge rounded ${
                        item?.labelColor === "yellow" ? "text-black" : ""
                      }`}
                      style={{ backgroundColor: item?.labelColor }}
                    >
                      {item?.labelName}
                    </span>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center gap-1">
                      {["0", "1"].includes(user?.role) ? (
                        <button
                          className="btn btn-alt-primary btn-sm rounded-0"
                          data-bs-toggle="modal"
                          data-bs-target="#modal-large32"
                          onClick={() => handleEditClick(item)}
                        >
                          <i className="fa fa-pen"></i>
                        </button>
                      ) : (
                        ""
                      )}

                      <Link
                        to={`/group-list/${item?.id}`}
                        className="btn btn-primary btn-sm rounded-0"
                      >
                        <i className="fa fa-users"></i>
                      </Link>
                      {["0", "1"].includes(user?.role) ? (
                        <button
                          className="btn btn-alt-primary btn-sm rounded-0"
                          data-bs-toggle="modal"
                          data-bs-target="#modal-large33"
                          onClick={() => handleEditClick(item)}
                        >
                          <i className="fa fa-clone"></i>
                        </button>
                      ) : (
                        ""
                      )}
                      {user.role && (user.role === "0" || user.role === "1") ? (
                        <button
                          className="btn btn-alt-primary btn-sm rounded-0"
                          onClick={() =>
                            activateChat(item.linkedEvent.authorId, item.id)
                          }
                        >
                          <i className="fab fa-rocketchat"></i>
                        </button>
                      ) : (
                        ""
                      )}
                      {["0", "1"].includes(user?.role) ? (
                        <button
                          className="btn btn-alt-primary btn-sm rounded-0"
                          onClick={() => handleDeleteClick(item?.id)}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <CloneModal group={selectedGroup} />
        <EditModal group={selectedGroup} />
        <CreategroupModal />
      </div>
    </div>
  );
}

export default TableGroup;
