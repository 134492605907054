import React,{useState} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../components/Common/Loader";

function Test() {
  const [showDayboxes, setDayCheckboxes] = useState(false);
  const [showSubscription, setshowSubscription] = useState(false);
  const Theme = useSelector((state) => state.settings.settings);
  const handleDay = () => {
    setDayCheckboxes(!showDayboxes);
  };
  const handleSubscribe = () => {
    setshowSubscription(!showSubscription);
  };
  return (
    <div>
      <Loader/>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className=" fa fa-calendar me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">Events Summary</h1>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="block block-rounded">
                  <div className="container">
                    <div className="">
                      <div className="text-start my-md-5 my-3">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Name Of Event
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="example-text-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Type Of Event{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="example-text-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Subscription State{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="example-text-input"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Max Subscription{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="example-text-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-8">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-file-input"
                              >
                                Files{" "}
                              </label>
                              <div>
                                <i className="far fa-3x fa-file-pdf"></i>
                                <i className="far fa-3x fa-file-pdf ms-3"></i>
                                <i className="far fa-3x fa-file-pdf ms-3"></i>
                                <i className="far fa-3x fa-file-pdf ms-3"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-file-input"
                              >
                                Images{" "}
                              </label>
                              <div
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  top: "408px",
                                  left: "968px",
                                  gap: "0px",
                                  opacity: "0px",
                                  backgroundColor: "#D9D9D9",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-4 ">
                              <label
                                className="form-label"
                                for="example-textarea-input"
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control form-control-lg"
                                id="example-textarea-input"
                                name="example-textarea-input"
                                rows="4"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4 ">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Age Of Subscriber{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="example-text-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Name Of Incompatible Event{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="example-text-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-date-input"
                              >
                                Date Of Incompatible Event{" "}
                              </label>
                              <input
                                type="date"
                                className="form-control form-control-lg"
                                id="example-date-input"
                                name="example-date-input"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-date-input"
                              >
                                Start Date{" "}
                              </label>
                              <input
                                type="date"
                                className="form-control form-control-lg"
                                id="example-date-input"
                                name="example-date-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-date-input"
                              >
                                End Date{" "}
                              </label>
                              <input
                                type="date"
                                className="form-control form-control-lg"
                                id="example-date-input"
                                name="example-date-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Event Location{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="example-text-input"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-time-input"
                              >
                                Start Hour{" "}
                              </label>
                              <input
                                type="time"
                                className="form-control form-control-lg"
                                id="example-time-input"
                                name="example-time-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-time-input"
                              >
                                End Hour{" "}
                              </label>
                              <input
                                type="time"
                                className="form-control form-control-lg"
                                id="example-time-input"
                                name="example-time-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-date-input"
                              >
                                Start Date Of Subscription{" "}
                              </label>
                              <input
                                type="date"
                                className="form-control form-control-lg"
                                id="example-date-input"
                                name="example-date-input"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-date-input"
                              >
                                End Date Of Subscription{" "}
                              </label>
                              <input
                                type="date"
                                className="form-control form-control-lg"
                                id="example-date-input"
                                name="example-date-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Event Subscription{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="example-text-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Event Subscription{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="example-text-input"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label className="form-label">
                                Allow Users Unsubscription
                              </label>
                              <div className="space-y-2 mt-3">
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label className="form-label">Recurring Event</label>
                              <div className="space-y-2 my-3 ">
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={handleDay}
                                  />
                                </div>
                              </div>
                              {showDayboxes && (<div className="space-y-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{
                                      border: `2px solid ${Theme.settingsTheme.colorSelection}`,
                                    }}
                                    id="example-checkbox-default1"
                                  />
                                  <label
                                    className="form-check-label ms-3"
                                    for="example-checkbox-default1"
                                  >
                                    Monday
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{
                                      border: `2px solid ${Theme.settingsTheme.colorSelection}`,
                                    }}
                                    name="example-checkbox-default2"
                                  />
                                  <label
                                    className="form-check-label ms-3"
                                    for="example-checkbox-default2"
                                  >
                                    Tuesday
                                  </label>
                                </div>
                              </div>)}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label className="form-label">
                                Multiple Subscription
                              </label>
                              <div className="space-y-2 mt-3">
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={handleSubscribe}
                                  />
                                </div>
                              </div>
                            </div>
                            {showSubscription && (
                              <div className="mt-4">
                              <label
                                className="form-label"
                                for="example-number-input"
                              >
                                Max Subscription{" "}
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-lg"
                                id="example-number-input"
                                name="example-number-input"
                              />
                            </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Selected Balance{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="example-text-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Personalized Role{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="example-text-input"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className="form-label"
                                for="example-text-input"
                              >
                                Name Of Balance{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                id="example-text-input"
                                name="example-text-input"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <div className="mb-4 ">
                              <label
                                className="form-label"
                                for="example-textarea-input"
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control form-control-lg"
                                id="example-textarea-input"
                                name="example-textarea-input"
                                rows="4"
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label className="form-label">
                                Apply Personal Discount{" "}
                              </label>
                              <div className="space-y-2 mt-3">
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-4">
                          <button
                            type="button"
                            className="btn btn-lg btn-secondary px-4"
                          >
                            BACK
                          </button>
                          <Link
                            to={"/eventcard-image"}
                            className="btn btn-lg btn-alt-primary px-4 "
                          >
                            CREATE
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Test;
