import React from "react";

function Left({ data }) {
  return (
    <>
      <div className="content pe-md-0">
        <div className="block block-rounded block-fx-shadow">
          <div className="block block-rounded overflow-hidden">
            <div className="block-content tab-content">
              <div className="mx-auto">
                <div className="">
                  <div className=" block-content-full mb-4">
                    <h1 className="block-title fs-4 fw-bolder">
                      {data?.data?.supplierData?.businessName}
                    </h1>
                    <p>{data?.data?.supplierData?.email}</p>
                    <div
                      className="d-flex flex-column"
                      style={{ fontSize: "12px" }}
                    >
                      <p className="mt-3 fs-5 fw-normal">
                        {data?.data?.supplierData?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Left;
