import React from "react";
import { Link } from "react-router-dom";
import BalanceModal from "./BalanceModal";
import EditModal from "./EditModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function BalanceTable({ balance, setChangeStatus }) {
  const handleDelete = async (id) => {
    const userConfirmed = window.confirm("Are you sure you want to delete?");
    if (!userConfirmed) {
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/admin/balanceSheet/deleteById`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ recordId: id }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Balance deleted successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(`Failed to delete Balance : ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error deleting Balance: ${error.message}`);
    }
  };

  const changeStatus = async (id, currentState) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/balanceSheet/updateRecord`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        status: currentState ? false : true,
        id: id,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Status updated successfully");
        setChangeStatus((prev) => !prev);
      } else {
        toast.error(`Failed to update status: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error updating status: ${error.message}`);
    }
  };
  const userinfo = useSelector((state) => state.userDetails.user);

  return (
    <div className="content">
      <div className="block block-rounded overflow-hidden">
        <div className="block-content tab-content">
          {userinfo?.role === "3" || userinfo?.role === "4" || userinfo?.role === "5" ? (
            ""
          ) : (
            <div className="col-md-12">
              <div className="mb-3 d-flex justify-content-end">
                <button
                  className="btn btn-lg btn-alt-primary px-4"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-large43"
                >
                  Create Balance
                </button>
              </div>
            </div>
          )}
          <div style={{ overflowX: "auto" }}>
            <table
              id="balanceDataTable"
              className="js-table-checkable table table-hover table-vcenter table-responsive"
            >
              <thead>
                <tr>
                  <th>NAME</th>
                  <th>DESCRIPTION</th>
                  <th>EVENT</th>
                  <th>TAG</th>
                  <th>STATE</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {balance?.map((bal, index) => (
                  <tr key={index}>
                    <td>{bal.balanceName}</td>
                    <td>{bal.description}</td>
                    <td>{bal?.eventReferred?.eventName}</td>
                    <td>
                      <span style={{ color: bal.color }}>{bal.label}</span>
                    </td>
                    <td>
                      <span
                        className={`badge rounded ${
                          bal.status ? "bg-success" : "bg-danger"
                        }`}
                      >
                        {bal.status ? "Open +" : "Close -"}
                      </span>
                    </td>
                    <td>
                      <div className="d-flex">
                        <button
                          onClick={() => changeStatus(bal.id, bal.status)}
                          className="btn btn-alt-primary btn-sm rounded-0 mx-1"
                        >
                          Change State
                        </button>
                        <EditModal />
                        <button
                          onClick={() => handleDelete(bal.id)}
                          className="btn btn-alt-primary btn-sm rounded-0 mx-1"
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                        <Link
                          to={`/eventbalance/${bal.id}`}
                          className="btn btn-alt-primary btn-sm rounded-0 mx-1"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <BalanceModal />
        </div>
      </div>
    </div>
  );
}

export default BalanceTable;
