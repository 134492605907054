import React from "react";
import AddModal from "./AddModal";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import moment from "moment";
import { formatAmount } from "./MoneyDetails";

function Table({ allUsers, allSuppliers, movements, balance }) {
  const handleDelete = async (id) => {
    const userConfirmed = window.confirm("Are you sure you want to delete?");
    if (!userConfirmed) {
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/admin/moneyMovement/deleteById`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ recordId: id }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Money Movement deleted successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(`Failed to delete Money Movement: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error deleting Money Movement: ${error.message}`);
    }
  };

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  return (
    <div>
      <div style={{ overflowX: "auto" }}>
        <table
          id="movementsDataTable"
          className="js-table-checkable table table-hover table-vcenter"
        >
          <thead>
            <tr>
              <th>DATE OF MOVEMENT</th>
              <th>MONEY SOURCE</th>
              <th>MONEY DESTINATION</th>
              <th>AMOUNT</th>
              <th>REASON FOR MOVEMENT</th>
              <th>BALANCE OPERATOR</th>
              <th>BALANCE REFERENCE</th>
              <th>PAYMENT VERIFIED</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {movements?.map((move, index) => (
              <tr key={index}>
                <td>{formatDate(move.dateOfMovement)}</td>
                <td>{move.sourceType}</td>
                <td>{move.destinationType}</td>
                <td>{formatAmount(move.amount)}</td>
                <td>{move.reasonForMovement}</td>
                <td>
                  {move?.balanceOperator?.firstName +
                    " " +
                    move.balanceOperator?.lastName}
                </td>
                <td>{move?.balanceReference?.balanceName === undefined ? "MAIN_BALANCE" : move?.balanceReference?.balanceName}</td>
                <td>
                  <span
                    className={`badge ${
                      move.paymentVerified ? "bg-success" : "bg-danger"
                    } rounded`}
                  >
                    {move.paymentVerified ? "Verified" : "Waiting verification"}
                  </span>
                </td>
                <td>
                  <div className="d-flex">
                    <Link
                      to={`/money-movements/${move.id}`}
                      className="btn btn-alt-primary btn-sm rounded-0 mx-1"
                    >
                      Details
                    </Link>
                    <button
                      className="btn btn-alt-primary btn-sm rounded-0 mx-1"
                      onClick={() => handleDelete(move.id)}
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AddModal
        allUsers={allUsers}
        allSuppliers={allSuppliers}
        balance={balance}
      />
    </div>
  );
}

export default Table;
