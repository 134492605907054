import React from "react";
import { Link } from "react-router-dom";

function Sidebar({ instituteName }) {
  return (
    <div
      className="col-md-3 bg-default text-white pt-5 d-none d-md-block"
      style={{ borderRadius: "16px 0 0 16px" }}
    >
      <div className="mx-lg-3">
        <h3>Benvenuto nella {instituteName}</h3>
        <div>Inserisci le tue credenziali e fai clic su ISCRIVITI</div>
        <div className="pt-4 fs-6">
        Hai già un account su <br/> easyOratorio?
          <Link to={"/"} className="ms-2 text-warning-light">
            <u>Login</u>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
