import React, { useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function CloneModal({ group }) {
  const [newGroupName, setNewGroupName] = useState("");

  const handleClone = async () => {
    const url = 'https://apis.easyoratorio.it/admin/group/cloneRecord';
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({ id: group?.id, groupName: newGroupName })
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Group cloned successfully");
        setTimeout(() => {
          window.location.reload()
        }, 1500);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("An error occurred while cloning the group");
      console.error(error);
    }
  };

  return (
    <div
      className="modal"
      id="modal-large33"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Cloning</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="my-7">
              <div className="container">
                <div className="container-fluid">
                  <div className="row">
                    <div className="d-flex justify-content-center">
                      <div className="col-md-6 col-12">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            htmlFor="example-text-input"
                          >
                            Group <strong>{group?.groupName}</strong> cloned with new name:
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="groupName"
                            name="groupName"
                            value={newGroupName}
                            onChange={(e) => setNewGroupName(e.target.value)}
                          />
                        </div>
                        <div className="text-end mt-md-6 mt-5">
                          <button
                            type="button"
                            className="btn py-2 btn-success px-4"
                            onClick={handleClone}
                          >
                            CONFIRM
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CloneModal;
