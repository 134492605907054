import React, { useEffect, useState } from "react";

function Card({ data }) {
  const [amounts, setAmounts] = useState({ inbound: 0, outbound: 0, balance: 0 });

  const calculateAmounts = (movements) => {
    let inbound = 0;
    let outbound = 0;

    movements.forEach((movement) => {
      switch (true) {
        case movement.destinationType === "organization" && movement.sourceType !== "organization":
          inbound += parseFloat(movement.amount);
          break;
        case movement.sourceType === "organization" && movement.destinationType !== "organization":
          outbound += parseFloat(movement.amount);
          break;
        default:
          break;
      }
    });

    return { inbound, outbound, balance: inbound - outbound };
  };

  useEffect(() => {
    const moneyMovements = data || [];
    const newAmounts = calculateAmounts(moneyMovements);
    setAmounts(newAmounts);
  }, [data]);

  const { inbound, outbound, balance } = amounts;

  return (
    <div className="mx-auto">
      <div className="row">
        <div className="col-md-3">
          <div className="block block-rounded">
            <div
              className="block-content block-content-full bg-gd-light-green"
              style={{ border: "3px solid #26A22B" }}
            >
              <div className="fs-lg fw-bold text-white mb-2 me-auto d-flex justify-content-end">
                {inbound} ,00 €
              </div>
              <p className="fs-lg fw-bold text-white mt-5 mb-0 d-flex justify-content-end">
                <div className="mb-0">INBOUND</div>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="block block-rounded">
            <div
              className="block-content block-content-full bg-gd-secondary"
              style={{ border: "3px solid #D56A6A" }}
            >
              <div className="fs-lg fw-bold text-white mb-2 d-flex justify-content-end">
                {outbound},00 €
              </div>
              <p className="fs-lg fw-bold text-white mt-5 mb-0 d-flex justify-content-end">
                <div className="mb-0">OUTBOUND</div>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="block block-rounded">
            <div
              className="block-content block-content-full bg-gd-purple"
              style={{ border: "3px solid #A16ECA" }}
            >
              <div className="fs-lg fw-bold text-white mb-2 d-flex justify-content-end">
                {balance},00 €
              </div>
              <p className="fs-lg fw-bold text-white mt-5 mb-0 d-flex justify-content-end">
                <div className="mb-0">BALANCE</div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
