import React from "react";
import ProfileEdit from "./ProfileEdit";

function Main() {
  return (
    <div>
      <main id="main-container">
        <div className="content">
          <ProfileEdit />
        </div>
      </main>
    </div>
  );
}

export default Main;
