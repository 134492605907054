import React, { useState } from "react";

function PersonalizeModal({ formData, onChange }) {
  const [newRole, setNewRole] = useState({
    name: "",
    description: "none",
    price: "",
    userPolicy: "",
  });

  const handleInputChange = (field, value) => {
    setNewRole((prev) => ({ ...prev, [field]: value }));
  };

  const handleAddRole = (e) => {
    e.preventDefault();
    const updatedRoles = [...formData.personalizedRoles, newRole];
    onChange({ target: { name: "personalizedRoles", value: updatedRoles } });
    setNewRole({ name: "", description: "none", price: "", userPolicy: "" });
  };

  const handleRemoveRole = (index) => {
    const updatedRoles = [...formData.personalizedRoles];
    updatedRoles.splice(index, 1);
    onChange({ target: { name: "personalizedRoles", value: updatedRoles } });
  };

  return (
    <div className="modal" id="modal-large40" tabindex="-1" role="dialog">
      <div className="modal-dialog modal-lggg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Add Personalized Roles</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <form onSubmit={handleAddRole}>
              <div className="row mx-2">
                <div className="col-6">
                  <div className="mb-4">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Name"
                      value={newRole.name}
                      onChange={(e) =>
                        handleInputChange("name", e.target.value)
                      }
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-4">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Description"
                      value={newRole.description}
                      onChange={(e) =>
                        handleInputChange("description", e.target.value)
                      }
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-4 euro-input-container">
                    <label className="form-label">Price</label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      placeholder="Price"
                      value={newRole.price}
                      onChange={(e) =>
                        handleInputChange("price", e.target.value)
                      }
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-4">
                    <label className="form-label">User Policy</label>
                    <select
                      className="form-select"
                      value={newRole.userPolicy}
                      required
                      onChange={(e) =>
                        handleInputChange("userPolicy", e.target.value)
                      }
                    >
                      <option disabled selected value="">
                        Choose Level
                      </option>
                      <option value="2">Level 2</option>
                      <option value="3">Level 3</option>
                      <option value="4">Level 4</option>
                      <option value="5">Level 5</option>
                    </select>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-alt-primary mx-4 px-3 mb-2"
                type="submit"
              >
                Add
              </button>
            </form>
            {formData.personalizedRoles.length > 0 ? (
              <div className="block block-rounded">
                <div className="container">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>DESCRIPTION</th>
                        <th>PRICE</th>
                        <th>USER POLICY</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.personalizedRoles.map((role, index) => (
                        <tr key={index}>
                          <td>{role.name}</td>
                          <td>{role.description}</td>
                          <td>{role.price}</td>
                          <td>{role.userPolicy}</td>
                          <td>
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => handleRemoveRole(index)}
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalizeModal;
