import React, { useEffect, useState } from "react";
import Table from "./Table";
import EntryModel from "./EntryModel";
import ExitModel from "./ExitModel";
import EntriesModel from "./EntriesModel";
import $ from "jquery";
import moment from "moment";

function Main() {
  const [allUsers, setAllUsers] = useState([]);
  const [entriesExit, setEntriesExit] = useState([]);
  const [changeStatus, setChangeStatus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/user/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
        setAllUsers(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchEntriesExit = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/entriesExit/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
        setEntriesExit(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchEntriesExit();
  }, [changeStatus]);

  useEffect(() => {
    if (entriesExit.length > 0) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const entryExitTable = $("#entryExitDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
        order: [[0, "desc"]],
      });

      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        const min = $('#minDate').val();
        const max = $('#maxDate').val();
        const date = moment(data[0], 'DD/MM/YYYY');

        if (
          (!min && !max) ||
          (!min && date.isSameOrBefore(moment(max))) ||
          (moment(min).isSameOrBefore(date) && !max) ||
          (moment(min).isSameOrBefore(date) && date.isSameOrBefore(moment(max)))
        ) {
          return true;
        }
        return false;
      });

      // Event listener for date inputs to redraw on change
      $("#minDate, #maxDate").on("change", function () {
        entryExitTable.draw();
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        entryExitTable.destroy();
      };
    }
  }, [entriesExit]);

  useEffect(() => {
    if (allUsers.length > 0) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const manualTable = $("#manualDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        manualTable.destroy();
      };
    }
  }, [allUsers]);

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className=" fa fa-door-open me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">Entries/Exits</h1>
            </div>
            <Table
              entriesExit={entriesExit}
              setChangeStatus={setChangeStatus}
            />
            {/* <GatesAccess/> */}
          </div>
        </div>
      </main>
      <EntryModel allUsers={allUsers} setChangeStatus={setChangeStatus} />
      <ExitModel allUsers={allUsers} setChangeStatus={setChangeStatus} />
      <EntriesModel allUsers={allUsers} setChangeStatus={setChangeStatus} />
    </div>
  );
}

export default Main;
