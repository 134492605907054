import React from "react";

function Pagination({ currentPage, totalPages, onPageChange }) {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 9;
    const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);

    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > maxVisiblePages) {
      if (currentPage <= halfMaxVisiblePages) {
        startPage = 1;
        endPage = maxVisiblePages - 1;
      } else if (currentPage + halfMaxVisiblePages >= totalPages) {
        startPage = totalPages - maxVisiblePages + 2;
        endPage = totalPages;
      } else {
        startPage = currentPage - halfMaxVisiblePages;
        endPage = currentPage + halfMaxVisiblePages - 1;
      }
    }

    if (startPage > 1) {
      pageNumbers.push(
        <li className="page-item" key={1}>
          <button className="page-link" onClick={() => onPageChange(1)}>
            1
          </button>
        </li>
      );
      if (startPage > 2) {
        pageNumbers.push(
          <li className="page-item disabled" key="start-ellipsis">
            <span className="page-link">...</span>
          </li>
        );
      }
    }

    for (let i = startPage; i < endPage; i++) {
      pageNumbers.push(
        <li
          className={`page-item ${currentPage === i ? "active" : ""}`}
          key={i}
        >
          <button className="page-link" onClick={() => onPageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <li
          className={`page-item ${currentPage === endPage ? "active" : ""}`}
          key={endPage}
        >
          <button className="page-link" onClick={() => onPageChange(endPage)}>
            {endPage}
          </button>
        </li>
      );
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <li className="page-item disabled" key="end-ellipsis">
            <span className="page-link">...</span>
          </li>
        );
      }
      pageNumbers.push(
        <li
          className={`page-item ${currentPage === totalPages ? "active" : ""}`}
          key={totalPages}
        >
          <button className="page-link" onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="row">
      <div className="col-md-5 col-12">
        <div
          className="dataTables_info"
          id="DataTables_Table_0_info"
          role="status"
          aria-live="polite"
        >
          Page <strong>{currentPage}</strong> of <strong>{totalPages}</strong>
        </div>
      </div>

      <div className="col-md-7 col-12">
        <nav aria-label="Page navigation">
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => onPageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </button>
            </li>
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>
            {renderPageNumbers()}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => onPageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Pagination;
