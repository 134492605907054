import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TermsModel from "../SignUp/TermsModel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setUser } from "../../redux/actions/userProfile";
import { useDispatch, useSelector } from "react-redux";

function Form() {
  const [securityCode, setSecurityCode] = useState(0);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    userSecurityCode: "",
  });
  const Theme = useSelector((state) => state.settings.settings);
  const logoUrl = Theme.settingsTheme?.logo;

  useEffect(() => {
    // Randomly generate a 6-digit security code
    setSecurityCode(Math.floor(100000 + Math.random() * 900000));
  }, []);

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (event) => {
    event.preventDefault();
    const inputCode = parseInt(credentials.userSecurityCode, 10);
    if (inputCode !== securityCode) {
      toast.error("Invalid security code");
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/user/user/login`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        email: credentials.email,
        password: credentials.password,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok) {
        toast.success("Login successful!");
        dispatch(setUser(data.data.userData));
        localStorage.setItem("userData", JSON.stringify(data.data.userData)); // Store user data as a JSON string
        setTimeout(() => {
          navigate("/dashboard");
        }, 1500);
      } else {
        toast.error(`Login failed: ${data.message}`);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const theme = useSelector((state) => state.settings.settings);

  return (
    <div className="col-md-5">
      <img
        src={logoUrl}
        alt="logo"
        className="mt-5 mx-md-0 mx-auto d-block"
        width={"30%"}
      />
      <h2 className="mt-3">
        Benvenuto nella <br />
        {theme?.settingsGeneral?.institutionName}
      </h2>
      <form onSubmit={handleLogin}>
        <div className="search mb-4">
          <i className="far fa-user pe-2 icon text-center"></i>
          <input
            type="text"
            className="form-control form-control-alt rounded"
            name="email"
            onChange={onChange}
            placeholder="Inserisci la tua email / Scansiona il tuo braccialetto"
            style={{ paddingLeft: "35px !important" }}
          />
        </div>

        <div className="search mb-4">
          <i className="fa fa-lock pe-2 icon text-center"></i>
          <input
            type="password"
            className="form-control form-control-alt rounded"
            name="password"
            onChange={onChange}
            placeholder="Password"
            style={{ paddingLeft: "35px" }}
          />
        </div>
        <div className="input-group mb-4" style={{ borderRadius: "0.5rem" }}>
          <span
            className="input-group-text bg-alt-primary text-white"
            style={{
              borderTopLeftRadius: "0.5rem",
              borderBottomLeftRadius: "0.5rem",
              userSelect: "none",
            }}
            onContextMenu={(e) => e.preventDefault()}
            unselectable="on"
          >
            {securityCode}
          </span>
          <input
            type="text"
            className="form-control form-control-alt py-3"
            name="userSecurityCode"
            onChange={onChange}
            placeholder="Ripeti qui il tuo codice di sicurezza"
            style={{
              borderTopRightRadius: "0.5rem",
              borderBottomRightRadius: "0.5rem",
              paddingLeft: "20px",
            }}
          />
        </div>

        <nav aria-label="breadcrumb ">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item text-white">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label fw-bold text-nowrap ms-2"
                  htmlFor="flexCheckDefault"
                >
                  Ricordati di me
                </label>
              </div>{" "}
            </li>
            <li className="ms-auto">
              <Link to="/forget-password" className="text-white fw-bold">
                Hai dimenticato la password?
              </Link>
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-sm-12 text-sm-end push">
            <button
              type="submit"
              className="btn rounded btn-lg btn-alt-primary fw-bold fs-5 w-100"
            >
              Login
            </button>
          </div>
          <div className="text-center mb-4">
            <div className="text-white">
              Non hai un account su easyOratorio?
              <button
                type="button"
                className="btn btn-outline-primary ms-md-2 ms-4"
                data-bs-toggle="modal"
                data-bs-target="#modal-large"
                style={{ borderRadius: "9px" }}
              >
                Iscrizione
              </button>
            </div>
          </div>
          <TermsModel />
        </div>
      </form>
    </div>
  );
}

export default Form;
