import React, { useState } from "react";
import Card from "./Card";
import ViewModel from "./ViewModel";
import ReceiptModel from "./ReceiptModel";
import moment from "moment/moment";

function Table({ wallet }) {
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [receiptData, setReceiptData] = useState([]);
  const [checkedRows, setCheckedRows] = useState(new Set());

  const handleViewClick = (wal, modalId) => {
    setSelectedWallet(wal);
    setTimeout(() => {
      const modal = new window.bootstrap.Modal(
        document.getElementById("modal-largeR")
      );
      modal.show();
    }, 0);
  };

  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      const allItems = wallet?.walletHistoryData?.map((wal) => ({
        paymentType: wal?.paymentType,
        description: wal?.description,
        eventName: wal?.eventId?.eventName,
        amount: wal?.amount,
      }));
      setReceiptData(allItems);
      const allCheckedRows = new Set(
        wallet?.walletHistoryData?.map((_, index) => index)
      );
      setCheckedRows(allCheckedRows);
    } else {
      setReceiptData([]);
      setCheckedRows(new Set());
    }
  };

  const handleRowCheckboxChange = (event, wal, index) => {
    if (event.target.checked) {
      setReceiptData((prevItems) => [
        ...prevItems,
        {
          paymentType: wal?.paymentType,
          description: wal?.description,
          eventName: wal?.eventId?.eventName,
          amount: wal?.amount,
        },
      ]);
      setCheckedRows((prev) => new Set(prev).add(index));
    } else {
      setReceiptData((prevItems) =>
        prevItems.filter(
          (item) =>
            item.paymentType !== wal?.paymentType || item.amount !== wal?.amount
        )
      );
      setCheckedRows((prev) => {
        const newSet = new Set(prev);
        newSet.delete(index);
        return newSet;
      });
    }
  };

  const handleClearAllChecks = () => {
    setReceiptData([]);
    setCheckedRows(new Set());
  };

  return (
    <div className="block block-rounded">
      <div className="block-content">
        <Card wallet={wallet} />
        <div className="text-end">
          <button
            className="btn btn-primary me-2"
            data-bs-toggle="modal"
            data-bs-target="#modal-large10"
            disabled={receiptData.length === 0}
          >
            Generate Receipt
          </button>
          {checkedRows.size > 0 && (
            <button
              className="btn btn-secondary me-2"
              onClick={handleClearAllChecks}
            >
              Clear Checked Items
            </button>
          )}
        </div>
        <div style={{ overflowX: "auto" }}>
          <table
            id="walletDataTable"
            className="js-table-checkable table table-hover table-vcenter"
          >
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={handleHeaderCheckboxChange}
                    checked={checkedRows.size === wallet.length}
                  />
                </th>
                <th>DATE OF PAYMENT</th>
                <th>AMOUNT OF MONEY</th>
                <th>TYPE OF PAYMENT</th>
                <th>REASON OF PAYMENT</th>
                <th>EVENT</th>
                <th>STATE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {wallet?.walletHistoryData?.map((wal, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={(event) =>
                        handleRowCheckboxChange(event, wal, index)
                      }
                      checked={checkedRows.has(index)}
                    />
                  </td>
                  <td>{moment(wal?.paymentDate).format("DD/MM/YYYY")}</td>
                  <td>{wal?.amount},00 €</td>
                  <td>{wal?.paymentType}</td>
                  <td>{wal?.description}</td>
                  <td>{wal?.eventId?.eventName}</td>
                  <td>
                    <span
                      className={`badge ${
                        wal?.state === "paid" ? "bg-success" : "bg-danger"
                      }`}
                    >
                      {wal?.state}
                    </span>
                  </td>
                  <td>
                    <button
                      className="badge p-2 me-2"
                      onClick={() => handleViewClick(wal, "modal-largeR")}
                      style={{
                        backgroundColor: " #FBC054",
                        border: "2px solid #F28C28",
                      }}
                    >
                      View
                    </button>
                    {wal?.state === "paid" ? (
                      ""
                    ) : (
                      <button
                        className="badge p-2"
                        onClick={() => handleViewClick(wal, "modal-largeR")}
                        style={{
                          backgroundColor: " #FBC054",
                          border: "2px solid #F28C28",
                        }}
                      >
                        Pay
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {selectedWallet && <ViewModel wallet={selectedWallet} />}
      {receiptData.length > 0 && <ReceiptModel receiptData={receiptData} />}
    </div>
  );
}

export default Table;
