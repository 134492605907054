import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminLvl(props) {

    async function handleClick() {
        // Prompt user to confirm the action
        const userConfirmed = window.confirm("Are you sure you want to give this user administrator policies?");
        if (!userConfirmed) {
            return; // Exit the function if user cancels
        }

        const url = `${process.env.REACT_APP_API_URL}/admin/user/makeSuperAdmin`;
        const options = {
            method: 'POST',
            headers: {'content-type': 'application/x-www-form-urlencoded'},
            body: new URLSearchParams({recordId: props.id})
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if (data.status === 'success') {
                toast.success('Role changed to Admin!');
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred: ' + error.message);
        }
    }

    return (
        <button className="btn btn-primary btn-sm w-100 mt-4 py-1" onClick={handleClick}>
            Give Administrator Policies
        </button>
    );
}

export default AdminLvl;
