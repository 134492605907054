import React, { useState, useEffect } from "react";
import AdduserModal from "./AdduserModal";
import MultiUser from "./MultiUser";
import { useParams } from "react-router-dom";
import $ from "jquery";
import "datatables.net-bs5";

function GroupLevel({ groupData }) {
  const { id: groupId } = useParams();
  const [selectedUser, setSelectedUser] = useState(null);
  const [multipleUser, setMultipleUser] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleOpenModal = (userId) => {
    setSelectedUser(userId);
  };

  const handleCheckboxChange = (userId) => {
    setMultipleUser((prevMultipleUser) =>
      prevMultipleUser.includes(userId)
        ? prevMultipleUser.filter((id) => id !== userId)
        : [...prevMultipleUser, userId]
    );
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allUserIds = groupData?.map((item) => item.id);
      setMultipleUser(allUserIds);
    } else {
      setMultipleUser([]);
    }
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const ageDiff = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  useEffect(() => {
    let table;
    if (groupData?.length > 0) {
      table = $("#groupUserDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        destroy: true, // Ensure the old instance is destroyed before reinitializing
      });
    }

    return () => {
      if (table) {
        table.destroy();
      }
    };
  }, [groupData]);

  return (
    <div className="ps-md-0">
      <div className="block-content tab-content">
        <div className="tab-pane active">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary me-3"
              data-bs-toggle="modal"
              data-bs-target="#modal-multi"
            >
              Add Members
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => window.history.back()}
            >
              Go Back
            </button>
          </div>
          <div style={{ overflowX: "auto" }}>
            <table
              className="js-table-checkable table table-hover table-vcenter table-responsive"
              id="groupUserDataTable"
            >
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </th>
                  <th>FIRST NAME</th>
                  <th>LAST NAME</th>
                  <th>AGE</th>
                  <th>EVENTS</th>
                  <th>GROUP MEMBERSHIP</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {groupData?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={multipleUser.includes(item?.id)}
                        onChange={() => handleCheckboxChange(item?.id)}
                      />
                    </td>
                    <td>{item?.firstName}</td>
                    <td>{item?.lastName}</td>
                    <td>{calculateAge(item?.birthday)}</td>
                    <td>
                      {item?.subscribedEvents?.map((event, eventIndex) => (
                        <div key={eventIndex}>{event?.eventName}</div>
                      ))}
                    </td>
                    <td>
                      {item?.groupsJoined?.map((group, groupIndex) => (
                        <div key={groupIndex}>{group?.groupName}</div>
                      ))}
                    </td>
                    <td>
                      <div className="d-flex">
                        <button
                          className="btn btn-alt-primary btn-sm rounded-0 mx-1"
                          data-bs-toggle="modal"
                          data-bs-target="#modal-add"
                          onClick={() => handleOpenModal(item?.id)}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AdduserModal groupId={groupId} userId={selectedUser} />
      <MultiUser groupId={groupId} selectedUsers={multipleUser} />
    </div>
  );
}

export default GroupLevel;
