import React, { useState, useEffect } from "react";
import AdminLvl from "./AdminLvl";
import EventLvl from "./EventLvl";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function ProfileSection(props) {
  const dateString = props?.userDetails?.data?.createdAt;
  const userinfo = useSelector((state) => state.userDetails.user);
  const navigate = useNavigate();
  const formattedDate = dateString
    ? moment(dateString).format("DD/MM/YYYY")
    : "Date not available";
  const params = useParams();
  const [formData, setFormData] = useState({
    recordId: "",
    rfid: "",
    notes: "",
    role: "",
    reservedNotes: "",
  });

  useEffect(() => {
    if (props.userDetails && props.userDetails.data) {
      setFormData({
        recordId: params.id,
        rfid: props.userDetails.data.rfid || "",
        notes: props.userDetails.data.notes || "",
        reservedNotes: props.userDetails.data.reservedNotes || "",
        role: props.userDetails.data.role || "",
      });
    }
  }, [props.userDetails, params.id]);

  const handleUpdate = async () => {
    const url = `${process.env.REACT_APP_API_URL}/admin/user/updateRecord`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Update Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to save!",
          text: data.message || "An error occurred while saving.",
        });
        console.error("Update failed:", data.message || "Unknown error");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed to save!",
        text: error.message || "An error occurred while saving.",
      });
      console.error("Request error:", error);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDeleteUser = async (id) => {
    const userDeleteConfirmed = window.confirm(
      "Are you sure you want to Delete the User?"
    );
    if (!userDeleteConfirmed) {
      return;
    }
    const url = `${process.env.REACT_APP_API_URL}/user/user/deleteUserAndRelatedData`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ userId: id }),
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("User Deleted Successfully");
        setTimeout(() => {
          navigate("/userinfo");
        }, 1500);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const calculateTotalAmounts = () => {
    let subscriptionTotal = 0;
    let rechargingTotal = 0;

    props?.userDetails?.data?.walletHistory?.forEach((history) => {
      if (history.description === "subscription") {
        subscriptionTotal += parseInt(history.amount, 10);
      } else if (history.description === "Recharging wallet") {
        rechargingTotal += parseInt(history.amount, 10);
      }
    });

    return { subscriptionTotal, rechargingTotal };
  };

  const { subscriptionTotal, rechargingTotal } = calculateTotalAmounts();

  return (
    <>
      <div className="content pe-md-0">
        <div className="block block-rounded block-fx-shadow">
          <div className="block block-rounded overflow-hidden">
            <div className="block-content tab-content">
              <div className="mx-auto">
                <div className="text-center border-start border-end border-bottom">
                  <div className="block-content block-content-full">
                    <img
                      className="img-avatar"
                      src={
                        props?.userDetails?.data?.profilePic === "null" ||
                        props?.userDetails?.data?.profilePic === undefined ||
                        props?.userDetails?.data?.profilePic === ""
                          ? "/assets/media/avatars/avatar6.jpg"
                          : props.userDetails.data.profilePic
                      }
                      alt="profile"
                    />
                  </div>
                  <div className="block-content-full mb-4">
                    <div className="fw-semibold fs-4">
                      {props?.userDetails?.data?.firstName}{" "}
                      {props?.userDetails?.data?.lastName}
                    </div>
                    <div className="d-flex flex-column">
                      <p className="text-muted m-0 fs-6">
                        {props?.userDetails?.data?.email}
                      </p>
                      <p className="text-muted m-0 fs-6">
                        Tel: {props?.userDetails?.data?.phonePersonal}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border">
                  <div className="d-flex justify-content-between align-items-center m-3">
                    <div className="d-flex">
                      <div>
                        <div className="fs-6 fw-semibold">Wallet Content</div>
                        <div className="text-muted">Amount</div>
                      </div>
                    </div>
                    <div
                      className={`fs-6 fw-semibold ${
                        props?.userDetails?.data?.walletBalance >= 0
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {props?.userDetails?.data?.walletBalance || "0"},00 €
                    </div>
                  </div>
                  <hr className="mx-3" />
                  <div className="d-flex justify-content-between align-items-center m-3">
                    <div className="d-flex">
                      <div>
                        <div className="fs-6 fw-semibold">Subscription</div>
                        <div className="text-muted">Amount</div>
                      </div>
                    </div>
                    <div className="fs-6 fw-semibold text-danger">
                      - {subscriptionTotal || "0"} ,00 €
                    </div>
                  </div>
                  <hr className="mx-3" />
                  <div className="d-flex justify-content-between align-items-center m-3">
                    <div className="d-flex">
                      <div>
                        <div className="fs-6 fw-semibold">
                          Recharging Wallet
                        </div>
                        <div className="text-muted">Amount</div>
                      </div>
                    </div>
                    <div className="text-success fs-6 fw-semibold">
                      + {rechargingTotal || "0"} ,00 €
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mt-3 form-label">Note</div>
                  <textarea
                    className="form-control"
                    onChange={onChange}
                    value={formData.notes}
                    name="notes"
                    cols="17"
                    rows="3"
                  ></textarea>
                   {userinfo.role === "0" || userinfo.role === "1" ? (
                  <>
                  <div className="mt-3 form-label">Reserved Note</div>
                  <textarea
                    className="form-control"
                    onChange={onChange}
                    value={formData.reservedNotes}
                    name="reservedNotes"
                    cols="17"
                    rows="3"
                  ></textarea></>
                ) : (
                  ""
                )}
                  <div className="mt-3 form-label">Registration Date</div>
                  <input
                    type="text"
                    className="form-control"
                    value={formattedDate}
                    disabled
                  />
                  <div className="mt-3 form-label">RFID Code</div>
                  <input
                    type="text"
                    className="form-control"
                    name="rfid"
                    value={formData.rfid}
                    onChange={onChange}
                  />
                  <div className="d-flex flex-column mb-5">
                    <div className="text-end">
                      <button
                        onClick={handleUpdate}
                        className="btn btn-primary btn-sm w-50 mt-4 py-1"
                      >
                        Update
                      </button>
                    </div>
                    {userinfo.role === "0" ? (
                      <>
                        <AdminLvl id={props?.userDetails?.data?.id} />
                        <EventLvl id={props?.userDetails?.data?.id} />
                      </>
                    ) : (
                      ""
                    )}
                    {userinfo.role === "0" || userinfo.role === "1" ? (
                      <button
                        onClick={() =>
                          handleDeleteUser(props?.userDetails?.data?.id)
                        }
                        className="btn btn-danger w-100 mt-5"
                      >
                        Delete User
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileSection;
