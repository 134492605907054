import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function SubscriptionModel({ formData }) {
  const userinfo = useSelector((state) => state.userDetails.user);

  const [credentials, setCredentials] = useState({
    userId: userinfo?.id,
    eventId: formData?.id || formData?._id,
    amount: "",
    multipleSubscription: "",
    subscriptionParam1: "",
    subscriptionParam2: "",
    personalizedRole: {},
    note: "",
    balanceOperator: userinfo?.id
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const onPersonalizedRoleChange = (e) => {
    const selectedRole = formData?.personalizedRoles?.find(
      (role) => role.name === e.target.value
    );
    setCredentials({
      ...credentials,
      personalizedRole: selectedRole,
      amount: selectedRole?.price || "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}/user/eventSubscription/addRecord`;
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(credentials),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Event Subscribed successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        setCredentials({
          userId: "",
          amount: "",
          multipleSubscription: "",
          subscriptionParam1: "",
          subscriptionParam2: "",
          personalizedRole: {},
          note: "",
          balanceOperator: userinfo?.id
        });
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="modal" id="modal-subscription" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lggg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Applied Event</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row mx-2 mt-2">
                <div className="col-6">
                  <div className="mb-4">
                    <label className="form-label">User</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      disabled
                      value={userinfo?.firstName + " " + userinfo?.lastName}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-4">
                    <label className="form-label">Personalized Role</label>
                    <select
                      className="form-select"
                      name="personalizedRole"
                      onChange={onPersonalizedRoleChange}
                      required
                    >
                      <option disabled selected value="">
                        Select Personalized Role
                      </option>
                      {formData?.personalizedRoles?.map((role, index) => (
                        <option key={index} value={role.name}>
                          {role.name + " (" + role.description + ")"}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-4">
                    <label className="form-label">Amount</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      disabled
                      name="amount"
                      value={`€ ${credentials.amount},00`}
                    />
                  </div>
                </div>
                {formData?.subscriptionParameter1 && (
                  <div className="col-6">
                    <div className="mb-4">
                      <label className="form-label">
                        {formData?.subscriptionParameter1}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="subscriptionParam1"
                        onChange={onChange}
                        value={credentials.subscriptionParam1}
                      />
                    </div>
                  </div>
                )}
                {formData?.subscriptionParameter2 && (
                  <div className="col-6">
                    <div className="mb-4">
                      <label className="form-label">
                        {formData?.subscriptionParameter2}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="subscriptionParam2"
                        onChange={onChange}
                        value={credentials.subscriptionParam2}
                      />
                    </div>
                  </div>
                )}
                {formData?.maxSubscription && (
                  <div className="col-12">
                    <div className="mb-4">
                      <label className="form-label">
                        Multiple Subscription
                      </label>
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        name="multipleSubscription"
                        onChange={onChange}
                        value={credentials.multipleSubscription}
                      />
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <div className="mt-3 form-label">Note</div>
                  <textarea
                    className="form-control"
                    name="note"
                    onChange={onChange}
                    value={credentials.note}
                    cols="17"
                    rows="3"
                  ></textarea>
                </div>
              </div>
              <div className="text-center my-5">
                <button type="submit" className="btn btn-alt-primary">
                  Confirm E-Pay From Wallet
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionModel;
