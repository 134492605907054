import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function Card({ wallet }) {
  // Ensure amounts are treated as numbers
  const userinfo = useSelector((state) => state?.userDetails?.user);

  console.log(userinfo)
  const [totalRechargeAmount, setTotalRechargeAmount] = useState(0);

    useEffect(() => {
      if (Array.isArray(wallet.walletHistoryData)) {
        const rechargeTotal = wallet.walletHistoryData
          .filter((entry) => entry.description === "Recharging wallet")
          .reduce((acc, entry) => acc + Number(entry.amount), 0);
        setTotalRechargeAmount(rechargeTotal);
      } else {
        setTotalRechargeAmount(0);
      }
    }, [wallet]);

  const theme = useSelector((state) => state.settings.settings);

  return (
    <div className="mx-auto">
      <div className="row">
        <div className="col-md-3">
          <div className="block block-rounded">
            <div
              className="block-content block-content-full bg-gd-flat"
              style={{ border: "3px solid #26A22B" }}
            >
              <div className="fs-lg fw-bold text-white mb-2 me-auto d-flex justify-content-end">
                {wallet.userWallet},00€
              </div>
              <p className="fs-lg fw-bold text-white mt-5 mb-0 d-flex justify-content-end">
                <div className="mb-0">Wallet Content</div>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 ">
          <div className="block block-rounded">
            <div
              className="block-content block-content-full bg-gd-elegance"
              style={{ border: "3px solid #5A4CFA" }}
            >
              <div className="fs-lg fw-bold text-white mb-2 d-flex justify-content-end">
                {totalRechargeAmount - userinfo?.walletBalance},00€
              </div>
              <p className="fs-lg fw-bold text-white mt-5 mb-0 d-flex justify-content-end">
                <div className="mb-0">Money Spent</div>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 "></div>
        {theme?.settingsGeneral?.electronicPayment ? (
          <div className="col-md-3">
            <div className="block block-rounded">
              <div
                className="block-content block-content-full bg-gd-sun"
                style={{ border: "3px solid #FFC700" }}
              >
                <p className="fs-lg fw-bold text-white mt-6 mb-0 d-flex justify-content-end">
                  <i className="fa fa-2x fa-wallet me-2"></i>
                  <div className="mb-0 fs-3">
                    <u>Recharge</u>
                  </div>
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Card;
