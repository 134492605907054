import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export function formatAmount(amount) {
  const formattedAmount = parseFloat(amount).toFixed(2);
  return formattedAmount.replace(".", ",") + " €";
}

function MoneyDetails() {
  const [data, setData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/moneyMovement/getById/${id}`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.status === "success") {
          setData(result.data);
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  const handleVerification = async () => {
    const url = `${process.env.REACT_APP_API_URL}/admin/moneyMovement/updateRecord`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        paymentVerified: true,
        id: id,
      }),
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      if (result.status === "success") {
        toast.success("Payment Verified Successfully!");
        setData((prev) => ({
          ...prev,
          paymentVerified: true,
        }));
      } else {
        toast.error(result.message || "Unable to Verify Payment");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error verifying payment");
    }
  };

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  return (
    <div>
      <div className="modal-content">
        <div className="block block-rounded shadow-none mb-0">
          <div className="block-header block-header-default">
            <h3 className="block-title fw-bold">Details Of Money Movement</h3>
          </div>
          <div className="block block-rounded">
            <div className="container">
              <div className="block-content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4 mt-md-3">
                      <label className="form-label" htmlFor="date-of-movement">
                        Date Of Movement
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="date-of-movement"
                        name="date-of-movement"
                        value={formatDate(data?.dateOfMovement)}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4 mt-md-3">
                      <label className="form-label" htmlFor="import">
                        Import
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="import"
                        name="import"
                        value={formatAmount(data?.amount)}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label
                        className="form-label"
                        htmlFor="increase-for-electronic-payment"
                      >
                        Source Type
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="increase-for-electronic-payment"
                        name="increase-for-electronic-payment"
                        value={data?.sourceType}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className="form-label" htmlFor="balance-reference">
                        Destination Type
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="balance-reference"
                        name="balance-reference"
                        value={data?.destinationType}
                        readOnly
                      />
                    </div>
                  </div>
                  {data?.sourceType.toLowerCase() === "user" && (
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          className="form-label"
                          htmlFor="balance-reference"
                        >
                          From {data?.sourceType}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="balance-reference"
                          name="balance-reference"
                          value={`${data?.sourceUser?.firstName} ${data?.sourceUser?.lastName} - (${data?.sourceUser?.email}) - ${moment(data?.sourceUser?.birthday).format("DD-MM-YYYY")}`}
                          readOnly
                        />
                      </div>
                    </div>
                  )}
                  {data?.destinationType.toLowerCase() === "user" && (
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          className="form-label"
                          htmlFor="balance-reference"
                        >
                          To USER
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="balance-reference"
                          name="balance-reference"
                          value={`${data?.destinationUser?.firstName} ${data?.destinationUser?.lastName} - (${data?.destinationUser?.email}) - ${moment(data?.destinationUser?.birthday).format("DD-MM-YYYY")}`}
                          readOnly
                        />
                      </div>
                    </div>
                  )}
                  {data?.destinationType.toLowerCase() === "supplier" && (
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          className="form-label"
                          htmlFor="balance-reference"
                        >
                          Destination Supplier
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="balance-reference"
                          name="balance-reference"
                          value={`${data?.destinationSupplier?.businessName} - (${data?.destinationSupplier?.email})`}
                          readOnly
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label
                        className="form-label"
                        htmlFor="reason-for-movement"
                      >
                        Reason For Movement
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="reason-for-movement"
                        name="reason-for-movement"
                        value={data?.reasonForMovement}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label
                        className="form-label"
                        htmlFor="type-of-money-movement"
                      >
                        Type Of Money Movement
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="type-of-money-movement"
                        name="type-of-money-movement"
                        value={data?.typeOfMovement}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className="form-label" htmlFor="recipe-of-payment">
                        Recipe Of Payment
                      </label>
                      <div>
                        <a
                          href={data?.recipeOfPayment}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="far fa-2x fa-file-pdf"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className="form-label" htmlFor="invoice-code">
                        Invoice Code
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="invoice-code"
                        name="invoice-code"
                        value={data?.invoiceCode}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className="form-label" htmlFor="invoice-date">
                        Invoice Date
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="invoice-date"
                        name="invoice-date"
                        value={formatDate(data?.invoiceDate)}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label
                        className="form-label"
                        htmlFor="invoice-associated"
                      >
                        Invoice Associated
                      </label>
                      <div>
                        <a
                          href={data?.invoiceAssociated}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="far fa-2x fa-file-pdf"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className="form-label" htmlFor="discount-applied">
                        Discount Applied
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="discount-applied"
                        name="discount-applied"
                        value={data?.discountApplied}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className="form-label" htmlFor="balance-operator">
                        Balance Operator
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="balance-operator"
                        name="balance-operator"
                        value={
                          data?.balanceOperator?.firstName +
                          " " +
                          data?.balanceOperator?.lastName
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label
                        className="form-label"
                        htmlFor="increase-for-electronic-payment"
                      >
                        Increase For Electronic Payment
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="increase-for-electronic-payment"
                        name="increase-for-electronic-payment"
                        value={data?.electronicPaymentIncrease}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className="form-label" htmlFor="balance-reference">
                        Balance Reference
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="balance-reference"
                        name="balance-reference"
                        value={data?.balanceReference?.balanceName}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className="form-label" htmlFor="payment-verified">
                        Payment Verified
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="payment-verified"
                        name="payment-verified"
                        value={data?.paymentVerified ? "Yes" : "No"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-md-4">
                      <label
                        className="form-label"
                        htmlFor="description-of-movement"
                      >
                        Description Of Movement
                      </label>
                      <textarea
                        className="form-control form-control-lg"
                        id="description-of-movement"
                        name="description-of-movement"
                        rows="4"
                        value={data?.movementDescription}
                        readOnly
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <Link to={"/money-movements"} className="btn btn-secondary">
                    Back
                  </Link>
                  <button
                    className="btn btn-alt-primary"
                    onClick={handleVerification}
                    disabled={data?.paymentVerified}
                  >
                    Money Transfer Verified
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoneyDetails;
