import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Events1 from "./Events1";
import Events3 from "./Events3";
import Events2 from "./Events2";
import Events4 from "./Events4";
import Swal from "sweetalert2";

function EventUpdateForm() {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    id: id,
    authorId: "",
    eventName: "",
    tag: { key: "", value: "" },
    subscriptionState: "",
    maxSubscription: "",
    eventFiles: [],
    eventImages: [],
    description: "",
    subscriberMinAge: "",
    subscriberMaxAge: "",
    incompatibleEvents: [],
    fatherEvent: false,
    startDate: "",
    endDate: "",
    eventLocation: "",
    startHour: "00:00AM",
    endHour: "12:00PM",
    subscriptionStartDate: "",
    subscriptionEndDate: "",
    subscriptionParameter1: "",
    subscriptionParameter2: "",
    allowUnsubscription: false,
    recurringEvent: false,
    recurringDays: [],
    mutipleSubscription: false,
    balanceSelection: "",
    applyPersonalDiscount: false,
    maxSubscribers: "",
    personalizedRoles: [],
  });

  const navigate = useNavigate();

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/event/getByIdForEventUpdate?eventId=${id}`;
      const options = { method: "GET" };
      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.status === "success") {
          setFormData(result.data);
        } else {
          console.error("Error fetching data:", result.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  const handleClick = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/event/updateRecord`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Update Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/event/view");
      } else {
        throw new Error(data.message || "Failed to save!");
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to save!",
        text: error.message || "An error occurred while saving.",
      });
    }
  };
  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className=" fa fa-calendar me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">Update Event</h1>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="block block-rounded">
                  <div className="container">
                    <Events1 formData={formData} onChange={onChange} />
                    <Events3 formData={formData} onChange={onChange} />
                    <Events2 formData={formData} onChange={onChange} />
                    <Events4 formData={formData} onChange={onChange} />
                    <div className="d-flex justify-content-between mb-4">
                      <Link
                        to={"/event/view"}
                        className="btn btn-lg btn-secondary px-4"
                      >
                        BACK
                      </Link>
                      <button
                        onClick={handleClick}
                        className="btn btn-lg btn-alt-primary px-4"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default EventUpdateForm;
