import React from "react";

function ReferredModal() {
  return (
    <div
      className="modal"
      id="modal-large45"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lggg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Create Balance</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>

            <div className="block block-rounded">
              <div className="container">
                <div className="block-content ">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4 mt-md-3">
                        <label className="form-label" for="example-text-input">
                          Name Of Balance{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="example-text-input"
                          name="example-text-input"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4 mt-md-3">
                        <label className="form-label" for="example-text-input">
                          Event Referred
                        </label>
                        <select
                          className="form-select form-select-lg "
                          aria-label=".form-select-lg example"
                        >
                          <option selected disabled>
                            {" "}
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4 mt-md-3">
                        <label className="form-label" for="example-text-input">
                          Label{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="example-text-input"
                          name="example-text-input"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4 mt-md-3">
                        <label className="form-label" for="example-text-input">
                          Color
                        </label>
                        <select
                          className="form-select form-select-lg "
                          aria-label=".form-select-lg example"
                        >
                          <option selected disabled>
                            {" "}
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12">
                      <div className="mb-md-4 ">
                        <label
                          className="form-label"
                          for="example-textarea-input"
                        >
                          Description
                        </label>
                        <textarea
                          className="form-control form-control-lg"
                          id="example-textarea-input"
                          name="example-textarea-input"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="  d-flex justify-content-end">
                      <button
                        type="button"
                        className=" btn btn-lg btn-alt-primary px-4"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferredModal;
