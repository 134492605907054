import React, { useEffect, useState, useRef } from "react";
import { MultiStepForm, Step } from "react-multi-form";
import PersonalInfo from "../components/SignUp/PersonalInfo";
import Sidebar from "../components/SignUp/Sidebar";
import AddressDetail from "../components/SignUp/AddressDetail";
import SchoolInfo from "../components/SignUp/SchoolInfo";
import ChoosePassword from "../components/SignUp/ChoosePassword";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function SignUp() {
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthday: "",
    birthPlace: "",
    nationality: "",
    sex: "",
    shirtSize: "",
    fiscalCode: "",
    residentialAddress: "",
    houseNumber: "",
    city: "",
    province: "",
    phonePersonal: "",
    phoneFather: "",
    phoneMother: "",
    phoneCustom1: { key: "", value: "" },
    phoneCustom2: { key: "", value: "" },
    schoolType: "",
    schoolName: "",
    schoolBlonging: "",
    schoolAuthority: "",
    password: "",
    confirmPassword: "",
    otherParish:"",
    role: "5"
  });

  const totalSteps = 4;
  const Theme = useSelector((state) => state.settings.settings);
  const logoUrl = Theme.settingsTheme?.logo;
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [isPasswordChecklistComplete, setIsPasswordChecklistComplete] =
    useState(false);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const validateCurrentStepFields = () => {
    const form = formRef.current;
    const currentStepFields = form.querySelectorAll(
      `[data-step="${activeStep}"]`
    );

    for (let field of currentStepFields) {
      if (!field.checkValidity()) {
        field.reportValidity();
        return false;
      }
    }
    return true;
  };

  const nextStep = () => {
    if (validateCurrentStepFields()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const prevStep = () => {
    if (activeStep > 1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSubmit = async () => {
    const url = `${process.env.REACT_APP_API_URL}/user/user/register`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok) {
        Swal.fire({
          title: "Done!",
          text: "Ti abbiamo inviato un'e-mail di conferma, fai clic sul collegamento di conferma per attivare il tuo account!",
          icon: "success",
          showCancelButton: true,
          confirmButtonColor: Theme.settingsTheme.colorSelection,
          confirmButtonText: "Gmail",
          cancelButtonColor: "#B5B5B5",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "https://gmail.com";
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            navigate("/");
          }
        });
      } else {
        throw new Error(data.message || "Failed to register");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === "password") {
      checkPasswordChecklistCompletion(
        e.target.value,
        formData.confirmPassword
      );
    }
    if (e.target.name === "confirmPassword") {
      checkPasswordChecklistCompletion(formData.password, e.target.value);
    }
  };

  const checkPasswordChecklistCompletion = (password, confirmPassword) => {
    const config = {
      minLength: 8,
      upperCaseMinCount: 1,
      numericMinCount: 2,
      symbolMinCount: 1,
      lowerCaseMinCount: 2,
    };

    const lengthMatch = password.length >= config.minLength;
    const upperCaseCount = (password.match(/[A-Z]/g) || []).length;
    const numericCount = (password.match(/[0-9]/g) || []).length;
    const symbolCount = (password.match(/[^a-zA-Z0-9]/g) || []).length;
    const lowerCaseCount = (password.match(/[a-z]/g) || []).length;
    const passwordsMatch = password === confirmPassword;

    const checklistComplete =
      lengthMatch &&
      upperCaseCount >= config.upperCaseMinCount &&
      numericCount >= config.numericMinCount &&
      symbolCount >= config.symbolMinCount &&
      lowerCaseCount >= config.lowerCaseMinCount &&
      passwordsMatch;

    setIsPasswordChecklistComplete(checklistComplete);
  };

  return (
    <div id="page-container" className="container">
      <main
        id="main-container"
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <div className="row block block-themed block-rounded mb-0">
          <Sidebar instituteName={Theme?.settingsGeneral?.institutionName} />
          <div className="col-md-9">
            <Link to="/" className="d-flex justify-content-center my-2">
              <img width={"15%"} src={logoUrl} alt="logo" />
            </Link>
            <div className="mx-5">
              <h3 className="fw-bold text-black">Crea un account</h3>
              <form ref={formRef}>
                <MultiStepForm
                  accentColor={Theme?.settingsTheme?.colorSelection}
                  activeStep={activeStep}
                >
                  <Step className="text-wrap" label="Anagrafica">
                    <h3 className="fw-bold text-black">Informazioni anagrafiche</h3>
                    <PersonalInfo formData={formData} onChange={onChange} />
                  </Step>
                  <Step label="Indirizzo">
                    <h3 className="fw-bold text-black">dettagli dell'indirizzo</h3>
                    <AddressDetail formData={formData} onChange={onChange} />
                  </Step>
                  <Step label="Scuola">
                    <h3 className="fw-bold text-black">Informazioni sulla scuola</h3>
                    <SchoolInfo formData={formData} onChange={onChange} />
                  </Step>
                  <Step className="text-wrap" label="Password">
                    <h3 className="fw-bold text-black">Scegli Password</h3>
                    <ChoosePassword formData={formData} onChange={onChange} />
                  </Step>
                </MultiStepForm>
              </form>
              <div className="d-flex justify-content-between mb-4">
                <button
                  type="button"
                  className="btn btn-lg btn-secondary px-4"
                  onClick={prevStep}
                  disabled={activeStep === 1}
                >
                  PRECEDENTE
                </button>
                {activeStep < totalSteps ? (
                  <button
                    type="button"
                    className="btn btn-lg btn-alt-primary px-4"
                    onClick={nextStep}
                  >
                    PROSSIMA
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-lg btn-alt-primary px-4"
                    onClick={handleSubmit}
                    disabled={!isPasswordChecklistComplete}
                  >
                    ISCRIZIONE
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SignUp;
