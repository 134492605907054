import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { setSettings } from "../../redux/actions/settings";
import { useColor } from "../../context/ColorContext";

function ThemeSetting() {
  const Theme = useSelector((state) => state.settings.settings);
  const [logoUrl, setLogoUrl] = useState(Theme.settingsTheme?.logo);
  const [colorSelection, setColorSelection] = useState(
    Theme.settingsTheme?.colorSelection
  );
  const { setColor } = useColor();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);

    const options = {
      method: "POST",
      body: formData,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/utils/uploadImage`,
        options
      );
      const data = await response.json();
      if (data.status === "success") {
        setLogoUrl(data.data.image);
        toast.success("Image uploaded!");
      } else {
        throw new Error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error(error.message || "Error uploading image");
    }
  };
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const url = `${process.env.REACT_APP_API_URL}/admin/settingsTheme/updateRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        colorSelection: colorSelection,
        logo: logoUrl,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (data.status === "success") {
        setColor(colorSelection);
        toast.success("Settings updated successfully!");
        dispatch(setSettings(data.data));
      } else {
        throw new Error(
          data.message || "Failed to update settings according to app logic"
        );
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error(error.message || "Failed to update settings");
    }
  };

  return (
    <div className="row push">
      <div className="col-lg-12">
        <h1 className="block-title fs-4 fw-bolder">Theme Setting</h1>
      </div>
      <div className="col-md-6">
        <div className="mb-4 mt-3">
          <label className="form-label" htmlFor="colorSelection">
            Color Selection
          </label>
          <select
            id="colorSelection"
            className="form-select form-select-lg"
            value={colorSelection}
            onChange={(e) => setColorSelection(e.target.value)}
          >
            <option value="#f29c3d">Orange</option>
            <option value="#14c19c">Light Green</option>
            <option value="#343a40">Light Black</option>
          </select>
        </div>
      </div>

      <div className="col-md-6 overflow-hidden">
        <div className="mb-4 mt-md-3">
          <label className="form-label" htmlFor="example-file-input">
            Easyoratorio Logo (255 x 157)
          </label>
          <input
            className="form-control form-control-lg"
            type="file"
            id="example-file-input"
            onChange={handleFileChange}
          />
        </div>
        <div className="bg-alt-primary text-center rounded">
          <img src={logoUrl} width={120} alt="logo_image" />
        </div>
      </div>
      <div className="block-content block-content-full block-content-sm text-end">
        <button
          type="button"
          className="btn btn-alt-primary px-4"
          onClick={handleSubmit}
        >
          Update
        </button>
      </div>
    </div>
  );
}

export default ThemeSetting;
