import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
function Events3({ onChange, formData }) {
  const Theme = useSelector((state) => state.settings.settings);

  const handleDayCheckboxChange = (e) => {
    const day = e.target.value;
    const isChecked = e.target.checked;
    let updatedDays = [...formData?.recurringDays];

    if (isChecked) {
      if (!updatedDays.includes(day)) {
        updatedDays.push(day);
      }
    } else {
      updatedDays = updatedDays.filter((d) => d !== day);
    }

    onChange({
      target: {
        name: "recurringDays",
        value: updatedDays,
      },
    });
  };

  const today = new Date().toISOString().split("T")[0];

  return (
    <div className="text-start my-md-5 my-3">
      <div className="row">
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label" htmlFor="example-date-input">
              Event Start Date*
            </label>
            <input
              type="date"
              className="form-control form-control-lg"
              onChange={onChange}
              value={moment(formData?.startDate).format("YYYY-MM-DD")}
              name="startDate"
              min={today}
              required
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label" htmlFor="example-date-input">
              Event End Date*
            </label>
            <input
              type="date"
              className="form-control form-control-lg"
              onChange={onChange}
              value={moment(formData?.endDate).format("YYYY-MM-DD")}
              name="endDate"
              min={moment(formData?.startDate).format("YYYY-MM-DD")}
              required
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label">Event Location*</label>
            <input
              type="text"
              className="form-control form-control-lg"
              onChange={onChange}
              value={formData?.eventLocation}
              placeholder="Event Location"
              name="eventLocation"
              required
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label" htmlFor="example-time-input">
              Event Start Hour
            </label>
            <input
              type="time"
              className="form-control form-control-lg"
              onChange={onChange}
              value={formData?.startHour}
              name="startHour"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label" htmlFor="example-time-input">
              Event End Hour
            </label>
            <input
              type="time"
              className="form-control form-control-lg"
              onChange={onChange}
              value={formData?.endHour}
              name="endHour"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label" htmlFor="example-date-input">
              Start Date Of Subscription{" "}
            </label>
            <input
              type="date"
              className="form-control form-control-lg"
              onChange={onChange}
              value={formData?.subscriptionStartDate}
              name="subscriptionStartDate"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label" htmlFor="example-date-input">
              End Date Of Subscription
            </label>
            <input
              type="date"
              className="form-control form-control-lg"
              onChange={onChange}
              value={formData?.subscriptionEndDate}
              name="subscriptionEndDate"
              min={formData?.subscriptionStartDate}
              max={formData?.endDate}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label">Subscription Parameters 1</label>
            <input
              type="text"
              className="form-control form-control-lg"
              onChange={onChange}
              value={formData?.subscriptionParameter1}
              name="subscriptionParameter1"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label">Subscription Parameters 2</label>
            <input
              type="text"
              className="form-control form-control-lg"
              onChange={onChange}
              value={formData?.subscriptionParameter2}
              name="subscriptionParameter2"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label">Maximum Number of Subscriber</label>
            <input
              type="number"
              className="form-control form-control-lg"
              onChange={onChange}
              value={formData?.maxSubscribers}
              name="maxSubscribers"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label">Allow Users Unsubscription</label>
            <div className="space-y-2 mt-3">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  onChange={(e) =>
                    onChange({
                      target: {
                        name: "allowUnsubscription",
                        value: e.target.checked,
                      },
                    })
                  }
                  value={formData?.allowUnsubscription}
                  type="checkbox"
                  checked={formData?.allowUnsubscription}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label">Recurring Event</label>
            <div className="space-y-2 my-3 ">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={(e) =>
                    onChange({
                      target: {
                        name: "recurringEvent",
                        value: e.target.checked,
                      },
                    })
                  }
                  checked={formData?.recurringEvent}
                />
              </div>
            </div>
            {formData?.recurringEvent && (
              <div className="mb-4">
                <label className="form-label">Recurring Days</label>
                <div className="space-y-3">
                  {[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ].map((day, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        style={{
                          border: `2px solid ${Theme.settingsTheme.colorSelection}`,
                        }}
                        value={day}
                        checked={formData?.recurringDays.includes(day)}
                        onChange={handleDayCheckboxChange}
                      />
                      <label
                        className="form-check-label ms-3"
                        htmlFor={`example-checkbox-default${index + 1}`}
                      >
                        {day}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label">Multiple Subscription</label>
            <div className="space-y-2 mt-3">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={(e) =>
                    onChange({
                      target: {
                        name: "mutipleSubscription",
                        value: e.target.checked,
                      },
                    })
                  }
                  checked={formData?.mutipleSubscription}
                />
              </div>
            </div>
          </div>
          {formData?.mutipleSubscription && (
            <div className="col-md-12">
              <div className="mb-4">
                <label className="form-label" htmlFor="example-number-input">
                  Max Reserved Seats
                </label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  onChange={onChange}
                  value={formData?.maxSubscription}
                  name="maxSubscription"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Events3;
