import React, { useRef, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { toast } from "react-toastify";

function QrCode({ eventId, eventName }) {
  const qrRef = useRef(null);
  const link = `https://easyoratorio.it/public/event/${eventId}`;
  const [fgColor, setFgColor] = useState("red");

  const handleCopy = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        toast.success('Link copied to clipboard');
      })
      .catch((err) => {
        toast.error('Failed to copy the link: ', err);
      });
  };

  const handleDownload = () => {
    if (qrRef.current) {
      qrRef.current.download("png", `${eventName}-qrcode.png`);
    }
  };

  const handleColorChange = (event) => {
    setFgColor(event.target.value);
  };

  return (
    <div
      className="modal"
      id="modal-qrcode"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">QR Code</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="my-7">
              <div className="container">
                <div className="text-center">
                  <h4 className="form-label" htmlFor="new-event-name">
                    Event Name : {eventName}
                  </h4>
                  <QRCode
                    ref={qrRef}
                    value={`https://easyoratorio.it/public/event/${eventId}`}
                    logoWidth={150}
                    logoHeight={150}
                    logoOpacity={0.4}
                    enableCORS={true}
                    bgColor={"transparent"}
                    fgColor={fgColor}
                  />
                  <div className="d-flex justify-content-center">
                    <input
                      className="form-control w-75"
                      value={link}
                      disabled
                      type="text"
                    />
                    <button onClick={handleCopy} className="btn">Copy</button>
                  </div>
                  <div className="mt-3 mx-5 px-2">
                    <select className="form-select" name="" id="" onChange={handleColorChange}>
                      <option value="" selected disabled>Choose Color For Qr Code</option>
                      <option value="black">Black</option>
                      <option value="white">White</option>
                      <option value="blue">Blue</option>
                      <option value="purple">Purple</option>
                      <option value="green">Green</option>
                      <option value="red">Red</option>
                      <option value="brown">Brown</option>
                      <option value="orange">Orange</option>
                    </select>
                  </div>
                  <div className="mt-3">
                    <button
                      type="button"
                      className="btn py-2 btn-primary px-4"
                      onClick={handleDownload}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrCode;
