import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import NotificationDropdown from "./NotificationDropdown";

function Header() {
  const navigate = useNavigate();
  const theme = useSelector((state) => state.settings.settings);
  const [familyMembers, setFamilyMembers] = useState([]);

  const userinfo = useSelector((state) => state?.userDetails?.user);

  useEffect(() => {
    const userData = localStorage?.getItem("userData");
    if (!userData) {
      navigate("/");
    }
    // eslint-disable-next-line
    fetchFamilyMembers(JSON.parse(userData).id);
  }, [navigate]);

  const fetchFamilyMembers = async (userId) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/user/user/getFamilyMembers/${userId}`;
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const response = await fetch(url, options);
      const data = await response.json();
      setFamilyMembers(data.data);
    } catch (error) {
      console.error("Error fetching family members:", error);
    }
  };

  const handleSidebarToggle = () => {
    const page = document.getElementById("page-container");
    window.innerWidth > 991
      ? page.classList.toggle("sidebar-mini")
      : page.classList.toggle("sidebar-o-xs");
  };
  const logout = () => {
    localStorage.removeItem("userData");
    window.location.reload("/");
  };

  return (
    <header id="page-header">
      <div className="content-header">
        <div className="space-x-1">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn btn-sm btn-alt-secondary"
              data-toggle="layout"
              onClick={handleSidebarToggle}
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
            <div className="text-white ms-2">
              EASYORATORIO{" "}
              <span className="d-none d-md-inline">
                {theme?.settingsGeneral?.institutionName}
              </span>
            </div>
          </div>
        </div>

        <div className="space-x-1">
          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn btn-sm btn-alt-secondary"
              id="page-header-notifications"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="far fa-user fs-4"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-notifications"
              style={{ borderRadius: "10px" }}
            >
              <div className="px-2 py-3 bg-body-light rounded-top">
                <h5 className="h6 text-center mb-0">Family Members</h5>
              </div>
              <ul className="nav-items my-2 fs-sm">
                {familyMembers.length > 0 ? (
                  familyMembers.map((member) => (
                    <li key={member.email}>
                      <a className="text-dark d-flex py-2" href="/">
                        <img
                          className="img-avatar me-2 ms-3"
                          src="/assets/media/avatars/avatar0.jpg"
                          alt=""
                        />
                        <div className="flex-grow-1 pe-2">
                          <p className="fw-medium mb-1">{`${member.firstName} ${member.lastName}`}</p>
                          <div className="text-muted">{member.email}</div>
                        </div>
                      </a>
                    </li>
                  ))
                ) : (
                  <li>No members</li>
                )}
              </ul>
              <div className="p-2 bg-body-light rounded-bottom">
                <a
                  className="dropdown-item text-center mb-0"
                  href="/add-member"
                >
                  <i className="fa fa-fw fa-plus opacity-50 me-1"></i> Add
                  Member
                </a>
              </div>
            </div>
          </div>
          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn btn-sm btn-alt-secondary"
              id="page-header-notifications"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="far fa-flag fs-5"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-notifications"
              style={{ borderRadius: "10px" }}
            >
              <ul className="nav-items my-2 fs-sm">
                <div className="">
                  <ul className="nav-users">
                    <li>
                      <a href="##">
                        <img
                          className="img-avatar"
                          src="/assets/media/avatars/avatar3.jpg"
                          alt=""
                        />
                        <i className="fa fa-circle text-success"></i>
                        <div>Lori Grant</div>
                        <div className="fw-normal fs-xs text-muted">
                          Photographer
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="##">
                        <img
                          className="img-avatar"
                          src="assets/media/avatars/avatar11.jpg"
                          alt=""
                        />
                        <i className="fa fa-circle text-success"></i>
                        <div>Brian Cruz</div>
                        <div className="fw-normal fs-xs text-muted">
                          Web Designer
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="##">
                        <img
                          className="img-avatar"
                          src="assets/media/avatars/avatar4.jpg"
                          alt=""
                        />
                        <i className="fa fa-circle text-warning"></i>
                        <div>Danielle Jones</div>
                        <div className="fw-normal fs-xs text-muted">
                          UI Designer
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="##">
                        <img
                          className="img-avatar"
                          src="assets/media/avatars/avatar12.jpg"
                          alt=""
                        />
                        <div>Justin Hunt</div>
                        <div className="fw-normal fs-xs text-muted">
                          Copywriter
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          </div>
          <NotificationDropdown />
          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn btn-sm btn-alt-secondary"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="d-none d-sm-inline-block fw-semibold">
                {`${userinfo?.firstName}\u00A0${userinfo?.lastName}`}
              </span>
              <img
                className="img-avatar img-avatar32 ms-2"
                src={
                  userinfo?.profilePic === null ||
                  userinfo?.profilePic === undefined ||
                  userinfo?.profilePic === "" ||
                  userinfo?.profilePic === "null"
                    ? "/assets/media/avatars/avatar15.jpg"
                    : userinfo.profilePic
                }
                alt="User"
              />
            </button>

            <div
              className="dropdown-menu dropdown-menu-md dropdown-menu-end p-0"
              aria-labelledby="page-header-user-dropdown"
            >
              <div className="px-2 py-3 bg-danger d-flex justify-content-center align-items-center  rounded-top text-center">
                <img
                  className="img-avatar img-avatar32 me-2"
                  src={
                    userinfo?.profilePic || "/assets/media/avatars/avatar15.jpg"
                  }
                  alt="user"
                />
                <h5 className="h6 mb-0 text-white">{`${userinfo?.firstName}\u00A0${userinfo?.lastName}`}</h5>
              </div>

              <div className="p-2">
                <a
                  className="dropdown-item d-flex align-items-center justify-content-between space-x-1"
                  href="/personal-info"
                >
                  <span>Profile</span>
                  <i className="fa fa-fw fa-user opacity-25"></i>
                </a>
                <Link
                  className="dropdown-item d-flex align-items-center justify-content-between"
                  to="/chat"
                >
                  <span>Inbox</span>
                  <i className="fa fa-fw fa-envelope-open opacity-25"></i>
                </Link>
                {userinfo?.role === "0" ? (
                  <Link
                    className="dropdown-item d-flex align-items-center justify-content-between space-x-1"
                    to="/"
                  >
                    <span>User Policies</span>
                    <i className="fa fa-fw fa-file opacity-25"></i>
                  </Link>
                ) : (
                  ""
                )}
                {userinfo?.role === "0" ? (
                  <Link
                    className="dropdown-item d-flex align-items-center justify-content-between space-x-1"
                    to="/settings"
                  >
                    <span>Settings</span>
                    <i className="fa fa-fw fa-wrench opacity-25"></i>
                  </Link>
                ) : (
                  ""
                )}
                <div
                  className="dropdown-item d-flex align-items-center justify-content-between space-x-1"
                  onClick={logout}
                >
                  <span>Logout</span>
                  <i className="fa fa-fw fa-sign-out-alt opacity-25"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="page-header-search"
        className="overlay-header bg-body-extra-light"
      >
        <div className="content-header">
          <form className="w-100">
            <div className="input-group">
              <button
                type="button"
                className="btn btn-secondary"
                data-toggle="layout"
                data-action="header_search_off"
              >
                <i className="fa fa-fw fa-times"></i>
              </button>
              <input
                type="text"
                className="form-control"
                placeholder="Search or hit ESC.."
                id="page-header-search-input"
                name="page-header-search-input"
              />
              <button type="submit" className="btn btn-secondary">
                <i className="fa fa-fw fa-search"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div id="page-header-loader" className="overlay-header bg-primary">
        <div className="content-header">
          <div className="w-100 text-center">
            <i className="far fa-sun fa-spin text-white"></i>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
