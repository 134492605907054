import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

function GeneralInfo(props) {
  const params = useParams();
  const [formData, setFormData] = useState({
    recordId: "",
    firstName: "",
    lastName: "",
    email: "",
    birthday: "",
    birthPlace: "",
    nationality: "",
    sex: "",
    shirtSize: "",
    fiscalCode: "",
    residentialAddress: "",
    houseNumber: "",
    city: "",
    province: "",
    phonePersonal: "",
    phoneFather: "",
    phoneMother: "",
    phoneCustom1: {
      key: "",
      value: "",
    },
    phoneCustom2: {
      key: "",
      value: "",
    },
    schoolType: "",
    schoolName: "",
    schoolBlonging: "",
    schoolAuthority: "",
    maxSpending: "",
    personalDiscount: "",
    otherParish: "",
  });

  useEffect(() => {
    if (props.userInfo && props.userInfo.data) {
      const formatDate = (date) => {
        if (!date) return "";
        
        const parsedDate = new Date(date);
        if (!isNaN(parsedDate.getTime())) {
          const year = parsedDate.getFullYear();
          const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
          const day = String(parsedDate.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        }
        
        return "";
      };

      setFormData({
        recordId: params.id,
        firstName: props.userInfo.data.firstName || "",
        lastName: props.userInfo.data.lastName || "",
        email: props.userInfo.data.email || "",
        birthday: formatDate(props.userInfo.data.birthday) || "",
        birthPlace: props.userInfo.data.birthPlace || "",
        nationality: props.userInfo.data.nationality || "",
        sex: props.userInfo.data.sex || "",
        shirtSize: props.userInfo.data.shirtSize || "",
        fiscalCode: props.userInfo.data.fiscalCode || "",
        residentialAddress: props.userInfo.data.residentialAddress || "",
        houseNumber: props.userInfo.data.houseNumber || "",
        city: props.userInfo.data.city || "",
        province: props.userInfo.data.province || "",
        phonePersonal: props.userInfo.data.phonePersonal || "",
        phoneFather: props.userInfo.data.phoneFather || "",
        phoneMother: props.userInfo.data.phoneMother || "",
        phoneCustom1: {
          key: props.userInfo.data.phoneCustom1?.key || "Name",
          value: props.userInfo.data.phoneCustom1?.value || "",
        },
        phoneCustom2: {
          key: props.userInfo.data.phoneCustom2?.key || "Name",
          value: props.userInfo.data.phoneCustom2?.value || "",
        },
        schoolType: props.userInfo.data.schoolType || "",
        schoolName: props.userInfo.data.schoolName || "",
        schoolBlonging: props.userInfo.data.schoolBlonging || "",
        schoolAuthority: props.userInfo.data.schoolAuthority || "",
        maxSpending: props.userInfo.data.maxSpending || "",
        personalDiscount: props.userInfo.data.personalDiscount || "",
        fatherName: props.userInfo.data.fatherName || "",
        motherName: props.userInfo.data.motherName || "",
        otherParish: props.userInfo.data.otherParish || "",
      });
    }
  }, [props.userInfo, params.id]);

  async function handleUpdate() {
    const url = `${process.env.REACT_APP_API_URL}/admin/user/updateRecord`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Update Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to save!",
          text: data.message || "An error occurred while saving.",
        });
        console.error("Update failed:", data.message || "Unknown error");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed to save!",
        text: error.message || "An error occurred while saving.",
      });
      console.error("Request error:", error);
    }
  }

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const theme = useSelector((state) => state.settings.settings);
  const parishNames = theme?.settingsGeneral.parishesName
    .split(";")
    .map((name) => name.trim())
    .filter((name) => name !== "");

  const handleInputChange2 = (event, fieldName) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: {
        ...prevData[fieldName],
        value: value,
      },
    }));
  };
  const handleCustomPhoneEdit = (event, fieldName, key) => {
    const newValue = event.target.textContent.trim();
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: {
        ...prevData[fieldName],
        [key]: newValue,
      },
    }));
  };
  const tshirt = useSelector((state) => state.settings.settings);
  const tShirtSizes = tshirt?.settingsGeneral?.tShirt
    .split(";")
    .map((size) => size.trim())
    .filter((size) => size !== "");
  return (
    <form>
      <div className="row ">
        <div className="col-lg-12">
          <h1 className="block-title fs-4 fw-bolder">General Information </h1>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4 mt-3">
              <label className="form-label" for="example-text-input">
                First Name{" "}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                value={formData.firstName}
                onChange={onChange}
                name="firstName"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-4 mt-md-3">
              <label className="form-label" for="example-text-input">
                Last Name
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                value={formData.lastName}
                name="lastName"
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" for="example-tel-input">
                Father Name and Surname
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                onChange={onChange}
                value={formData?.fatherName}
                name="fatherName"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" for="example-tel-input">
                Mother Name and Surname
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                onChange={onChange}
                value={formData?.motherName}
                name="motherName"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" for="example-password-input">
                Password{" "}
              </label>
              <input
                type="password"
                disabled
                className="form-control form-control-lg"
                placeholder="*******"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" for="example-text-input">
                Nationality{" "}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                value={formData.nationality}
                onChange={onChange}
                name="nationality"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" for="example-text-input">
                Sex
              </label>
              <select
                className="form-select form-select-lg "
                aria-label=".form-select-lg example"
                value={formData.sex}
                onChange={onChange}
                name="sex"
              >
                <option selected disabled value="">
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-12">
          <h1 className="block-title fs-4 fw-bolder">Birth Date </h1>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4 mt-3">
              <label className="form-label" for="example-date-input">
                Date Of Birth{" "}
              </label>
              <input
                type="date"
                className="form-control form-control-lg"
                value={formData.birthday}
                onChange={onChange}
                name="birthday"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-md-4 mt-md-3">
              <label className="form-label" for="example-text-input">
                Place Of Birth{" "}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                value={formData.birthPlace}
                name="birthPlace"
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <h1 className="block-title fs-4 fw-bolder">Residence Data</h1>
        </div>
        <div className="col-md-4">
          <div className="mb-4 mt-3">
            <label className="form-label" for="example-text-input">
              Residential Address{" "}
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              value={formData.residentialAddress}
              onChange={onChange}
              name="residentialAddress"
            />
          </div>
        </div>

        <div className="col-md-4">
          <div className="mb-4 mt-md-3">
            <label className="form-label" for="example-text-input">
              House Number{" "}
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              value={formData.houseNumber}
              onChange={onChange}
              name="houseNumber"
            />
          </div>
        </div>

        <div className="col-md-4">
          <div className="mb-4 mt-md-3">
            <label className="form-label" for="example-text-input">
              City{" "}
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              value={formData.city}
              onChange={onChange}
              name="city"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label" for="example-text-input">
              Province{" "}
            </label>
            <select
              className="form-select form-select-lg mb-4"
              aria-label=".form-select-lg example"
              value={formData.province}
              onChange={onChange}
              name="province"
            >
              <option selected disabled value="">
                Province
              </option>
              <option value="AG">AG</option>
              <option value="AL">AL</option>
              <option value="AN">AN</option>
              <option value="AO">AO</option>
              <option value="AR">AR</option>
              <option value="AP">AP</option>
              <option value="AT">AT</option>
              <option value="AV">AV</option>
              <option value="BA">BA</option>
              <option value="BT">BT</option>
              <option value="BL">BL</option>
              <option value="BN">BN</option>
              <option value="BG">BG</option>
              <option value="BI">BI</option>
              <option value="BO">BO</option>
              <option value="BZ">BZ</option>
              <option value="BS">BS</option>
              <option value="BR">BR</option>
              <option value="CA">CA</option>
              <option value="CL">CL</option>
              <option value="CB">CB</option>
              <option value="CI">CI</option>
              <option value="CE">CE</option>
              <option value="CT">CT</option>
              <option value="CZ">CZ</option>
              <option value="CH">CH</option>
              <option value="CO">CO</option>
              <option value="CS">CS</option>
              <option value="CR">CR</option>
              <option value="KR">KR</option>
              <option value="CN">CN</option>
              <option value="EN">EN</option>
              <option value="FM">FM</option>
              <option value="FE">FE</option>
              <option value="FI">FI</option>
              <option value="FG">FG</option>
              <option value="FC">FC</option>
              <option value="FR">FR</option>
              <option value="GE">GE</option>
              <option value="GO">GO</option>
              <option value="GR">GR</option>
              <option value="IM">IM</option>
              <option value="IS">IS</option>
              <option value="SP">SP</option>
              <option value="AQ">AQ</option>
              <option value="LT">LT</option>
              <option value="LE">LE</option>
              <option value="LC">LC</option>
              <option value="LI">LI</option>
              <option value="LO">LO</option>
              <option value="LU">LU</option>
              <option value="MC">MC</option>
              <option value="MN">MN</option>
              <option value="MS">MS</option>
              <option value="MT">MT</option>
              <option value="ME">ME</option>
              <option value="MI">MI</option>
              <option value="MO">MO</option>
              <option value="MB">MB</option>
              <option value="NA">NA</option>
              <option value="NO">NO</option>
              <option value="NU">NU</option>
              <option value="OT">OT</option>
              <option value="OR">OR</option>
              <option value="PD">PD</option>
              <option value="PA">PA</option>
              <option value="PR">PR</option>
              <option value="PV">PV</option>
              <option value="PG">PG</option>
              <option value="PU">PU</option>
              <option value="PE">PE</option>
              <option value="PC">PC</option>
              <option value="PI">PI</option>
              <option value="PT">PT</option>
              <option value="PN">PN</option>
              <option value="PZ">PZ</option>
              <option value="PO">PO</option>
              <option value="RG">RG</option>
              <option value="RA">RA</option>
              <option value="RC">RC</option>
              <option value="RE">RE</option>
              <option value="RI">RI</option>
              <option value="RN">RN</option>
              <option value="RM">RM</option>
              <option value="RO">RO</option>
              <option value="SA">SA</option>
              <option value="VS">VS</option>
              <option value="SS">SS</option>
              <option value="SV">SV</option>
              <option value="SI">SI</option>
              <option value="SR">SR</option>
              <option value="SO">SO</option>
              <option value="TA">TA</option>
              <option value="TE">TE</option>
              <option value="TR">TR</option>
              <option value="TO">TO</option>
              <option value="OG">OG</option>
              <option value="TP">TP</option>
              <option value="TN">TN</option>
              <option value="TV">TV</option>
              <option value="TS">TS</option>
              <option value="UD">UD</option>
              <option value="VA">VA</option>
              <option value="VE">VE</option>
              <option value="VB">VB</option>
              <option value="VC">VC</option>
              <option value="VR">VR</option>
              <option value="VV">VV</option>
              <option value="VI">VI</option>
              <option value="VT">VT</option>
            </select>
          </div>
        </div>
        <div className="col-md-8">
          <div className="mb-4">
            <label className="form-label" for="example-text-input">
            Fiscal Code
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              value={formData.fiscalCode}
              onChange={onChange}
              name="fiscalCode"
            />
          </div>
        </div>
      </div>

      <div className="row ">
        <div className="col-lg-12">
          <h1 className="block-title fs-4 fw-bolder">Contact Information</h1>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4 mt-3">
              <label className="form-label" for="example-email-input">
                Email{" "}
              </label>
              <input
                type="email"
                className="form-control form-control-lg"
                value={formData.email}
                onChange={onChange}
                name="email"
                disabled
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-4 mt-md-3">
              <label className="form-label" for="example-tel-input">
                Personal Phone{" "}
              </label>
              <input
                type="tel"
                className="form-control form-control-lg"
                value={formData.phonePersonal}
                onChange={onChange}
                name="phonePersonal"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" for="example-tel-input">
                Father's Phone{" "}
              </label>
              <input
                type="tel"
                className="form-control form-control-lg"
                value={formData.phoneFather}
                onChange={onChange}
                name="phoneFather"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" for="example-tel-input">
                Mother's Phone{" "}
              </label>
              <input
                type="tel"
                className="form-control form-control-lg"
                value={formData.phoneMother}
                onChange={onChange}
                name="phoneMother"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="input-group mb-4">
              <span
                className="input-group-text"
                contentEditable="true"
                onBlur={(event) =>
                  handleCustomPhoneEdit(event, "phoneCustom1", "key")
                }
              >
                {props.userInfo?.data?.phoneCustom1?.key || "Name"}
              </span>
              <input
                type="text"
                className="form-control form-control-lg"
                value={props.userInfo?.data?.phoneCustom1?.value}
                placeholder="Phone Number "
                onChange={(event) => handleInputChange2(event, "phoneCustom1")}
                name="value"
                style={{
                  borderTopRightRadius: "0.5rem",
                  borderBottomRightRadius: "0.5rem",
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-group mb-4">
              <span
                className="input-group-text"
                contentEditable="true"
                onBlur={(event) =>
                  handleCustomPhoneEdit(event, "phoneCustom2", "key")
                }
              >
                {formData.phoneCustom2?.key || "Name"}
              </span>
              <input
                type="text"
                className="form-control form-control-lg"
                value={formData.phoneCustom2?.value}
                placeholder="Phone Number "
                onChange={(event) => handleInputChange2(event, "phoneCustom2")}
                name="value"
                style={{
                  borderTopRightRadius: "0.5rem",
                  borderBottomRightRadius: "0.5rem",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-12">
          <h1 className="block-title fs-4 fw-bolder">Other Information</h1>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4 mt-3">
              <label className="form-label" for="example-text-input">
                Type Of School{" "}
              </label>
              <select
                className="form-select form-select-lg mb-4"
                aria-label=".form-select-lg example"
                value={formData.schoolType}
                onChange={onChange}
                name="schoolType"
              >
                <option selected disabled value="">
                  School Type
                </option>
                <option value="SCUOLA MATERNA">SCUOLA MATERNA</option>
                <option value="SCUOLA PRIMARIA">SCUOLA PRIMARIA</option>
                <option value="SCUOLA SECONDARIA DI PRIMO GRADO">
                  SCUOLA SECONDARIA DI PRIMO GRADO
                </option>
                <option value="SCUOLA SECONDARIA DI SECONDO GRADO">
                  SCUOLA SECONDARIA DI SECONDO GRADO
                </option>
                <option value="UNIVERSITA">UNIVERSITA&apos;</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className=" mb-4 mt-md-3">
              <label className="form-label" for="example-text-input">
                School Name{" "}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                value={formData.schoolName}
                onChange={onChange}
                name="schoolName"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className=" mb-4 ">
              <label className="form-label" for="example-text-input">
                Parish Of Belonging
              </label>
              <select
                className="form-select form-select-lg mb-4"
                aria-label=".form-select-lg example"
                value={formData.schoolBlonging}
                onChange={onChange}
                name="schoolBlonging"
              >
                <option value="" selected disabled>
                  Select
                </option>
                {parishNames?.map((name, index) => (
                  <option
                    key={index}
                    value={name}
                    selected={formData?.schoolBlonging === name ? true : false}
                  >
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" for="example-text-input">
                Other Parish
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                value={formData.otherParish}
                onChange={onChange}
                name="otherParish"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" for="example-text-input">
                T-Shirt
              </label>
              <select
                className="form-select form-select-lg "
                aria-label=".form-select-lg example"
                value={formData.shirtSize}
                onChange={onChange}
                name="shirtSize"
              >
                <option selected disabled value="">
                  Size of Tshirt
                </option>
                {tShirtSizes?.map((size, index) => (
                  <option key={index} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" for="example-text-input">
                Maximum Daily Payment In Bar (€){" "}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                value={formData.maxSpending}
                onChange={onChange}
                name="maxSpending"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-4">
              <label className="form-label" for="example-text-input">
                Permanent Discount (%){" "}
              </label>
              <select
                className="form-select form-select-lg "
                aria-label=".form-select-lg example"
                value={formData.personalDiscount}
                onChange={onChange}
                name="personalDiscount"
              >
                <option selected disabled value="">
                  Select Discount
                </option>
                <option value="25">25%</option>
                <option value="50">50%</option>
                <option value="75">75%</option>
                <option value="100">100%</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-4">
              <label className="form-label" for="example-text-input">
                People Authorized To Collect The Minor:{" "}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                value={formData.schoolAuthority}
                onChange={onChange}
                name="schoolAuthority"
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-around my-4">
          <Link
            to={"/userinfo"}
            type="button"
            className="btn btn-danger btn-lg"
            style={{ width: "120px" }}
          >
            Cancel
          </Link>
          <button
            type="button"
            className="btn btn-primary btn-lg"
            style={{ width: "120px" }}
            onClick={handleUpdate}
          >
            Update
          </button>
        </div>
      </div>
    </form>
  );
}

export default GeneralInfo;
