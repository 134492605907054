import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdatePassword() {
  useEffect(() => {
    const email = localStorage.getItem("email");
    const otpFromStorage = localStorage.getItem("otp");
    if (!otpFromStorage && !email) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const email = localStorage.getItem("email");

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/user/user/updateForgottenPassword`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ email, password: newPassword }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Password updated successfully!");
        localStorage.removeItem("email");
        localStorage.removeItem("otp");
        setTimeout(() => {
          navigate("/");
        }, 1200);
      } else {
        toast.error(data.message || "Failed to update password.");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error("Error updating password. Please try again.");
    }
  };

  return (
    <div id="page-container" className="main-content-boxed">
      <main id="main-container">
        <div className="bg-body-dark">
          <div className="row mx-0 justify-content-center">
            <div className="hero-static col-lg-6 col-xl-5 d-flex justify-content-center align-items-center">
              <div className="content content-full overflow-hidden">
                <form className="js-validation-signin" onSubmit={handleSubmit}>
                  <div className="block block-themed block-rounded block-fx-shadow">
                    <div className="text-center py-4">
                      <img src="./assets/media/logo.png" alt="" width={"30%"} />
                    </div>
                    <div className="block-content">
                      <h3 className="text-black">nuova password</h3>
                      <div className="search mb-4">
                        <i className="fa fa-lock pe-2 icon text-center"></i>
                        <input
                          type="password"
                          className="form-control form-control-alt rounded"
                          name="password"
                          placeholder="nuova password"
                          style={{ paddingLeft: "35px" }}
                          value={newPassword}
                          onChange={handleNewPasswordChange}
                        />
                      </div>

                      <div className="search mb-4">
                        <i className="fa fa-lock pe-2 icon text-center"></i>
                        <input
                          type="password"
                          className="form-control form-control-alt rounded"
                          id="confirm-password"
                          name="confirm-password"
                          placeholder="Conferma password"
                          style={{ paddingLeft: "35px" }}
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                        />
                      </div>

                      <div className="row">
                        <div className="col-sm-12 text-sm-end push">
                          <button
                            type="submit"
                            className="btn rounded btn-lg btn-alt-primary fw-bold fs-5 w-100"
                          >
                            Aggiorna password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default UpdatePassword;
