import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EventLvl(props) {

    async function handleClick() {
        const userConfirmed = window.confirm("Are you sure you want to give this user Event Manager policies?");
        if (!userConfirmed) {
            return; 
        }
        const url = `${process.env.REACT_APP_API_URL}/admin/user/makeEventAdmin`;
        const options = {
            method: 'POST',
            headers: {'content-type': 'application/x-www-form-urlencoded'},
            body: new URLSearchParams({recordId: props.id})
        };
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if (data.status === 'success') {
                toast.success('Role changed to Event Manager!');
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred: ' + error.message);
        }
    }

    return (
        <button onClick={handleClick} className="btn btn-primary btn-sm w-100 mt-4 py-1">
            Give Event Manager Policies
        </button>
    );
}

export default EventLvl;
