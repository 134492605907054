import React from "react";

function Detail() {
  return (
    <div className="mx-auto">
      <div className="row">
        <div className="col-md-6">
          <div className="mb-md-5">
            <div className="block block-rounded">
              <div className="block-content block-content-full bg-gd-sea">
                <div className="fs-lg fw-bold text-white mb-2">About Us</div>
                <p className=" fw-semibold text-white mb-0">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Facilis cumque porro quaerat suscipit atque eius cum dolores
                  labore aliquid! Mollitia, vero porro. Neque distinctio odio
                  pariatur similique possimus quam eaque.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="block block-themed shadow-lg block-rounded mb-5">
            <div className="block block-rounded">
              <h4 className="pt-3 ps-3 mb-0">Events</h4>
              <div className="block-content">
                <table className="table table-vcenter">
                  <thead className="thead-light">
                    <tr>
                      <th>Name</th>
                      <th className="d-none d-sm-table-cell">Date</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Jose Mills</td>
                      <td className="d-none d-sm-table-cell">
                        12-4-24
                      </td>
                      <td className="text-center">
                      <span className="badge bg-primary">Subscribed</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Helen Jacobs</td>
                      <td className="d-none d-sm-table-cell">
                        12-4-24
                      </td>
                      <td className="text-center">
                      <span className="badge bg-primary">Subscribed</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Jon Smith</td>
                      <td className="d-none d-sm-table-cell">
                        10-4-24
                      </td>
                      <td className="text-center">
                      <span className="badge bg-primary">Subscribed</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Detail;
