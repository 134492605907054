import moment from "moment";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

function ViewModel({ wallet }) {
  const [status, setStatus] = useState(wallet?.state);

  useEffect(() => {
    setStatus(wallet?.state);
  }, [wallet]);

  const formattedDate = moment(wallet?.paymentDate).format("DD/MM/YYYY");

  async function handleStatus() {
    const url = `${process.env.REACT_APP_API_URL}/user/walletHistory/updateRecord`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: wallet?.id, state: status }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Status Updated");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(data.message || "Status unable to Updated");
      }
    } catch (error) {
      console.error(error);
    }
  }

  console.log(wallet)

  return (
    <div
      className="modal"
      id="modal-largeR"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-largeR"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Payment Details</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="my-5">
              <div className="container">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4 ">
                        <label className="form-label" for="example-date-input">
                          Date
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-alt"
                          value={formattedDate}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4 ">
                        <label className="form-label" for="example-text-input">
                          User
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-alt"
                          value={`${wallet?.userId?.firstName} ${wallet?.userId?.lastName}`}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4 ">
                        <label className="form-label" for="example-text-input">
                          Import
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-alt"
                          id="example-text-input"
                          name="example-text-input"
                          value={`${wallet?.amount}, 00 €`}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4 ">
                        <label className="form-label" for="example-text-input">
                          Type Of Payment
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-alt"
                          id="example-text-input"
                          name="example-text-input"
                          value={wallet?.paymentType}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4 ">
                        <label className="form-label" for="example-text-input">
                          Money Operator
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-alt"
                          id="example-text-input"
                          name="example-text-input"
                          value={`${wallet?.moneyOperator?.firstName} ${wallet?.moneyOperator?.lastName}`}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4 ">
                        <label className="form-label" for="example-text-input">
                          State
                        </label>
                        <select
                          id="status-select"
                          className="form-control form-control-lg"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}
                          disabled={wallet.state === "paid" ? true : false}
                        >
                          <option disabled selected value="">
                            Select
                          </option>
                          <option value="unpaid">Unpaid</option>
                          <option value="paid">Paid</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4 ">
                        <label className="form-label" for="example-text-input">
                          Event
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-alt"
                          id="example-text-input"
                          name="example-text-input"
                          value={wallet?.eventId?.eventName || ""}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4 ">
                        <label className="form-label" for="example-text-input">
                          Reason of Payment
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-alt"
                          id="example-text-input"
                          name="example-text-input"
                          value={wallet?.description}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  {wallet?.state === "paid" ? (
                    ""
                  ) : (
                    <div className="text-end">
                      <button
                        onClick={handleStatus}
                        className="btn btn-alt-primary"
                      >
                        Update Status
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewModel;
