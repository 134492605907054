import React, { useEffect, useState } from "react";
import ReferredModal from "./ReferredModal";
import { toast } from "react-toastify";

function BalanceModal() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/event/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
        setEvents(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  const [credentials, setCredentials] = useState({
    balanceName: "",
    eventReferred: "",
    label: "",
    color: "",
    description: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}/admin/balanceSheet/addRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(credentials),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Balance Added successful!");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        setCredentials({
          balanceName: "",
          eventReferred: "",
          label: "",
          color: "",
          description: "",
        });
      } else {
        toast.error("Unable to add Balance");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="modal"
      id="modal-large43"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lggg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Create Balance</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>

            <div className="block block-rounded">
              <div className="container">
                <div className="block-content ">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4 mt-md-3">
                          <label
                            className="form-label"
                            for="example-text-input"
                          >
                            Name Of Balance
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="balanceName"
                            onChange={onChange}
                            value={credentials.balanceName}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4 mt-md-3">
                          <label
                            className="form-label"
                            for="example-text-input"
                          >
                            Event Referred
                          </label>
                          <select
                            className="form-select form-select-lg"
                            name="eventReferred"
                            onChange={onChange}
                            value={credentials.eventReferred}
                          >
                            <option selected disabled value="">
                              Select Event
                            </option>
                            {events?.map((event, index) => (
                              <option key={index} value={event.id}>
                                {event.eventName} ({event.tag.key})
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <ReferredModal />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4 mt-md-3">
                          <label
                            className="form-label"
                            for="example-text-input"
                          >
                            Label
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="example-text-input"
                            name="label"
                            onChange={onChange}
                            value={credentials.label}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="mb-4 mt-md-3">
                          <label
                            className="form-label"
                            for="example-text-input"
                          >
                            Color
                          </label>
                          <select
                            className="form-select form-select-lg "
                            name="color"
                            onChange={onChange}
                            value={credentials.color}
                          >
                            <option selected disabled value="">
                              Choose Color
                            </option>
                            <option value="red">Red</option>
                            <option value="yellow">Yellow</option>
                            <option value="green">Green</option>
                            <option value="blue">Blue</option>
                            <option value="orange">Orange</option>
                            <option value="purple">Purple</option>
                            <option value="brown">Brown</option>
                            <option value="pink">Pink</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12">
                        <div className="mb-md-4 ">
                          <label
                            className="form-label"
                            for="example-textarea-input"
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control form-control-lg"
                            name="description"
                            onChange={onChange}
                            value={credentials.description}
                            rows="4"
                          >
                            {credentials.description}
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="  d-flex justify-content-end">
                        <button
                          type="submit"
                          className=" btn btn-lg btn-alt-primary px-4"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BalanceModal;
