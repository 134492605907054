import React, { useState } from "react";
import { toast } from "react-toastify";

function UserCsv() {
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return;
    }
    const url = `${process.env.REACT_APP_API_URL}/admin/utils/uploadImage`;
    const form = new FormData();
    form.append("image", file);
    const options = {
      method: "POST",
      body: form,
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        setSelectedFile(data.data.image);
        toast.success("File Selected");
      } else {
        toast.error("Unable to Select File");
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function handleCsv() {
    const url = `${process.env.REACT_APP_API_URL}/admin/user/addWithCsvLink`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ csvUrl: selectedFile }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success")
        {
          toast.success("File Uploaded")
        }
        else{
          toast.error(data.message)
        }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="row push">
      <div className="col-lg-12">
        <h1 className="block-title fs-4 fw-bolder">Manual Registration</h1>
      </div>
      <div className="mb-4 mt-md-3">
        <label className="form-label" htmlFor="example-file-input">
          Select CSV File Only
        </label>
        <input
          className="form-control form-control-lg"
          type="file"
          onChange={handleFileChange}
        />
      </div>

      <div className="block-content block-content-full block-content-sm text-end">
        <button
          type="button"
          className="btn btn-alt-primary px-4"
          onClick={handleCsv}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default UserCsv;
