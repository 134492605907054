import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Otp() {
  const [otp, setOtp] = useState("");
  const [storedOtp, setStoredOtp] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem("email");
    const otpFromStorage = localStorage.getItem("otp");
    if (!otpFromStorage && !email) {
      navigate("/");
    }
    toast.success("Please check your email for the OTP");
    setStoredOtp(otpFromStorage);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    if (otp === storedOtp) {
      toast.success("OTP verified successfully!");
      navigate("/update-password");
    } else {
      toast.error("Invalid OTP. Please try again.");
    }
  };

  return (
    <div id="page-container" className="main-content-boxed">
      <main id="main-container">
        <div className="bg-body-dark">
          <div className="row mx-0 justify-content-center">
            <div className="hero-static col-lg-8 col-xl-5 d-flex justify-content-center align-items-center">
              <div className="content content-full overflow-hidden">
                <form
                  className="js-validation-signin"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="block block-themed block-rounded block-fx-shadow">
                    <div className="text-center py-4">
                      <img src="./assets/media/logo.png" alt="" width={"30%"} />
                    </div>
                    <div className="block-content">
                      <h3 className="text-black">Verificare OTP</h3>
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                          width: "4rem",
                          height: "4rem",
                          borderRadius: "8px",
                          border: "none",
                          margin: "auto",
                          marginBottom: "22px",
                          backgroundColor: "#F3F3F3",
                        }}
                      />

                      <div className="row">
                        <div className="col-sm-12 text-sm-end push">
                          <button
                            type="button"
                            onClick={handleSubmit}
                            className="btn rounded btn-lg btn-alt-primary fw-bold fs-5 w-100"
                          >
                            Verificare OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Otp;
