import React from "react";

function InfoCards() {
  return (
    <div className="mx-auto">
      <div className="row">
        <div className="col-md-6 col-xl-4">
          <div className="block block-rounded">
            <div className="block-content block-content-full bg-gd-emerald">
              <div className="fs-lg fw-bold text-white mb-2">Wallet</div>
              <p className="fs-lg fw-bold text-white mt-5 mb-0 d-flex justify-content-between">
                <h3>$50</h3>
                <button className="btn btn-emerald mb-0">Recharge</button>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="block block-rounded">
          <div className="block-content block-content-full bg-gd-dusk">
              <div className="fs-lg fw-bold text-white mb-2">Subscribed Events</div>
              <p className="fs-lg fw-bold text-white d-flex justify-content-center mt-4">
                <h3>15</h3>
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="block block-rounded">
            <div className="block-content block-content-full bg-gd-cherry">
              <div className="fs-lg fw-bold text-white mb-2">Balance</div>
              <p className="fs-lg fw-semibold text-white mb-0 d-flex justify-content-between">
                <div>Entries:</div>
                <div>10</div>
              </p>
              <p className="fs-lg fw-semibold text-white mb-0 d-flex justify-content-between">
                <div>Exits:</div>
                <div>10</div>
              </p>
              <p className="fs-lg fw-semibold text-white mb-0 d-flex justify-content-between">
                <div>Balance:</div>
                <div>10</div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCards;
