import React from "react";
import PersonalizeModal from "./PersonalizeModal";

function Events4({ onChange, formData }) {
  // const [balance, setBalance] = useState([]);

  // useEffect(() => {
  //   const fetchBalanceData = async () => {
  //     const url = `${process.env.REACT_APP_API_URL}/admin/balanceSheet/getAll`;
  //     const options = { method: "GET" };

  //     try {
  //       const response = await fetch(url, options);
  //       const result = await response.json();
  //       if (result.status === "success") {
  //         setBalance(result.data);
  //       } else {
  //         console.error("Failed to fetch balance data");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching balance data:", error);
  //     }
  //   };

  //   fetchBalanceData();
  // }, [formData?.balanceSelection]);

  return (
    <div className="text-start my-md-5 my-3">
      {/* <div className="row">
        <div className="col-6 mt-3">
          <div className="mb-4">
            <label className="form-label" htmlFor="example-text-input">
              Balance Selection*
            </label>
            <select
              className="form-select form-select-lg"
              aria-label=".form-select-lg example"
              name="balanceSelection"
              onChange={onChange}
              value={formData?.balanceSelection}
              required
            >
              <option selected disabled value="">
                Select
              </option>
              {balance.map((data, index) => (
                <option key={index} value={data.id}>
                  {data.balanceName} ({data.description})
                </option>
              ))}
            </select>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-4 col-12">
          <div className="mb-4">
            <label className="form-label">Apply Personal Discount</label>
            <div className="space-y-2 mt-3">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  onChange={(e) =>
                    onChange({
                      target: {
                        name: "applyPersonalDiscount",
                        value: e.target.checked,
                      },
                    })
                  }
                  value={formData?.applyPersonalDiscount}
                  type="checkbox"
                  checked={formData?.applyPersonalDiscount}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-8">
          <div className="mb-4">
            <label className="form-label"></label>
            <div className="mt-3 block-content-sm">
              <button
                type="button"
                className="btn btn-lg btn-alt-primary px-md-5 py-2"
                data-bs-toggle="modal"
                data-bs-target="#modal-large40"
              >
                Personalized Role*
              </button>
            </div>
          </div>
        </div>
        <PersonalizeModal formData={formData} onChange={onChange} />
      </div>
    </div>
  );
}

export default Events4;
