import moment from "moment";
import React, { useState } from "react";
import WalletModel from "./WalletModel";
import SubscribeFromEventTitle from "./SubscribeFromEventTitle";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function TitlememberTable1({ usersData, eventData }) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [disableSubscription, setDisableSubscription] = useState(false);

  const userinfo = useSelector((state) => state.userDetails.user);

  const handleRechargeClick = (user) => {
    setSelectedUser(user);
  };

  const handleSubscribeClick = (user) => {
    setSelectedUser(user);
  };

  useEffect(() => {
    if (eventData?.subscriptionState === "Event Ended") {
      setDisableSubscription(true);
    }
  }, [eventData]);

  return (
    <div className="block block-rounded">
      <div className="container">
        <div className="my-md-4 my-3">
          <div style={{ overflowX: "auto" }}>
            <table
              id="unsubscribedUsersDataTable"
              className="js-table-checkable table table-hover table-vcenter table-responsive"
            >
              <thead>
                <tr>
                  <th>FIRST NAME</th>
                  <th>LAST NAME</th>
                  <th>DATE OF BIRTH</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {usersData?.map((user, index) => (
                  <tr key={index}>
                    <td>{user?.firstName}</td>
                    <td>{user?.lastName}</td>
                    <td>{moment(user?.birthday).format("DD/MM/YYYY")}</td>
                    <td>
                      {["0", "1", "2", "3"].includes(userinfo?.role) ? (
                        <button
                          type="button"
                          disabled={disableSubscription}
                          data-bs-toggle="modal"
                          data-bs-target="#modal-subscribe-event"
                          onClick={() => handleSubscribeClick(user)}
                          className="btn btn-primary me-3"
                        >
                          Subscribe
                        </button>
                      ) : (
                        ""
                      )}
                      {userinfo?.role &&
                      ["0", "1", "2"].includes(userinfo.role) ? (
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#modal-wallet2"
                          className="btn btn-primary"
                          onClick={() => handleRechargeClick(user)}
                        >
                          Recharge wallet
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <WalletModel userData={selectedUser} />
      <SubscribeFromEventTitle userData={selectedUser} eventData={eventData} />
    </div>
  );
}

export default TitlememberTable1;
