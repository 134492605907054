import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function ReceiptModel({ receiptData }) {
  const Theme = useSelector((state) => state.settings.settings);
  const logoUrl = Theme.settingsTheme?.logo;
  const name = useSelector((state) => state.settings.settings);
  const contact = useSelector((state) => state.settings.settings);
  const [imageLoaded, setImageLoaded] = useState(false);
  const downloadButtonRef = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.src = logoUrl;
    img.onload = () => setImageLoaded(true);
  }, [logoUrl]);

  const handleDownloadClick = async () => {
    const downloadButton = downloadButtonRef.current;
    if (!downloadButton) return;

    downloadButton.style.display = "none"; // Hide the download button

    const input = document.getElementById("receiptContent");
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const canvas = await html2canvas(input, { scale: 1.5, useCORS: true });
    const imgData = canvas.toDataURL("image/jpeg", 0.85);
    const imgProps = pdf.getImageProperties(imgData);
    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "JPEG", 0, position, pdfWidth, imgHeight);
    heightLeft -= pdfHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "JPEG", 0, position, pdfWidth, imgHeight);
      heightLeft -= pdfHeight;
    }

    pdf.save("receipt.pdf");
    window.location.reload();
  };

  const totalAmount = receiptData.reduce(
    (sum, rec) => sum + parseFloat(rec.amount),
    0
  );

  return (
    <div
      className="modal"
      id="modal-large10"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal-large10"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Receipt</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="my-3">
              <div className="container" id="receiptContent" style={{ padding: "20px 0" }}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="text-center">
                        <img
                          width={100}
                          src={logoUrl}
                          alt="logo"
                          crossOrigin="anonymous"
                          onLoad={() => setImageLoaded(true)}
                        />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <small className="text-black">
                        <p className="p-0 m-0">
                          {name?.settingsGeneral?.institutionName}
                        </p>
                        <p className="p-0 m-0">
                          Address: {contact?.settingsGeneral?.address}
                        </p>
                        <p className="p-0 m-0">
                          Telefono: {contact?.settingsGeneral?.telephone}
                        </p>
                        <p className="p-0 m-0">
                          Mobile: {contact?.settingsGeneral?.mobile}
                        </p>
                        <p className="p-0 m-0">
                          Email: {contact?.settingsGeneral?.email}
                        </p>
                      </small>
                    </div>
                  </div>
                  <div className="text-end">
                    <div className="me-3 mt-3">
                      <p className="m-0 p-0">{moment().format("DD-MM-YYYY")}</p>
                    </div>
                  </div>
                </div>
                <table
                  id="walletDataTable"
                  className="js-table-checkable table table-hover table-vcenter mt-3"
                >
                  <thead>
                    <tr>
                      <th className="text-center">DESCRIPTION</th>
                      <th className="text-center">EVENT NAME</th>
                      <th className="text-center">AMOUNT OF MONEY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {receiptData?.map((rec, index) => (
                      <tr key={index}>
                        <td className="text-center">{rec?.description}</td>
                        <td className="text-center">{rec?.eventName}</td>
                        <td className="text-center">
                          {parseFloat(rec?.amount)},00 €
                        </td>
                      </tr>
                    ))}
                    <tr className="fw-bold">
                      <td className="text-center">Total</td>
                      <td></td>
                      <td className="text-center">{totalAmount},00 €</td>
                    </tr>
                  </tbody>
                </table>
                <div style={{ height: "50px" }}></div> {/* Extra space at bottom */}
              </div>

              <div className="me-3 mt-3 text-end">
                <button
                  ref={downloadButtonRef}
                  id="downloadButton"
                  type="button"
                  className="btn btn-alt-primary px-4"
                  onClick={handleDownloadClick}
                  disabled={!imageLoaded}
                >
                  Download
                </button>
                {!imageLoaded && <p>Loading image, please wait...</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReceiptModel;
