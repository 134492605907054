import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Editor from "./Editor";

function Main() {
  const [allUsers, setAllUsers] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [sendType, setSendType] = useState("users");
  const [eventGroupId, setEventGroupId] = useState("null");
  const [attachments, setAttachments] = useState([]);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const userOptions = allUsers.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName} - ${user.rfid} (${formatDate(
      user.birthday
    )})`,
    email: user.email,
  }));

  const eventOptions = allEvents.map((event) => ({
    value: event.id,
    label: event.eventName,
  }));

  const groupOptions = allGroups.map((group) => ({
    value: group.id,
    label: group.groupName,
  }));

  useEffect(() => {
    const fetchUsersData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/user/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
        setAllUsers(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    const fetchEventData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/event/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
        setAllEvents(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    const fetchGroupData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/group/getAll`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      try {
        const response = await fetch(url, options);
        const { data } = await response.json();
        setAllGroups(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchUsersData();
    fetchEventData();
    fetchGroupData();
  }, []);

  const handleSelectChange = (selectedOptions) => {
    setSelectedUsers(selectedOptions);
  };

  const handleAttachmentChange = (e) => {
    setAttachments(e.target.files);
  };

  const handleSendClick = async (e) => {
    e.preventDefault();

    const selectedUserEmails = selectedUsers.map((user) => user.email);
    const url = `${process.env.REACT_APP_API_URL}/admin/admin/sendEmailToUsers`;
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("message", message);
    formData.append("sendType", sendType);
    formData.append("eventGroupId", eventGroupId);
    selectedUserEmails.forEach((email) => formData.append("users", email));
    Array.from(attachments).forEach((file) =>
      formData.append("attachments", file)
    );

    const options = {
      method: "POST",
      body: formData,
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Email Sent Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        setSubject("");
        setMessage("");
        setSelectedUsers([]);
        setAttachments([]);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendTypes = [
    { key: "users", value: "Users" },
    { key: "event", value: "Events" },
    { key: "group", value: "Groups" },
  ];

  const sendTypeOptions = sendTypes.map((sendType) => ({
    value: sendType.key,
    label: sendType.value,
  }));

  const onChange = (data) => {
    setMessage(data);
  };

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className="fa fa-envelope me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">Email</h1>
            </div>
            <div className="block block-rounded">
              <div className="container">
                <form onSubmit={handleSendClick} encType="multipart/form-data">
                  <div className="row mt-3 mb-2">
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label htmlFor="sendType" className="form-label">
                          Send To
                        </label>
                        <Select
                          id="sendType"
                          options={sendTypeOptions}
                          onChange={(selected) => {
                            setEventGroupId("null");
                            setSendType(selected.value);
                          }}
                          value={
                            sendTypeOptions.filter(
                              (s) => s.value === sendType
                            )[0]
                          }
                          name="sendType"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-4">
                        {sendType === "users" ? (
                          <>
                            <label className="form-label">
                              Destination Usernames
                            </label>
                            <Select
                              id="sourceUser"
                              options={userOptions}
                              onChange={handleSelectChange}
                              value={selectedUsers}
                              isClearable
                              name="sourceUser"
                              isMulti
                            />
                          </>
                        ) : (
                          <>
                            <label className="form-label">
                              Destination{" "}
                              {sendType === "event" ? "Event" : "Group"}
                            </label>
                            <Select
                              id={`source${
                                sendType === "event" ? "Event" : "Group"
                              }`}
                              options={
                                sendType === "event"
                                  ? eventOptions
                                  : groupOptions
                              }
                              onChange={(selected) => {
                                setEventGroupId(selected.value);
                              }}
                              value={
                                sendType === "event"
                                  ? eventOptions.filter(
                                      (e) => e.value === eventGroupId
                                    )
                                  : groupOptions.filter(
                                      (g) => g.value === eventGroupId
                                    )
                              }
                              name={`source${
                                sendType === "event" ? "Event" : "Group"
                              }`}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label className="form-label">Subject</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          name="subject"
                          onChange={(e) => setSubject(e.target.value)}
                          value={subject}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-md-4">
                        <label
                          className="form-label"
                          htmlFor="example-textarea-input"
                        >
                          Message
                        </label>
                        <Editor message={message} onChange={onChange} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label className="form-label">Attachments</label>
                        <input
                          type="file"
                          className="form-control"
                          name="attachments"
                          multiple
                          onChange={handleAttachmentChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 text-end my-3">
                      <div className="my-2">
                        <button
                          className="btn btn-alt-primary px-4"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Main;
