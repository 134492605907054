import React from "react";

function Left({ data }) {

  const calculateAmounts = (movements) => {
    let inbound = 0;
    let outbound = 0;

    movements.forEach((movement) => {
      switch (true) {
        case movement.destinationType === "ORGANIZATION" &&
             movement.sourceType !== "ORGANIZATION":
          inbound += parseFloat(movement.amount);
          break;
        case movement.sourceType === "ORGANIZATION" &&
             movement.destinationType !== "ORGANIZATION":
          outbound += parseFloat(movement.amount);
          break;
        default:
          // For other scenarios, we assume 0 for this example
          break;
      }
    });

    return { inbound, outbound, balance: inbound - outbound };
  };

  const moneyMovements = data?.data?.moneyMovements || [];
  const { inbound, outbound, balance } = calculateAmounts(moneyMovements);

  return (
    <>
      <div className="content pe-md-0">
        <div className="block block-rounded block-fx-shadow">
          <div className="block block-rounded overflow-hidden">
            <div className="block-content tab-content">
              <div className="mx-auto">
                <div className="">
                  <div className="block-content-full mb-4">
                    <h1 className="block-title fs-4 fw-bolder">
                      {data?.data?.balanceSheetData?.balanceName}
                    </h1>
                    <div
                      className="d-flex flex-column"
                      style={{ fontSize: "12px" }}
                    >
                      <p className="mt-3 mt-md-5 fs-5 fw-normal">
                        {data?.data?.balanceSheetData?.description}
                      </p>
                      <h5 className="fw-medium mb-0 d-flex justify-content-between">
                        <div>Balance Amount:</div>
                        <div>{balance.toFixed(0)},00 €</div>
                      </h5>
                      <h5 className="mt-4 fw-medium mb-0 d-flex justify-content-between">
                        <div>Inbound:</div>
                        <div>{inbound.toFixed(0)},00 € </div>
                      </h5>
                      <h5 className="mt-4 fw-medium mb-0 d-flex justify-content-between">
                        <div>Outbound:</div>
                        <div>{outbound.toFixed(0)},00 €</div>
                      </h5>
                      <h3 className="mt-5 fw-bold mb-0">
                        <div>Event Associated</div>
                      </h3>
                      <div className="mt-4 d-flex justify-content-between">
                        <h5 className="p-0 m-0">
                          {
                            data?.data?.balanceSheetData?.eventReferred
                              ?.eventName
                          }
                        </h5>
                        <span
                          className={`fw-medium badge rounded fs-6 ${
                            data?.data?.balanceSheetData?.eventReferred?.tag
                              .value === "yellow"
                              ? "text-black"
                              : ""
                          }`}
                          style={{
                            backgroundColor:
                              data?.data?.balanceSheetData?.eventReferred?.tag
                                .value,
                          }}
                        >
                          {data?.data?.balanceSheetData?.eventReferred?.tag.key}
                        </span>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Left;
