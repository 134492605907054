import React from "react";
import { useNavigate } from "react-router-dom";

function ContactList({ contacts, onSelectUser, selectedUser }) {
  const navigate = useNavigate();
  return (
    <ul className="list-unstyled chat-list mt-2 mb-0">
      {contacts.map((contact) => (
        <li
          key={contact.id}
          className={`clearfix ${
            selectedUser.id === contact.id ? "active" : ""
          }`}
          onClick={() => {
            navigate(`/chat?active=${contact.id}`);
            onSelectUser(contact);
          }}
        >
          <img src={contact.avatar} alt="avatar" />
          <div className="about">
            <div className="name">{contact.name}</div>
            {contact.type !== "user" ? (
              <div
                className={`badge rounded ${contact.tag.value === 'yellow' ? 'text-black' : ''}`}
                style={{ backgroundColor: contact.tag.value, color: "white" }}
              >
                {contact.tag.key}
              </div>
            ) : (
              ""
            )}
          </div>
        </li>
      ))}
    </ul>
  );
}

export default ContactList;
