import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";

function AddModal({ allUsers, allSuppliers, balance }) {
  const userinfo = useSelector((state) => state.userDetails.user);

  const [credentials, setCredentials] = useState({
    dateOfMovement: "",
    amount: "",
    reasonForMovement: "",
    typeOfMovement: "",
    recipeOfPayment: "",
    invoiceCode: "",
    invoiceDate: "",
    invoiceAssociated: "",
    discountApplied: "",
    balanceOperator: userinfo?.id,
    electronicPaymentIncrease: "",
    balanceReference: "",
    paymentVerified: "",
    movementDescription: "",
    sourceType: "",
    destinationType: "",
    description: "",
    sourceUser: "",
    destinationUser: "",
    destinationSupplier: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setCredentials((prev) => ({
      ...prev,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}/admin/moneyMovement/addRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(credentials),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Money Movement Added successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        setCredentials({
          dateOfMovement: "",
          amount: "",
          reasonForMovement: "",
          typeOfMovement: "",
          recipeOfPayment: "",
          invoiceCode: "",
          invoiceDate: "",
          invoiceAssociated: "",
          discountApplied: "",
          balanceOperator: userinfo?.id,
          electronicPaymentIncrease: "",
          balanceReference: "",
          paymentVerified: "",
          movementDescription: "",
          sourceType: "",
          destinationType: "",
          description: "",
          sourceUser: "",
          destinationUser: "",
          destinationSupplier: "",
        });
      } else {
        toast.error("Unable to add Money Movement");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleFileChange = async (event, key) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/utils/uploadImage`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        const url = data.data.image;
        setCredentials((prev) => ({ ...prev, [key]: url }));
        toast.success(`${key.replace(/([A-Z])/g, " $1").trim()} Uploaded!`);
      } else {
        throw new Error(data.message || "Failed to upload image");
      }
    } catch (error) {
      toast.error(error.message || "Error uploading image");
    }
  };

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  const userOptions = allUsers.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName} - ${user.rfid} (${formatDate(
      user.birthday
    )}) `,
  }));

  const supplierOptions = allSuppliers.map((supplier) => ({
    value: supplier.id,
    label: `${supplier.businessName} (${supplier.typeOfBusiness})`,
  }));

  const balanceOptions = balance.map((bal) => ({
    value: bal.id,
    label: bal.balanceName,
  }));

  return (
    <div
      className="modal"
      id="modal-large48"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Add Movement</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="block block-rounded">
              <div className="container">
                <div className="block-content">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4 mt-md-3">
                          <label
                            className="form-label"
                            htmlFor="example-date-input"
                          >
                            Date Of Movement
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-lg"
                            name="dateOfMovement"
                            onChange={onChange}
                            value={credentials.dateOfMovement}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4 mt-md-3 euro-input-container">
                          <label
                            className="form-label"
                            htmlFor="example-text-input"
                          >
                            Amount
                          </label>
                          <input
                            type="number"
                            className="form-control form-control-lg"
                            name="amount"
                            onChange={onChange}
                            value={credentials.amount}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            htmlFor="reasonForMovement"
                          >
                            Reason For Movement
                          </label>
                          <select
                            className="form-select form-select-lg"
                            name="reasonForMovement"
                            onChange={onChange}
                            value={credentials.reasonForMovement}
                            required
                          >
                            <option selected disabled value="">
                              Reason For Movement
                            </option>
                            <option value="Subscription">Subscription</option>
                            <option value="unsubscription">
                              Unsubscription
                            </option>
                            <option value="Provider payment">
                              Provider payment
                            </option>
                            <option value="Money write-off">
                              Money write-off
                            </option>
                            <option value="Deposit">Deposit</option>
                            <option value="Bail">Bail</option>
                            <option value="Earnings">Earnings</option>
                            <option value="Free offer">Free offer</option>
                            <option value="Send money to bank">
                              Send money to bank
                            </option>
                            <option value="Wallet recharge">
                              Wallet recharge
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label className="form-label">
                            Type Of Money Movement
                          </label>
                          <select
                            className="form-select form-select-lg"
                            name="typeOfMovement"
                            onChange={onChange}
                            value={credentials.typeOfMovement}
                            required
                          >
                            <option selected disabled value="">
                              Type of Money Movement
                            </option>
                            <option value="Inside system">Inside system</option>
                            <option value="Cash">Cash</option>
                            <option value="Bank transfer">Bank transfer</option>
                            <option value="Stripe">Electronic – Stripe</option>
                            <option value="Paypal">Electronic – Paypal</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            htmlFor="recipeOfPayment"
                          >
                            Recipe Of Payment
                          </label>
                          <div>
                            <input
                              className="form-control form-control-lg"
                              type="file"
                              onChange={(e) =>
                                handleFileChange(e, "recipeOfPayment")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label className="form-label" htmlFor="invoiceCode">
                            Invoice Code
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="invoiceCode"
                            onChange={onChange}
                            value={credentials.invoiceCode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label className="form-label" htmlFor="invoiceDate">
                            Invoice Date
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-lg"
                            name="invoiceDate"
                            onChange={onChange}
                            value={credentials.invoiceDate}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            htmlFor="invoiceAssociated"
                          >
                            Invoice Associated
                          </label>
                          <div>
                            <input
                              className="form-control form-control-lg"
                              type="file"
                              id="invoiceAssociated"
                              onChange={(e) =>
                                handleFileChange(e, "invoiceAssociated")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            htmlFor="discountApplied"
                          >
                            Discount Applied
                          </label>
                          <input
                            type="number"
                            className="form-control form-control-lg"
                            name="discountApplied"
                            onChange={onChange}
                            value={credentials.discountApplied}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            htmlFor="balanceOperator"
                          >
                            Balance Operator
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="balanceOperator"
                            disabled
                            value={
                              userinfo?.firstName + " " + userinfo?.lastName
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            htmlFor="electronicPaymentIncrease"
                          >
                            Increase For Electronic Payment
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="electronicPaymentIncrease"
                            onChange={onChange}
                            value={credentials.electronicPaymentIncrease}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            htmlFor="balanceReference"
                          >
                            Balance Reference
                          </label>
                          <Select
                            id="balanceReference"
                            options={balanceOptions}
                            onChange={(option) =>
                              handleSelectChange(option, {
                                name: "balanceReference",
                              })
                            }
                            value={balanceOptions.find(
                              (option) =>
                                option.value === credentials.balanceReference
                            )}
                            isClearable
                            required
                            name="balanceReference"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            htmlFor="paymentVerified"
                          >
                            Payment Verified
                          </label>
                          <select
                            className="form-select form-select-lg"
                            name="paymentVerified"
                            onChange={onChange}
                            value={credentials.paymentVerified}
                            required
                          >
                            <option selected disabled value="">
                              Payment Verified
                            </option>
                            <option value="true">Verified</option>
                            <option value="false">Waiting verification</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12">
                        <div className="">
                          <label
                            className="form-label"
                            htmlFor="movementDescription"
                          >
                            Description Of Movement
                          </label>
                          <textarea
                            className="form-control form-control-lg"
                            name="movementDescription"
                            onChange={onChange}
                            value={credentials.movementDescription}
                            rows="4"
                          >
                            {credentials.movementDescription}
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <h1 className="block-title fs-4 my-4 fw-bolder">
                          Money Movement Source
                        </h1>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-4">
                          <label htmlFor="sourceType" className="form-label">
                            Choose Money Movement Source
                          </label>
                          <select
                            id="sourceType"
                            className="form-control"
                            name="sourceType"
                            value={credentials.sourceType}
                            onChange={onChange}
                            required
                          >
                            <option disabled selected value="">
                              Select Source
                            </option>
                            <option value="ORGANIZATION">Organization</option>
                            <option value="USER">User</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        {credentials.sourceType === "USER" && (
                          <div className="form-group mb-4">
                            <label htmlFor="sourceUser" className="form-label">
                              Choose Source User
                            </label>
                            <Select
                              id="sourceUser"
                              options={userOptions}
                              onChange={(option) =>
                                handleSelectChange(option, {
                                  name: "sourceUser",
                                })
                              }
                              value={userOptions.find(
                                (option) =>
                                  option.value === credentials.sourceUser
                              )}
                              isClearable
                              name="sourceUser"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <h1 className="block-title fs-4 mb-4 fw-bolder">
                          Money Movement Destination
                        </h1>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="destinationType"
                            className="form-label"
                          >
                            Choose Money Movement Destination
                          </label>
                          <select
                            id="destinationType"
                            className="form-control"
                            name="destinationType"
                            value={credentials.destinationType}
                            onChange={onChange}
                            required
                          >
                            <option disabled selected value="">
                              Select Destination
                            </option>
                            <option value="ORGANIZATION">Organization</option>
                            <option value="SUPPLIER">Supplier</option>
                            <option value="USER">User</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        {credentials.destinationType === "USER" && (
                          <div className="form-group mb-4">
                            <label
                              htmlFor="destinationUser"
                              className="form-label"
                            >
                              Choose Destination User
                            </label>
                            <Select
                              id="destinationUser"
                              options={userOptions}
                              onChange={(option) =>
                                handleSelectChange(option, {
                                  name: "destinationUser",
                                })
                              }
                              value={userOptions.find(
                                (option) =>
                                  option.value === credentials.destinationUser
                              )}
                              isClearable
                              name="destinationUser"
                            />
                          </div>
                        )}
                        {credentials.destinationType === "SUPPLIER" && (
                          <div className="form-group mb-4">
                            <label
                              htmlFor="destinationSupplier"
                              className="form-label"
                            >
                              Choose Destination Supplier
                            </label>
                            <Select
                              id="destinationSupplier"
                              options={supplierOptions}
                              onChange={(option) =>
                                handleSelectChange(option, {
                                  name: "destinationSupplier",
                                })
                              }
                              value={supplierOptions.find(
                                (option) =>
                                  option.value ===
                                  credentials.destinationSupplier
                              )}
                              isClearable
                              name="destinationSupplier"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between mt-4">
                          <button
                            type="button"
                            className="btn btn-lg btn-primary px-4"
                            onClick={() =>
                              setCredentials({
                                dateOfMovement: "",
                                amount: "",
                                reasonForMovement: "",
                                typeOfMovement: "",
                                recipeOfPayment: "",
                                invoiceCode: "",
                                invoiceDate: "",
                                invoiceAssociated: "",
                                discountApplied: "",
                                balanceOperator: userinfo?.id,
                                electronicPaymentIncrease: "",
                                balanceReference: "",
                                paymentVerified: "",
                                movementDescription: "",
                                sourceType: "",
                                destinationType: "",
                                description: "",
                                sourceUser: "",
                                destinationUser: "",
                                destinationSupplier: "",
                              })
                            }
                          >
                            Reset
                          </button>
                          <button
                            type="submit"
                            className="btn btn-lg btn-success px-4 "
                          >
                            Add Money Movement
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddModal;
