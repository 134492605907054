import React from "react";
import { toast } from "react-toastify";
import $ from "jquery";
import { useSelector } from "react-redux";

function Table({ entriesExit, setChangeStatus }) {
  const userinfo = useSelector((state) => state.userDetails.user);

  const handleDelete = async (id) => {
    const userConfirmed = window.confirm("Are you sure you want to delete?");
    if (!userConfirmed) {
      return;
    }
    const url = `${process.env.REACT_APP_API_URL}/admin/entriesExit/deleteById`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ recordId: id }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Entry/Exit record deleted successfully");
        window.location.reload();
      } else {
        toast.error(`Failed to delete record: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error deleting record: ${error.message}`);
    }
  };

  const changeStatus = async (id, currentState) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/entriesExit/updateRecord`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        state: currentState === "IN" ? "OUT" : "IN",
        id: id,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Status updated successfully");
        setChangeStatus((prev) => !prev);
      } else {
        toast.error(`Failed to update status: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error updating status: ${error.message}`);
    }
  };

  const resetDateFilters = () => {
    $("#minDate").val("");
    $("#maxDate").val("");
    $("#entryExitDataTable").DataTable().draw();
  };

  return (
    <div className="container">
      <div className="block-content ">
        <div className="row">
          <div className="col-md-6 order-1 order-md-0">
            <div className="d-flex gap-3 mb-3 align-items-center">
              <input
                type="date"
                id="minDate"
                className="form-control"
                name="minDate"
              />
              <span className="fw-bolder">-</span>
              <input
                type="date"
                className="form-control"
                id="maxDate"
                name="maxDate"
              />
              <button
                onClick={resetDateFilters}
                className="btn btn-alt-primary text-nowrap"
              >
                Reset Filter
              </button>
            </div>
          </div>
        </div>
        <div style={{ overflowX: "auto" }}>
          <table
            id="entryExitDataTable"
            className="js-table-checkable table table-hover table-vcenter table-responsive"
          >
            <thead>
              <tr>
                <th>TIMESTAMP</th>
                <th>FIRST NAME</th>
                <th>LAST NAME</th>
                <th>STATE</th>
                {userinfo?.role === "5" || userinfo?.role === "4" ? <th>RFID</th> : "" }
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {entriesExit?.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.timestamp}</td>
                  <td>{entry.firstName}</td>
                  <td>{entry.lastName}</td>
                  <td>
                    <span
                      className={`badge ${
                        entry.state === "IN" ? "bg-success" : "bg-danger"
                      } rounded`}
                    >
                      {entry.state}
                    </span>
                  </td>
                  {userinfo?.role === "5" || userinfo?.role === "4" ?  <td>{entry.rfid}</td> : ""}
                  <td className="d-sm-table-cell d-flex">
                    <button
                      className="btn btn-primary btn-sm rounded-0 ms-2"
                      onClick={() => changeStatus(entry.id, entry.state)}
                    >
                      Change State
                    </button>
                    {userinfo?.role === "0" || userinfo?.role === "1" ? (
                      <button
                        className="btn btn-primary btn-sm rounded-0 ms-2"
                        onClick={() => handleDelete(entry.id)}
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="row ">
          <div className="col-md-12 col-12">
            {userinfo?.role === "5" ? (
              ""
            ) : (
              <div className="my-3 d-flex gap-3">
                <button
                  className="btn btn-alt-primary px-md-4 btn-lg"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-large30"
                >
                  Entries Scanning
                </button>
                <button
                  className="btn btn-alt-primary px-md-4 btn-lg"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-large40"
                >
                  Exits Scanning
                </button>

                <button
                  className="btn btn-alt-primary px-md-4 btn-lg"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-large20"
                >
                  Manual Entry
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
