import React, { useState } from "react";
import { toast } from "react-toastify";

function SuppliersModal() {
  const [credentials, setCredentials] = useState({
    typeOfBusiness: "",
    businessName: "",
    address: "",
    phone1: "",
    phone2: "",
    email: "",
    emailCertified: "",
    vatNumber: "",
    fiscalCode: "",
    iban: "",
    bankName: "",
    bankCountry: "",
    bicCode: "",
    accountNumber: "",
    description: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}/admin/supplier/addRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(credentials),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Supplier Added successful!");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        setCredentials({
          typeOfBusiness: "",
          businessName: "",
          address: "",
          phone1: "",
          phone2: "",
          email: "",
          emailCertified: "",
          vatNumber: "",
          fiscalCode: "",
          iban: "",
          bankName: "",
          bankCountry: "",
          bicCode: "",
          accountNumber: "",
          description: "",
        });
      } else {
        toast.error("Unable to add Supplier");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="modal"
      id="modal-large38"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lggg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Add Supplier</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>

            <div className="block block-rounded">
              <div className="container">
                <div className="block-content ">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-4 mt-3">
                          <label
                            className="form-label"
                            for="example-text-input"
                          >
                            Type Of Business
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="example-text-input"
                            name="typeOfBusiness"
                            onChange={onChange}
                            value={credentials.typeOfBusiness}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-4 mt-md-3">
                          <label
                            className="form-label"
                            for="example-text-input"
                          >
                            Name Of Business
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="example-text-input"
                            name="businessName"
                            onChange={onChange}
                            value={credentials.businessName}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="mb-4 mt-md-3">
                          <label
                            className="form-label"
                            for="example-text-input"
                          >
                            Address
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="example-text-input"
                            name="address"
                            onChange={onChange}
                            value={credentials.address}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label className="form-label" for="example-tel-input">
                            Phone Number 1
                          </label>
                          <input
                            type="number"
                            className="form-control form-control-lg"
                            id="example-tel-input"
                            name="phone1"
                            onChange={onChange}
                            value={credentials.phone1}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label className="form-label" for="example-tel-input">
                            Phone Number 2
                          </label>
                          <input
                            type="number"
                            className="form-control form-control-lg"
                            id="example-tel-input"
                            name="phone2"
                            onChange={onChange}
                            value={credentials.phone2}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            for="example-email-input"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            id="example-email-input"
                            name="email"
                            onChange={onChange}
                            value={credentials.email}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            for="example-email-input"
                          >
                            Certified Email
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            id="example-email-input"
                            name="emailCertified"
                            onChange={onChange}
                            value={credentials.emailCertified}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label className="form-label" for="example-tel-input">
                            VAT Number
                          </label>
                          <input
                            type="tel"
                            className="form-control form-control-lg"
                            id="example-tel-input"
                            name="vatNumber"
                            onChange={onChange}
                            value={credentials.vatNumber}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            for="example-text-input"
                          >
                            Fiscal Code
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="example-text-input"
                            name="fiscalCode"
                            onChange={onChange}
                            value={credentials.fiscalCode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            for="example-text-input"
                          >
                            SWIFT/BIC Code
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="example-text-input"
                            name="bicCode"
                            onChange={onChange}
                            value={credentials.bicCode}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            for="example-text-input"
                          >
                            IBAN
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="example-text-input"
                            name="iban"
                            onChange={onChange}
                            value={credentials.iban}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            for="example-text-input"
                          >
                            Bank Name
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="example-text-input"
                            name="bankName"
                            onChange={onChange}
                            value={credentials.bankName}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            className="form-label"
                            for="example-text-input"
                          >
                            Country of the Bank
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="example-text-input"
                            name="bankCountry"
                            onChange={onChange}
                            value={credentials.bankCountry}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-md-4 ">
                          <label
                            className="form-label"
                            for="example-textarea-input"
                          >
                            Account Number
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="example-text-input"
                            name="accountNumber"
                            onChange={onChange}
                            value={credentials.accountNumber}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-md-4 ">
                          <label
                            className="form-label"
                            for="example-textarea-input"
                          >
                            Description / Notes
                          </label>
                          <textarea
                            className="form-control form-control-lg"
                            id="example-textarea-input"
                            name="description"
                            onChange={onChange}
                            rows="4"
                            value={credentials.description}
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="block-content block-content-full block-content-sm d-flex justify-content-end">
                        <button
                          type="submit"
                          className=" btn btn-lg btn-success py-2 px-4"
                        >
                          Add Supplier
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuppliersModal;
