import React, { useEffect, useState } from "react";
import TableGroup from "./TableGroup";
import $ from "jquery";
import "datatables.net-bs5";

function Main() {
  const [group, setGroup] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/group/getAll`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.status === "success") {
          setGroup(result.data);
        } else {
          console.error("Error:", result.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (group.length > 0) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const table = $("#groupDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
        sort: false,
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        table.destroy();
      };
    }
  }, [group]);
  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className=" fa fa-users me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">
                Groups/Distribution
              </h1>
            </div>
            <TableGroup group={group} />
          </div>
        </div>
      </main>
    </div>
  );
}

export default Main;
