import React from "react";

function AddressDetail({ formData, onChange }) {
  const handleCustomPhoneKeyChange = (e, customPhone) => {
    const newKey = e.target.textContent;
    const newCustomPhoneData = { ...formData[customPhone], key: newKey };
    onChange({ target: { name: customPhone, value: newCustomPhoneData } });
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(e.target);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleCustomPhoneChange = (e, customPhone) => {
    const { name, value } = e.target;
    const newCustomPhoneData = { ...formData[customPhone], [name]: value };
    onChange({ target: { name: customPhone, value: newCustomPhoneData } });
  };

  const defaultLabel = "Etichetta";

  const handleLabelClick = (e, customPhone) => {
    const currentText = e.target.textContent;
    if (currentText === defaultLabel) {
      e.target.textContent = "";
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <div className="mb-4 search ">
            <i className="fa fa-map-location-dot text-center pe-2 icon"></i>
            <input
              type="text"
              className="form-control form-control-alt rounded"
              name="residentialAddress"
              placeholder="Indirizzo (via, piazza, largo, etc.)*"
              style={{ paddingLeft: "35px" }}
              value={formData.residentialAddress}
              onChange={onChange}
              required
              data-step="2"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4 search ">
            <i className="si si-home text-center pe-2 icon"></i>
            <input
              type="text"
              className="form-control form-control-alt rounded"
              name="houseNumber"
              placeholder="Numero civico*"
              style={{ paddingLeft: "35px" }}
              value={formData.houseNumber}
              onChange={onChange}
              required
              data-step="2"
            />
          </div>
        </div>
        <div className="col-md-8">
          <div className="mb-4 search ">
            <i
              style={{ padding: "8px" }}
              className="fa  fa-city text-center pe-2 icon"
            ></i>
            <input
              type="text"
              className="form-control form-control-alt rounded"
              name="city"
              placeholder="Città*"
              style={{ paddingLeft: "35px" }}
              value={formData.city}
              onChange={onChange}
              required
              data-step="2"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4 search ">
            <i class="far  fa-map text-center pe-2 icon"></i>
            <select
              className=" form-control form-control-alt rounded form-select form-select-lg mb-4 py-3"
              aria-label=".form-select-lg example"
              style={{ paddingLeft: "60px" }}
              value={formData.province}
              onChange={onChange}
              name="province"
              required
              data-step="2"
            >
              <option disabled value="" selected>
              Seleziona Provincia*
              </option>
              <option value="AG">AG</option>
              <option value="AL">AL</option>
              <option value="AN">AN</option>
              <option value="AO">AO</option>
              <option value="AR">AR</option>
              <option value="AP">AP</option>
              <option value="AT">AT</option>
              <option value="AV">AV</option>
              <option value="BA">BA</option>
              <option value="BT">BT</option>
              <option value="BL">BL</option>
              <option value="BN">BN</option>
              <option value="BG">BG</option>
              <option value="BI">BI</option>
              <option value="BO">BO</option>
              <option value="BZ">BZ</option>
              <option value="BS">BS</option>
              <option value="BR">BR</option>
              <option value="CA">CA</option>
              <option value="CL">CL</option>
              <option value="CB">CB</option>
              <option value="CI">CI</option>
              <option value="CE">CE</option>
              <option value="CT">CT</option>
              <option value="CZ">CZ</option>
              <option value="CH">CH</option>
              <option value="CO">CO</option>
              <option value="CS">CS</option>
              <option value="CR">CR</option>
              <option value="KR">KR</option>
              <option value="CN">CN</option>
              <option value="EN">EN</option>
              <option value="FM">FM</option>
              <option value="FE">FE</option>
              <option value="FI">FI</option>
              <option value="FG">FG</option>
              <option value="FC">FC</option>
              <option value="FR">FR</option>
              <option value="GE">GE</option>
              <option value="GO">GO</option>
              <option value="GR">GR</option>
              <option value="IM">IM</option>
              <option value="IS">IS</option>
              <option value="SP">SP</option>
              <option value="AQ">AQ</option>
              <option value="LT">LT</option>
              <option value="LE">LE</option>
              <option value="LC">LC</option>
              <option value="LI">LI</option>
              <option value="LO">LO</option>
              <option value="LU">LU</option>
              <option value="MC">MC</option>
              <option value="MN">MN</option>
              <option value="MS">MS</option>
              <option value="MT">MT</option>
              <option value="ME">ME</option>
              <option value="MI">MI</option>
              <option value="MO">MO</option>
              <option value="MB">MB</option>
              <option value="NA">NA</option>
              <option value="NO">NO</option>
              <option value="NU">NU</option>
              <option value="OT">OT</option>
              <option value="OR">OR</option>
              <option value="PD">PD</option>
              <option value="PA">PA</option>
              <option value="PR">PR</option>
              <option value="PV">PV</option>
              <option value="PG">PG</option>
              <option value="PU">PU</option>
              <option value="PE">PE</option>
              <option value="PC">PC</option>
              <option value="PI">PI</option>
              <option value="PT">PT</option>
              <option value="PN">PN</option>
              <option value="PZ">PZ</option>
              <option value="PO">PO</option>
              <option value="RG">RG</option>
              <option value="RA">RA</option>
              <option value="RC">RC</option>
              <option value="RE">RE</option>
              <option value="RI">RI</option>
              <option value="RN">RN</option>
              <option value="RM">RM</option>
              <option value="RO">RO</option>
              <option value="SA">SA</option>
              <option value="VS">VS</option>
              <option value="SS">SS</option>
              <option value="SV">SV</option>
              <option value="SI">SI</option>
              <option value="SR">SR</option>
              <option value="SO">SO</option>
              <option value="TA">TA</option>
              <option value="TE">TE</option>
              <option value="TR">TR</option>
              <option value="TO">TO</option>
              <option value="OG">OG</option>
              <option value="TP">TP</option>
              <option value="TN">TN</option>
              <option value="TV">TV</option>
              <option value="TS">TS</option>
              <option value="UD">UD</option>
              <option value="VA">VA</option>
              <option value="VE">VE</option>
              <option value="VB">VB</option>
              <option value="VC">VC</option>
              <option value="VR">VR</option>
              <option value="VV">VV</option>
              <option value="VI">VI</option>
              <option value="VT">VT</option>
            </select>
          </div>
        </div>

        <div className="col-md-6">
          <div className="input-group mb-4" style={{ borderRadius: "0.5rem" }}>
            <span
              className="input-group-text bg-alt-primary"
              style={{
                borderTopLeftRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                color: "white",
              }}
            >
              Personale
            </span>
            <input
              type="number"
              className="form-control form-control-alt py-3"
              name="phonePersonal"
              placeholder="Numero di telefono*"
              value={formData.phonePersonal}
              onChange={onChange}
              data-step="2"
              style={{
                borderTopRightRadius: "0.5rem",
                borderBottomRightRadius: "0.5rem",
              }}
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-group mb-4" style={{ borderRadius: "0.5rem" }}>
            <span
              className="input-group-text bg-alt-primary"
              style={{
                borderTopLeftRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                color: "white",
              }}
            >
              Padre
            </span>
            <input
              type="number"
              className="form-control form-control-alt py-3"
              name="phoneFather"
              placeholder="Numero di telefono"
              style={{
                borderTopRightRadius: "0.5rem",
                borderBottomRightRadius: "0.5rem",
              }}
              value={formData.phoneFather}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-group mb-4" style={{ borderRadius: "0.5rem" }}>
            <span
              className="input-group-text bg-alt-primary"
              style={{
                borderTopLeftRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                color: "white",
              }}
            >
              Madre
            </span>
            <input
              type="number"
              className="form-control form-control-alt py-3"
              name="phoneMother"
              placeholder="Numero di telefono"
              style={{
                borderTopRightRadius: "0.5rem",
                borderBottomRightRadius: "0.5rem",
              }}
              value={formData.phoneMother}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-group mb-4" style={{ borderRadius: "0.5rem" }}>
            <span
              className="input-group-text bg-alt-primary"
              style={{
                borderTopLeftRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                color: "white",
              }}
              contentEditable={true}
              onClick={(e) => handleLabelClick(e, "phoneCustom1")}
              onInput={(e) => handleCustomPhoneKeyChange(e, "phoneCustom1")}
              name="key"
            >
              {formData.phoneCustom1.key
                ? formData.phoneCustom1.key
                : defaultLabel}
            </span>
            <input
              type="number"
              className="form-control form-control-alt py-3"
              name="value"
              placeholder="Numero di telefono"
              value={formData.phoneCustom1?.value || ""}
              onChange={(e) => handleCustomPhoneChange(e, "phoneCustom1")}
              style={{
                borderTopRightRadius: "0.5rem",
                borderBottomRightRadius: "0.5rem",
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-group mb-4" style={{ borderRadius: "0.5rem" }}>
            <span
              className="input-group-text bg-alt-primary"
              style={{
                borderTopLeftRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                color: "white",
              }}
              contentEditable={true}
              onClick={(e) => handleLabelClick(e, "phoneCustom2")}
              onInput={(e) => handleCustomPhoneKeyChange(e, "phoneCustom2")}
              name="key"
            >
              {formData.phoneCustom2.key
                ? formData.phoneCustom2.key
                : defaultLabel}
            </span>
            <input
              type="number"
              className="form-control form-control-alt py-3"
              name="value"
              placeholder="Numero di telefono"
              value={formData.phoneCustom2?.value || ""}
              onChange={(e) => handleCustomPhoneChange(e, "phoneCustom2")}
              style={{
                borderTopRightRadius: "0.5rem",
                borderBottomRightRadius: "0.5rem",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressDetail;
