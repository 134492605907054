import React, { useEffect, useState } from "react";
import Table from "./Table";
import $ from "jquery";
import { useSelector } from "react-redux";
import moment from "moment";

function Main() {
  const [wallet, setWallet] = useState([]);
  const userinfo = useSelector((state) => state.userDetails.user);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/walletHistory/getByUserId?userId=${userinfo.id}`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        const response = await fetch(url, options);
        const { data } = await response.json();

        console.log(data)

        // Sort the data by `dateOfMovement` from latest to earliest
        const walletHistoryData = data.walletHistoryData.sort((a, b) => {
          const dateA = moment(a.paymentDate);
          const dateB = moment(b.paymentDate);
          return dateB - dateA; // Sort descending (latest first)
        });

        setWallet({
          ...data,
          walletHistoryData: walletHistoryData
        });
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [userinfo]);

  useEffect(() => {
    if (wallet?.walletHistoryData?.length > 0) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const table = $("#walletDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
        sort: false
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        table.destroy();
      };
    }
  }, [wallet]);

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <Table wallet={wallet} />
        </div>
      </main>
    </div>
  );
}

export default Main;
