import React, { useEffect, useState } from "react";
import Left from "./Left";
import RightsideTable from "./RightsideTable";
import { useParams } from "react-router-dom";
import $ from "jquery";

function Main() {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const url = `${process.env.REACT_APP_API_URL}/admin/moneyMovement/getByBalanceSheetId?balanceSheetId=${id}`;
      const options = { method: "GET", headers: { "Content-Type": "" } };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.status === "success") {
          setData(data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [id]);

  useEffect(() => {
    if (data) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const table = $("#balanceDetailsDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
        sort: false,
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        table.destroy();
      };
    }
  }, [data]);

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <h1 className="block-title fs-4 fw-bolder">
                Balance Sheet Details
              </h1>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Left data={data} />
              </div>
              <div className="col-md-8">
                <RightsideTable data={data} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Main;
