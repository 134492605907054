import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import DOMPurify from "dompurify";

function EventSummary() {
  const location = useLocation();
  const formData = location?.state?.formData;
  const navigate = useNavigate();
  const [eventElements, setEventElements] = useState([]);
  const [fatherEventDetails, setFatherEventDetails] = useState(null);

  const handleClick = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/event/addRecord`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Update Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/events");
      } else {
        console.log(data)
        throw new Error(data.missedInputs || "Failed to save!");
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to save!",
        text: error.message || "An error occurred while saving.",
      });
    }
  };

  // const [balance, setBalance] = useState({});
  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }

    return age;
  };

  const convertDateFormat = (dateStr) => {
    if (!dateStr) return "";
    const dateObj = new Date(dateStr);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const minAge = calculateAge(formData?.subscriberMinAge);
  const maxAge = calculateAge(formData?.subscriberMaxAge);

  const safeHTML = DOMPurify.sanitize(formData?.description);

  // useEffect(() => {
  //   const fetchBalanceDataById = async () => {
  //     const url = `${process.env.REACT_APP_API_URL}/admin/balanceSheet/getById/${formData?.balanceSelection}`;
  //     const options = { method: "GET" };

  //     try {
  //       const response = await fetch(url, options);
  //       const data = await response.json();
  //       if (data.status === "success") {
  //         // setBalance(data.data);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchBalanceDataById();
  // }, [formData]);

  function getBadgeClass(state) {
    switch (state) {
      case "Waiting Subscription":
        return "bg-warning";
      case "Subscription Opened":
        return "bg-success";
      case "Event Started":
        return "bg-primary";
      default:
        return "bg-danger";
    }
  }

  useEffect(() => {
    const fetchEvents = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/event/getAll`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        const eventsArray = result.data || [];
        if (!Array.isArray(eventsArray)) {
          throw new Error("Events data is not an array");
        }
        const incompatibleEventIds = formData?.incompatibleEvents || [];
        const filteredEvents = eventsArray.filter((event) =>
          incompatibleEventIds.includes(event.id)
        );
        const elements = filteredEvents.map((event, index) => (
          <tr key={index}>
            <td>{event.eventName}</td>
            <td>{convertDateFormat(event.startDate)}</td>
            <td>{convertDateFormat(event.endDate)}</td>
          </tr>
        ));

        setEventElements(elements);

        const fatherEventId = formData?.fatherEvent;
        if (fatherEventId) {
          const fatherEvent = eventsArray.find(
            (event) => event.id === fatherEventId
          );
          setFatherEventDetails(fatherEvent);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    if (formData) {
      fetchEvents();
    }
  }, [formData]);

  const handleBack = () => {
    navigate("/event/create", { state: { newFormData: formData } });
  };

  return (
    <main id="main-container">
      <div className="content">
        <div className="block block-rounded">
          <div className="block-header block-header-default">
            <i className="fa fa-calendar me-3 text-warning"></i>
            <h1 className="block-title fs-4 fw-bolder">Event Summary</h1>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="p-5">
                <h1 className="block-title fs-1 text-center fw-bolder">
                  {formData?.eventName}
                </h1>
                <div className="row mt-4">
                  {formData?.eventImages.map((imageSrc, index) => (
                    <div key={index} className="col-md-6 text-center">
                      <img
                        className="rounded"
                        src={imageSrc}
                        width={"60%"}
                        height={"250px"}
                        alt={`Event ${index}`}
                      />
                    </div>
                  ))}
                </div>
                <p className="text-md-center my-5">
                  <div dangerouslySetInnerHTML={{ __html: safeHTML }} />
                </p>

                <div className="row mt-4">
                  <div className="col-md-6">
                    <h1 className="block-title fs-4 fw-bolder text-start">
                      Event Details
                    </h1>
                    <span
                      className={`fw-medium mt-5 badge rounded fs-6 ${
                        formData?.tag.value === "yellow" ? "text-black" : ""
                      }`}
                      style={{ backgroundColor: formData?.tag.value }}
                    >
                      {formData?.tag.key}
                    </span>

                    <div className="mt-5">
                      <h5 className="fw-medium">Event Duration:</h5>
                      <h5 className="fw-medium">
                        {convertDateFormat(formData?.startDate)} to{" "}
                        {convertDateFormat(formData?.endDate)}
                      </h5>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium"> Event Time:</h5>
                      <h5 className="fw-medium">
                        {formData?.startHour} to {formData?.endHour}
                      </h5>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">
                        Event Location : {formData?.eventLocation}
                      </h5>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">Father Event :</h5>
                      {fatherEventDetails ? (
                        <div>
                          <p>{fatherEventDetails.eventName}</p>
                          <p>
                            {convertDateFormat(fatherEventDetails.startDate)} to{" "}
                            {convertDateFormat(fatherEventDetails.endDate)}
                          </p>
                        </div>
                      ) : (
                        <p>No Father Event Found</p>
                      )}
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">Incompatible Events:</h5>
                      <table
                        id="eventDataTable"
                        className="table table-bordered table-striped table-vcenter table-responsive"
                      >
                        <thead>
                          <tr>
                            <th>EVENT NAME</th>
                            <th>START DATE</th>
                            <th>END DATE</th>
                          </tr>
                        </thead>
                        <tbody>{eventElements}</tbody>
                      </table>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">Recurring Event:</h5>
                      <div className="space-y-2 my-3">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={formData?.recurringEvent}
                            disabled
                          />
                        </div>
                      </div>
                      {formData?.recurringEvent && (
                        <div>
                          {formData?.recurringDays.map((day, index) => (
                            <div key={index} className="space-y-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={true}
                                  id={`example-checkbox-default${index}`}
                                />
                                <label
                                  className="form-check-label ms-3"
                                  htmlFor={`example-checkbox-default${index}`}
                                >
                                  {day}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {/* <div className="mt-5">
                      <h5 className="fw-medium">
                        Balance Name: {balance?.balanceName}
                      </h5>
                      <h5 className="fw-medium">
                        Balance Description: {balance?.description}
                      </h5>
                    </div> */}
                  </div>
                  <div className="col-md-6">
                    <h1 className="block-title fs-4 fw-bolder text-start">
                      Subscription Details
                    </h1>
                    <div className="mt-5">
                      <h5 className="fw-medium">
                        Subscription State :{" "}
                        <div
                          className={`badge ${getBadgeClass(
                            formData?.subscriptionState
                          )}`}
                        >
                          {formData?.subscriptionState}
                        </div>
                      </h5>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">Subscription Duration:</h5>
                      <h5 className="fw-medium">
                        {convertDateFormat(formData?.subscriptionStartDate)} to{" "}
                        {convertDateFormat(formData?.subscriptionEndDate)}
                      </h5>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">Age Of Subscriber :</h5>
                      <h5 className="fw-medium">
                        From {maxAge || 0} years to {minAge || 150} years old
                      </h5>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">
                        Event Subscription Parameter :{" "}
                        {formData?.subscriptionParameter1}
                      </h5>
                      <h5 className="fw-medium">
                        Event Subscription Parameter :{" "}
                        {formData?.subscriptionParameter2}
                      </h5>
                    </div>

                    <div className="mt-5">
                      <h5 className="fw-medium">Allow User to Unsubscribe :</h5>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={formData?.allowUnsubscription}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">Multiple Subscription</h5>
                      <div className="space-y-2 mt-3">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={formData?.mutipleSubscription}
                            disabled
                          />
                        </div>
                      </div>
                      {formData?.mutipleSubscription && (
                        <div className="mt-4">
                          <h5 className="fw-medium">
                            Max number of seats reserved: {formData?.maxSubscription}
                          </h5>
                        </div>
                      )}
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">Apply Personal Discount : </h5>
                      <div className="space-y-2 mt-3">
                        <div className="space-y-2 mt-3">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={formData?.applyPersonalDiscount}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <h5 className="fw-medium">
                        Maximum Subscriber : {formData.maxSubscribers}
                      </h5>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mt-5">
                      <h5 className="fw-medium">Personalized Role</h5>
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>NAME</th>
                            <th>DESCRIPTION</th>
                            <th>PRICE</th>
                            <th>USER POLICY</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData?.personalizedRoles.length > 0 ? (
                            formData?.personalizedRoles.map((role, index) => (
                              <tr key={index}>
                                <td>{role.name}</td>
                                <td>{role.description}</td>
                                <td>{role.price},00 €</td>
                                <td>{role.userPolicy}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5" className="text-center">
                                No data entered
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="my-6">
                  <h5 className="fw-medium">Documents </h5>
                  <div className="d-flex justify-content-center bg-body text-center rounded mt-2">
                    {formData.eventFiles &&
                      formData.eventFiles.map((imageSrc, index) => (
                        <a
                          key={index}
                          target="_blank"
                          without
                          rel="noreferrer"
                          href={imageSrc}
                          download={`optional-value-${index}`}
                        >
                          <img
                            className="rounded m-2"
                            src="/assets/media/pdf.png"
                            width={"110"}
                            height={"auto"}
                            alt={`Event ${index}`}
                          />
                        </a>
                      ))}
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <button
                    onClick={handleBack}
                    className="btn btn-lg btn-secondary px-4"
                  >
                    BACK
                  </button>
                  <button
                    onClick={handleClick}
                    className="btn btn-lg btn-alt-primary px-4"
                  >
                    CREATE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default EventSummary;
