import React from 'react'
import Main from '../components/UserInfo/Main'
import Navbar from "../components/Common/Navbar";
import Header from "../components/Common/Header";
function UserInfo() {
  return (
    <div
      id="page-container"
      className="sidebar-o enable-page-overlay side-scroll page-header-modern main-content-boxed"
    >
      <Navbar />
      <Header />
      <Main/>
    </div>
  )
}

export default UserInfo
