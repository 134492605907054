import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function EventShowCard({ event }) {
  const userinfo = useSelector((state) => state.userDetails.user);
  const [eventCompatible, setEventCompatible] = useState(false);
  const [subscribedEvents, setSubscribedEvents] = useState([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/eventSubscription/getAllSubscriptions?userId=${userinfo.id}`
        );
        const result = await response.json();
        if (result.status === "success") {
          setSubscribedEvents(result.data.subscriptionData);
        } else {
          console.error("Error:", result.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchSubscriptions();
  }, [userinfo]);

  useEffect(() => {
    let disable = false;
    const currentDate = new Date();

    if (
      userinfo.age &&
      (userinfo.age < calculateAge(event?.subscriberMaxAge) ||
        userinfo.age > calculateAge(event?.subscriberMinAge))
    ) {
      disable = true;
    }

    if (
      event?.incompatibleEvents.length > 0 &&
      !checkIncompatibilityOfEvents(event?.incompatibleEvents, subscribedEvents)
    ) {
      disable = true;
    }

    if (
      event?.fatherEvent &&
      !checkFatherEvent(event?.fatherEvent?.id, subscribedEvents)
    ) {
      disable = true;
    }

    setEventCompatible(disable);

    if (event?.startDate && new Date(event.startDate) <= currentDate) {
      if (event.subscriptionState !== "Event Started") {
        event.subscriptionState = "Event Started";
        updateSubscriptionState(event.id, "Event Started");
      }
    }

    // Compare Event End Date with Current Date (end of the event date)
    const endDate = new Date(event?.endDate);
    endDate.setDate(endDate.getDate() + 1); // Add one day to include the end date fully
    if (event?.endDate && currentDate >= endDate) {
      if (event.subscriptionState !== "Event Ended") {
        event.subscriptionState = "Event Ended";
        updateSubscriptionState(event.id, "Event Ended");
      }
    }
  }, [event, userinfo, subscribedEvents]);

  const updateSubscriptionState = async (id, state) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/event/updateRecord`,
        {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({ id, subscriptionState: state }),
        }
      );
      const data = await response.json();
      console.error(data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const checkIncompatibilityOfEvents = (idsArray, objectsArray) => {
    return !idsArray.some((id) =>
      objectsArray.some((obj) => id === obj.eventId?.id)
    );
  };

  const checkFatherEvent = (fatherId, objectsArray) => {
    return objectsArray.some((obj) => fatherId === obj.eventId?.id);
  };

  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    const dayDiff = today.getDate() - birth.getDate();
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    return age;
  };

  function getBadgeClass(state) {
    switch (state) {
      case "Waiting Subscription":
        return "bg-warning";
      case "Subscription Opened":
        return "bg-success";
      case "Event Started":
        return "bg-primary";
      default:
        return "bg-danger";
    }
  }

  return (
    <div className="col-md-4" key={event.id}>
      <div className="content">
        <div className="block block-rounded">
          <div className="block-header block-header-default">
            <h1 className="block-title fs-4 fw-bolder text-center">
              {event.eventName}
            </h1>
            {event?.subscribed && (
              <span className="badge bg-danger rounded">I</span>
            )}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="options-container position-relative">
                <img
                  height={300}
                  width={"100%"}
                  className={`options-item ${
                    eventCompatible ? "grayscale-image" : ""
                  }`}
                  src={event.eventImages[0] || "assets/media/photos/photo1.jpg"}
                  alt={event.eventName}
                />
                <div className="d-flex justify-content-between block-header block-header-default border-last">
                  <span>{new Date(event.startDate).toLocaleDateString()}</span>
                  <span
                    className={`badge rounded fs-6 ${
                      event?.tag?.value === "yellow" ? "text-black" : ""
                    }`}
                    style={{ backgroundColor: event?.tag?.value }}
                  >
                    {event?.tag?.key}
                  </span>
                  <Link
                    to={`/event/${event.id}`}
                    className="btn btn-lg btn-alt-primary px-4"
                  >
                    View
                  </Link>
                </div>
                <div
                  className={`position-absolute text-white bottom-0 end-0 mb-7 me-3 p-1 px-2 rounded ${getBadgeClass(
                    event?.subscriptionState
                  )}`}
                >
                  {event.subscriptionState}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventShowCard;
