import React from "react";

function InfoCards({ metaData }) {
  return (
    <div className="mx-auto">
      <div className="row">
        {/* <div className="col-md-6 col-xl-4">
          <div className="block block-rounded">
            <div className="block-content block-content-full bg-gd-cherry">
              <div className="fs-lg fw-bold text-white mb-2">Balance</div>
              <p className="fs-lg fw-semibold text-white mb-0 d-flex justify-content-between">
                <div>Entries:</div>
                <div>{metaData?.totalEntries}</div>
              </p>
              <p className="fs-lg fw-semibold text-white mb-0 d-flex justify-content-between">
                <div>Exits:</div>
                <div>{metaData?.totalExits}</div>
              </p>
              <p className="fs-lg fw-semibold text-white mb-0 d-flex justify-content-between">
                <div>Balance:</div>
                <div>{metaData?.collectiveBalance},00 €</div>
              </p>
            </div>
          </div>
        </div> */}
        <div className="col-md-6">
          <div className="block block-rounded">
            <div className="block-content block-content-full bg-gd-sea">
              <div className="fs-lg fw-bold text-white mb-2">
                Navigating The System
              </div>
              <p className="fs-lg fw-semibold text-white mb-0 d-flex justify-content-between">
                <div>Users:</div>
                <div>{metaData?.totalUsers}</div>
              </p>
              <p className="fs-lg fw-semibold text-white mb-0 d-flex justify-content-between">
                <div>Events:</div>
                <div>{metaData?.totalEvents}</div>
              </p>
              <p className="fs-lg fw-semibold text-white mb-0 d-flex justify-content-between">
                <div>Subscriptions:</div>
                <div>{metaData?.totalSubscriptions}</div>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="block block-rounded">
            <div className="block-content block-content-full bg-gd-leaf">
              <div className="fs-lg fw-bold text-white mb-2">
                Daily Activity
              </div>
              <p className="fs-lg fw-semibold text-white mb-0 d-flex justify-content-between">
                <div>Today's Entries:</div>
                <div>{metaData?.entriesToday}</div>
              </p>
              <p className="fs-lg fw-semibold text-white  d-flex justify-content-between">
                <div>Today's Exits:</div>
                <div>{metaData?.exitsToday}</div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCards;
