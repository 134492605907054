import React, { useState } from "react";
import GroupModal from "./GroupModal";
import CheckinglistModal from "./CheckinglistModal";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { toast } from "react-toastify";
import UpdateFlag from "./UpdateFlag";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

function UsersTable({
  groupMemberData,
  groupMemberData2,
  id,
  allUsers,
  groupData,
}) {
  const [selectedUserId, setSelectedUserId] = useState(null);

  // Split the data based on progressiveFlag
  const upperTableData = groupMemberData.filter(
    (member) => member.progressiveFlag === 0 || member.progressiveFlag === 1
  );
  const lowerTableData = groupMemberData2.filter(
    (member) => member.progressiveFlag === 2
  );

  const handleSelectUser = (userId) => {
    setSelectedUserId(userId);
  };

  const handleDelete = async (recordId) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/groupMember/deleteById`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ recordId }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Member Deleted Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Failed to delete record:", error);
    }
  };

  async function handleCheck() {
    const url = `${process.env.REACT_APP_API_URL}/admin/group/checkList`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ groupId: id }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.message === "error") {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleExport = (event) => {
    const exportType = event.target.value;
    if (exportType === "excel") {
      exportToExcel();
    } else if (exportType === "pdf") {
      exportToPdf();
    }
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheetData = [
      [`Group: ${groupData?.data?.groupName}`],
      [
        "Sr No",
        "FIRST NAME",
        "LAST NAME",
        "PRESENCE CHECK",
        "AGE",
        "DATE OF BIRTH",
        "ROLE",
      ],
      ...groupMemberData.map((member, index) => [
        index + 1,
        member?.userId?.firstName,
        member?.userId?.lastName,
        member?.progressiveFlag === 0
          ? "?"
          : member?.progressiveFlag === 1
          ? "✓"
          : "✗",
        member?.userId?.age,
        moment(member?.userId?.birthday).format("DD/MM/YYYY"),
        member?.role,
      ]),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Group Members");
    XLSX.writeFile(workbook, "Group_Members.xlsx");
  };

  const exportToPdf = () => {
    const doc = new jsPDF();
    const groupName = `Group: ${groupData?.data?.groupName}`;
    doc.text(groupName, 14, 15);
    const tableColumn = [
      "Sr No",
      "FIRST NAME",
      "LAST NAME",
      "PRESENCE CHECK",
      "AGE",
      "DATE OF BIRTH",
      "ROLE",
    ];
    const tableRows = [];

    groupMemberData.forEach((member, index) => {
      const memberData = [
        index + 1,
        member?.userId?.firstName,
        member?.userId?.lastName,
        member?.progressiveFlag === 0
          ? "?"
          : member?.progressiveFlag === 1
          ? "Yes"
          : "X",
        member?.userId?.age,
        moment(member?.userId?.birthday).format("DD/MM/YYYY"),
        member?.role,
      ];
      tableRows.push(memberData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 35,
      theme: "grid",
    });

    doc.save("Group_Members.pdf");
  };

  const renderTableHeaders = (includeRole = true) => (
    <thead>
      <tr>
        <th>FIRST NAME</th>
        <th>LAST NAME</th>
        <th>PRESENCE CHECK</th>
        <th>AGE</th>
        <th>DATE OF BIRTH</th>
        {includeRole && <th>ROLE</th>}
        <th>ACTION</th>
      </tr>
    </thead>
  );

  const renderTableRows = (data, includeRole = true) => {
    return data.map((member, index) => (
      <tr key={index} className="fs-sm">
        <td>{member?.userId?.firstName}</td>
        <td>{member?.userId?.lastName}</td>
        <td>
          {member?.progressiveFlag === 0 ? (
            <i className="fa fa-2x fa-circle-question text-info"></i>
          ) : member?.progressiveFlag === 1 ? (
            <i className="fa fa-2x fa-circle-check text-success"></i>
          ) : (
            <i className="si fa-2x si-close text-danger"></i>
          )}
        </td>
        <td>{member?.userId?.age}</td>
        <td>{moment(member?.userId?.birthday).format("DD/MM/YYYY")}</td>
        {includeRole && <td>{member?.role}</td>}
        <td className="d-sm-table-cell d-flex">
          <div className="d-flex gap-1">
            <Link
              to={`/edituser/${member?.userId?.id}`}
              className="btn btn-alt-primary btn-sm rounded-0"
            >
              Details
            </Link>
            {member?.progressiveFlag === 0 || member?.progressiveFlag === 1 ? (
              <>
                <button
                  onClick={() => handleSelectUser(member?.userId?.id)}
                  className="btn btn-alt-primary btn-sm rounded-0"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-large34"
                >
                  <i className="si si-directions"></i>
                </button>
                <button
                  onClick={() => handleDelete(member?.id)}
                  className="btn btn-alt-primary btn-sm rounded-0"
                >
                  <i className="fa fa-minus"></i>
                </button>
              </>
            ) : (
              <button
                data-bs-toggle="modal"
                data-bs-target="#modal-updateRole"
                className="btn btn-alt-primary btn-sm rounded-0"
                onClick={() => handleSelectUser(member?.id)}
              >
                <i className="fa fa-plus"></i>
              </button>
            )}
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div className="content ps-md-0">
      <div className="block block-rounded block-fx-shadow">
        <div className="block block-rounded overflow-hidden">
          <div className="block-content tab-content">
            <div
              className="tab-pane active"
              id="btabs-static-home"
              role="tabpanel"
              aria-labelledby="btabs-static-home-tab"
            >
              <div className="mt-4 d-flex justify-content-end">
                <select
                  className="btn btn-lg btn-alt-primary "
                  onChange={handleExport}
                >
                  <option value="" selected disabled>
                    Export As
                  </option>
                  <option value="excel">Excel File</option>
                  <option value="pdf">PDF File</option>
                </select>
              </div>
              <div style={{ overflowX: "auto" }}>
                <table
                  id="groupMembersDataTable"
                  className="js-table-checkable table table-hover table-vcenter table-responsive"
                >
                  {renderTableHeaders()}
                  <tbody>{renderTableRows(upperTableData)}</tbody>
                </table>
              </div>

              <div className="col-md-12 col-12 mt-3">
                <div className="mb-5 d-flex flex-column flex-md-row align-items-start">
                  <div className="col-12 col-md-auto mb-3 mb-md-0">
                    <Link
                      to={`/group-list-level/${id}`}
                      className="btn btn-alt-primary btn-lg px-4 w-100"
                    >
                      Edit Members
                    </Link>
                  </div>
                  <div className="col-12 col-md-auto ms-md-3">
                    <button
                      className="btn btn-lg btn-primary px-4 w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#modal-large35"
                    >
                      Checking List
                    </button>
                    <CheckinglistModal
                      allUsers={allUsers}
                      groupMemberData={groupMemberData}
                    />
                  </div>

                  <div className="col-12 col-md-auto ms-md-3">
                    <button
                      className="btn btn-lg btn-primary px-4 w-100"
                      onClick={() => {
                        handleCheck();
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000)
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ overflowX: "auto" }}>
              <table
                id="groupMembersDataTable2"
                className="js-table-checkable table table-hover table-vcenter table-responsive"
              >
                {renderTableHeaders(false)}
                <tbody>{renderTableRows(lowerTableData, false)}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <UpdateFlag selectedUserId={selectedUserId} />
      <GroupModal selectedUserId={selectedUserId} previousGroupId={id} />
    </div>
  );
}

export default UsersTable;
