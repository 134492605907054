import React, { useEffect, useState } from "react";
import GroupLeft from "./GroupLeft";
import UsersTable from "./UsersTable";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import "datatables.net-bs5";
import Loader from "../Common/Loader";

function Main() {
  const { id } = useParams();
  const [groupData, setGroupData] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [groupMemberData, setGroupMemberData] = useState([]);
  const [groupMemberData2, setGroupMemberData2] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userResponse, groupResponse, memberResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/admin/user/getAll`),
          fetch(`${process.env.REACT_APP_API_URL}/admin/group/getById/${id}`),
          fetch(`${process.env.REACT_APP_API_URL}/admin/group/getMembersData?groupId=${id}`)
        ]);

        const users = await userResponse.json();
        const group = await groupResponse.json();
        const members = await memberResponse.json();

        if (users.data) setAllUsers(users.data);
        if (group.status === "success") {
          setGroupData(group);
        } else {
          toast.error(group.message);
        }

        if (members.status === "success") {
          setGroupMemberData(members.data);
          setGroupMemberData2(members.data);
        } else {
          console.error("Error:", members.message);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (groupMemberData.length > 0) {
      initializeDataTable("#groupMembersDataTable", groupMemberData);
    }
    return () => {
      destroyDataTable("#groupMembersDataTable");
    };
  }, [groupMemberData]);

  useEffect(() => {
    if (groupMemberData2.length > 0) {
      initializeDataTable("#groupMembersDataTable2", groupMemberData2);
    }
    return () => {
      destroyDataTable("#groupMembersDataTable2");
    };
  }, [groupMemberData2]);

  const initializeDataTable = (selector) => {
    $.extend(true, $.fn.dataTable.defaults, {
      language: {
        lengthMenu: "_MENU_",
        search: "_INPUT_",
        searchPlaceholder: "Search..",
        info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
        paginate: {
          first: '<i class="fa fa-angle-double-left"></i>',
          previous: '<i class="fa fa-angle-left"></i>',
          next: '<i class="fa fa-angle-right"></i>',
          last: '<i class="fa fa-angle-double-right"></i>',
        },
      },
    });

    $(selector).DataTable({
      autoWidth: false,
      responsive: false,
      retrieve: true,
    });
  };

  const destroyDataTable = (selector) => {
    if ($.fn.DataTable.isDataTable(selector)) {
      $(selector).DataTable().destroy();
    }
  };

  if (loading) return <Loader />;

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <h1 className="block-title fs-4 fw-bolder">Group List</h1>
            </div>
            <div className="row">
              <div className="col-md-4">
                <GroupLeft groupData={groupData} />
              </div>
              <div className="col-md-8">
                <UsersTable
                  groupData={groupData}
                  allUsers={allUsers}
                  groupMemberData={groupMemberData}
                  groupMemberData2={groupMemberData2}
                  id={id}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Main;
