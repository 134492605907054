import React from "react";
import EventsCard from "./EventsCard";

function Main() {
  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className=" fa fa-calendar me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">Events</h1>
            </div>
            <div className="row">
              <div className="col-md-12">
                <EventsCard />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Main;
