import React, { useState } from "react";
import { toast } from "react-toastify";

function CloningModal({ eventId, eventName }) {
  const [newEventName, setNewEventName] = useState("");

  const handleInputChange = (e) => {
    setNewEventName(e.target.value);
  };

  const handleConfirm = async () => {
    if (!newEventName) {
      toast.error("Please Enter a new event name.");
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/user/event/cloneRecord`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: eventId, eventName: newEventName }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Event cloned successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(data.message || "Failed to clone event.");
      }
    } catch (error) {
      toast.error("Error cloning event: " + error.message);
    }
  };

  return (
    <div
      className="modal"
      id="modal-large42"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Cloning</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="my-7">
              <div className="container">
                <div className="container-fluid">
                  <div className="row">
                    <div className="d-flex justify-content-center">
                      <div className="col-md-6 col-12">
                        <div className="mb-4">
                          <label className="form-label" htmlFor="new-event-name">
                          L’evento {eventName} verrà clonato con il nuovo nome di:
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="new-event-name"
                            name="new-event-name"
                            value={newEventName}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="text-end mt-md-6 mt-5">
                          <button
                            type="button"
                            className="btn py-2 btn-success px-4"
                            onClick={handleConfirm}
                          >
                            CONFIRM
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CloningModal;
