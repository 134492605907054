import React from "react";
import SettingForm from "./SettingForm";

function Main() {
  return (
    <div>
      <main id="main-container">
        <div className="content">
          <SettingForm />
        </div>
      </main>
    </div>
  );
}

export default Main;
