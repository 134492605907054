import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function NotificationDropdown() {
  const userinfo = useSelector((state) => state?.userDetails?.user);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch notifications when component mounts
  useEffect(() => {
    const fetchNotifications = async () => {
      if (!userinfo?.id) return;
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/admin/notifications/unread/${userinfo.id}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        const data = await response.json();

        console.log(data.data)

        setNotifications(data.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
      setLoading(false);
    };

    fetchNotifications();
  }, [userinfo]);

  // Function to mark notification as read and navigate to chat
  const handleNotificationClick = async (notification) => {
    try {
      // Mark the notification as read
      await fetch(
        `${process.env.REACT_APP_API_URL}/admin/notifications/markAsRead`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ notificationIds: [notification._id] }),
        }
      );

      const newNotfications = notifications.filter(
        (n) => n._id !== notification._id
      );

      setNotifications(newNotfications);

      navigate(`/chat?active=${notification.chatId._id}`);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  // Helper function to render the notification message
  const renderNotificationMessage = (notification) => {
    const { notificationType, chatName } = notification;

    if (notificationType === "user") {
      return `You have a message from ${chatName}`;
    } else if (notificationType === "group") {
      return `You have a group message in ${chatName}`;
    } else if (notificationType === "event") {
      return `You have an event message in ${chatName}`;
    } else {
      return "You have a new message";
    }
  };

  return (
    <div className="dropdown d-inline-block">
      <button
        type="button"
        className="btn btn-sm btn-alt-secondary position-relative"
        id="page-header-notifications"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="far fa-bell fs-4"></i>
        {/* Red dot for unread notifications */}
        {notifications.length > 0 && (
          <span
            className="position-absolute top-0 start-100 translate-middle bg-danger border border-light rounded-circle"
            style={{ width: "10px", height: "10px" }}
          ></span>
        )}
      </button>
      <div
        className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
        aria-labelledby="page-header-notifications"
        style={{ borderRadius: "10px" }}
      >
        <div className="px-2 py-3 bg-body-light rounded-top">
          <h5 className="h6 text-center mb-0">Notifications</h5>
        </div>
        <ul className="nav-items my-2 fs-sm">
          {loading ? (
            <li className="text-center">Loading...</li>
          ) : notifications.length === 0 ? (
            <li className="text-center">No notifications</li>
          ) : (
            notifications.map((notification) => (
              <li key={notification._id}>
                <span
                  className="text-dark d-flex py-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <div className="flex-shrink-0 me-2 ms-3">
                    <i className="fa fa-fw fa-comment text-primary"></i>
                  </div>
                  <div className="flex-grow-1 pe-2">
                    <p className="fw-medium mb-1">
                      {renderNotificationMessage(notification)}
                    </p>
                    <div className="text-muted">
                      {new Date(notification.createdAt).toLocaleString()}
                    </div>
                  </div>
                </span>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
}
