import React from "react";

function GroupLeft({ groupData }) {
  return (
    <>
      <div className="content pe-md-0">
        <div className="block block-rounded block-fx-shadow">
          <div className="block block-rounded overflow-hidden">
            <div className="block-content tab-content">
              <div className="mx-auto">
                <div className="">
                  <div className=" block-content-full mb-4">
                    <h1 className="block-title fs-4 fw-bolder">
                      {groupData?.data?.groupName}
                    </h1>

                    <div
                      className="d-flex flex-column"
                      style={{ fontSize: "12px" }}
                    >
                      <div className="mt-4">
                        <span
                          className={`rounded px-4 py-1 ${
                            (groupData?.data?.labelColor === "yellow") ? "text-black" : "text-white"
                          }`}
                          style={{
                            background: groupData?.data?.labelColor,
                          }}
                        >
                          {groupData?.data?.labelName}
                        </span>
                      </div>
                      <p className="mt-3 mt-md-4 fs-5 fw-normal">
                        {groupData?.data?.description}
                      </p>
                      <h5 className=" fw-medium  mb-0 d-flex justify-content-between">
                        <div>Maximum Capacity Of Group:</div>
                        <div>
                          {groupData?.data?.maxMembers === 1 ? "∞" : groupData?.data?.maxMembers}
                        </div>
                      </h5>
                      <h5 className="mt-4 fw-medium mb-0 d-flex justify-content-between">
                        <div>Number Of Members:</div>
                        <div>{groupData?.data?.membersCount}</div>
                      </h5>
                      <h5 className="mt-4">
                        Linked Event :{" "}
                        <span className="fw-normal">
                          {" "}
                          {groupData?.data?.linkedEvent?.eventName}
                        </span>
                      </h5>{" "}
                      <div>
                        <span
                          className={`rounded px-4 py-1 text-white`}
                          style={{
                            background:
                              groupData?.data?.linkedEvent?.tag?.value,
                          }}
                        >
                          {groupData?.data?.linkedEvent?.tag?.key}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GroupLeft;
