import React from "react";
import Events from "./Events";
import GeneralInfo from "./GeneralInfo";
import Groups from "./Groups";
import Wallet from "./Wallet";
import { useSelector } from "react-redux";

function GeneralSetting(props) {

  const userinfo = useSelector((state) => state.userDetails.user);

  return (
    <div className="content ps-md-0">
      <div className="block block-rounded block-fx-shadow">
        <div className="block block-rounded overflow-hidden">
          <ul className="nav nav-tabs nav-tabs-block" role="tablist">
            <li className="nav-item">
              <button
                className="nav-link active"
                id="btabs-static-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#btabs-static-home"
                role="tab"
                aria-controls="btabs-static-home"
                aria-selected="true"
              >
                <i className="fa  fa-user"></i>
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                id="btabs-static-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#btabs-static-profile"
                role="tab"
                aria-controls="btabs-static-profile"
                aria-selected="false"
              >
                <i className="far fa-calendar"></i>{" "}
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                id="btabs-static-groups-tab"
                data-bs-toggle="tab"
                data-bs-target="#btabs-static-groups"
                role="tab"
                aria-controls="btabs-static-groups"
                aria-selected="false"
              >
                <i className="fa fa-users"></i>{" "}
              </button>
            </li>

            {userinfo?.role && ["0", "1", "2"].includes(userinfo.role) ? (
              <li className="nav-item">
                <button
                  className="nav-link"
                  id="btabs-static-wallet-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#btabs-static-wallet"
                  role="tab"
                  aria-controls="btabs-static-wallet"
                  aria-selected="false"
                >
                  <i className="fa fa-wallet"></i>{" "}
                </button>
              </li>
            ) : (
              ""
            )}
          </ul>
          <div className="block-content tab-content">
            <div
              className="tab-pane active"
              id="btabs-static-home"
              role="tabpanel"
              aria-labelledby="btabs-static-home-tab"
            >
              <GeneralInfo userInfo={props.userDetails} />
            </div>
            <div
              className="tab-pane"
              id="btabs-static-profile"
              role="tabpanel"
              aria-labelledby="btabs-static-profile-tab"
            >
              <Events userData={props.userDetails} />
            </div>
            <div
              className="tab-pane"
              id="btabs-static-groups"
              role="tabpanel"
              aria-labelledby="btabs-static-groups-tab"
            >
              <Groups userData={props.userDetails} />
            </div>
            <div
              className="tab-pane"
              id="btabs-static-wallet"
              role="tabpanel"
              aria-labelledby="btabs-static-wallet-tab"
            >
              <Wallet userData={props.userDetails} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralSetting;
