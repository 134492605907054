import moment from "moment";
import React from "react";

function UserDetails({ metaData }) {
  return (
    <div className="mx-auto">
      <div className="row">
        <div className="col-md-6">
          <div className="block block-themed shadow-lg block-rounded mb-md-5">
            <ul className="nav-items my-2 fs-sm">
              <div className="pb-3">
                <ul className="nav-users text-black pt-3 mx-3">
                  <div className="fw-bold fs-lg">Events</div>
                  {metaData?.upcomingEvents?.map((event, index) => (
                    <li key={index} className="border-bottom mt-3">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex mb-3">
                          <div className="ms-2">
                            <div className="fw-semibold">
                              {event?.eventName}
                            </div>
                            <div className="fw-semibold fs-xs">
                              {moment(event?.startDate).format("DD-MM-YYYY")}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="fs-lg me-3 text-info fw-bold">{event?.subscriptions}</div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="block block-themed shadow-lg block-rounded mb-5">
            <ul className="nav-items my-2 fs-sm">
              <div className="pb-3">
                <ul className="nav-users text-black pt-3 mx-3">
                  <div className="fw-bold fs-lg">Users With Debt</div>
                  {metaData?.usersWithMostDebt?.map((user, index) => (
                    <li key={index} className="border-bottom mt-3">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex mb-3">
                          <img
                            className="img-avatar"
                            src={`${
                              user?.profilePic !== ""
                                ? user?.profilePic
                                : "assets/media/avatars/avatar3.jpg"
                            }`}
                            alt=""
                            style={{ width: "40px", height: "40px" }}
                          />
                          <div className="ms-2">
                            <div className="fw-semibold">
                              {user?.firstName + " " + user?.lastName}
                            </div>
                            <div className="fw-semibold fs-xs">
                              {user?.email}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="fs-lg me-3 text-danger fw-bold">
                            {user?.walletBalance},00 €
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className="triangle"></div>
    </div>
  );
}

export default UserDetails;
