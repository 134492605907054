import React, { useEffect, useState, useCallback } from "react";
import GroupLevel from "./GroupLevel";
import { useParams } from "react-router-dom";
import "datatables.net-bs5";
import Loader from "../Common/Loader";

function Main() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [groupData, setGroupData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    age: "",
    eventName: "",
    groupName: "",
    name: "",
  });
  const [filterApplied, setFilterApplied] = useState(false);
  const [reverseFilterApplied, setReverseFilterApplied] = useState(false);
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/admin/group/getNonMembersData?groupId=${id}`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.status === "success") {
          setGroupData(result.data);
          setFilteredData(result.data.nonMembersData);
        } else {
          console.error("Error:", result.message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const ageDiff = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const applyFilters = useCallback(() => {
    if (!groupData) return;

    let data = groupData.nonMembersData;

    if (filters.age) {
      const ages = filters.age.split(",").map((age) => parseInt(age.trim()));
      data = data.filter((user) => ages.includes(calculateAge(user.birthday)));
    }

    if (filters.eventName) {
      const eventNames = filters.eventName
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      data = data.filter((user) =>
        user.subscribedEvents.some(
          (event) =>
            event &&
            eventNames.some((name) =>
              event.eventName.toLowerCase().includes(name)
            )
        )
      );
    }

    if (filters.groupName) {
      const groupNames = filters.groupName
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      data = data.filter((user) =>
        user.groupsJoined.some(
          (group) =>
            group &&
            groupNames.some((name) =>
              group.groupName.toLowerCase().includes(name)
            )
        )
      );
    }

    if (filters.name) {
      const names = filters.name
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      data = data.filter(
        (user) =>
          names.some((name) => user.firstName.toLowerCase().includes(name)) ||
          names.some((name) => user.lastName.toLowerCase().includes(name))
      );
    }

    setFilteredData(data);
    setFilterApplied(true);
    setReverseFilterApplied(false);
    setRenderKey((prevKey) => prevKey + 1); // Update the key to force re-render
  }, [filters, groupData]);

  const applyReverseFilters = useCallback(() => {
    if (!groupData) return;

    let data = groupData.nonMembersData;

    if (filters.age) {
      const ages = filters.age.split(",").map((age) => parseInt(age.trim()));
      data = data.filter((user) => !ages.includes(calculateAge(user.birthday)));
    }

    if (filters.eventName) {
      const eventNames = filters.eventName
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      data = data.filter(
        (user) =>
          !user.subscribedEvents.some(
            (event) =>
              event &&
              eventNames.some((name) =>
                event.eventName.toLowerCase().includes(name)
              )
          )
      );
    }

    if (filters.groupName) {
      const groupNames = filters.groupName
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      data = data.filter(
        (user) =>
          !user.groupsJoined.some(
            (group) =>
              group &&
              groupNames.some((name) =>
                group.groupName.toLowerCase().includes(name)
              )
          )
      );
    }

    if (filters.name) {
      const names = filters.name
        .toLowerCase()
        .split(",")
        .map((name) => name.trim());
      data = data.filter(
        (user) =>
          !names.some((name) => user.firstName.toLowerCase().includes(name)) &&
          !names.some((name) => user.lastName.toLowerCase().includes(name))
      );
    }

    setFilteredData(data);
    setFilterApplied(false);
    setReverseFilterApplied(true);
    setRenderKey((prevKey) => prevKey + 1); // Update the key to force re-render
  }, [filters, groupData]);

  const clearFilters = () => {
    setFilters({
      age: "",
      eventName: "",
      groupName: "",
      name: "",
    });
    setFilteredData(groupData.nonMembersData);
    setFilterApplied(false);
    setReverseFilterApplied(false);
    setRenderKey((prevKey) => prevKey + 1); // Update the key to force re-render
  };

  const handleFilterButtonClick = () => {
    applyFilters();
  };

  const handleReverseFilterButtonClick = () => {
    applyReverseFilters();
  };

  if (loading && !groupData) return <Loader />;


  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <h1 className="block-title fs-4 fw-bolder">
                Add members to the Group: {groupData?.groupData?.groupName}
                {filterApplied && (
                  <span
                    className="badge bg-primary ms-2"
                    style={{ fontSize: "10px" }}
                  >
                    Filter Applied
                  </span>
                )}
                {reverseFilterApplied && (
                  <span
                    className="badge bg-secondary ms-2"
                    style={{ fontSize: "10px" }}
                  >
                    Reverse Filter Applied
                  </span>
                )}
              </h1>
            </div>
            <div className="row p-4 gap-2 gap-md-0">
              <div className="col-12 d-flex justify-content-between align-items-center mb-4">
                <h5>Filter Data:</h5>
                <div className="d-flex justify-content-end align-items-end flex-column flex-md-row gap-2">
                  <button
                    className="btn btn-primary"
                    onClick={handleFilterButtonClick}
                  >
                    Apply Filters
                  </button>
                  <button
                    className="btn btn-secondary ms-2"
                    onClick={handleReverseFilterButtonClick}
                  >
                    Reverse Filters
                  </button>
                  <button className="btn btn-light ms-2" onClick={clearFilters}>
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="col-12 mb-3">
                <small className="text-info">
                  Apply multiple values filter with comma seperator 41, 32 For
                  age Example
                </small>
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  name="age"
                  value={filters.age}
                  onChange={handleInputChange}
                  placeholder="Filter by Age"
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  name="eventName"
                  value={filters.eventName}
                  onChange={handleInputChange}
                  placeholder="Filter by Event Name"
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  name="groupName"
                  value={filters.groupName}
                  onChange={handleInputChange}
                  placeholder="Filter by Group Name"
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  name="name"
                  value={filters.name}
                  onChange={handleInputChange}
                  placeholder="Filter by Name"
                  className="form-control"
                />
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-12">
                <GroupLevel key={renderKey} groupData={filteredData} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Main;
