import React, { useState } from "react";
import { toast } from "react-toastify";
import NoteModel from "./NoteModel";
import { useSelector } from "react-redux";

function Events({ userData }) {
  const [selectedNote, setSelectedNote] = useState("");
  const [selectedSubscriptionParameter1, setSelectedSubscriptionParameter1] =
    useState("");
  const [selectedSubscriptionParameter2, setSelectedSubscriptionParameter2] =
    useState("");
  const [selectedMaxSubscription, setSelectedMaxSubscription] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const userinfo = useSelector((state) => state.userDetails.user);

  function getBadgeClass(state) {
    switch (state) {
      case "Waiting Subscription":
        return "bg-warning";
      case "Subscription Opened":
        return "bg-success";
      case "Event Started":
        return "bg-primary";
      default:
        return "bg-danger";
    }
  }

  const convertDateFormat = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const unsubscribe = async (eventId) => {
    const url = `${process.env.REACT_APP_API_URL}/user/eventSubscription/deleteByParams`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        userId: userData?.data?.id,
        eventId,
        moneyOperator: userinfo?.id,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Unsubscribed successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(data.message || "Unsubscription failed");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const confirmRole = async (eventId) => {
    const url = `${process.env.REACT_APP_API_URL}/user/eventSubscription/changeConfirmRole`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        recordId: eventId,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Role Confirmed successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(data.message || "Unable to Confirm Role");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleViewNote = (
    note,
    subscriptionParameter1,
    subscriptionParameter2,
    maxSubscription,
    event
  ) => {
    setSelectedNote(note);
    setSelectedSubscriptionParameter1(subscriptionParameter1);
    setSelectedSubscriptionParameter2(subscriptionParameter2);
    setSelectedMaxSubscription(maxSubscription);
    setSelectedEvent(event);
  };

  return (
    <div className="block block-rounded">
      <div className="col-lg-12">
        <h1 className="block-title fs-4 fw-bolder mb-3">Events</h1>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table
          id="userEventsDataTable"
          className="js-table-checkable table table-hover table-vcenter"
        >
          <thead>
            <tr>
              <th>EVENT NAME</th>
              <th>START DATE</th>
              <th>TAG</th>
              <th>ROLE</th>
              <th>STATE</th>
              <th className="text-center">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {userData?.data?.subscribedEvents?.map((event, index) => (
              <tr key={index}>
                <td>{event?.eventId?.eventName}</td>
                <td>{convertDateFormat(event?.eventId?.startDate)}</td>
                <td>
                  <span
                    className={`badge rounded ${
                      event?.eventId?.tag?.value === "yellow"
                        ? "text-black"
                        : ""
                    }`}
                    style={{ backgroundColor: event?.eventId?.tag?.value }}
                  >
                    {event?.eventId?.tag?.key}
                  </span>
                </td>
                <td>
                  {event?.personalizedRole?.name +
                    " (" +
                    event?.personalizedRole?.userPolicy +
                    ")"}
                </td>
                <td>
                  <span
                    className={`badge rounded ${getBadgeClass(
                      event?.eventId?.subscriptionState
                    )}`}
                  >
                    {event?.eventId?.subscriptionState}
                  </span>
                </td>
                <td>
                  {event?.confirmRole ? (
                    ""
                  ) : (
                    <button
                      onClick={() => confirmRole(event?.id || event?._id)}
                      className="btn btn-primary text-nowrap my-1"
                    >
                      Confirm Role
                    </button>
                  )}
                  <button
                    onClick={() =>
                      handleViewNote(
                        event?.note,
                        event?.subscriptionParam1,
                        event?.subscriptionParam2,
                        event?.eventId?.maxSubscription,
                        event?.eventId
                      )
                    }
                    data-bs-toggle="modal"
                    data-bs-target="#modal-note"
                    className="btn btn-primary text-nowrap mb-1"
                  >
                    View Note
                  </button>
                  <button
                    onClick={() => unsubscribe(event?.eventId?.id)}
                    className="btn btn-primary text-nowrap"
                  >
                    Unsubscribe
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <NoteModel
        note={selectedNote}
        subscriptionParameter1={selectedSubscriptionParameter1}
        subscriptionParameter2={selectedSubscriptionParameter2}
        maxSubscription={selectedMaxSubscription}
        selectedEvent={selectedEvent}
      />
    </div>
  );
}

export default Events;
