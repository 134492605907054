import React from "react";
import Navbar from "../components/Common/Navbar";
import Header from "../components/Common/Header";
import EventSummary from "../components/events/EventSummary";

function EventsSummary() {
  return (
    <div
      id="page-container"
      className="sidebar-o enable-page-overlay side-scroll page-header-modern main-content-boxed"
    >
      <Navbar />
      <Header />
      <EventSummary />
    </div>
  );
}

export default EventsSummary;
