import moment from "moment";
import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function ChatWindow({ selectedUser, messages, addMessage }) {
  const [newMessage, setNewMessage] = React.useState("");
  const endOfMessagesRef = useRef(null);

  console.log(selectedUser);

  const user = useSelector((state) => state.userDetails.user);
  const userId = user.id;

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    console.log(selectedUser.receiverIds)

    const url = `${process.env.REACT_APP_API_URL}/admin/message/addRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        chatId: selectedUser.id,
        senderId: userId,
        message: newMessage,
        receiverIds: JSON.stringify(selectedUser.receiverIds),
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status !== "success") {
        toast.success("Something went wrong");
      } else {
        console.log(data.data);
        addMessage({
          text: newMessage,
          timestamp: moment().fromNow(),
          user: data.data.senderName,
          userId: data.data.senderId,
        });
        
        setNewMessage("");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred: " + error.message);
    }
  };

  useEffect(() => {
    if (endOfMessagesRef.current) {
      // Adjust scrolling to be slower
      const timer = setTimeout(() => {
        endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100); // Delay the scroll to slow it down
      return () => clearTimeout(timer);
    }
  }, [messages]);

  return (
    <div className="chat">
      <div className="chat-header clearfix">
        <div className="row">
          <div className="col-lg-6 d-flex">
            <img src={selectedUser.avatar} alt="avatar" />
            <div className="chat-about">
              <h6 className="mb-0">{selectedUser.name}</h6>
              {selectedUser.type === "event" ? (
                <div
                  className={`badge rounded ${
                    selectedUser.tag.value === "yellow" ? "text-black" : ""
                  }`}
                  style={{
                    backgroundColor: selectedUser.tag.value,
                    color: "white",
                  }}
                >
                  {selectedUser.tag.key}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="chat-history"
        style={{ overflowY: "auto", height: "500px" }}
      >
        <ul className="mb-0">
          {messages &&
            messages.map((msg, index) => {
              return (
                <li
                  key={index}
                  className={`clearfix ${
                    msg.userId === userId ? "text-end" : ""
                  }`}
                >
                  <div className="message-data">
                    <span className="message-data-time">
                      {msg.timestamp} By <strong>{msg.user}</strong>
                    </span>
                  </div>
                  <div
                    className={`message ${
                      msg.userId === userId ? "my-message" : "other-message"
                    }`}
                  >
                    {msg.text}
                  </div>
                </li>
              );
            })}
          <div ref={endOfMessagesRef} />
        </ul>
      </div>
      <div className="chat-message clearfix">
        {user.role && user.role === "0" ? (
          <div className="input-group mb-0">
            <input
              type="text"
              className="form-control"
              placeholder="Enter text here..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
            />
            <button onClick={handleSendMessage} className="input-group-text">
              Send
            </button>
          </div>
        ) : selectedUser.type !== "user" && selectedUser.senderId !== userId ? (
          ""
        ) : (
          <div className="input-group mb-0">
            <input
              type="text"
              className="form-control"
              placeholder="Enter text here..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
            />
            <button onClick={handleSendMessage} className="input-group-text">
              Send
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatWindow;
