import React from "react";
import { toast } from "react-toastify";
import moment from "moment";

function EntriesModel({ allUsers, setChangeStatus }) {
  function getCurrentTimestampInItaly() {
    const now = new Date();
    const options = {
      timeZone: "Europe/Rome",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const formatter = new Intl.DateTimeFormat("en-GB", options);
    const formattedDate = formatter.format(now);
    return formattedDate;
  }

  const handleSubmit = async (type, firstName, lastName, rfid) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/entriesExit/addRecord`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        timestamp: getCurrentTimestampInItaly(),
        firstName: firstName,
        lastName: lastName,
        state: type,
        rfid: rfid,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Users Added to Entries/Exit Successfully!");
        setChangeStatus((prev) => !prev);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="modal"
      id="modal-large20"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lggg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Entries/Exit Record</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => window.location.reload()}
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="my-3">
              <div className="container">
                <div className="block-content ">
                  <div>
                    <table
                      id="manualDataTable"
                      className="js-table-checkable table table-hover table-vcenter table-responsive"
                    >
                      <thead>
                        <tr>
                          <th>FIRST NAME</th>
                          <th>LAST NAME</th>
                          <th>DATE OF BIRTH</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allUsers?.map((user, index) => (
                          <tr key={index}>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{moment(user.birthday).format('DD/MM/YYYY')}</td>
                            <td>
                              <button
                                className="badge p-2 px-3"
                                style={{
                                  backgroundColor: " #FBC054",
                                  border: "2px solid #F28C28",
                                }}
                                onClick={() =>
                                  handleSubmit(
                                    "IN",
                                    user.firstName,
                                    user.lastName,
                                    user.rfid
                                  )
                                }
                              >
                                IN
                              </button>
                              <button
                                className="badge ms-md-2 ms-0 mt-md-0 mt-2  p-2 px-3"
                                style={{
                                  backgroundColor: " #FBC054",
                                  border: "2px solid #F28C28",
                                }}
                                onClick={() =>
                                  handleSubmit(
                                    "OUT",
                                    user.firstName,
                                    user.lastName,
                                    user.rfid
                                  )
                                }
                              >
                                OUT
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntriesModel;
