import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import SubscriptionModel from "./SubscriptionModel";
import Loader from "../Common/Loader";
import { useSelector } from "react-redux";

function EventDetail() {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);
  const [eventElements, setEventElements] = useState([]);
  const userinfo = useSelector((state) => state.userDetails.user);
  const [subscribedEvents, setSubscribedEvents] = useState([]);
  const [disableSubscription, setDisableSubscription] = useState(false);
  const [messages, setMessages] = useState([]);

  const navigate = useNavigate();

  const currentUserId = useSelector((state) => state.userDetails.user.id);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/event/getById/${id}?userId=${userinfo?.id}`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.status === "success") {
          setFormData(result.data);
        } else {
          console.error("Error fetching data:", result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id, userinfo]);

  useEffect(() => {
    const fetchEvents = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/event/getAll`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        const eventsArray = result.data || [];
        if (!Array.isArray(eventsArray)) {
          throw new Error("Events data is not an array");
        }
        const incompatibleEventIds = formData?.incompatibleEvents || [];
        const filteredEvents = eventsArray.filter((event) =>
          incompatibleEventIds.includes(event.id)
        );
        const elements = filteredEvents.map((event, index) => (
          <tr key={index}>
            <td>{event.eventName}</td>
            <td>{convertDateFormat(event.startDate)}</td>
            <td>{convertDateFormat(event.endDate)}</td>
          </tr>
        ));

        setEventElements(elements);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    if (formData) {
      fetchEvents();
    }
  }, [formData]);

  const activateChat = async (receiverId) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/chat/addRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        senderId: currentUserId,
        receiverId,
        chatType: "user",
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status !== "success") {
        toast.success("Something went wrong");
      } else {
        navigate(`/chat?active=${data.data._id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred: " + error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/eventSubscription/getAll?userId=${userinfo.id}`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.status === "success") {
          setSubscribedEvents(result.data.subscriptionData);
        } else {
          console.error("Error:", result.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [userinfo]);

  useEffect(() => {
    let disable = false;
    let messageArray = [];
    const currentDate = new Date();

    // Check Subscription Date Range
    if (formData?.subscriptionStartDate && formData?.subscriptionEndDate) {
      const subscriptionStatus = isCurrentDateInRange(
        formData.subscriptionStartDate,
        formData.subscriptionEndDate
      );

      if (subscriptionStatus === "notStarted") {
        disable = true;
        if (messageArray.length === 0) messageArray.push("Subscription Not Started Yet");
      } else if (subscriptionStatus === "expired") {
        disable = true;
        if (messageArray.length === 0) messageArray.push("Subscription Expired");
      }
    } else {
      const currentDate = new Date();
      const end = new Date(formData?.endDate);

      if (currentDate > end) {
        disable = true;
        if (messageArray.length === 0) messageArray.push("Event Ended");
      }
    }

    // Check Age Range
    if (
      userinfo.age &&
      (userinfo.age < calculateAge(formData?.subscriberMaxAge) ||
        userinfo.age > calculateAge(formData?.subscriberMinAge))
    ) {
      disable = true;
      if (messageArray.length === 0) messageArray.push("Age is out of Range");
    }

    // Incompatible Events Validation
    if (
      formData?.incompatibleEvents.length > 0 &&
      !checkIncompatibilityOfEvents(
        formData?.incompatibleEvents,
        subscribedEvents
      )
    ) {
      disable = true;
      if (messageArray.length === 0) messageArray.push("This is not Compatible with the current one");
    }

    // Check Father Event
    if (
      formData?.fatherEvent &&
      !checkFatherEvent(formData?.fatherEvent?.id, subscribedEvents)
    ) {
      disable = true;
      if (messageArray.length === 0) messageArray.push("Father Event not Subscribed");
    }

    // Check Already Subscribed
    if (formData?.subscribed) {
      disable = true;
    }

    // Check Max Subscribers
    if (
      formData?.maxSubscribers &&
      formData?.subscriptions >= formData?.maxSubscribers
    ) {
      disable = true;
      if (messageArray.length === 0) messageArray.push("Max Subscribers reached");

      if (formData.subscriptionState !== "Subscription Closed") {
        formData.subscriptionState = "Subscription Closed";
        updateSubscriptionState(formData.id, "Subscription Closed");
      }
    }

    // Compare Event Start Date with Current Date
    if (formData?.startDate && new Date(formData.startDate) <= currentDate) {
      if (formData.subscriptionState !== "Event Started") {
        formData.subscriptionState = "Event Started";
        updateSubscriptionState(formData.id, "Event Started");
      }
    }

    // Compare Event End Date with Current Date (end of the event date)
    const endDate = new Date(formData?.endDate);
    endDate.setDate(endDate.getDate() + 1); // Add one day to include the end date fully
    if (formData?.endDate && currentDate >= endDate) {
      if (formData.subscriptionState !== "Event Ended") {
        formData.subscriptionState = "Event Ended";
        updateSubscriptionState(formData.id, "Event Ended");
      }
    }

    setDisableSubscription(disable);
    setMessages(messageArray);
  }, [formData, userinfo, subscribedEvents]);

  const updateSubscriptionState = async (id, state) => {
    const url = `${process.env.REACT_APP_API_URL}/user/event/updateRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ id, subscriptionState: state }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const isCurrentDateInRange = (startDate, endDate) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    end.setDate(end.getDate() + 1);

    if (isNaN(start) || isNaN(end)) {
      return false;
    }

    if (currentDate < start) {
      return "notStarted";
    } else if (currentDate > end) {
      return "expired";
    }

    return "inRange";
  };

  const convertDateFormat = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    const dayDiff = today.getDate() - birth.getDate();
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    return age;
  };

  const checkIncompatibilityOfEvents = (idsArray, objectsArray) => {
    for (let id of idsArray) {
      for (let obj of objectsArray) {
        if (id === obj.eventId?.id) {
          return false;
        }
      }
    }
    return true;
  };

  const checkFatherEvent = (fatherId, objectsArray) => {
    for (let obj of objectsArray) {
      if (fatherId === obj.eventId?.id || fatherId === obj.eventId?._id) {
        return true;
      }
    }
    return false;
  };

  if (!formData) {
    return <Loader />;
  }

  function getBadgeClass(state) {
    switch (state) {
      case "Waiting Subscription":
        return "bg-warning";
      case "Subscription Opened":
        return "bg-success";
      case "Event Started":
        return "bg-primary";
      case "Event Ended":
        return "bg-danger";
      case "Subscription Closed":
        return "bg-danger";
      default:
        return "bg-danger";
    }
  }

  const handleUnsubscribeEvent = async (eventId) => {
    const eventUnsubbscribeConfirmed = window.confirm(
      "Are you sure you want to Unsubscribe this Event?"
    );
    if (!eventUnsubbscribeConfirmed) {
      return;
    }
    const url = `${process.env.REACT_APP_API_URL}/user/eventSubscription/deleteByParams`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        userId: userinfo?.id,
        eventId,
        moneyOperator: userinfo?.id,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Event Unsubscribed successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(data.message || "Unsubscription failed");
      }
    } catch (error) {
      console.error(error);
    }
  };


function checkAge(date) {
  if (!date) return null;
  const birthDate = new Date(date);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())  
  ) {
    age--;
  }

  return age;
}

function AgeRange() {
  const minAge = checkAge(formData?.subscriberMinAge);
  const maxAge = checkAge(formData?.subscriberMaxAge);

  const [lowerAge, higherAge] =
    minAge && maxAge
      ? [Math.min(minAge, maxAge), Math.max(minAge, maxAge)]
      : [minAge, maxAge];

  return (
    <h5 className="fw-medium">
      {lowerAge && higherAge
        ? `From ${lowerAge} years to ${higherAge} years old`
        : lowerAge
        ? `Minimum age: ${lowerAge} years`
        : higherAge
        ? `Maximum age: ${higherAge} years`
        : "For All ages"}
    </h5>
  );
}

  return (
    <main id="main-container">
      <div className="content">
        <div className="block block-rounded">
          <div className="block-header block-header-default">
            <i className="fa fa-calendar me-3 text-warning"></i>
            <h1 className="block-title fs-4 fw-bolder">Event Summary</h1>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="p-5">
                <h1 className="block-title fs-1 text-center fw-bolder">
                  {formData?.eventName}
                </h1>
                <div className="row mt-4">
                  {formData?.eventImages?.map((imageSrc, index) => (
                    <div key={index} className="col-md-6 text-center">
                      <img
                        className="rounded"
                        src={imageSrc}
                        width={"60%"}
                        height={"250px"}
                        alt={`Event ${index}`}
                      />
                    </div>
                  ))}
                </div>
                <div
                  className="text-md-center my-5"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify?.sanitize(formData?.description),
                  }}
                />
                <div className="row mt-4">
                  <div className="col-md-6">
                    <h1 className="block-title fs-4 fw-bolder text-start">
                      Event Details
                    </h1>
                    <span
                      className={`fw-medium mt-5 badge rounded fs-6 ${
                        formData?.tag.value === "yellow" ? "text-black" : ""
                      }`}
                      style={{ backgroundColor: formData?.tag.value }}
                    >
                      {formData?.tag.key}
                    </span>

                    <div className="mt-5">
                      <h5 className="fw-medium">Event Duration:</h5>
                      <h5 className="fw-medium">
                        {convertDateFormat(formData?.startDate)} {"to "}
                        {convertDateFormat(formData?.endDate)}
                      </h5>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium"> Event Time:</h5>
                      <h5 className="fw-medium">
                        {formData?.startHour} to {formData?.endHour}
                      </h5>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">
                        Event Location : {formData?.eventLocation}
                      </h5>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">Father Event :</h5>
                      {formData?.fatherEvent ? (
                        <div>
                          <p>{formData?.fatherEvent?.eventName}</p>
                          <p>
                            {convertDateFormat(
                              formData?.fatherEvent?.startDate
                            )}{" "}
                            to{" "}
                            {convertDateFormat(formData?.fatherEvent?.endDate)}
                          </p>
                        </div>
                      ) : (
                        <p>No Father Event Found</p>
                      )}
                    </div>
                    {userinfo?.role === "4" || userinfo?.role === "5" ? (
                      ""
                    ) : (
                      <div className="mt-5">
                        <h5 className="fw-medium">Incompatible Event:</h5>
                        <table
                          id="eventDataTable"
                          className="table table-bordered table-striped table-vcenter table-responsive"
                        >
                          <thead>
                            <tr>
                              <th>EVENT NAME</th>
                              <th>START DATE</th>
                              <th>END DATE</th>
                            </tr>
                          </thead>
                          <tbody>{eventElements}</tbody>
                        </table>
                      </div>
                    )}
                    <div className="mt-5">
                      <h5 className="fw-medium">Recurring Event:</h5>
                      <div className="space-y-2 my-3 ">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={formData?.recurringEvent}
                            disabled
                          />
                        </div>
                      </div>
                      {formData?.recurringEvent && (
                        <div>
                          {formData?.recurringDays.map((day, index) => (
                            <div key={index} className="space-y-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={true}
                                  id={`example-checkbox-default${index}`}
                                />
                                <label
                                  className="form-check-label ms-3"
                                  htmlFor={`example-checkbox-default${index}`}
                                >
                                  {day}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {userinfo?.role === "4" || userinfo?.role === "5" ? (
                      ""
                    ) : (
                      <div className="mt-5">
                        <h5 className="fw-medium">
                          Balance Name:{" "}
                          {formData?.balanceSelection?.balanceName}
                        </h5>
                        <h5 className="fw-medium">
                          Balance Description:{" "}
                          {formData?.balanceSelection?.description}
                        </h5>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <h1 className="block-title fs-4 fw-bolder text-start">
                      Subscription Details
                    </h1>
                    <div className="mt-5">
                      <h5 className="fw-medium">
                        Subscription State :{" "}
                        <div
                          className={`badge ${getBadgeClass(
                            formData?.subscriptionState
                          )}`}
                        >
                          {formData?.subscriptionState}
                        </div>
                      </h5>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">Subscription Duration:</h5>
                      <h5 className="fw-medium">
                        {convertDateFormat(formData?.subscriptionStartDate)}{" "}
                        {formData?.startDate !== "" && formData?.endDate !== ""
                          ? "to "
                          : ""}
                        {convertDateFormat(formData?.subscriptionEndDate)}
                      </h5>
                    </div>
                    <div className="mt-5">
                      <h5 className="fw-medium">Age Of Subscriber :</h5>
                      <h5 className="fw-medium">{AgeRange()}</h5>
                    </div>
                    {userinfo?.role === "4" || userinfo?.role === "5" ? (
                      ""
                    ) : (
                      <div>
                        <div className="mt-5">
                          <h5 className="fw-medium">
                            Event Subscription Parameter :{" "}
                            {formData?.subscriptionParameter1}
                          </h5>
                          <h5 className="fw-medium">
                            Event Subscription Parameter :{" "}
                            {formData?.subscriptionParameter2}
                          </h5>
                        </div>
                        <div className="mt-5">
                          <h5 className="fw-medium">
                            Allow User to Unsubscribe :
                          </h5>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={formData?.allowUnsubscription}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="mt-5">
                          <h5 className="fw-medium">Multiple Subscription</h5>
                          <div className="space-y-2 mt-3">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={formData?.mutipleSubscription}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="mt-4">
                            <h5 className="fw-medium">
                              Max number of seats reserved:{" "}
                              {formData?.maxSubscription || "10000"}
                            </h5>
                          </div>
                        </div>
                        <div className="mt-5">
                          <h5 className="fw-medium">
                            Apply Personal Discount :{" "}
                          </h5>
                          <div className="space-y-2 mt-3">
                            <div className="space-y-2 mt-3">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={formData?.applyPersonalDiscount}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5">
                          <h5 className="fw-medium">
                            Maximum Subscriber : {formData?.maxSubscribers}
                          </h5>
                          <h5 className="fw-medium">
                            Number of users subscribed :{" "}
                            {formData?.subscriptions}
                          </h5>
                        </div>
                      </div>
                    )}
                  </div>
                  {userinfo?.role === "4" || userinfo?.role === "5" ? (
                    ""
                  ) : (
                    <div className="col-12">
                      <div className="mt-5">
                        <h5 className="fw-medium">Personalized Role</h5>
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>NAME</th>
                              <th>DESCRIPTION</th>
                              <th>PRICE</th>
                              <th>USER POLICY</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData?.personalizedRoles &&
                            formData?.personalizedRoles.length > 0 ? (
                              formData?.personalizedRoles.map((role, index) => (
                                <tr key={index}>
                                  <td>{role.name}</td>
                                  <td>{role.description}</td>
                                  <td>{role.price},00 €</td>
                                  <td>{role.userPolicy}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="4" className="text-center">
                                  No data entered
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                <div className="my-6">
                  <h5 className="fw-medium">Documents </h5>
                  <div className="d-flex justify-content-center bg-body text-center rounded mt-2">
                    {formData?.eventFiles &&
                      formData?.eventFiles.map((imageSrc, index) => (
                        <a
                          key={index}
                          target="_blank"
                          without
                          rel="noreferrer"
                          href={imageSrc}
                          download={`optional-value-${index}`}
                        >
                          <img
                            className="rounded m-2"
                            src="/assets/media/pdf.png"
                            width={"110"}
                            height={"auto"}
                            alt={`Event ${index}`}
                          />
                        </a>
                      ))}
                  </div>
                </div>

                <div className="mb-4 row">
                  <div className="col-md-3 order-2 order-md-0 ">
                    <Link
                      to="/events"
                      className="btn btn-lg btn-secondary px-4 w-100 "
                    >
                      BACK
                    </Link>
                  </div>
                  <div className="col-md-3 col-12 order-0 order-md-1 offset-md-3">
                    <button
                      onClick={() => activateChat(formData.authorId)}
                      className="btn btn-lg btn-alt-primary me-3 w-100"
                    >
                      Write to Event Manager
                    </button>
                  </div>
                  {formData?.allowUnsubscription && formData?.subscribed ? (
                    <div className="col-md-3 col-12 order-1 order-md-2 my-md-0 my-3">
                      <button
                        type="button"
                        className={`btn btn-lg btn-danger w-100`}
                        onClick={() => handleUnsubscribeEvent(formData?.id)}
                      >
                        Unsubscribe the Event
                      </button>
                    </div>
                  ) : (
                    <div className="col-md-3 col-12 order-1 order-md-2 my-md-0 my-3">
                      <button
                        disabled={disableSubscription}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-subscription"
                        className={`btn btn-lg ${
                          formData?.subscribed
                            ? "btn-alt-danger"
                            : "btn-alt-primary"
                        } w-100`}
                      >
                        {formData?.subscribed
                          ? "Event Subscribed"
                          : "Subscribe to Event"}
                      </button>
                    </div>
                  )}
                </div>
                <div>
                  {messages.map((message, index) => (
                    <div key={index} className="alert alert-danger">
                      {message}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <SubscriptionModel formData={formData} />
      </div>
    </main>
  );
}

export default EventDetail;
