import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import { setUser } from "../../redux/actions/userProfile";
import "react-toastify/dist/ReactToastify.css";

function ProfilePic() {
  const userinfo = useSelector((state) => state.userDetails.user);
  const fileInputRef = useRef(null);
  const cropperRef = useRef(null);
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      const modal = new window.bootstrap.Modal(
        document.getElementById("cropModal")
      );
      modal.show();
    };
    reader.readAsDataURL(file);
  };

  const handleCrop = () => {
    const cropper = cropperRef.current.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      setCroppedImage(blob);
    });
  };

  const handleUpload = async () => {
    if (!croppedImage) {
      toast.error("Please crop the image before uploading.");
      return;
    }

    const formData = new FormData();
    formData.append("image", croppedImage, "profile.jpg");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/utils/uploadImage`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        updateProfilePic(data.data.image);
      } else {
        throw new Error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error(error.message || "Error uploading image");
    }
  };

  const updateProfilePic = async (profilePicUrl) => {
    const url = `${process.env.REACT_APP_API_URL}/user/user/updateProfilePic`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        recordId: userinfo?.id,
        profilePic: profilePicUrl,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        dispatch(setUser(data.data));
        toast.success("Profile picture updated successfully.");
        setImage(null);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(data.message || "Failed to update profile picture");
      }
    } catch (error) {
      console.error("Error updating profile picture:", error);
      toast.error(error.message || "Error updating profile picture");
    }
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="bg-image bg-image-bottom">
      <div className="py-4">
        <div className="content content-full text-center">
          <div className="mb-2">
            <img
              className="img-avatar img-avatar96 img-avatar-thumb"
              src={userinfo?.profilePic || "assets/media/avatars/avatar15.jpg"}
              alt="profile"
            />
          </div>
          <h4 className="text-black mb-2">
            {`${userinfo?.firstName}\u00A0${userinfo?.lastName}`}
          </h4>
          <div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept="image/*"
            />
            <div
              className="btn btn-primary rounded edit-btn"
              onClick={handleFileInputClick}
            >
              <i className="fa fa-fw fa-pencil-alt opacity-50 mb-1"></i> Edit
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="cropModal"
        tabIndex="-1"
        aria-labelledby="cropModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="block block-rounded shadow-none mb-0">
              <div className="block-header block-header-default">
                <h5 className="modal-title" id="cropModalLabel">
                  Crop Image
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="container d-flex justify-content-center p-3">
                <Cropper
                  style={{ width: "100%!important", height: "100%!important" }}
                  src={image}
                  ref={cropperRef}
                  crop={handleCrop}
                  viewMode={2}
                  minCropBoxHeight={120}
                  minCropBoxWidth={120}
                  minContainerWidth={300}
                  minContainerHeight={300}
                  autoCropArea={1}
                  initialAspectRatio={1}
                  checkOrientation={true}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleUpload}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePic;
