import React from "react";
import SliderHome from "./SliderHome";
import Form from "./Form";

function Main() {
  return (
    <div id="page-container" className="main-content-boxed">
      <main
        id="main-container"
        className="d-flex justify-content-center align-items-center"
      >
        <div className="content">
          <div className="block block-rounded bg-custom ">
            <div className="container">
              <div className="row">
                <div className="col-md-7 overflow-auto">
                  <SliderHome />
                </div>
                <Form />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Main;
