import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

function Token() {
  const params = useParams();
  const navigate = useNavigate();
  const [mainMessage, setMainMessage] = useState("Verifying...");
  const [responseMessage, setResponseMessage] = useState(
    "Verifying your email..."
  );
  const [redirectMessage, setRedirectMessage] = useState("Wait a Moment");

  useEffect(() => {
    const verifyEmail = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/user/verifyEmailFromToken`;
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({ emailToken: params.token }),
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.status ==="success") {
          setMainMessage("Verified");
          setResponseMessage("Email verified successfully!");
          setRedirectMessage("Redirecting to login page...");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        } else {
          setMainMessage("Failed");
          setResponseMessage("Token Expired or Already Verified");
          setRedirectMessage("Redirecting to login page...");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
      } catch (error) {
        console.error(error);
        setMainMessage("Error");
        setResponseMessage("An error occurred while verifying the email.");
        setRedirectMessage("Redirecting...");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    };

    if (params.token) {
      verifyEmail();
    } else {
      setMainMessage("Try Again");
      setResponseMessage("No Authorization provided.");
      setRedirectMessage("Redirecting ...");
      setTimeout(() => {
        navigate("/error");
      }, 3000);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div id="page-container" className="main-content-boxed">
      <main id="main-container">
        <div className="hero bg-body-extra-light">
          <div className="hero-inner">
            <div className="content content-full">
              <div className="py-4 text-center">
                <div className="display-1 fw-bold text-primary">
                  <i className="fa fa-envelope-open-text opacity-50 me-1"></i>{" "}
                  {mainMessage}
                </div>
                <h1 className="fw-bold mt-5 mb-2">{responseMessage}</h1>
                <h2 className="fs-4 fw-medium text-muted mb-5">
                  {redirectMessage}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Token;
