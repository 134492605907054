import React, { useEffect, useState } from "react";
import ProfileSection from "./ProfileSection";
import GeneralSetting from "./GeneralSetting";
import { useParams } from "react-router-dom";
import $ from "jquery";

function Main() {
  const { id: userId } = useParams();
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/user/getById/${userId}`;
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    if (userData?.data?.subscribedEvents.length > 0) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const table = $("#userEventsDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
        sort: false,
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        table.destroy();
      };
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.data?.walletHistory.length > 0) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const table = $("#userWalletHistoryDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
        sort: false,
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        table.destroy();
      };
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.data?.groupsJoined.length > 0) {
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          lengthMenu: "_MENU_",
          search: "_INPUT_",
          searchPlaceholder: "Search..",
          info: "Page <strong>_PAGE_</strong> of <strong>_PAGES_</strong>",
          paginate: {
            first: '<i class="fa fa-angle-double-left"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
          },
        },
      });

      const table = $("#userGroupJoinedDataTable").DataTable({
        autoWidth: false,
        responsive: false,
        retrieve: true,
        sort: false,
      });

      $(".dt-search input").removeClass("form-control-sm");
      $(".dt-search input").addClass("form-control-lg");

      $(".dt-length select").removeClass("form-select-sm");
      $(".dt-length select").addClass("form-select-lg");

      return () => {
        table.destroy();
      };
    }
  }, [userData]);

  return (
    <div>
      <main id="main-container">
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <i className="fa  fa-user me-3 text-warning"></i>
              <h1 className="block-title fs-4 fw-bolder">Edit Users</h1>
            </div>
            <div className="row">
              <div className="col-md-4">
                <ProfileSection userDetails={userData} />
              </div>
              <div className="col-md-8">
                <GeneralSetting userDetails={userData} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Main;
