import React, { useEffect } from "react";
import $ from "jquery";

function TableUser({
  users,
  selectedUsers,
  handleUserSelection,
  filters,
  setFilters,
  clearFilters,
  applyFilters,
  applyReverseFilters,
}) {
  useEffect(() => {
    const table = $("#userDataTable").DataTable({
      autoWidth: false,
      responsive: true,
      destroy: true,
      sort: false,
    });

    return () => {
      table.destroy();
    };
  }, [users]);

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const ageDiff = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  return (
    <div className="container">
      <div className="block-content">
        <h5>Select Users</h5>
        <div className="row mb-4">
          <div className="col-12 mb-3">
            <small className="text-info">
              Apply multiple values filter with comma seperator 41, 32 For age Example 
            </small>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              name="age"
              value={filters.age}
              onChange={handleInputChange}
              placeholder="Filter by Age"
              className="form-control"
            />
          </div>
          <div className="col-md-3">
            <input
              type="text"
              name="eventName"
              value={filters.eventName}
              onChange={handleInputChange}
              placeholder="Filter by Event Name"
              className="form-control"
            />
          </div>
          <div className="col-md-3">
            <input
              type="text"
              name="groupName"
              value={filters.groupName}
              onChange={handleInputChange}
              placeholder="Filter by Group Name"
              className="form-control"
            />
          </div>
          <div className="col-md-3">
            <input
              type="text"
              name="name"
              value={filters.name}
              onChange={handleInputChange}
              placeholder="Filter by Name"
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 d-flex flex-column flex-md-row gap-3 justify-content-end">
            <button className="btn btn-primary" onClick={applyFilters}>
              Apply Filters
            </button>
            <button className="btn btn-secondary" onClick={applyReverseFilters}>
              Reverse Filters
            </button>
            <button className="btn btn-light" onClick={clearFilters}>
              Clear Filters
            </button>
          </div>
        </div>
        <div style={{ overflowX: "auto" }}>
          <table
            id="userDataTable"
            className="js-table-checkable table table-hover table-vcenter table-responsive"
          >
            <thead>
              <tr>
                <th></th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Age</th>
                <th>Events</th>
                <th>Group Members</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(user.id)}
                      onChange={() => handleUserSelection(user.id)}
                    />
                  </td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{calculateAge(user.birthday)}</td>
                  <td>
                    {user?.subscribedEvents?.map((event, eventIndex) => (
                      <div key={eventIndex}>{event?.eventName}</div>
                    ))}
                  </td>
                  <td>
                    {user?.groupsJoined?.map((group, groupIndex) => (
                      <div key={groupIndex}>{group?.groupName}</div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TableUser;
