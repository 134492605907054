import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function CheckinglistModal({ groupMemberData, allUsers }) {
  const [rfid, setRfid] = useState("");
  const [scannedUser, setScannedUser] = useState("");
  const { id } = useParams();
  const inputRef = useRef(null);

  useEffect(() => {
    const modal = document.getElementById("modal-large35");
    const handleModalShow = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    modal.addEventListener("shown.bs.modal", handleModalShow);
    return () => {
      modal.removeEventListener("shown.bs.modal", handleModalShow);
    };
  }, []);

  const handleChange = async (e) => {
    const newRfid = e.target.value;
    setRfid(newRfid);

    if (newRfid.length === 10) {
      const user = allUsers.find((user) => user.rfid === newRfid);
      if (user) {
        setScannedUser(user);
        const groupMember = groupMemberData.find(
          (member) => member.userId.id === user.id
        );
        if (groupMember) {
          if (groupMember.progressiveFlag === 0) {
            const url = `${process.env.REACT_APP_API_URL}/admin/groupMember/updateRecord`;
            const options = {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: new URLSearchParams({
                id: groupMember.id,
                progressiveFlag: "1",
              }),
            };

            try {
              const response = await fetch(url, options);
              const data = await response.json();
              if (data.status === "success") {
                toast.success("User Presence Updated Successfully");
                setRfid("");
              } else {
                toast.error("Something went wrong");
              }
            } catch (error) {
              console.error(error);
            }
          } else {
            toast.error("User Already Checked");
            setRfid("");
          }
        } else {
          const url = `${process.env.REACT_APP_API_URL}/admin/groupMember/addRecord`;
          const options = {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({
              userId: user.id,
              groupId: id,
              progressiveFlag: "2",
            }),
          };

          try {
            const response = await fetch(url, options);
            const data = await response.json();
            if (data.status === "success") {
              toast.error("Member not Present in Group");
              setRfid("");
            }
          } catch (error) {
            console.error(error);
          }
        }
      } else {
        toast.error("User Not Found");
        setScannedUser("");
        setRfid("");
      }
    }
  };

  return (
    <div
      className="modal"
      id="modal-large35"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal2-large"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="block block-rounded shadow-none mb-0">
            <div className="block-header block-header-default">
              <h3 className="block-title fw-bold">Checking List</h3>
              <div className="block-options">
                <button
                  type="button"
                  className="btn-block-option"
                  onClick={() => window.location.reload()}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times text-black"></i>
                </button>
              </div>
            </div>
            <div className="my-7">
              <div className="container">
                <div className="container-fluid">
                  <div className="row">
                    <div className="d-flex flex-column justify-content-center">
                      <div className="col-md-12">
                        <div className="mb-5">
                          <label
                            className="form-label"
                            htmlFor="example-text-input"
                          >
                            RFID Code
                          </label>
                          <input
                            type="number"
                            className="form-control form-control-lg"
                            value={rfid}
                            onChange={handleChange}
                            name="rfid"
                            placeholder="0123456789"
                            ref={inputRef}
                          />
                        </div>
                      </div>
                      {scannedUser && (
                        <div className="col-12">
                          <table className="table table-responsive">
                            <thead>
                              <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{scannedUser?.firstName}</td>
                                <td>{scannedUser?.lastName}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckinglistModal;
