import React from "react";
import InfoCards from "./InfoCards";
import Detail from "./Detail";

function CardMain() {
  return (
    <div id="page-container" className="container">
      <main id="main-container" className="my-5 mx-3">
        <div className=" row block block-themed block-rounded">
          <div className="text-black mt-5 ms-3 mb-3">
            <h3>Hi, John Smith</h3>
            <h6>Welcome to EASYORATORIO</h6>
          </div>
          <InfoCards />
          <Detail/>
        </div>
      </main>
    </div>
  );
}

export default CardMain;
