import React from "react";
import ThemeSetting from "./ThemeSetting";
import GeneralSetting from "./GeneralSetting";
import RegistrationSetting from "./RegistrationSetting";
import EmailSetting from "./EmailSetting";
import PaymentSetting from "./PaymentSetting";
import UserCsv from "./UserCsv";
function SettingForm() {
  return (
    <div className="block block-rounded">
      <div className="block-header block-header-default">
        <h1 className="block-title fs-4 fw-bolder">Settings</h1>
      </div>
      <div className="container">
        <div className="block-content ">
            <GeneralSetting/>
            <ThemeSetting/>
            <RegistrationSetting/>
            <PaymentSetting/>
            <EmailSetting/>
            <UserCsv/>
        </div>
      </div>
    </div>
  );
}

export default SettingForm;
