import React from "react";
import Editor from "./Editor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Events1({ formData, onChange }) {
  const handleCustomPhoneKeyChange = (e, customPhone) => {
    const newKey = e.target.textContent;
    const newCustomPhoneData = { ...formData[customPhone], key: newKey };
    onChange({ target: { name: customPhone, value: newCustomPhoneData } });
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(e.target);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleCustomPhoneChange = (e, customPhone) => {
    const { name, value } = e.target;
    const newCustomPhoneData = { ...formData[customPhone], [name]: value };
    onChange({ target: { name: customPhone, value: newCustomPhoneData } });
  };

  const defaultLabel = "Tag";
  const handleLabelClick = (e, customPhone) => {
    const currentText = e.target.textContent;
    if (currentText === defaultLabel) {
      e.target.textContent = "";
    }
  };

  const handleFileChange = async (e, fileType) => {
    const files = Array.from(e.target.files);
    const currentFileCount =
      formData[fileType === "image" ? "eventImages" : "eventFiles"].length;
    const maxLimit = fileType === "image" ? 2 : 5;

    if (currentFileCount + files.length > maxLimit) {
      toast.error(
        `You can only upload a maximum of ${maxLimit} ${fileType} files in total.`
      );
      return;
    }

    const form = new FormData();
    files.forEach((file) => {
      form.append("file", file);
    });

    const options = {
      method: "POST",
      body: form,
      headers: {},
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/utils/uploadMultipleFiles`,
        options
      );
      const data = await response.json();
      if (response.ok) {
        const fieldName = fileType === "image" ? "eventImages" : "eventFiles";
        const urls = data.data.map((item) => item); // Extract URLs

        urls.forEach((url) => {
          onChange({
            target: {
              name: fieldName,
              value: [...formData[fieldName], url], // Add new file to the current state
            },
          });
        });

        toast.success(
          `Successfully uploaded ${files.length} ${fileType} files.`
        );
      } else {
        toast.error(`Failed to upload files: ${data.message}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(`An error occurred: ${error.message}`);
    }
  };

  // Function to remove image
  const handleRemoveImage = (index) => {
    const updatedImages = formData.eventImages.filter(
      (_, imgIndex) => imgIndex !== index
    );
    onChange({
      target: {
        name: "eventImages",
        value: updatedImages, // Update state with the new image array
      },
    });
  };

  // Function to remove file
  const handleRemoveFile = (index) => {
    const updatedFiles = formData.eventFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    onChange({
      target: {
        name: "eventFiles",
        value: updatedFiles, // Update state with the new file array
      },
    });
  };

  return (
    <div className="text-start my-md-5 my-3">
      <div className="row">
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label" htmlFor="example-text-input">
              Name Of Event*
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="eventName"
              placeholder="Name of Event"
              value={formData?.eventName}
              onChange={onChange}
              required
            />
          </div>
        </div>
        <div className="col-md-4">
          <div>
            <label className="form-label">Tag</label>
            <label className="form-label ms-5">Color</label>
          </div>
          <div className="input-group mb-4">
            <span
              className="input-group-text bg-alt-primary"
              style={{
                borderTopLeftRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                color: "white",
              }}
              contentEditable={true}
              onClick={(e) => handleLabelClick(e, "tag")}
              onInput={(e) => handleCustomPhoneKeyChange(e, "tag")}
              name="key"
            >
              {formData?.tag.key ? formData?.tag.key : defaultLabel}
            </span>
            <select
              className="form-select form-select-lg "
              aria-label=".form-select-lg example"
              name="value"
              value={formData?.tag.value}
              onChange={(e) => handleCustomPhoneChange(e, "tag")}
            >
              <option selected value="" disabled>
                Choose Color
              </option>
              <option value="red">Red</option>
              <option value="yellow">Yellow</option>
              <option value="green">Green</option>
              <option value="blue">Blue</option>
              <option value="orange">Orange</option>
              <option value="purple">Purple</option>
              <option value="brown">Brown</option>
              <option value="pink">Pink</option>
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-4">
            <label className="form-label" htmlFor="example-text-input">
              Subscription State*
            </label>
            <select
              className="form-select form-select-lg "
              aria-label=".form-select-lg example"
              name="subscriptionState"
              onChange={onChange}
              value={formData?.subscriptionState}
              required
            >
              <option selected disabled value="">
                Choose State
              </option>
              <option value="Waiting Subscription">Waiting Subscription</option>
              <option value="Subscription Opened">Subscription Opened</option>
              <option value="Subscription Closed">Subscription Closed</option>
              <option value="Event Started">Event Started</option>
              <option value="Event Ended">Event Ended</option>
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="row">
          <div className="col-md-6 overflow-hidden">
            <div className="mb-4">
              <label className="form-label" htmlFor="pdf-file-input">
                Upload PDF Files
              </label>
              <input
                className="form-control form-control-lg"
                type="file"
                accept="application/pdf"
                multiple
                id="pdf-file-input"
                onChange={(e) => handleFileChange(e, "pdf")}
              />
              <div className="d-flex justify-content-around bg-body text-center rounded mt-2">
                {formData?.eventFiles &&
                  formData?.eventFiles?.map((fileSrc, index) => (
                    <div key={index} className="position-relative">
                      {/* Cross Icon */}
                      <span
                        className="position-absolute translate-middle badge rounded-pill bg-danger"
                        style={{ cursor: "pointer", top: "20px", right: "5px" }}
                        onClick={() => handleRemoveFile(index)}
                      >
                        &times;
                      </span>
                      {/* PDF Icon */}
                      <a
                        target="_blank"
                        without
                        rel="noreferrer"
                        href={fileSrc}
                        download={`optional-value-${index}`}
                      >
                        <img
                          className="rounded m-2"
                          src="/assets/media/pdf.png"
                          width={"110"}
                          height={"auto"}
                          alt={`File ${index}`}
                        />
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-6 overflow-hidden">
            <div className="mb-4">
              <label className="form-label" htmlFor="image-file-input">
                Upload Images* (Max 2)
              </label>
              <input
                className="form-control form-control-lg"
                type="file"
                accept="image/*"
                multiple
                id="image-file-input"
                onChange={(e) => handleFileChange(e, "image")}
                required
              />
              <div className="d-flex justify-content-around bg-body text-center rounded mt-2">
                {formData?.eventImages &&
                  formData?.eventImages?.map((imageSrc, index) => (
                    <div key={index} className="position-relative">
                      {/* Cross Icon */}
                      <span
                        className="position-absolute translate-middle badge rounded-pill bg-danger"
                        style={{ cursor: "pointer", top: "20px", right: "5px" }}
                        onClick={() => handleRemoveImage(index)}
                      >
                        &times;
                      </span>
                      {/* Image */}
                      <img
                        className="rounded m-2"
                        src={imageSrc}
                        width={"200"}
                        height={"auto"}
                        alt={`Event ${index}`}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Editor formData={formData} onChange={onChange} />
    </div>
  );
}

export default Events1;
